import React from 'react';
// import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import { useStyles } from './styles';

const propTypes = {};

function ParentPageContainer({
  isLoading = false,
  children,
}) {
  const classes = useStyles();

  return (
    <>
      {isLoading && (
        <CircularIndeterminate
          type="fullPage"
          style={{ position: 'initial' }}
        />
      )}
      {!isLoading && (
        <Container className={classes.container}>
          <Switch>
            {children}
          </Switch>
        </Container>
      )}
    </>
  );
}

ParentPageContainer.propTypes = propTypes;

export default ParentPageContainer;
