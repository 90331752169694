import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 384,
    backgroundColor: theme.palette.greyC.gray,
    borderRadius: 6,
    '&:focus': {
      outline: 'none',
    },
  },
  headerContainer: {
    padding: 24,
  },
  headerStr: {
    fontWeight: 'bold',
    fontSize: theme.typography.fontSize16,
  },
  modalBody: {
    padding: 24,
    backgroundColor: theme.palette.background.default,
    borderRadius: 6,
  },
}));

export default {
  useStyles,
};
