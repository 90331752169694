import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  // necessary for content to be below app bar
  toolbar: {
    [theme.breakpoints.down('xs')]: {
      ...theme.mixins.toolbar,
    },
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    width: 'calc(100% - 240px - 300px)',
    // transition: 'width 1950ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
    },
  },
  rightPanel: {
    position: 'relative',
  },
}));

const propTypes = {
  navigation: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

function PageContainer({
  navigation,
  children,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {navigation}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
      <div id="right-panel" className={classes.rightPanel} />
    </div>
  );
}

PageContainer.propTypes = propTypes;

export default PageContainer;
