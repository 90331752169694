import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: (props) => props.drawerWidth,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    color: theme.palette.primary.contrastText,
  },
  container: {
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
    overflowY: 'auto',
  },
  // necessary for content to be below app bar
  toolbar: {
    [theme.breakpoints.down('xs')]: {
      ...theme.mixins.toolbar,
    },
  },
  drawerList: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    height: '100%',
  },
  text: {
    flex: '0 1 auto',
  },
  logoutBtn: {
    marginTop: 'auto',
  },
  navigationLink: {
    color: theme.palette.primary.contrastText,
  },
  supportLink: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
  },
  activeCustomListItemMenu: {
    '& .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary': {
      fontWeight: 'bold',
    },
  },
}));

export default {
  useStyles,
};
