import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  chartContainer: {
  },
  chartTopLegend: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 50px',
    paddingRight: 70,
  },
  chartTopLeftLegend: {
    display: 'flex',
  },
  iconLeft: {
    width: 20,
    height: 20,
    borderRadius: 4,
    backgroundColor: 'rgb(204, 229, 223)',
    border: '1px solid rgb(105, 179, 162)',
    marginRight: 7,
  },
  iconRight: {
    width: 20,
    height: 20,
    borderRadius: 4,
    backgroundColor: 'steelblue',
    border: '1px solid steelblue',
    marginLeft: 7,
  },
  titleStr: {
  },
  chartBottomLegend: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 110,
    paddingBottom: 12,
  },
  yAxisCount: {
    '& line': {
      stroke: theme.palette.greyC.gray2,
      transform: 'translateX(-6px)',
    },
    '& path': {
      stroke: theme.palette.greyC.gray2,
    },
    '& text': {
      fontSize: theme.typography.fontSize14,
      fontFamily: theme.typography.fontFamily,
    },
    '& g:first-child': {
      display: 'none',
    },
  },
  yAxisAvPrice: {
    '& line': {
      stroke: theme.palette.greyC.gray2,
      transform: 'translateX(-6px)',
    },
    '& path': {
      stroke: theme.palette.greyC.gray2,
    },
    '& text': {
      fontSize: theme.typography.fontSize14,
      fontFamily: theme.typography.fontFamily,
    },
    '& g:first-child': {
      display: 'none',
    },
  },
  xAxis: {
    '& line': {
      stroke: theme.palette.greyC.gray2,
    },
    '& path': {
      stroke: theme.palette.greyC.gray2,
    },
    '& text': {
      fontSize: theme.typography.fontSize14,
      fontFamily: theme.typography.fontFamily,
      transform: 'translateX(5px)',
    },
  },
}));

export default {
  useStyles,
};
