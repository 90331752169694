import {
  GET_OPERATORS,
  GET_ONE_OPERATOR_REQUEST,
  GET_ACTIONS_ONE_OPERATOR_REQUEST,
  ADD_NEW_OPERATOR_REQUEST,
  UPDATE_OPERATOR_REQUEST,
  REMOVE_OPERATOR_REQUEST,
  GET_LIST_FRONT_OFFICE_RIGHTS,
  GET_CURRENT_OPERATOR,
  CLEAR_CACHE_ONE_OPERATOR,
  CLEAR_CACHE_OPERATORS, GET_SUBSCRIPTIONS, CLEAR_CACHE_SUBSCRIPTIONS, CLEAR_CACHE_ONE_SUBSCRIPTION,
} from 'actions/types';
import { updateStoreReqRes } from '@parkly/shared/helpers';

const INITIAL_STATE = {
  subscriptions: {
    loading: false,
    list: [],
  },
  oneSubscription: {
    loading: false,
    list: [],
  },
};

function updateSubscriptionsList({
  list,
  newSubscriptions,
  param,
}) {
  if (!newSubscriptions) {
    return list;
  }

  const stringifyParam = JSON.stringify(param);

  const newList = [
    ...list
      .slice(0, 5)
      .filter((item = {}) => {
        const { strParam } = item || {};
        const isFilter = strParam !== stringifyParam;

        return isFilter;
      }),
    {
      data: newSubscriptions,
      strParam: stringifyParam,
      timeStamp: new Date().getTime(),
    },
  ];

  return newList;
}

function updateOneSubscriptionList({
  list,
  subscription,
  error,
}) {
  const { id } = subscription || error || {};

  if (!id) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).id;
      const isFilter = currentId !== id;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    subscription || error,
  ];

  return newList;
}

export default function subscriptions(state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_SUBSCRIPTIONS:
    return {
      ...state,
      subscriptions: {
        ...state.subscriptions,
        loading: action.payload.loading,
        list: updateSubscriptionsList({
          list: state.subscriptions.list,
          newSubscriptions: action.payload.subscriptions,
          param: action.payload.param,
        }),
      },
    };

  case GET_ONE_OPERATOR_REQUEST:
    return {
      ...state,
      oneSubscription: {
        ...state.oneSubscription,
        loading: action.payload.loading,
        list: updateOneSubscriptionList({
          list: state.oneSubscription.list,
          subscription: action.payload.subscription,
          error: action.payload.error,
        }),
      },
    };

  case CLEAR_CACHE_ONE_SUBSCRIPTION:
    return {
      ...state,
      oneSubscription: INITIAL_STATE.oneSubscription,
    };

  case CLEAR_CACHE_SUBSCRIPTIONS:
    return {
      ...state,
      subscriptions: INITIAL_STATE.subscriptions,
    };

  default:
    return state;
  }
}
