import React from 'react';
// import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { formatPhoneNumber } from '@parkly/shared/helpers/others';

import { useStyles } from './styles';

const propTypes = {};

function BlockedUsersTable({
  tableTitle = '',
  noDataTitle = '',
  dataList = [],
  onClick = () => {},
}) {
  const classes = useStyles();

  const isNoBlockedUser = !dataList?.length;

  return (
    <Paper className={classes.root}>
      {!isNoBlockedUser && (
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {[tableTitle, 'Причина'].map((label) => (
                  <TableCell
                    key={label}
                    align="left"
                  >
                    <Typography className={classes.headerStr}>
                      {label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList?.map(({
                id, carNumber, phone, comment,
              }) => {
                function onRowClick() {
                  onClick({ id });
                }

                return (
                  <TableRow
                    key={id}
                    className={classes.dataRow}
                    onClick={onRowClick}
                    hover
                  >
                    <TableCell
                      className={classes.firstCell}
                      align="left"
                    >
                      <Typography className={classes.rowId}>
                        {carNumber || formatPhoneNumber(phone)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.comment}>
                        {comment || '—'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isNoBlockedUser && (
        <div className={classes.noDataContainer}>
          <Typography>
            {noDataTitle}
          </Typography>
        </div>
      )}
    </Paper>
  );
}

BlockedUsersTable.propTypes = propTypes;

export default BlockedUsersTable;
