import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0!important',
  },
  subContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  title: {
    color: theme.palette.greyC.darkGrey,
  },
  price: {
    fontSize: 20,
    fontWeight: 'bold',
  },
}));

export default {
  useStyles,
};
