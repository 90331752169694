import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  container: {
    margin: 0,
    padding: 24,
    minWidth: 270,
    width: 'auto',
    borderRadius: 6,
    border: `1px solid ${theme.palette.greyC.gray}`,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  btnWatchActions: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24,
  },
  actionTitle: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  actionDate: {
    color: theme.palette.greyC.darkGrey,
  },
}));

export default {
  useStyles,
};
