import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 24,
    padding: 0,
  },
  container: {
    padding: 0,
  },
  headerStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  firstCell: {
    width: 200,
  },
  dataRow: {
    '&.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.greyC.lightGrey,
    },
    cursor: 'pointer',
  },
  rowId: {
    fontWeight: 'bold',
  },
  comment: {

  },
  noDataContainer: {
    display: 'flex',
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 480,
  },
}));

export default {
  useStyles,
};
