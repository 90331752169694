import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import PhoneIcon from '@parkly/shared/components/atoms/icons/PhoneIcon';

const CustomCircleBtn = withStyles((theme) => ({
  root: {
    height: 40,
    width: 40,
    marginRight: 8,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover svg circle': {
      fill: theme.palette.greyC.lightGrey,
    },
    '&.Mui-disabled svg path': {
      stroke: theme.palette.greyC.mediumGrey,
    },
  },
}))(IconButton);

function PhoneIconBtn({
  className = '',
  onClick = () => {},
  ...props
}) {
  return (
    <CustomCircleBtn
      className={className}
      onClick={onClick}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...(props || {})}
    >
      <PhoneIcon />
    </CustomCircleBtn>
  );
}

export default PhoneIconBtn;
