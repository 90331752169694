import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useDidUpdateEffect(fn, inputs = []) {
  const didMountRef = useRef(false);

  useEffect(
    () => {
      if (didMountRef.current) {
        fn();
      } else {
        didMountRef.current = true;
      }
    },
    // eslint-disable-next-line
    inputs,
  );
}

export function useFormFields({
  initValues = {},
  formatValueFunc: formatFormFieldsValue = (_, value) => value,
}) {
  const [state, setState] = useState(initValues);
  const [errors, setErrors] = useState({});

  const updateState = useCallback((newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, []);

  const updateErrors = useCallback((newErrors = {}) => {
    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));
  }, []);
  const onChange = useCallback((event) => {
    const { target } = event || {};
    const {
      name: filedName,
      value,
      checked,
      type,
    } = target || {};

    if (type !== 'checkbox') {
      updateState({
        [filedName]: formatFormFieldsValue(filedName, value),
      });
    } else {
      updateState({
        [filedName]: checked,
      });
    }

    updateErrors({
      [filedName]: false,
    });
  }, []);

  return [
    state,
    errors,
    onChange,
    updateErrors,
    updateState,
  ];
}

export function useFetchedReduxData({
  stateDataExtractor = () => {},
  getAction,
  param,
  updTimeSec = 30,
}) {
  const content = useSelector(param ? stateDataExtractor(param) : stateDataExtractor);
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (!getAction) {
        return;
      }

      const nowTime = new Date().getTime();
      const timeStamp = content?.timeStamp;
      const isUpdTime = !updTimeSec
        || !timeStamp
        || (nowTime - timeStamp) / 1000 > updTimeSec;

      if (isUpdTime) {
        // eslint-disable-next-line no-use-before-define
        update();
      }
    },
    // eslint-disable-next-line
    [getAction, updTimeSec, JSON.stringify(param)],
  );

  function update() {
    dispatch(getAction(param));
  }

  return {
    content,
    update,
  };
}

// eslint-disable-next-line import/prefer-default-export
export function useDeviceSystem() {
  const [state, setState] = useState({
    isMobile: true,
    isDesktop: false,
    isAndroid: false,
    isIOS: false,
  });

  useEffect(
    () => {
      const { userAgent, platform } = navigator || {};
      const isAndroid = /(android)/i.test(userAgent);
      const isIOS = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(platform) || (userAgent.includes('Mac') && 'ontouchend' in document);

      const isMobile = isAndroid || isIOS;

      setState({
        isMobile,
        isDesktop: !isMobile,
        isAndroid,
        isIOS,
      });
    },
    [],
  );

  return state;
}

export function useTimer(
  isTimer = false,
  effectParam,
  timerStart = 59,
) {
  const [timer, setTimer] = useState(0);

  useEffect(
    () => {
      let timerId;

      function stopInterval() {
        clearInterval(timerId);
      }

      if (!isTimer) {
        stopInterval();
        return stopInterval;
      }

      // eslint-disable-next-line no-use-before-define
      setTimer(timerStart);

      timerId = setInterval(
        () => {
          setTimer((prevTimerValue) => {
            const isNeedToStop = prevTimerValue <= 1;

            if (isNeedToStop) {
              stopInterval();
              return 0;
            }

            return prevTimerValue - 1;
          });
        },
        1000,
      );

      return stopInterval;
    },
    [isTimer, effectParam],
  );

  return timer;
}

export default {
  useFetchedReduxData,
  useTimer,
};
