import axios from 'axios';
import * as Sentry from '@sentry/react';

import { DEFAULT_PATH_PAGES } from '@parkly/shared/config/constants';

import { checkProduction, detectRussianLanguage } from '@parkly/shared/helpers/others';

import { getApiUrl, getCurrentUser, showApiErrorMsg } from '@parkly/shared/helpers';
import { toast } from 'react-toastify';

const BASE_URL = getApiUrl();

export function getAuthToken() {
  const user = getCurrentUser() || {};
  const { token } = user;

  return token;
}

export const callAPI = async (
  endpoint,
  method = 'get',
  data,
  headers = {},
  isShowErrorNotification = true,
  isNoToken = false,
  isRedirectToLogin = true,
  options = {},
  token = getAuthToken(),
) => {
  const url = `${BASE_URL}/${endpoint}`;

  const defaultHeaders = {
    'Content-Type': 'application/json',
    ...(() => ((isNoToken || !token)
        ? {}
        : { Authorization: `Bearer ${token}` }
    ))(),
  };

  const currentHeaders = {
    ...defaultHeaders,
    ...headers,
  };

  try {
    const result = await axios({
      method,
      url,
      data,
      headers: currentHeaders,
      ...options,
    });

    return result;
  } catch (error) {
    const { response } = error || {};
    const { status } = response || {};

    if (
      isRedirectToLogin
      && status === 401
      && endpoint !== DEFAULT_PATH_PAGES.logout.slice(1)
    ) {
      window.location.href = DEFAULT_PATH_PAGES.logout;
    }

    if (isShowErrorNotification) {
      showApiErrorMsg(error);
    }

    if (response && checkProduction()) {
      Sentry.captureException(error);
    }

    throw error;
  }
};

export const downloadFileContentDispositionApi = (
  endpoint,
) => {
  const url = `${BASE_URL}/${endpoint}`;

  const defaultHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken()}`,
  };

  axios({
    url,
    method: 'GET',
    responseType: 'blob',
    headers: defaultHeaders,
  })
    .then((response) => {
      const fileName = response.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]/g, '');
      const blobURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = blobURL;
      link.setAttribute('download', fileName); // or any other extension
      if (typeof link.download === 'undefined') {
        link.setAttribute('target', '_blank');
      }

      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobURL);
      }, 200);
    })
    .catch(async (error) => {
      const { response } = error || {};

      const responseBlob = (response || {}).data;

      const responseData = JSON.parse(await responseBlob.text());

      const {
        message = '',
      } = responseData || {};
      const isRussian = detectRussianLanguage(message);

      if (isRussian) {
        toast.error(message);
      } else {
        toast.error('Упс! Что-то пошло не так.');
      }

      if (checkProduction()) {
        Sentry.captureException(error);
      }
    });
};

export const downloadFileApi = (
  endpoint,
) => {
  const splitEndpoint = endpoint.split('/');
  const fileName = splitEndpoint[splitEndpoint.length - 1] || 'file';

  const urlApp = process.env.REACT_APP_URL;
  const useOnlyHttps = process.env.REACT_APP_USE_ONLY_HTTPS === 'true';
  const protocol = useOnlyHttps
    ? 'https:'
    : window.location.protocol;
  const baseUrl = `${protocol}//${urlApp}`;

  const url = baseUrl + endpoint;

  const defaultHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken()}`,
  };

  axios({
    url,
    method: 'GET',
    responseType: 'blob',
    headers: defaultHeaders,
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(async (error) => {
      const { response } = error || {};

      const responseBlob = (response || {}).data;

      const responseData = JSON.parse(await responseBlob.text());

      const {
        message = '',
      } = responseData || {};
      const isRussian = detectRussianLanguage(message);

      if (isRussian) {
        toast.error(message);
      } else {
        toast.error('Упс! Что-то пошло не так.');
      }

      if (checkProduction()) {
        Sentry.captureException(error);
      }
    });
};

export default {
  callAPI,
  downloadFileApi,
};
