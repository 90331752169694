import React from 'react';

function SitesIcon({
  fill = '#fff',
  size = 32,
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M16 23.333l-.651.372a.75.75 0 001.302 0L16 23.333zM17.905 20v-.75a.75.75 0 00-.652.378l.652.372zm-3.81 0l.651-.372a.75.75 0 00-.65-.378V20zm-5.21.64l-.614-.43.614.43zm-3.117 4.453l.615.43-.615-.43zm20.464 0l.614-.43-.614.43zm-3.117-4.453l-.615.43.614-.43zm-6.464 3.065l1.905-3.333-1.303-.744-1.904 3.333 1.302.744zm-3.207-3.333l1.905 3.333 1.302-.744-1.905-3.333-1.302.744zm-3.33.378h3.981v-1.5h-3.98v1.5zm-.614.32c.14-.2.37-.32.614-.32v-1.5a2.25 2.25 0 00-1.843.96l1.229.86zm-3.117 4.453L9.5 21.07l-1.229-.86-3.117 4.453 1.229.86zm.204.394a.25.25 0 01-.204-.394l-1.23-.86c-.811 1.16.019 2.754 1.434 2.754v-1.5zm18.826 0H6.587v1.5h18.826v-1.5zm.204-.394a.25.25 0 01-.204.394v1.5c1.415 0 2.245-1.594 1.433-2.754l-1.229.86zM22.5 21.07l3.117 4.453 1.23-.86-3.118-4.453-1.229.86zm-.614-.32a.75.75 0 01.614.32l1.229-.86a2.25 2.25 0 00-1.843-.96v1.5zm-3.981 0h3.98v-1.5h-3.98v1.5z"
        fill={fill}
      />
      <path
        d="M16 23.333l5.375-9.405C23.733 9.8 20.753 4.667 16 4.667v0c-4.753 0-7.733 5.134-5.374 9.26L16 23.334z"
        stroke={fill}
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
      <circle cx={16} cy={10.667} r={2} fill={fill} />
    </svg>
  );
}

export default SitesIcon;
