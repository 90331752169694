import React from 'react';

function OperatorsIconsActive({
  fill = '#fff',
  size = 32,
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" {...props}>
      <circle
        cx={11}
        cy={11.5}
        r={3}
        fill={fill}
        stroke={fill}
        strokeWidth={1.5}
      />
      <circle
        cx={21.5}
        cy={11.5}
        r={3}
        fill={fill}
        stroke={fill}
        strokeWidth={1.5}
      />
      <path
        d="M14 22a5 5 0 015-5h5a5 5 0 015 5v1a2 2 0 01-2 2H16a2 2 0 01-2-2v-1z"
        fill={fill}
      />
      <path
        d="M14 22a5 5 0 015-5h5a5 5 0 015 5v1a2 2 0 01-2 2H16a2 2 0 01-2-2v-1z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.576 17.253A4.994 4.994 0 0013 17H8a5 5 0 00-5 5v1a2 2 0 002 2h7.968a3.188 3.188 0 01-.593-1.86v-1.055c0-1.937.854-3.67 2.201-4.832z"
        fill={fill}
      />
    </svg>
  );
}

export default OperatorsIconsActive;
