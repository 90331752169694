import React from 'react';

function EndedIcon(props) {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
      <rect
        x={1}
        y={1}
        width={16}
        height={16}
        rx={8}
        stroke="#004BF6"
        strokeWidth={1.5}
      />
      <path
        d="M6.5 13V9.818m0 0V6h6L11 8l1.5 1.818h-6z"
        stroke="#004BF6"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EndedIcon;
