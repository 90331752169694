import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import PrivateRoute from 'components/templates/containers/PrivateRoute';
import AllOperators from 'components/pages/AllOperators';
import OneOperator from 'components/pages/OneOperator';
import OperatorActionsPage from 'components/pages/OperatorActionsPage';
import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';

import { PATH_PAGES } from 'config/constants';
import { getOperatorsAction, getAllPlatforms } from 'actions';

const propTypes = {
};

function OperatorsPage({
  operators,
  allPlatforms,
  currentOperator,
  getOperatorsReq = () => {},
  getAllPlatformsReq = () => {},
}) {
  useEffect(
    () => {
      const { userRights } = currentOperator || {};
      const { isReadOperatorsList } = userRights || {};

      const {
        loading: isOperatorsLoading,
        list: operatorsList = [],
      } = operators || {};
      const allOperators = operatorsList.find(
        ({ platformSign }) => platformSign === 'all',
      );

      if (isReadOperatorsList && (!isOperatorsLoading && !allOperators)) {
        getOperatorsReq();
      }

      const { loading: isAllPltLoading } = allPlatforms || {};
      if (!isAllPltLoading && !allPlatforms) {
        getAllPlatformsReq();
      }
    },
    // eslint-disable-next-line
    [JSON.stringify(operators, allPlatforms)],
  );

  const { userRights, data } = currentOperator || {};
  const { isReadOperatorsList, isReadOperatorsInfo, isReadOperatorsActions } = userRights || {};
  const operatorId = (data || {}).id;

  const urlActions = PATH_PAGES.operatorActions.replace(':operatorId', operatorId);
  // eslint-disable-next-line no-nested-ternary
  const url = isReadOperatorsInfo
    ? PATH_PAGES.oneOperator.replace(':id', operatorId)
    : isReadOperatorsActions
      ? urlActions
      : PATH_PAGES.notEnoughRights;

  const redirectStr = isReadOperatorsList
    ? PATH_PAGES.operatorsAll
    : url;

  const { list } = operators || {};
  const allOperators = (list || []).find(({ platformSign }) => platformSign === 'all');
  const isLoading = (isReadOperatorsList && !allOperators) || !(allPlatforms || {}).platforms;

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute exact path={PATH_PAGES.operatorsAll} component={AllOperators} />
      <PrivateRoute
        exact
        path={[PATH_PAGES.operatorActions]}
        component={OperatorActionsPage}
      />
      <PrivateRoute
        exact
        path={[PATH_PAGES.oneOperator, PATH_PAGES.addNewOperator]}
        component={OneOperator}
      />
      <Redirect to={redirectStr} />
    </ParentPageContainer>
  );
}

OperatorsPage.propTypes = propTypes;

function mapStateToProps(state) {
  const { operators, platform } = state || {};
  const { allPlatforms } = platform || {};
  const { currentOperator } = operators || {};

  return {
    operators: (operators || {}).operators,
    allPlatforms,
    currentOperator,
  };
}

const ConnectedOperatorsPage = connect(
  mapStateToProps,
  {
    getOperatorsReq: getOperatorsAction,
    getAllPlatformsReq: getAllPlatforms,
  },
)(OperatorsPage);

export default ConnectedOperatorsPage;
