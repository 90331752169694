import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import TariffChart from 'components/organisms/TariffChart';
import Collapse from '@material-ui/core/Collapse';

import { useStyles } from './styles';

/* Help functions */

function getData(tariffList = []) {
  const tabs = tariffList.map(({ name: label, id }) => ({ label, id }));

  return {
    tabs,
    tariffListRow: tariffList,
  };
}

/* Main component */

const propTypes = {
};

function PriceList({
  tariffList,
}) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const {
    tabs,
    tariffListRow,
  } = getData(tariffList);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.container}>
      <CustomTabBar
        tabs={tabs}
        tabValue={tabValue}
        handleParamChange={handleTabChange}
      />
      {(tariffListRow || []).map(({ tPriceList, name }, i) => (
        <Collapse key={name} in={tabValue === i}>
          <TariffChart
            tPriceList={tPriceList}
          />
        </Collapse>
      ))}
    </div>
  );
}

PriceList.propTypes = propTypes;

export default PriceList;
