import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import BlockedUserForm from 'components/organisms/BlockedUserForm';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import { useFetchedReduxData } from '@parkly/shared/helpers';
import {
  extractAllPlatformsFromState,
  extractCurrentOperatorFromState,
  getBlockedUsersExtractor,
} from 'helpers';
import { getBlockedUsersAction } from 'actions';
import { updateBlockedUser, removeBlockedUser } from 'api';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';

import { useStyles } from './styles';

/* Help functions */

function getBlockedUser({
  blockedUsersData,
  blockedUserId,
}) {
  const {
    byCarNumber = [],
    byPhone = [],
  } = blockedUsersData || {};

  const blockedUser = byCarNumber.find(({ id }) => id.toString() === blockedUserId);
  if (blockedUser) {
    return blockedUser;
  }

  return byPhone.find(({ id }) => id.toString() === blockedUserId);
}

/* Main component */

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
      facilityId: PropTypes.any,
    }).isRequired,
  }).isRequired,
};

function OneBlockedUser({
  match,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);
  const { content: allPlatforms } = useFetchedReduxData({
    stateDataExtractor: extractAllPlatformsFromState,
  });
  const { content: currentOperator } = useFetchedReduxData({
    stateDataExtractor: extractCurrentOperatorFromState,
  });
  const facilities = allPlatforms.platforms.data;
  const facilitiesList = facilities.map(({ id, title }) => ({ id, title }));
  const blockedUserId = match.params?.id;
  const facilityId = match.params?.facilityId;

  const {
    content: blockedUsers,
    update: updateBlockedUsers,
  } = useFetchedReduxData({
    stateDataExtractor: getBlockedUsersExtractor,
    getAction: getBlockedUsersAction,
    param: { facilityId },
    updTimeSec: 0,
  });
  const {
    loading: isLoading,
    data: blockedUsersData,
  } = blockedUsers || {};
  const blockedUser = getBlockedUser({
    blockedUsersData,
    blockedUserId,
  });

  // TODO потенциальная ошибка, когда будет несколько facilities: мы не будем
  // знать, заблокирован ли пользователь в других facilities

  const initData = {
    ...blockedUser,
    facilitiesChecked: [facilityId],
  };

  const { userRights } = currentOperator || {};
  const { isAdmin } = userRights || {};
  const createUpdateBlockedUsersPltList = (userRights || {})[`list${RIGHT_KEYS.createUpdateBlockedUsers}`];
  const isCreateUpdateBlockedUsers = isAdmin
    || createUpdateBlockedUsersPltList.includes(+facilityId);

  function onSaveBtnClick({
    blockedType: type,
    carNumber,
    phoneNumber,
    comment,
  }) {
    setIsSaveBtnDisabled(true);
    updateBlockedUser({
      facilityId,
      blockedUserId,
      type,
      carNumber,
      phoneNumber,
      comment,
    })
      .then(() => {
        updateBlockedUsers();
        setIsSaveBtnDisabled(false);
      })
      .catch(() => {
        setIsSaveBtnDisabled(false);
      });
  }
  function onRemoveBtnClick() {
    setIsSaveBtnDisabled(true);
    removeBlockedUser({
      facilityId,
      blockedUserId,
    })
      .then(() => {
        updateBlockedUsers();
        history.push(PATH_PAGES.blockedUsersAll);
      })
      .catch(() => {
        setIsSaveBtnDisabled(false);
      });
  }

  return (
    <Container
      className={classes.container}
      maxWidth="md"
    >
      <HeaderWithBackBtn
        title={t('blockedUsers.blockedUser')}
      />
      {!isLoading && (
        <BlockedUserForm
          initData={initData}
          facilitiesList={facilitiesList}
          isRemoveBtn={isCreateUpdateBlockedUsers}
          isSelectorDisabled
          isFacilitySelectorDisabled
          isSaveBtnDisabled={isSaveBtnDisabled || !isCreateUpdateBlockedUsers}
          isInputsDisabled={!isCreateUpdateBlockedUsers}
          onSubmit={onSaveBtnClick}
          onRemoveBlockedUser={onRemoveBtnClick}
        />
      )}
      {isLoading && (
        <CircularIndeterminate style={{ minHeight: 600 }} />
      )}
    </Container>
  );
}

OneBlockedUser.propTypes = propTypes;

export default OneBlockedUser;
