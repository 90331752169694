import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  oneTabTabsClass: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& button': {
      cursor: 'default',
    },
  },
}));

export default {
  useStyles,
};
