import React from 'react';

function SubscriptionsActiveIcon({
  size = 32,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4307 13.7914C22.7213 13.6537 21.9584 13.8596 21.4089 14.409C20.5303 15.2877 20.5303 16.7123 21.4089 17.591L23.0472 19.2293C21.8227 21.8972 19.1277 23.75 16 23.75C12.4871 23.75 9.52017 21.4128 8.56923 18.2086C9.27862 18.3463 10.0415 18.1404 10.5909 17.591C11.4696 16.7123 11.4696 15.2877 10.5909 14.409L8.95271 12.7708C10.1772 10.1028 12.8722 8.25 16 8.25C19.5128 8.25 22.4798 10.5872 23.4307 13.7914ZM13.75 11.25C13.3358 11.25 13 11.5858 13 12V16.5V20C13 20.4142 13.3358 20.75 13.75 20.75C14.1642 20.75 14.5 20.4142 14.5 20V17.25H16.5C18.1568 17.25 19.5 15.9069 19.5 14.25C19.5 12.5931 18.1568 11.25 16.5 11.25H13.75ZM16.5 15.75H14.5V12.75H16.5C17.3284 12.75 18 13.4216 18 14.25C18 15.0784 17.3284 15.75 16.5 15.75Z"
        fill="#FFFFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53033 16.5303C9.23744 16.8232 8.76256 16.8232 8.46967 16.5303L6 14.0607L3.53033 16.5303C3.23744 16.8232 2.76256 16.8232 2.46967 16.5303C2.17678 16.2374 2.17678 15.7626 2.46967 15.4697L5.46967 12.4697C5.61032 12.329 5.80109 12.25 6 12.25C6.19891 12.25 6.38968 12.329 6.53033 12.4697L9.53033 15.4697C9.82322 15.7626 9.82322 16.2374 9.53033 16.5303Z"
        fill="#FFFFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5303 15.4697C29.2374 15.1768 28.7626 15.1768 28.4697 15.4697L26 17.9393L23.5303 15.4697C23.2374 15.1768 22.7626 15.1768 22.4697 15.4697C22.1768 15.7626 22.1768 16.2374 22.4697 16.5303L25.4697 19.5303C25.6103 19.671 25.8011 19.75 26 19.75C26.1989 19.75 26.3897 19.671 26.5303 19.5303L29.5303 16.5303C29.8232 16.2374 29.8232 15.7626 29.5303 15.4697Z"
        fill="#FFFFFF"
      />
      <path
        d="M6 13.5V16C6 21.5228 10.4772 26 16 26C19.491 26 22.5642 24.2111 24.353 21.5M26 18.5V16C26 10.4772 21.5228 6 16 6C12.509 6 9.43576 7.78889 7.64702 10.5"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SubscriptionsActiveIcon;
