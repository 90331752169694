import React from 'react';
// import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

function GridTitleBase({
  title = '',
  style,
  classNameContainer = '',
  classNameTitleStr = '',
  classNameTitleContainer = '',
  children,
  titleSm = 3,
  titleXs,
}) {
  const classes = useStyles();

  const containerClass = `${classes.container} ${classNameContainer}`;
  const titleStrClass = `${classes.titleStr} ${classNameTitleStr}`;
  const titleContClass = `${classes.titleCont} ${classNameTitleContainer}`;

  return (
    <Grid style={style} className={containerClass} container>
      <Grid item className={titleContClass} xs={titleXs || 12} sm={titleSm}>
        <Typography component={(typeof title === 'string') ? 'p' : 'div'} className={titleStrClass}>
          {title}
        </Typography>
      </Grid>
      <Grid item className={classes.base} xs={titleXs ? 12 - titleXs : 12} sm={12 - titleSm}>
        {children}
      </Grid>
    </Grid>
  );
}

export default GridTitleBase;
