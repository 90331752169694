import React from 'react';
import { useTheme } from '@material-ui/core/styles';

function ActiveCircleIcon({
  active = true,
  className = '',
}) {
  const theme = useTheme();
  const activeFill = theme.palette.greenC.green3;
  const notActiveFill = theme.palette.redC.red3;

  return (
    <svg
      className={className}
      width={8}
      height={8}
      viewBox="0 0 8 8"
      fill="none"
    >
      <rect
        width={8}
        height={8}
        rx={4}
        fill={active ? activeFill : notActiveFill}
      />
    </svg>
  );
}

export default ActiveCircleIcon;
