import React from 'react';

function SupportIcon(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={32} height={32} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21 7a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2h8l3.293 3.293c.63.63 1.707.184 1.707-.707V17h1a2 2 0 002-2V7z"
        stroke="#FFFFFF"
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
      <path
        d="M7.5 11h.009M12 11h.009M16.5 11h.009"
        stroke="#FFFFFF"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SupportIcon;
