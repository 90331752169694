import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';
import { scaleLinear, select, max, axisBottom } from 'd3';

import { TARIFF_TYPES, TARIFF_UNIT_TYPES } from 'config/constants';
import { useStyles } from './styles';

const propTypes = {
};

function TariffChart({
  tPriceList,
  width = 440,
  height = 100,
  maxPriceY = 0,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const d3Container = useRef(null);

  const currency = t('others.currency');
  const margin = {
    top: 25,
    right: 60,
    bottom: 35,
    left: 70,
  };
  const sizes = {
    bandwidth: 32,
    spaceBetween: 8,
  };

  useEffect(
    () => {
      if (!tPriceList || !d3Container.current) {
        return;
      }

      const maxMinimalCost = max(tPriceList, ({ minimalCost: price }) => price);
      const maxPrice = maxMinimalCost < maxPriceY ? maxPriceY : maxMinimalCost;

      const xScale = scaleLinear()
        .domain([0, maxPrice])
        .range([0, width - margin.left - margin.right]);

      const sortedPriceList = (tPriceList || [])
        .sort((a, b) => {
          const aMinHours = (a || {}).minHours;
          const bMinHours = (b || {}).minHours;

          return aMinHours - bMinHours;
        })
        .map((row, index, array) => {
          const {
            minHours,
            maxHours,
            ...rest
          } = row || {};

          const isLastRow = index === (array.length - 1);
          const minHoursStr = (minHours || +minHours === 0) ? minHours : '?';
          const maxHoursStr = (() => {
            if (!(maxHours || +maxHours === 0)) {
              return '?';
            }

            if ((maxHours - 1) < 0 || isLastRow) {
              return maxHours;
            }

            return maxHours - 1;
          })();

          return {
            ...rest,
            minHoursStr,
            maxHoursStr,
          };
        });

      function getY(i) {
        return margin.top + i * (sizes.spaceBetween + sizes.bandwidth);
      }

      const nHeight = margin.top + margin.bottom
        + ((tPriceList || []).length) * (sizes.bandwidth + sizes.spaceBetween);

      const svg = select(d3Container.current);
      svg.attr('height', nHeight)
        .attr('viewBox', `0 0 ${width} ${nHeight}`);

      const bars = svg.selectAll('rect.price')
        .data(sortedPriceList);

      bars.join(
        (enter) => enter
          .append('rect')
          .attr('x', margin.left)
          .attr('width', 0)
          .attr('height', sizes.bandwidth),
        (update) => update,
        (exit) => exit
          .remove(),
      )
        .attr('class', ['price', classes.priceRect].join(' '))
        .attr('y', (_, i) => (getY(i)))
        .attr('width', ({ minimalCost }) => xScale(minimalCost));

      bars.enter()
        .append('text')
        .attr('class', ['label', classes.label].join(' '))
        .attr('y', (_, i) => (getY(i) + (sizes.bandwidth + sizes.spaceBetween) / 2))
        .attr('x', ({ minimalCost }) => margin.left + xScale(minimalCost) + 5)
        .text(({ minimalCost }) => `${minimalCost} ${currency}`);

      bars.enter()
        .append('path')
        .attr('d', `m 1 0 v ${sizes.bandwidth}`)
        .attr('class', ['line', classes.line].join(' '))
        .attr('transform', ({ minimalCost }, i) => `translate(${margin.left + xScale(minimalCost)}, ${getY(i)})`);

      /* Axis Y */

      bars.enter()
        .append('text')
        .attr('class', ['axisYLabel', classes.axisYLabel].join(' '))
        .attr('y', (_, i) => (getY(i) + (sizes.bandwidth + sizes.spaceBetween) / 2))
        .attr('x', margin.left - 10)
        .text(({ minHoursStr, maxHoursStr, tariffType }) => {
          const unit = TARIFF_UNIT_TYPES[tariffType];

          switch (tariffType) {
            case TARIFF_TYPES.hours:
              return `${minHoursStr} - ${maxHoursStr} ${unit}`
            case TARIFF_TYPES.days:
              return `${Math.round(minHoursStr / 24)} - ${Math.round(maxHoursStr / 24)} ${unit}`
            case TARIFF_TYPES.month:
              return `${Math.round(minHoursStr / 720)} - ${Math.round(maxHoursStr / 720)} ${unit}`
          }
        });

      /* Axis X */

      const xAxis = axisBottom()
        .ticks(5)
        .tickSize(-(nHeight - margin.bottom - margin.top))
        .tickPadding(10)
        .scale(xScale);

      svg.append('g')
        .attr('class', classes.xAxis)
        .attr('transform', `translate(${margin.left}, ${nHeight - margin.bottom})`)
        .call(xAxis);
    },
    // eslint-disable-next-line
    [tPriceList, height, width],
  );

  return (
    <svg
      ref={d3Container}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    />
  );
}

TariffChart.propTypes = propTypes;

export default TariffChart;
