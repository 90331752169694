import React, { useEffect, useState } from 'react';
import { getUpcomingBookingNotification } from 'api/bookings';
import moment from 'moment-timezone';

export function useUpcomingReservationNotification() {
  const [isEnabledState, setIsEnabledState] = useState(true);
  const [alreadyNotifyState, setAlreadyNotifyState] = useState([]);

  async function sendNotification(reservation) {
    if (Notification.permission === 'default' || Notification.permission === 'denied') {
      await Notification.requestPermission();
    }

    if (Notification.permission === 'granted') {
      const startTimeStr = moment(reservation.startTime).format('HH:mm DD-MM-YYYY');

      const not = new Notification(`Новое бронирование ${reservation.passCode}`, {
        body: `Начало в ${startTimeStr}`,
        icon: 'favicon.ico',
        badge: 'favicon.ico',
        silent: false,
      });
      not.onshow = () => {
        document.getElementById('notification_sound').play();
      };
    }
  }

  async function tryNotify(isEnabled, alreadyNotify) {
    if (isEnabled) {
      const { enabled, newReservations } = await getUpcomingBookingNotification();
      setIsEnabledState(enabled);

      newReservations.forEach((reservation) => {
        if (alreadyNotify.includes(reservation.passCode)) {
          return;
        }

        sendNotification(reservation).then();

        setAlreadyNotifyState((oldAlreadyNotify) => [
          ...oldAlreadyNotify,
          reservation.passCode,
        ]);
      });
    }
  }

  useEffect(() => {
    const interval = setInterval(
      () => tryNotify(isEnabledState, alreadyNotifyState),
      60000,
    );

    return () => {
      clearInterval(interval);
    };
  }, [alreadyNotifyState, isEnabledState]);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio id="notification_sound" preload="auto">
      <source src="notification_sound.mp3" type="audio/mpeg" />
      <source src="notification_sound.ogg" type="audio/ogg" />
      <embed hidden autostart="false" loop={false} src="notification_sound.mp3" />
    </audio>
  )
}

export default {
  useUpcomingReservationNotification,
};
