import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiPopover-paper': {
      marginTop: 12,
    },
  },
  label: {
    height: 40,
    width: 240,
    padding: 12,
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderRadius: 6,
    cursor: 'pointer',
  },
  startDate: {
    minWidth: 70,
    marginRight: 6,
  },
  endDate: {
    minWidth: 70,
    marginLeft: 6,
  },
  patern: {
    color: theme.palette.greyC.darkGrey,
  },
  calendarIconBtn: {
    marginLeft: 'auto',
    padding: 8,
  },
  calendarIcon: {
  },
}));

export default {
  useStyles,
};
