import React from 'react';

function SortedSmallFirstIcon(props) {
  return (
    <svg width={9} height={8} viewBox="0 0 9 8" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h3v2H0V0zm6 3v2H0V3h6zm3 3H0v2h9V6z"
        fill="#7E8AA7"
      />
    </svg>
  );
}

export default SortedSmallFirstIcon;
