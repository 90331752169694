import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: 0,
    marginLeft: 0,
    maxWidth: 'unset',
  },
}));

export default {
  useStyles,
};
