import { getOneSubscription, getSubscriptions } from 'api/subscriptions';
import {
  CLEAR_CACHE_ONE_SUBSCRIPTION,
  CLEAR_CACHE_SUBSCRIPTIONS,
  GET_ONE_SUBSCRIPTION,
  GET_SUBSCRIPTIONS,
} from './types';

export function clearCacheOneSubscriptionAction() {
  function actionClearCacheOneSubscription() {
    return { type: CLEAR_CACHE_ONE_SUBSCRIPTION };
  }

  return (dispatch) => {
    dispatch(actionClearCacheOneSubscription());
  };
}

export function clearCacheSubscriptionsAction() {
  function actionClearCacheSubscriptions() {
    return { type: CLEAR_CACHE_SUBSCRIPTIONS };
  }

  return (dispatch) => {
    dispatch(actionClearCacheSubscriptions());
  };
}

export function getOneSubscriptionAction({
  id,
}) {
  function getOneSubscriptionRequest(payload) {
    return { type: GET_ONE_SUBSCRIPTION, payload };
  }

  return (dispatch) => {
    dispatch(getOneSubscriptionRequest({
      loading: true,
    }));

    getOneSubscription({
      id,
    })
      .then(
        (subscription) => {
          dispatch(getOneSubscriptionRequest({
            loading: false,
            subscription,
          }));
        },
        (error) => {
          dispatch(getOneSubscriptionRequest({
            loading: false,
            error: {
              id,
              error,
            },
          }));
        },
      );
  };
}

export function getSubscriptionsAction(param) {

  const {
    platformSign,
    status,
    page,
  } = param || {};

  const platformId = platformSign === 'unselect' ? undefined : platformSign;

  const apiParam = {
    id: platformId,
    status: status.join(','),
    page,
  };

  function getSubscriptionsRequest(payload) {
    return { type: GET_SUBSCRIPTIONS, payload };
  }

  return (dispatch) => {
    dispatch(getSubscriptionsRequest({
      loading: true,
    }));

    getSubscriptions(apiParam)
      .then(
        (subscriptions) => {
          dispatch(getSubscriptionsRequest({
            loading: false,
            subscriptions,
            param,
          }));
        },
        (error) => {
          dispatch(getSubscriptionsRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export default {
  clearCacheOneSubscriptionAction,
  clearCacheSubscriptionsAction,
  getOneSubscriptionAction,
  getSubscriptionsAction,
};
