import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import PlatformStatisticHeader from 'components/organisms/PlatfromStatisticHeader';
import ScheduleSummary from 'components/organisms/ScheduleSummary';
import BasePlatformInfo from 'components/templates/BasePlatformInfo';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import { getOnePlatformAction } from 'actions';
import { getCurrentPlatform } from 'helpers';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';

import { useStyles } from './styles';

/* Help functions */

/* Main component */

const propTypes = {
  allPlatforms: PropTypes.shape({
    loading: PropTypes.bool,
    platforms: PropTypes.any,
  }),
  onePlatform: PropTypes.shape({
    loading: PropTypes.bool,
    list: PropTypes.array,
  }),
  currentOperator: PropTypes.shape({
    userRights: PropTypes.object,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any.isRequired,
    }).isRequired,
  }).isRequired,
  getOnePlatformReq: PropTypes.func,
};

function OnePlatform({
  allPlatforms = {},
  onePlatform = {},
  currentOperator = {},
  match,
  getOnePlatformReq = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(
    () => {
      const { params } = match || {};
      const { id } = params || {};

      const {
        currentPlatform,
      } = getCurrentPlatform({
        onePlatform,
        platformId: id,
      });

      if (currentPlatform) {
        const nowTime = new Date().getTime();
        const { timeStamp = nowTime } = currentPlatform || {};
        const isUpdTime = (nowTime - timeStamp) / 1000 < 30;

        if (isUpdTime) {
          return;
        }
      }

      getOnePlatformReq({
        id,
      });
    },
    // eslint-disable-next-line
    [getOnePlatformReq, match.params.id],
  );

  const { params } = match || {};
  const { id } = params || {};

  const { platforms } = allPlatforms;
  const isBackBtn = platforms && platforms.data && (platforms.data.length > 1);

  const {
    isLoading,
    currentPlatform,
  } = getCurrentPlatform({
    onePlatform,
    platformId: id,
  });

  const currentPlatformData = (currentPlatform || {}).data;

  const {
    name = '', address = '', statistic, options, findEntranceInstructions, parkingInstructions, phone,
    tariffList, photos, mapLink, type: platformType,
  } = currentPlatformData || {};
  const {
    income = {}, availablePlaces, schedules, reservations, mobilePayments,
  } = statistic || {};

  const isLoader = isLoading || !currentPlatformData;
  const { userRights } = currentOperator || {};

  const isBlockedUserSettingsLink = userRights[`is${RIGHT_KEYS.readBlockedUsers}`];

  return (
    <>
      {isLoader && (
        <CircularIndeterminate
          type="fullPage"
          style={{ position: 'initial' }}
        />
      )}
      {!isLoader && (
        <Container
          className={classes.container}
          maxWidth="md"
        >
          <div>
            <HeaderWithBackBtn
              title={name}
              isBackBtn={isBackBtn}
            />
            <PlatformStatisticHeader {...{
              address,
              statisticIncome: income,
              mapLink,
            }}
            />
            <ScheduleSummary {...{
              platformType,
              availablePlaces,
              schedules,
              reservations,
              mobilePayments,
              platformId: id,
              userRights,
            }}
            />
            {isBlockedUserSettingsLink && (
              <div className={classes.settingsContainer}>
                <Typography variant="h2" className={classes.title}>
                  {t('platforms.additional')}
                </Typography>
                <div className={classes.settingsList}>
                  <CustomLink
                    className={classes.blockedUsersLink}
                    href={PATH_PAGES.blockedUsersAll}
                  >
                    {t('blockedUsers.blockedUsers')}
                  </CustomLink>
                </div>
              </div>
            )}
            <BasePlatformInfo {...{
              platformId: id,
              platformName: name,
              options,
              findEntranceInstructions,
              parkingInstructions,
              phone,
              tariffList,
              photos,
            }}
            />
          </div>
        </Container>
      )}
    </>
  );
}

OnePlatform.propTypes = propTypes;

function mapStateToProps(state) {
  const { platform, operators } = state || {};
  const { allPlatforms, onePlatform } = platform || {};
  const { currentOperator } = operators || {};

  return {
    allPlatforms,
    onePlatform,
    currentOperator,
  };
}

const ConnectedOnePlatform = connect(
  mapStateToProps,
  {
    getOnePlatformReq: getOnePlatformAction,
  },
)(OnePlatform);

export default ConnectedOnePlatform;
