import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';

import { useStyles } from './styles';

function getLastActions(actionsList) {
  const result = actionsList
    .slice(0, 3)
    .map((itemAction) => {
      const {
        id,
        date,
        title,
      } = itemAction || {};

      const dateStr = date.format('DD.MM.YYYY, HH:mm');

      return {
        id,
        title,
        dateStr,
      };
    });

  return result;
}

const propTypes = {
  actionsList: PropTypes.arrayOf(PropTypes.object),
  isActionsLoading: PropTypes.bool,
  onWatchActionsBtnClick: PropTypes.func,
};

function UserLogSummary({
  actionsList = [],
  isActionsLoading = false,
  onWatchActionsBtnClick = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const lastActions = getLastActions(actionsList);

  const isZeroActions = lastActions.length === 0;

  return (
    <div className={classes.root}>
      <Container
        className={classes.container}
      >
        {isActionsLoading && (
          <CircularIndeterminate type="fullWidth" />
        )}
        {!isActionsLoading && isZeroActions && (
          <div className={classes.actionContainer}>
            <Typography
              className={classes.actionTitle}
            >
              {t('operators.noActionsYet')}
            </Typography>
          </div>
        )}
        {!isActionsLoading && lastActions.map((action) => {
          const { id, dateStr, title } = action || {};

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={id} className={classes.actionContainer}>
              <Typography
                className={classes.actionTitle}
              >
                {title}
              </Typography>
              <Typography
                className={classes.actionDate}
              >
                {dateStr}
              </Typography>
            </div>
          );
        })}
        <CustomBtn
          btnType="secondary"
          className={classes.btnWatchActions}
          onClick={onWatchActionsBtnClick}
        >
          {t('operators.watchTheAction')}
        </CustomBtn>
      </Container>
    </div>

  );
}
UserLogSummary.propTypes = propTypes;

export default UserLogSummary;
