/* eslint-disable camelcase */
import moment from 'moment-timezone';

import { callAPI } from '@parkly/shared/api/base';

import {
  SCHEDULE_SETTINGS_TYPES, SCHEDULE_SETTINGS_TYPES_BACKEND,
  SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ,
} from '../config/constants';

/* validation help functions */

function validUpdPlatformScheduleRes({
  res,
}) {
  const { data } = res || {};

  return data;
}

function transformScheduleTypes(type) {
  switch (type) {
  case SCHEDULE_SETTINGS_TYPES_BACKEND.day:
    return SCHEDULE_SETTINGS_TYPES.day;
  case SCHEDULE_SETTINGS_TYPES_BACKEND.week:
    return SCHEDULE_SETTINGS_TYPES.week;
  case SCHEDULE_SETTINGS_TYPES_BACKEND.default:
  default:
    return SCHEDULE_SETTINGS_TYPES.default;
  }
}

export async function validGetPlatformScheduleRes({
  res,
  id,
  month,
}) {
  const { data } = res || {};
  const curData = (data || {}).data;

  const {
    days,
    every_day_places,
    every_day_schedule,
    tariffs,
  } = curData;

  const transformedTariffs = (tariffs || []).map((tariffItem) => {
    const {
      id: tariffId,
      name,
    } = tariffItem || {};

    return {
      id: tariffId,
      name,
    };
  });
  const tariffIdByName = (transformedTariffs || []).reduce(
    (acc, item) => {
      const {
        id: tariffId,
        name,
      } = item || {};

      return {
        ...acc,
        [name]: tariffId,
      };
    },
    {},
  );

  const {
    count,
    time_from,
    time_to,
  } = every_day_places || {};
  const everyDayPlaces = {
    id: (every_day_places || {}).id,
    count,
    timeFrom: time_from,
    timeTo: time_to,
  };

  const {
    available_from,
    available_to,
    is_available,
    tariff_id: everyDayTariffId = 1,
  } = every_day_schedule || {};

  const everyDaySchedule = {
    id: (every_day_schedule || {}).id,
    availableFrom: available_from,
    availableTo: available_to,
    isAvailable: is_available,
    tariff: ((tariffs || []).find(({ id: curId }) => curId === everyDayTariffId) || {}).name,
    tariffId: everyDayTariffId,
  };

  const transformedDays = days.map((day) => {
    const {
      date,
      places,
      schedule,
    } = day;
    const isAvailable = (day || {}).is_available;

    const scheduleAvailableFrom = (schedule || {}).available_from;
    const scheduleAvailableTo = (schedule || {}).available_to;
    const { tariff: dayTariff } = schedule || {};
    const tariffId = tariffIdByName[dayTariff];

    const placesCount = (places || {}).count;
    const placesTimeFrom = (places || {}).time_from;
    const placesTimeTo = (places || {}).time_to;

    return {
      date,
      isAvailable,
      places: {
        id: (places || {}).id,
        type: transformScheduleTypes((places || {}).type),
        count: placesCount,
        timeFrom: placesTimeFrom,
        timeTo: placesTimeTo,
      },
      schedule: {
        availableFrom: scheduleAvailableFrom,
        availableTo: scheduleAvailableTo,
        type: transformScheduleTypes((schedule || {}).type),
        id: (schedule || {}).id,
        tariff: dayTariff,
        tariffId,
      },
    };
  });

  return {
    requestParam: {
      id,
      month,
    },
    data: {
      days: transformedDays,
      everyDayPlaces,
      everyDaySchedule,
      tariffs: transformedTariffs,
    },
  };
}

/* other functions */

function getAddPlatformParkSpaceBody({
  platformId,
  dayDate,
  type,
  weekDay,
  parkSpace,
}) {
  switch (type) {
  case SCHEDULE_SETTINGS_TYPES.week:
    // eslint-disable-next-line
    let weekDays = [0, 0, 0, 0, 0, 0, 0];
    weekDays[7 - weekDay] = 1;

    return {
      facility_id: platformId,
      type: SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ.week,
      value: weekDays.join(''),
      time_from: '00:00:00',
      time_to: '00:00:00',
      count: parseInt(parkSpace, 10),
    };

  case SCHEDULE_SETTINGS_TYPES.day:
  default:
    return {
      facility_id: platformId,
      type: SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ.day,
      value: dayDate,
      time_from: '00:00:00',
      time_to: '00:00:00',
      count: parseInt(parkSpace, 10),
    };
  }
}

function getChangePlatformParkSpaceBody({
  weekDay = 0,
  dayDate,
  type,
  parkSpace,
}) {
  switch (type) {
  case SCHEDULE_SETTINGS_TYPES.default:
    return {
      type: SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ.default,
      value: '1',
      time_from: '00:00:00',
      time_to: '00:00:00',
      count: parkSpace,
    };

  case SCHEDULE_SETTINGS_TYPES.week:
    // eslint-disable-next-line
    let weekDays = [0, 0, 0, 0, 0, 0, 0];
    weekDays[7 - weekDay] = 1;

    return {
      type: SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ.week,
      value: weekDays.join(''),
      time_from: '00:00:00',
      time_to: '00:00:00',
      count: parkSpace,
    };

  case SCHEDULE_SETTINGS_TYPES.day:
  default:
    return {
      type: SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ.day,
      value: dayDate,
      time_from: '00:00:00',
      time_to: '00:00:00',
      count: parkSpace,
    };
  }
}

function getAddPlatformScheduleBody({
  type,
  platformId,
  isAvailable = true,
  dayDate,
  weekDay,
  timeEnd,
  timeStart,
  tariffId,
}) {
  const timeStartMoment = moment(timeStart, 'HH:mm');
  const timeStartUtcStr = timeStartMoment.utc().format('HH:mm:ss');
  const timeEndMoment = moment(timeEnd, 'HH:mm');
  const timeEndUtcStr = timeEndMoment.utc().format('HH:mm:ss');

  switch (type) {
  case SCHEDULE_SETTINGS_TYPES.week:
    return {
      facility_id: platformId,
      type: SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ.week,
      value: weekDay,
      is_available: isAvailable,
      available_from: timeStartUtcStr,
      available_to: timeEndUtcStr,
      tariff_id: tariffId,
    };

  case SCHEDULE_SETTINGS_TYPES.day:
  default:
    return {
      facility_id: platformId,
      type: SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ.day,
      value: dayDate,
      is_available: isAvailable,
      available_from: timeStartUtcStr,
      available_to: timeEndUtcStr,
      tariff_id: tariffId,
    };
  }
}

function getChangePlatformScheduleBody({
  type,
  dayDate,
  weekDay,
  isAvailable = true,
  timeEnd,
  timeStart,
  tariffId,
}) {
  const timeStartMoment = moment(timeStart, 'HH:mm');
  const timeStartUtcStr = timeStartMoment.utc().format('HH:mm:ss');
  const timeEndMoment = moment(timeEnd, 'HH:mm');
  const timeEndUtcStr = timeEndMoment.utc().format('HH:mm:ss');

  switch (type) {
  case SCHEDULE_SETTINGS_TYPES.default:
    return {
      type: SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ.default,
      value: '1',
      is_available: true,
      available_from: timeStartUtcStr,
      available_to: timeEndUtcStr,
    };

  case SCHEDULE_SETTINGS_TYPES.week:
    return {
      type: SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ.week,
      value: weekDay,
      is_available: isAvailable,
      available_from: timeStartUtcStr,
      available_to: timeEndUtcStr,
      tariff_id: tariffId,
    };

  case SCHEDULE_SETTINGS_TYPES.day:
  default:
    return {
      type: SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ.day,
      value: dayDate,
      is_available: isAvailable,
      available_from: timeStartUtcStr,
      available_to: timeEndUtcStr,
      tariff_id: tariffId,
    };
  }
}

/* schedule */

export async function changePlatformScheduleRule({
  ruleId,
  type,
  dayDate,
  weekDay,
  timeEnd,
  timeStart,
  isAvailable,
  tariffId,
}) {
  const endpoint = `facility_schedule/${ruleId}`;
  const method = 'PUT';

  const data = getChangePlatformScheduleBody({
    type,
    dayDate,
    weekDay,
    isAvailable,
    timeEnd,
    timeStart,
    tariffId,
  });

  return callAPI(endpoint, method, data)
    .then((res) => validUpdPlatformScheduleRes({
      res,
    }));
}

export async function addPlatformScheduleRule({
  platformId,
  type,
  dayDate,
  weekDay,
  timeEnd,
  timeStart,
  isAvailable,
  tariffId,
}) {
  const endpoint = 'facility_schedule';
  const method = 'post';

  const data = getAddPlatformScheduleBody({
    type,
    platformId,
    isAvailable,
    dayDate,
    weekDay,
    timeEnd,
    timeStart,
    tariffId,
  });

  return callAPI(endpoint, method, data)
    .then((res) => validUpdPlatformScheduleRes({
      res,
    }));
}

export async function delPlatformScheduleRule({
  ruleId,
}) {
  const endpoint = `facility_schedule/${ruleId}`;
  const method = 'DELETE';

  return callAPI(endpoint, method)
    .then((res) => validUpdPlatformScheduleRes({
      res,
    }));
}

/* park space */

export async function changePlatformParkSpaceRule({
  ruleId,
  weekDay,
  dayDate,
  type,
  parkSpace,
}) {
  const endpoint = `facility_places/${ruleId}`;
  const method = 'PUT';

  const data = getChangePlatformParkSpaceBody({
    weekDay,
    dayDate,
    type,
    parkSpace,
  });

  return callAPI(endpoint, method, data)
    .then((res) => validUpdPlatformScheduleRes({
      res,
    }));
}

export async function addPlatformParkSpaceRule({
  platformId,
  dayDate,
  weekDay,
  type,
  parkSpace,
}) {
  const endpoint = 'facility_places';
  const method = 'post';

  const data = getAddPlatformParkSpaceBody({
    platformId,
    dayDate,
    type,
    parkSpace,
    weekDay,
  });

  return callAPI(endpoint, method, data)
    .then((res) => validUpdPlatformScheduleRes({
      res,
    }));
}

export async function delPlatformParkSpaceRule({
  ruleId,
}) {
  const endpoint = `facility_places/${ruleId}`;
  const method = 'DELETE';

  return callAPI(endpoint, method)
    .then((res) => validUpdPlatformScheduleRes({
      res,
    }));
}

/* --- */

export async function updPlatformSchedule({
  platformId,
  type,

  isTimeDefault,
  isTariffDefault,

  dayDate,
  weekDay,
  timeEnd,
  timeStart,
  isAvailable,
  tariffId,

  timeRuleId: ruleId,
  timeRuleType: ruleType,
}) {
  if (ruleType === type) {
    if (isTariffDefault && isTimeDefault && isAvailable) {
      return delPlatformScheduleRule({
        ruleId,
      });
    }

    return changePlatformScheduleRule({
      ruleId,
      type,
      dayDate,
      weekDay,
      timeEnd,
      timeStart,
      isAvailable,
      tariffId,
    });
  }

  return addPlatformScheduleRule({
    platformId,
    type,
    dayDate,
    weekDay,
    timeEnd,
    timeStart,
    isAvailable,
    tariffId,
  });
}

export async function updPlatformParkSpace({
  dayDate,
  weekDay,
  platformId,
  type,
  parkSpace,
  isParkSpaceDefault,

  placesRuleId: ruleId,
  placesRuleType: ruleType,
}) {
  if (ruleType === type) {
    if (isParkSpaceDefault) {
      return delPlatformParkSpaceRule({
        ruleId,
      });
    }

    return changePlatformParkSpaceRule({
      ruleId,
      dayDate,
      weekDay,
      type,
      parkSpace,
    });
  }

  return addPlatformParkSpaceRule({
    platformId,
    dayDate,
    weekDay,
    type,
    parkSpace,
  });
}

export async function getPlatformSchedule({
  id,
  month,
}) {
  const endpoint = `facility/${id}/settings?date=${month}`;

  return callAPI(endpoint)
    .then((res) => validGetPlatformScheduleRes({
      res,
      id,
      month,
    }));
}

export default {
  updPlatformSchedule,
};
