import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
  },
}));

function ChevronDownIcon({
  className = '',
  ...props
}) {
  const classes = useStyles();
  const classNameStr = `${classes.root} ${className}`;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      className={classNameStr}
      {...props}
    >
      <path
        d="M7 10l5 5 5-5"
        stroke="#7E8AA7"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ChevronDownIcon;
