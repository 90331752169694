import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  select: {
    width: '100%',
  },
  selectedStatusContainer: {
    display: 'flex',
    height: 17,
  },
  selectedStatusIcon: {
    // marginLeft: 5,
  },
  selectStatus: {
    width: '100%',
    paddingRight: 10,
  },
  selectStatusBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    backgroundColor: theme.palette.greyC.lightGrey,
    '&:hover': {
      backgroundColor: theme.palette.greyC.gray,
    },
    borderRadius: 0,
  },
  selectedStatusStr: {
    textTransform: 'initial',
    paddingLeft: 12,
  },
  statusItem: {
    display: 'flex',
    alignItems: 'center',
    '&.Mui-selected': {
      backgroundColor: 'white',
    },
  },
  statusItemStr: {
    paddingLeft: 12,
    marginLeft: 5,
  },
  unSelectStr: {
    color: theme.palette.greyC.gray2,
    paddingLeft: 12,
  },
  selectedStr: {
  },
  datePicker: {
    width: '100%',
    minWidth: '215px!important',
  },
  searchField: {

  },
}));

export default {
  useStyles,
};
