export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const FAST_LOGOUT = 'FAST_LOGOUT';
export const LOGIN_LOGOUT_PROGRESS = 'LOGIN_LOGOUT_PROGRESS';

export const GET_CONTRACTOR_FL = 'GET_CONTRACTOR_FL';
export const GET_CONTRACTOR_UL = 'GET_CONTRACTOR_UL';
export const CLEAR_CONTRACTOR_CACHE = 'CLEAR_CONTRACTOR_CACHE';

export default {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGIN_LOGOUT_PROGRESS,

  GET_CONTRACTOR_FL,
  GET_CONTRACTOR_UL,
  CLEAR_CONTRACTOR_CACHE,
};
