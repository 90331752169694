/* eslint-disable react/require-default-props */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  axisBottom,
  axisLeft,
  extent,
  line,
  max,
  scaleLinear,
  scaleTime,
  select,
  stack,
  stackOffsetNone,
  stackOrderNone,
  timeHour,
} from 'd3';

import { hideTooltip, showTooltip } from '@parkly/shared/helpers';

import { useStyles } from './styles';

/* help functions */

const propTypes = {
  barChartData: PropTypes.arrayOf(PropTypes.object),
  width: PropTypes.number,
  height: PropTypes.number,
  onAlertClick: PropTypes.func,
};

const CongestionBarChart = React.memo(({
  barChartData = [],
  width = 1060,
  height = 400,
  onAlertClick = () => {},
}) => {
  const d3Container = useRef(null);
  const classes = useStyles();

  useEffect(
    () => {
      if (!barChartData || !d3Container.current) {
        return;
      }

      const margin = {
        top: 25,
        right: 25,
        bottom: 25,
        left: 68,
        leftAxis: 40,
      };
      const chartWidth = width - margin.left - margin.right;
      const spaceBetween = -1;
      const sizes = {
        bandwidth: Math.round(100 * (chartWidth / barChartData.length - spaceBetween)) / 100,
        spaceBetween,
      };
      const keysList = ['reservedSpots', 'emptySpots'];

      const stackForData = stack()
        .keys(keysList)
        .order(stackOrderNone)
        .offset(stackOffsetNone);
      const stackedSeries = stackForData(barChartData);

      const svg = select(d3Container.current);

      const yMax = max(barChartData, ({ totalSpots }) => totalSpots);
      const yScale = scaleLinear()
        .domain([0, yMax])
        .range([height - margin.bottom, margin.top]);

      const yAlert = Math.round(0.8 * yMax);

      /* Bar chart start */

      svg.selectAll('.stacked')
        .data(stackedSeries)
        .join('g')
        .attr('class', (d, i) => `stacked ${classes.stacked} ${classes[keysList[i]]}`)
        .selectAll('rect')
        .data((d) => d)
        .join(
          (enter) => enter
            .append('rect')
            .attr('y', height - margin.bottom)
            .attr('height', 0),
          (update) => update,
          (exit) => exit
            .remove(),
        )
        .attr('class', (d) => (d[1] === d.data.reservedSpots
          ? classes.reservedRect
          : classes.emptyRect))
        .attr('x', (d, i) => (margin.left + i * (sizes.spaceBetween + sizes.bandwidth)))
        .attr('width', sizes.bandwidth)
        .on('mousemove', (currentEvent, d) => {
          const isReserved = d[1] === d.data.reservedSpots;
          const timeStr = moment(d.data.parsedTime).format('HH:mm');
          const toolTipStr = isReserved
            ? `${timeStr}, Забронировано ${d.data.reservedSpots} `
            : `${timeStr}, Свободно ${d.data.emptySpots}`;
          const toolTipHtml = `<div class=${classes.tooltipNode}>
            <div class="${classes.tooltipCircle} ${isReserved ? '' : classes.tooltipCircleEmpty}"></div>
            <Typography
                class=${classes.tooltipStr}
            >
              ${toolTipStr}
            </Typography>
          </div>`;

          showTooltip(currentEvent, toolTipHtml);
        })
        .on('mouseout', hideTooltip)
        .transition()
        .duration(500)
        .delay(250)
        .attr('y', (d) => yScale(d[1]))
        .attr('height', (d) => yScale(d[0]) - yScale(d[1]));

      /* Bar chart end */

      if (barChartData.length > 1) {
        /* Axis start */

        const yAxis = axisLeft()
          .ticks(5)
          .tickSize(6)
          .tickPadding(10)
          .tickSizeInner(-(width - margin.leftAxis + 6))
          .scale(yScale);

        const yAxisG = svg.select('.yAxis').empty()
          ? svg.append('g')
            .attr('class', `yAxis ${classes.yAxis}`)
            .attr('transform', `translate(${margin.leftAxis}, 0)`)
          : svg.select('.yAxis');

        yAxisG
          .transition()
          .duration(500)
          .call(yAxis);

        const timeDomain = extent(barChartData, ({ parsedTime }) => moment(parsedTime));
        const xScale = scaleTime()
          .domain(timeDomain)
          .range([
            margin.left,
            margin.left + (barChartData.length - 1) * (sizes.spaceBetween + sizes.bandwidth),
          ]);

        const xAxis = axisBottom()
          .ticks(timeHour.every(1))
          // .ticks(24)
          .tickSize(0)
          .tickPadding(10)
          .tickFormat((time) => moment(time).format('HH:mm'))
          .scale(xScale);

        const xAxisG = svg.select('.xAxis').empty()
          ? svg.append('g')
            .attr('class', `xAxis ${classes.xAxis}`)
            .attr('transform', `translate(${0}, ${height - margin.bottom})`)
          : svg.select('.xAxis');

        xAxisG
          .transition()
          .duration(500)
          .call(xAxis);

        const lineData = [
          {
            x: margin.left - 27,
            y: 0.5,
          },
          {
            x: margin.left + 1,
            y: 0.5,
          },
        ];

        xAxisG.select('.xAxisLine').remove();

        xAxisG
          .append('path')
          .attr('class', 'xAxisLine')
          .datum(lineData)
          .attr('d', line().x(({ x }) => x).y(({ y }) => y));

        /* Axis end */
      }

      /* Alerts start */

      // Line
      const yAlertAbs = yScale(yAlert);

      const alertLine = svg.select('.alertLine').empty()
        ? svg.append('line')
          .attr('class', `alertLine ${classes.alertLine}`)
          .attr('x1', margin.leftAxis)
          .attr('x2', width)
          .attr('y1', height - margin.bottom)
          .attr('y2', height - margin.bottom)
        : svg.select('.alertLine');

      if (barChartData.length > 1) {
        alertLine
          .transition()
          .duration(500)
          .attr('y1', yAlertAbs)
          .attr('y2', yAlertAbs);
      } else {
        alertLine.remove();
      }

      // Icons
      let prevIndex;
      const alertIconsData = barChartData.reduce(
        (acc, item, index) => {
          const { reservedSpots } = item || {};
          const isAlert = reservedSpots >= yAlert;

          if (!isAlert) {
            return acc;
          }

          const isPrev = (index - 1) === prevIndex;
          prevIndex = index;

          if (isPrev) {
            return acc;
          }

          return [
            ...acc,
            {
              item,
              index,
            },
          ];
        },
        [],
      );

      function getIconX(d) {
        return margin.left + d.index * (sizes.spaceBetween + sizes.bandwidth) - 12;
      }

      svg.selectAll('.alertIcons')
        .data(alertIconsData)
        .join(
          (enter) => enter
            .append('g')
            .attr('class', `alertIcons ${classes.alertIcons}`)
            .attr('transform', (d) => `translate(${getIconX(d)}, ${height - margin.bottom - 24})`)
            .html('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">\n'
              + '    <path d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z" fill="#F34E4E" stroke="#F34E4E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n'
              + '    <path d="M12 7V13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n'
              + '    <path d="M11.9894 16.5H11.9994" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n'
              + '  </svg>')
            .on('click', onAlertClick),
          (update) => update,
          (exit) => exit
            .remove(),
        )
        .on('mousemove', (currentEvent) => {
          const toolTipHtml = `<div class=${classes.tooltipNodeAlert}>
            <div class="${classes.tooltipAlert}"></div>
            <Typography
                class=${classes.tooltipAlertStr}
            >
              Увеличьте количество мест
            </Typography>
          </div>`;

          showTooltip(currentEvent, toolTipHtml);
        })
        .on('mouseout', hideTooltip)
        .transition()
        .duration(500)
        .delay(200)
        .attr(
          'transform',
          (d) => `translate(${getIconX(d)}, ${yAlertAbs - 12})`,
        );

      /* Alerts end */
    },
    // eslint-disable-next-line
    [barChartData, classes, height, width],
  );

  return (
    <svg
      ref={d3Container}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    />
  );
});

CongestionBarChart.propTypes = propTypes;

export default CongestionBarChart;
