// eslint-disable react/require-default-props
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { area, axisBottom, axisLeft, axisRight, curveStep, line, max, scaleLinear, scaleSqrt, select } from 'd3';
import Typography from '@material-ui/core/Typography';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import { CHART_CONTAINER_ID } from '@parkly/shared/config/constants';

import { useStyles } from './styles';

/* Help functions */

/* Main function */

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  width: PropTypes.number,
  height: PropTypes.number,
  isLoading: PropTypes.bool,
};

const DistributionByDurationChart = React.memo(({
  data = [],
  width = 1060,
  height = 400,
  isLoading,
}) => {
  const classes = useStyles();
  const d3Container = useRef(null);

  useEffect(
    () => {
      if (!data || !d3Container.current) {
        return;
      }

      const margin = {
        top: 10,
        right: 0,
        bottom: 28,
        left: 75,
        leftAxis: 40,
      };

      const svg = select(d3Container.current);

      const xMax = max(data, ({ duration }) => duration);
      const xScale = scaleSqrt()
        .domain([1, xMax])
        .range([margin.left, width - margin.left - margin.right]);

      const yMaxCount = max(data, ({ count }) => count) * 1.1;
      const yScaleCount = scaleLinear()
        .domain([0, yMaxCount])
        .range([height - margin.bottom, margin.top]);

      const yMaxAvPrice = max(data, ({ averPrice }) => averPrice) * 1.1;
      const yScaleAvPrice = scaleLinear()
        .domain([0, yMaxAvPrice])
        .range([height - margin.bottom, margin.top]);

      /* Axis start */
      if (data.length > 1) {
        const xAxis = axisBottom()
          .tickPadding(10)
          .scale(xScale);

        const xAxisG = svg.select('.xAxis').empty()
          ? svg.append('g')
            .attr('class', `xAxis ${classes.xAxis}`)
            .attr('transform', `translate(${0}, ${height - margin.bottom})`)
          : svg.select('.xAxis');

        xAxisG
          .transition()
          .duration(500)
          .call(xAxis);

        const yAxisCount = axisLeft()
          .tickPadding(10)
          .scale(yScaleCount);

        const yAxisG = svg.select('.yAxis').empty()
          ? svg.append('g')
            .attr('class', `yAxis ${classes.yAxisCount}`)
            .attr('transform', `translate(${margin.left}, 0)`)
          : svg.select('.yAxis');

        yAxisG
          .transition()
          .duration(500)
          .call(yAxisCount);

        // Price chart

        const yAxisAvPrice = axisRight()
          .tickPadding(10)
          .scale(yScaleAvPrice);

        const yAxisGAvPrice = svg.append('g')
          .attr('class', `yAxis ${classes.yAxisAvPrice}`)
          .attr('transform', `translate(${width - margin.left - margin.right}, 0)`);

        yAxisGAvPrice
          .transition()
          .duration(500)
          .call(yAxisAvPrice);
      }
      /* Axis end */

      const areaCount = area()
        .curve(curveStep)
        .x(({ duration }) => xScale(duration))
        .y0(yScaleCount(0))
        .y1(({ count }) => yScaleCount(count));

      const areaPath = svg.select('.areaPath').empty()
        ? svg.append('path')
          .attr('class', 'areaPath')
          .attr('fill', '#cce5df')
          .attr('stroke', '#69b3a2')
          .attr('stroke-width', 1.5)
        : svg.select('.areaPath');

      areaPath.transition()
        .duration(500)
        .attr('d', areaCount(data));

      const lineAvPrice = line()
        .x(({ duration }) => xScale(duration))
        .y(({ averPrice }) => yScaleAvPrice(averPrice));

      const linePath = svg.select('.linePath').empty()
        ? svg.append('path')
          .attr('fill', 'none')
          .attr('stroke', 'steelblue')
          .attr('stroke-width', 1.5)
        : svg.select('.linePath');

      linePath
        .transition()
        .duration(500)
        .attr('d', lineAvPrice(data));

      /* Axis titles */

      if (svg.select('.axisAvPriceTitle').empty()) {

        svg.append('text')
          .attr('class', 'axisAvPriceTitle')
          .attr('transform', `translate(${margin.left}, ${margin.top - 60})`)
          .text('Количество бронирований');
      }
    },
    // eslint-disable-next-line
    [data, height, width],
  );

  return (
    <div
      className={classes.chartContainer}
      id={CHART_CONTAINER_ID}
    >
      <div className={classes.chartTopLegend}>
        <div className={classes.chartTopLeftLegend}>
          <div className={classes.iconLeft} />
          <Typography className={classes.titleStr}>
            {'Количество бронирований'}
          </Typography>
        </div>
        <div className={classes.chartTopLeftLegend}>
          <Typography className={classes.titleStr}>
            {'Средняя стоимость бронирования, ₽'}
          </Typography>
          <div className={classes.iconRight} />
        </div>
      </div>
      <svg
        ref={d3Container}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      />
      <div className={classes.chartBottomLegend}>
        <Typography className={classes.titleStr}>
          {'Продолжительность бронирования, час'}
        </Typography>
      </div>
      {isLoading && (data.length < 1) && (
        <CircularIndeterminate style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: 400,
          width: 1060,
        }}
        />
      )}
    </div>
  );
});

DistributionByDurationChart.propTypes = propTypes;

export default DistributionByDurationChart;
