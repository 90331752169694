/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import FilledInput from '@material-ui/core/FilledInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';

import { useStyles } from './styles';

function getFormClassName({
  type,
  className,
  classes,
}) {
  switch (type) {
    case 'secondary':
      return `${classes.default} ${classes.secondary} ${className}`;

    case 'text':
    default:
      return `${classes.default} ${className}`;
  }
}

function CustomTextInput({
  className = '',
  type = 'text',
  required = false,
  error = false,
  label,
  onBlur,
  onChange,
  inputRef,
  ...props
}) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (type === 'passwordMightVisible') {
    return (
      <FormControl
        className={`${classes.pasFormControl} ${className}`}
        variant="filled"
      >
        <InputLabel
          className={`${classes.pasLabel}`}
          htmlFor="filled-adornment-password"
          required={required}
          error={!!error}
        >
          {label}
        </InputLabel>
        <FilledInput
          inputRef={inputRef}
          className={`${classes.pasFilledInput}`}
          id="filled-adornment-password"
          type={values.showPassword ? 'text' : 'password'}
          required={required}
          error={!!error}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
          onChange={onChange}
          onBlur={onBlur}
          {...props}
        />
      </FormControl>
    );
  }

  const defaultClassName = getFormClassName({
    type,
    className,
    classes,
  });

  function handleOnBlur(event) {
    if (onBlur) {
      onBlur(event);
      return;
    }

    if (type === 'password') {
      return;
    }

    const { target } = event || {};
    const { value, name } = target || {};
    const newValue = (value || '').trim();

    if (onChange) {
      const newEvent = {
        ...event,
        target: {
          ...target,
          value: newValue,
          name,
        },
      };
      onChange(newEvent);
    } else {
      event.nativeEvent.target.value = newValue;
    }
  }

  return (
    <TextField
      inputRef={inputRef}
      className={defaultClassName}
      type={type}
      label={label}
      required={required}
      variant="filled"
      margin="normal"
      error={!!error}
      fullWidth
      onChange={onChange}
      onBlur={handleOnBlur}
      {...props}
    />
  );
}

export default CustomTextInput;
