import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import { PATH_PAGES, PLATFORM_TYPES } from 'config/constants';
import { getAllPlatforms } from 'actions';
import { getScheduleTimeStr } from '@parkly/shared/helpers/time';

import { useStyles } from './styles';

/* help function */

function getHeaders({
  t,
}) {
  return [
    {
      label: t('platforms.nameAndAddress'),
    },
    {
      label: t('platforms.emptySpaces'),
    },
    {
      label: t('platforms.bookings'),
    },
    {
      label: t('platforms.mobilePayments'),
    },
    {
      label: t('platforms.workSchedule'),
    },
    {
      label: t('platforms.monthAmount'),
    },
  ];
}

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.any,
  currentOperator: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.any,
    userRights: PropTypes.any,
  }),
  allPlatforms: PropTypes.shape({
    loading: PropTypes.bool,
    platforms: PropTypes.any,
  }),
  getAllPlatformsReq: PropTypes.func,
};

function AllPlatforms({
  history,
  allPlatforms = {},
  currentOperator = {},
  getAllPlatformsReq = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(
    () => {
      const { loading, platforms } = allPlatforms;
      const { data } = platforms || {};
      const isPlatformsLoaded = !!data;

      if (isPlatformsLoaded || loading) {
        return;
      }

      getAllPlatformsReq();
    },
    // eslint-disable-next-line
    []
  );

  const { userRights } = currentOperator || {};
  const {
    isAdmin,
    readPlatformInfoRightPlatforms,
    readActBookLisPlatforms,
    readHistBookLisPlatforms,
  } = userRights || {};

  const { loading, platforms } = allPlatforms;
  const { data } = platforms || {};

  const headers = getHeaders({ t });

  function createHandlerLinkClick(linkStr, isRight) {
    return function handleEmptySpaceClick(event) {
      if (!isRight) {
        return;
      }

      event.stopPropagation();

      const nodeName = event.nativeEvent.target.nodeName || '';

      if (nodeName.toLowerCase() !== 'a') {
        history.push(linkStr);
      }
    };
  }

  return (
    <Container
      className={classes.container}
      maxWidth="md"
    >
      <HeaderWithBackBtn
        title={t('navigation.platform')}
        isBackBtn={false}
      >
        <Typography className={classes.headerTime}>
          {moment().format('DD MMMM, yyyy')}
        </Typography>
      </HeaderWithBackBtn>
      <div className={classes.pageBody}>
        {data && !loading && (
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headers.map(({ label }) => (
                    <TableCell
                      key={label}
                      align="left"
                    >
                      <Typography className={classes.headerStr}>
                        {label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {data.map((platform = {}, index, array) => {
                  const key = platform.id;
                  const {
                    address,
                    id,
                    monthlyIncome,
                    title,
                    type,
                    todayPlacesCount,
                    todayReservations,
                    todayMobilePayments,
                    todaySchedule: {
                      availableFrom,
                      availableTo,
                    },
                  } = platform;

                  const hasReservation = [PLATFORM_TYPES.all, PLATFORM_TYPES.reservation].includes(type);
                  const hasMobilePayment = [PLATFORM_TYPES.all, PLATFORM_TYPES.mobilePayment].includes(type);

                  const availableFromMoment = moment.utc(availableFrom, 'HH:mm:ss').format();
                  const availableFromStr = getScheduleTimeStr(availableFromMoment);
                  const availableToMoment = moment.utc(availableTo, 'HH:mm:ss').format();
                  const availableToStr = getScheduleTimeStr(availableToMoment);

                  const isPlatformInfoRight = readPlatformInfoRightPlatforms.includes(+id);
                  const isPlatformBookingsRight = readActBookLisPlatforms.includes(+id)
                    || readHistBookLisPlatforms.includes(+id);
                  const isRightToReadPlatformInfo = isAdmin || isPlatformInfoRight;
                  const isRightToReadBookingsInfo = isAdmin || isPlatformBookingsRight;

                  const scheduleLinkStr = PATH_PAGES.platformSchedule.replace(':id', id);
                  const linkPlatformInfo = `${PATH_PAGES.platforms}/${id}`;
                  const bookingsLink = PATH_PAGES.bookingsId.replace(':id', id);
                  const mobilePaymentsLink = PATH_PAGES.mobilePaymentsId.replace(':id', id);

                  const isLast = index === (array.length - 1);
                  const tableRowClass = [
                    isRightToReadPlatformInfo ? classes.tableRow : classes.tableWithoutRightRow,
                    isLast ? classes.tableRowLast : '',
                  ];

                  return (
                    <TableRow
                      className={tableRowClass.join(' ')}
                      key={key}
                      name={id}
                      hover
                      tabIndex={-1}
                      onClick={createHandlerLinkClick(linkPlatformInfo, isRightToReadPlatformInfo)}
                    >
                      <TableCell
                        align="left"
                        className={classes.tableCellNameAddress}
                      >
                        <Typography className={classes.tableCellName}>
                          {title}
                        </Typography>
                        <Typography className={classes.tableCellAddress}>
                          {address}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.tableCell} ${(hasReservation && isRightToReadPlatformInfo) ? classes.tCellHovered : ''}`}
                        onClick={createHandlerLinkClick(
                          scheduleLinkStr,
                          isRightToReadPlatformInfo,
                        )}
                      >
                        {hasReservation && isRightToReadPlatformInfo && (
                          <CustomLink
                            href={scheduleLinkStr}
                          >
                            {todayPlacesCount}
                          </CustomLink>
                        )}
                        {hasReservation && !isRightToReadPlatformInfo && (
                          <Typography
                            className={classes.tableCellName}
                          >
                            {todayPlacesCount}
                          </Typography>
                        )}
                        {!hasReservation && (
                          <Typography
                            className={classes.tableCellName}
                          >
                            -
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.tableCell} ${(hasReservation && isRightToReadBookingsInfo) ? classes.tCellHovered : ''}`}
                        onClick={createHandlerLinkClick(
                          bookingsLink,
                          isRightToReadBookingsInfo,
                        )}
                      >
                        {hasReservation && isRightToReadBookingsInfo && (
                          <CustomLink
                            href={bookingsLink}
                          >
                            {todayReservations}
                          </CustomLink>
                        )}
                        {hasReservation && !isRightToReadBookingsInfo && (
                          <Typography
                            className={classes.tableCellName}
                          >
                            {todayReservations}
                          </Typography>
                        )}
                        {!hasReservation && (
                          <Typography
                            className={classes.tableCellName}
                          >
                            -
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.tableCell} ${(hasMobilePayment && isRightToReadBookingsInfo) ? classes.tCellHovered : ''}`}
                        onClick={createHandlerLinkClick(
                          mobilePaymentsLink,
                          isRightToReadBookingsInfo,
                        )}
                      >
                        {hasMobilePayment && isRightToReadBookingsInfo && (
                          <CustomLink
                            href={mobilePaymentsLink}
                          >
                            {todayMobilePayments}
                          </CustomLink>
                        )}
                        {hasMobilePayment && !isRightToReadBookingsInfo && (
                          <Typography
                            className={classes.tableCellName}
                          >
                            {todayMobilePayments}
                          </Typography>
                        )}
                        {!hasMobilePayment && (
                          <Typography
                            className={classes.tableCellName}
                          >
                            -
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.tableCell} ${(hasReservation && isRightToReadPlatformInfo) ? classes.tCellHovered : ''}`}
                        onClick={createHandlerLinkClick(
                          scheduleLinkStr,
                          isRightToReadPlatformInfo,
                        )}
                      >
                        {hasReservation && isRightToReadPlatformInfo && (
                          <CustomLink
                            href={scheduleLinkStr}
                          >
                            {`${availableFromStr} - ${availableToStr}`}
                          </CustomLink>
                        )}
                        {hasReservation && !isRightToReadPlatformInfo && (
                          <Typography
                            className={classes.tableCellName}
                          >
                            {`${availableFromStr} - ${availableToStr}`}
                          </Typography>
                        )}
                        {!hasReservation && (
                          <Typography
                            className={classes.tableCellName}
                          >
                            -
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        // className={classes.tableCell}
                      >
                        <Typography className={classes.tableCellName}>
                          {`${monthlyIncome} ${t('others.currency')}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {loading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
      </div>
    </Container>
  );
}

AllPlatforms.propTypes = propTypes;

function mapStateToProps(state) {
  const { platform, operators } = state || {};
  const { allPlatforms } = platform || {};
  const { currentOperator } = operators || {};

  return {
    allPlatforms,
    currentOperator,
  };
}

const ConnectedAllPlatforms = connect(
  mapStateToProps,
  {
    getAllPlatformsReq: getAllPlatforms,
  },
)(AllPlatforms);

export default ConnectedAllPlatforms;
