import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import WorkScheduleTable from 'components/organisms/WorkScheduleTable';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import ChangeableValueAndTitle from '@parkly/shared/components/molecules/ChangeableValueAndTitle';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomDatePicker from '@parkly/shared/components/atoms/CustomDatePicker';
import ScheduleChanger from 'components/templates/ScheduleChanger';

import { getPlatformScheduleAction } from 'actions';
import { getScheduleTimeStr } from '@parkly/shared/helpers/time';
import { SCHEDULE_SETTINGS_TYPES } from 'config/constants';

import { useStyles } from './styles';

/* help functions */

function getDefTimeStrWithLocal({
  defTimeStartStr,
  defTimeEndStr,
  localUpdList,
}) {
  const currentUpd = (localUpdList || []).find((itemUpd) => {
    const {
      param,
    } = itemUpd || {};

    const {
      isScheduleChanges,
      type,
    } = param || {};

    return isScheduleChanges && type === SCHEDULE_SETTINGS_TYPES.default;
  });

  if (currentUpd) {
    const {
      param,
    } = currentUpd || {};

    const {
      timeEnd,
      timeStart,
    } = param || {};

    return `${timeStart}-${timeEnd}`;
  }

  const defaultTimeStr = `${defTimeStartStr}-${defTimeEndStr}`;

  return defaultTimeStr;
}

function getDefParkSpaceWithLocal({
  defaultParkSpace,
  localUpdList,
}) {
  const currentUpd = (localUpdList || []).find((itemUpd) => {
    const {
      param,
    } = itemUpd || {};

    const {
      isParkSpaceChanges,
      type,
    } = param || {};

    return isParkSpaceChanges && type === SCHEDULE_SETTINGS_TYPES.default;
  });

  if (!currentUpd) {
    return defaultParkSpace;
  }

  const {
    param,
  } = currentUpd || {};

  const {
    parkSpace,
  } = param || {};

  return parkSpace;
}

function mergeLocalParkSpaceUpd({
  daysData = [],
  localUpdList = [],
}) {
  const currentUpdParkSpace = (localUpdList || []).find((itemUpd) => {
    const {
      param,
    } = itemUpd || {};

    const {
      isParkSpaceChanges,
    } = param || {};

    return isParkSpaceChanges;
  });

  if (currentUpdParkSpace) {
    const {
      param,
    } = currentUpdParkSpace || {};

    const {
      dayDate,
      weekDay,
      type,
      parkSpace,
      isParkSpaceDefault,
      placesRuleType,
    } = param || {};

    if (type === SCHEDULE_SETTINGS_TYPES.day) {
      if (placesRuleType === SCHEDULE_SETTINGS_TYPES.day) {
        if (isParkSpaceDefault) {
          return daysData.map((day = {}) => {
            const { date } = day || {};

            if (date === dayDate) {
              return {
                ...day,
                places: {
                  ...(day || {}).places,
                  count: parkSpace,
                  type: SCHEDULE_SETTINGS_TYPES.default,
                },
              };
            }

            return day;
          });
        }
      }

      return daysData.map((day = {}) => {
        const { date } = day || {};

        if (date === dayDate) {
          return {
            ...day,
            places: {
              ...(day || {}).places,
              count: parkSpace,
              type: SCHEDULE_SETTINGS_TYPES.day,
            },
          };
        }

        return day;
      });
    }

    if (type === SCHEDULE_SETTINGS_TYPES.week) {
      if (placesRuleType === SCHEDULE_SETTINGS_TYPES.week) {
        if (isParkSpaceDefault) {
          return daysData.map((day = {}) => {
            const {
              date,
              places = {},
            } = day || {};
            const {
              type: placesType,
            } = places || {};
            const itemWeekDay = moment(date, 'YYYY-MM-DD').isoWeekday();

            if (weekDay === itemWeekDay && placesType !== SCHEDULE_SETTINGS_TYPES.day) {
              return {
                ...day,
                places: {
                  ...places,
                  count: parkSpace,
                  type: SCHEDULE_SETTINGS_TYPES.default,
                },
              };
            }

            return day;
          });
        }
      }

      return daysData.map((day = {}) => {
        const {
          date,
          places = {},
        } = day || {};
        const {
          type: placesType,
        } = places || {};
        const itemWeekDay = moment(date, 'YYYY-MM-DD').isoWeekday();

        if (weekDay === itemWeekDay && placesType !== SCHEDULE_SETTINGS_TYPES.day) {
          return {
            ...day,
            places: {
              ...places,
              count: parkSpace,
              type: SCHEDULE_SETTINGS_TYPES.week,
            },
          };
        }

        return day;
      });
    }

    if (type === SCHEDULE_SETTINGS_TYPES.default) {
      return daysData.map((day = {}) => {
        const { places } = day || {};
        const { type: dayType } = places || {};

        if (dayType === SCHEDULE_SETTINGS_TYPES.default) {
          return {
            ...day,
            places: {
              ...(day || {}).places,
              count: parkSpace,
            },
          };
        }

        return day;
      });
    }
  }

  return daysData;
}

function mergeLocalScheduleUpd({
  daysData = [],
  localUpdList = [],
  tariffs = [],
}) {
  const currentUpdSchedule = (localUpdList || []).find((itemUpd) => {
    const {
      param,
    } = itemUpd || {};

    const {
      isScheduleChanges,
    } = param || {};

    return isScheduleChanges;
  });

  if (currentUpdSchedule) {
    const {
      param,
    } = currentUpdSchedule || {};

    const {
      type,
      timeRuleType,
      isTimeDefault,
      isTariffDefault,
      dayDate,
      weekDay,
      timeEnd,
      timeStart,
      isAvailable,
      tariffId,
    } = param || {};

    const {
      name: tariff,
    } = tariffs.find(({ id: itemTariffId }) => itemTariffId === tariffId) || {};

    const timeStartMoment = moment(timeStart, 'HH:mm');
    const availableFrom = timeStartMoment.utc().format();
    const timeEndMoment = moment(timeEnd, 'HH:mm');
    const availableTo = timeEndMoment.utc().format();

    if (type === SCHEDULE_SETTINGS_TYPES.day) {
      if (timeRuleType === SCHEDULE_SETTINGS_TYPES.day) {
        if (isTimeDefault && isAvailable && isTariffDefault) {
          return daysData.map((day = {}) => {
            const { date } = day || {};

            if (date === dayDate) {
              return {
                ...day,
                isAvailable,
                schedule: {
                  ...(day || {}).schedule,
                  availableFrom,
                  availableTo,
                  tariffId,
                  tariff,
                  type: SCHEDULE_SETTINGS_TYPES.default,
                },
              };
            }

            return day;
          });
        }
      }

      return daysData.map((day = {}) => {
        const { date } = day || {};

        if (date === dayDate) {
          return {
            ...day,
            isAvailable,
            schedule: {
              ...(day || {}).schedule,
              availableFrom,
              availableTo,
              tariffId,
              tariff,
              type: SCHEDULE_SETTINGS_TYPES.day,
            },
          };
        }

        return day;
      });
    }

    if (type === SCHEDULE_SETTINGS_TYPES.week) {
      if (timeRuleType === SCHEDULE_SETTINGS_TYPES.week) {
        if (isTimeDefault && isAvailable && isTariffDefault) {
          return daysData.map((day = {}) => {
            const {
              date,
              schedule = {},
            } = day || {};
            const {
              type: scheduleType,
            } = schedule || {};
            const itemWeekDay = moment(date, 'YYYY-MM-DD').isoWeekday();

            if (weekDay === itemWeekDay && scheduleType !== SCHEDULE_SETTINGS_TYPES.day) {
              return {
                ...day,
                isAvailable,
                schedule: {
                  ...schedule,
                  availableFrom,
                  availableTo,
                  tariffId,
                  tariff,
                  type: SCHEDULE_SETTINGS_TYPES.default,
                },
              };
            }

            return day;
          });
        }
      }

      return daysData.map((day = {}) => {
        const {
          date,
          schedule = {},
        } = day || {};
        const {
          type: scheduleType,
        } = schedule || {};
        const itemWeekDay = moment(date, 'YYYY-MM-DD').isoWeekday();

        if (weekDay === itemWeekDay && scheduleType !== SCHEDULE_SETTINGS_TYPES.day) {
          return {
            ...day,
            isAvailable,
            schedule: {
              ...schedule,
              availableFrom,
              availableTo,
              tariffId,
              tariff,
              type: SCHEDULE_SETTINGS_TYPES.week,
            },
          };
        }

        return day;
      });
    }

    if (type === SCHEDULE_SETTINGS_TYPES.default) {
      return daysData.map((day = {}) => {
        const {
          schedule = {},
        } = day || {};
        const {
          type: scheduleType,
        } = schedule || {};

        if (scheduleType === SCHEDULE_SETTINGS_TYPES.default) {
          return {
            ...day,
            isAvailable,
            schedule: {
              ...schedule,
              availableFrom,
              availableTo,
            },
          };
        }

        return day;
      });
    }
  }

  return daysData;
}

function mergeLocalUpd({
  daysData = [],
  localUpdList = [],
  tariffs = [],
}) {
  if (!localUpdList || localUpdList.length < 1) {
    return daysData;
  }

  const mergedLocalScheduleUpd = mergeLocalScheduleUpd({
    daysData,
    localUpdList,
    tariffs,
  });

  const mergedAllUpd = mergeLocalParkSpaceUpd({
    daysData: mergedLocalScheduleUpd,
    localUpdList,
  });

  return mergedAllUpd;
}

function getMonthTableDays({
  dayOfMonth = moment(),
  daysData = [],
  localUpdList,
  tariffs,
}) {
  const currentDay = moment();
  const monthStart = dayOfMonth.startOf('month');
  const numberOfDay = dayOfMonth.daysInMonth();
  const monthStartWeekDay = monthStart.isoWeekday(); //  1-7
  const draftDays1 = numberOfDay + monthStartWeekDay - 1;
  const draftDays2 = 7 * Math.ceil(draftDays1 / 7);
  const draftTableDays = new Array(draftDays2).fill(0);
  const daysDataWithLocalUpd = mergeLocalUpd({
    daysData,
    localUpdList,
    tariffs,
  });

  const tableDays = draftTableDays.reduce(
    (accum, current, index) => {
      const dayNumber = index + 1 - (monthStartWeekDay - 1);
      const isEmpty = dayNumber < 1 || dayNumber > numberOfDay;
      const momentDay = moment(monthStart).add((dayNumber - 1), 'days');
      const isCurrentDay = momentDay.isSame(currentDay, 'd');
      const isPast = momentDay.isBefore(currentDay) && !isCurrentDay;

      const dayData = isEmpty
        ? null
        : daysDataWithLocalUpd[dayNumber - 1];

      const day = {
        isEmpty,
        isPast,
        isCurrentDay,
        dayNumber,
        dayData,
        momentDay,
      };

      const currentWeek = accum[accum.length - 1];

      if (currentWeek.length >= 7) {
        const newWeek = [day];

        return [
          ...accum,
          newWeek,
        ];
      }

      return [
        ...accum.slice(0, -1),
        [
          ...currentWeek,
          day,
        ],
      ];
    },
    [[]],
  );

  return tableDays;
}

function getCurrentSchedule({
  platformSchedules,
  monthTime,
  platformId,
}) {
  const monthStr = monthTime.format('YYYY-MM');
  const { list } = platformSchedules || {};
  const currentSchedule = (list || []).find(({ requestParam }) =>
    // eslint-disable-next-line eqeqeq,implicit-arrow-linebreak
    (requestParam || {}).id == platformId && requestParam.month === monthStr);

  return {
    currentSchedule,
  };
}

/* Main components */

const propTypes = {
  platformSchedules: PropTypes.shape({
    loading: PropTypes.bool,
    list: PropTypes.array,
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  onePlatform: PropTypes.shape({
    list: PropTypes.array,
    loading: PropTypes.bool,
  }),
  currentOperator: PropTypes.shape({
    userRights: PropTypes.object,
  }),
  updPlatformScheduleRes: PropTypes.shape({
    list: PropTypes.array,
  }),
  getPlatformScheduleReq: PropTypes.func,
};

function WorkSchedule({
  platformSchedules,
  match,
  currentOperator,
  updPlatformScheduleRes,
  getPlatformScheduleReq = () => {},
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [monthTime, setMonthTime] = useState(moment());
  const [isHideTable, setIsHideTable] = useState(false);
  const [modalOption, setModalOption] = useState({
    isModalOpen: false,
  });

  useEffect(
    () => {
      const { params } = match || {};
      const { id } = params || {};
      const month = monthTime.format('YYYY-MM');

      const {
        currentSchedule,
      } = getCurrentSchedule({
        platformSchedules,
        monthTime,
        platformId: id,
      });

      if (currentSchedule) {
        return;
      }

      getPlatformScheduleReq({
        id,
        month,
      });
    },
    // eslint-disable-next-line
    [match.params.id, monthTime, platformSchedules.list],
  );
  useEffect(
    () => {
      // TODO для устранения мерцания, по идее нужно переделать в слайдер
      setIsHideTable(true);
      setTimeout(
        () => {
          setIsHideTable(false);
        },
        100,
      );
    },
    // eslint-disable-next-line
    [monthTime],
  );

  const { params } = match || {};
  const { id: platformId } = params || {};

  const { userRights } = currentOperator || {};
  const { isAdmin, updSchedulePlatforms } = userRights || {};
  const isRightUpdateSchedule = isAdmin || (updSchedulePlatforms || []).includes(+platformId);

  const {
    currentSchedule,
  } = getCurrentSchedule({
    platformSchedules,
    monthTime,
    platformId,
  });

  const { data } = currentSchedule || {};
  const {
    days,
    everyDayPlaces,
    everyDaySchedule,
    tariffs,
  } = data || {};

  const {
    list: updList,
  } = updPlatformScheduleRes || {};
  const localUpdList = (updList || []).filter((item) => {
    const { param } = item || {};
    const { platformId: curPltId } = param || {};

    // eslint-disable-next-line eqeqeq
    return curPltId == platformId;
  });

  const tableDays = getMonthTableDays({
    dayOfMonth: monthTime,
    daysData: days,
    localUpdList,
    tariffs,
  });

  const defaultParkSpace = (everyDayPlaces || {}).count;
  const defTimeStart = (everyDaySchedule || {}).availableFrom;
  const defTimeEnd = (everyDaySchedule || {}).availableTo;
  const defTimeStartStr = getScheduleTimeStr(defTimeStart);
  const defTimeEndStr = getScheduleTimeStr(defTimeEnd);

  const defaultParkSpaceWithLocal = getDefParkSpaceWithLocal({
    defaultParkSpace,
    localUpdList,
  });
  const defaultTimeStrWithLocal = getDefTimeStrWithLocal({
    defTimeStartStr,
    defTimeEndStr,
    localUpdList,
  });

  function handleCloseModal() {
    setModalOption((prevModalOption) => ({
      ...prevModalOption,
      isModalOpen: false,
    }));
  }
  function openChangeScheduleModal({ type, momentDate, tab }) {
    setModalOption((prevModalOption) => ({
      ...prevModalOption,
      isModalOpen: true,
      tab,
      type,
      momentDate,
    }));
  }
  function onDefaultTimeChange() {
    openChangeScheduleModal({
      type: SCHEDULE_SETTINGS_TYPES.default,
      tab: 'time',
      momentDate: monthTime,
    });
  }
  function onDefaultParkSpaceChange() {
    openChangeScheduleModal({
      type: SCHEDULE_SETTINGS_TYPES.default,
      tab: 'parkSpace',
      momentDate: monthTime,
    });
  }

  function onChangeDate(date) {
    setIsHideTable(true);
    setTimeout(
      () => {
        setMonthTime(date);
      },
      100,
    );
  }

  const {
    isModalOpen, type, tab, momentDate,
  } = modalOption;

  const isLoading = !data;

  return (
    <Container className={classes.containerPage}>
      <Box className={classes.headerContainer}>
        <div className={classes.leftHeader}>
          <HeaderWithBackBtn
            title={t('platforms.workSchedule')}
            isBackBtn
          />
          <div className={classes.monthSelContainer}>
            <CustomDatePicker
              className={classes.datePicker}
              format="MMMM, YYYY"
              arrowBtnsStep="month"
              value={monthTime}
              onChange={onChangeDate}
            />
          </div>
        </div>
        {!isLoading && (
          <div className={classes.rightHeader}>
            <ChangeableValueAndTitle
              title={t('platforms.baseParkingSpace')}
              value={defaultParkSpaceWithLocal}
              isChangeable={isRightUpdateSchedule}
              onChangeClick={onDefaultParkSpaceChange}
            />
            <ChangeableValueAndTitle
              title={t('platforms.baseSchedule')}
              value={defaultTimeStrWithLocal}
              isChangeable={isRightUpdateSchedule}
              onChangeClick={onDefaultTimeChange}
            />
          </div>
        )}
      </Box>
      {!isLoading && (
        <WorkScheduleTable
          isRightUpdateSchedule={isRightUpdateSchedule}
          tableDays={tableDays}
          isHideTable={isHideTable}
          openChangeScheduleModal={openChangeScheduleModal}
        />
      )}
      {isLoading && (
        <CircularIndeterminate
          type="fullPage"
          style={{
            // minHeight: 235,
            marginLeft: -32,
            top: 0,
          }}
        />
      )}

      <ScheduleChanger
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        momentDate={momentDate}
        platformId={platformId}
        type={type}
        tab={tab}
      />

    </Container>
  );
}

WorkSchedule.propTypes = propTypes;

function mapStateToProps(state) {
  const { platform, operators } = state || {};
  const { platformSchedules, updPlatformScheduleRes } = platform || {};
  const { currentOperator } = operators || {};

  return {
    platformSchedules,
    currentOperator,
    updPlatformScheduleRes,
  };
}

const ConnectedWorkSchedule = connect(
  mapStateToProps,
  {
    getPlatformScheduleReq: getPlatformScheduleAction,
  },
)(WorkSchedule);

export default ConnectedWorkSchedule;
