import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import ForOrBackWord from '@parkly/shared/components/atoms/icons/ForOrBackWord';

const CustomNextBackIconBtn = withStyles((theme) => ({
  root: {
    height: 32,
    width: 32,
    marginRight: 8,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :hover svg circle': {
      fill: theme.palette.greyC.lightGrey,
    },
  },
}))(IconButton);

function NextBackIconBtn({
  className = '',
  isBack = false,
  onClick = () => {},
}) {
  return (
    <CustomNextBackIconBtn
      className={className}
      onClick={onClick}
      aria-label={isBack ? 'back' : 'forward'}
    >
      <ForOrBackWord isBack={isBack} />
    </CustomNextBackIconBtn>
  );
}

export default NextBackIconBtn;
