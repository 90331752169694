import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@parkly/shared/components/atoms/icons/CloseIcon';
import ChangeScheduleTabs from 'components/organisms/ChangeScheduleTabs';

import { SCHEDULE_SETTINGS_TAB_TYPES } from 'config/constants';

import { useStyles } from './styles';

/* Help functions */

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function getTitle({
  type,
  momentDate,
  t = () => {},
}) {
  if (type === 'default') {
    return t('platforms.defaultSettings');
  }

  if (type === 'week') {
    const weekIndex = momentDate.isoWeekday() - 1;
    const weekDays = ['Понедельники', 'Вторники', 'Среды', 'Четверги', 'Пятницы', 'Субботы', 'Воскресенья'];
    const monthStr = momentDate.format('MMMM');

    return `${weekDays[weekIndex]}, ${monthStr}`;
  }

  if (type === 'day') {
    return momentDate.format('D MMMM, dddd');
  }

  return '';
}

/* Components */

const CustomCloseIconBtn = withStyles(() => ({
  root: {
    height: 32,
    width: 32,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 16,
    right: 16,
  },
}))(IconButton);

/* Main component */

const propTypes = {
  defTariffObj: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  scheduleLinkStr: PropTypes.string,
  tariffs: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

function ChangeScheduleModal({
  type = 'day',
  tab = SCHEDULE_SETTINGS_TAB_TYPES.time,
  isOpen = false,
  momentDate = moment(),
  dayWithRule = {},
  defaultParkSpace,
  defTimeStart,
  defTimeEnd,
  defTariffObj,
  scheduleLinkStr,
  tariffs,
  loading = false,
  handleApplyChanges = () => {},
  handleClose = () => {},
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const title = getTitle({
    t,
    type,
    momentDate,
  });

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
      disableAutoFocus
    >
      <div
        style={modalStyle}
        className={classes.paper}
      >
        <div className={classes.headerContainer}>
          <CustomCloseIconBtn
            onClick={handleClose}
          >
            <CloseIcon />
          </CustomCloseIconBtn>
          <Typography className={classes.headerStr}>
            {title}
          </Typography>
        </div>

        <div className={classes.modalBody}>
          <ChangeScheduleTabs {...{
            type,
            tab,
            defaultParkSpace,
            defTimeStart,
            defTimeEnd,
            defTariffObj,
            dayWithRule,
            loading,
            scheduleLinkStr,
            tariffs,
            handleApplyChanges,
          }}
          />
        </div>
      </div>
    </Modal>
  );
}

ChangeScheduleModal.propTypes = propTypes;

export default ChangeScheduleModal;
