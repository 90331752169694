import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  containerClassic: {
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
}));

const propTypes = {
  to: PropTypes.string.isRequired,
  matchTo: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  variant: PropTypes.string,
};

function NavigationLink({
  to = '/',
  matchTo,
  className,
  variant,
  activeClassName,
  children,
  isNeedExact = false,
  ...props
}) {
  const classes = useStyles();
  const match = useRouteMatch(matchTo || to);

  const isActive = isNeedExact
    ? (!!match && match.isExact)
    : !!match;

  const containerStyle = variant === 'classic'
    ? classes.containerClassic
    : classes.container;

  const activeClass = (activeClassName && isActive) ? activeClassName : '';
  const currentClassName = `${className} ${containerStyle} ${activeClass}`;
  const isFunction = typeof children === 'function';

  return (
    <Link
      to={to}
      className={currentClassName}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      { isFunction ? children({ isActive }) : children }
    </Link>
  );
}

NavigationLink.propTypes = propTypes;

export default NavigationLink;
