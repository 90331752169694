import {
  GET_PLATFORMS_REQUEST,
  GET_ONE_PLATFORM_REQUEST,
  GET_PLATFORM_SCHEDULE_REQUEST,
  UPDATE_PLATFORM_SCHEDULE_REQUEST,
  CLEAR_CACHE_PLATFORM_SCHEDULE,
  CLEAR_CACHE_ONE_PLATFORMS,
  CLEAR_CACHE_ALL_PLATFORMS,
} from 'actions/types';

const INITIAL_STATE = {
  allPlatforms: {
    loading: false,
    platforms: undefined,
  },
  onePlatform: {
    loading: false,
    list: [],
  },
  platformSchedules: {
    loading: false,
    list: [],
  },
  updPlatformScheduleRes: {
    loading: false,
  },
};

function updateSchedulesList({
  newPlatformSchedule,
  platformSchedules,
}) {
  const { list = [] } = platformSchedules || {};

  if (!newPlatformSchedule) {
    return list;
  }

  const { requestParam = {} } = newPlatformSchedule || {};

  const newList = [
    ...list
      .slice(0, 5)
      .filter((item = {}) => {
        const currentId = (item.requestParam || {}).id;
        const currentMonth = (item.requestParam || {}).month;

        const isFilter = !(currentId === requestParam.id && currentMonth === requestParam.month);

        return isFilter;
      }),
    newPlatformSchedule,
  ];

  return newList;
}

function updateOnePlatformList({
  list,
  newPlatform,
}) {
  if (!newPlatform) {
    return list;
  }
  const { data } = newPlatform;
  const { id } = data;

  const newList = [
    ...list
      .slice(0, 5)
      .filter((item = {}) => {
        const currentId = (item.data || {}).id;
        const isFilter = currentId !== id;

        return isFilter;
      }),
    {
      ...newPlatform,
      timeStamp: new Date().getTime(),
    },
  ];

  return newList;
}

function getUpdPlatformScheduleRes({
  prevState,
  updId,
  loading,
  param,
}) {
  const {
    list = [],
  } = prevState || {};

  const newList = (() => {
    if (loading) {
      return [
        ...list,
        {
          updId,
          loading,
          param,
        },
      ];
    }

    return list.filter(({ updId: itemUpdId }) => itemUpdId !== updId);
  })();

  const newLoading = newList.length > 0;

  return {
    loading: newLoading,
    list: newList,
  };
}

export default function platform(state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_PLATFORMS_REQUEST:
    return {
      ...state,
      allPlatforms: {
        ...state.allPlatforms,
        ...action.payload,
        timeStamp: new Date().getTime(),
      },
    };

  case GET_ONE_PLATFORM_REQUEST:
    return {
      ...state,
      onePlatform: {
        ...state.onePlatform,
        loading: action.payload.loading,
        list: updateOnePlatformList({
          list: state.onePlatform.list,
          newPlatform: action.payload.platform,
        }),
      },
    };

  case GET_PLATFORM_SCHEDULE_REQUEST:
    return {
      ...state,
      platformSchedules: {
        ...state.platformSchedules,
        loading: action.payload.loading,
        list: updateSchedulesList({
          newPlatformSchedule: action.payload.platformSchedule,
          platformSchedules: state.platformSchedules,
        }),
      },
    };

  case UPDATE_PLATFORM_SCHEDULE_REQUEST:
    return {
      ...state,
      updPlatformScheduleRes: getUpdPlatformScheduleRes({
        prevState: state.updPlatformScheduleRes,
        ...action.payload,
      }),
    };

  case CLEAR_CACHE_PLATFORM_SCHEDULE:
    return {
      ...state,
      platformSchedules: INITIAL_STATE.platformSchedules,
    };

  case CLEAR_CACHE_ONE_PLATFORMS:
    return {
      ...state,
      onePlatform: INITIAL_STATE.onePlatform,
    };

  case CLEAR_CACHE_ALL_PLATFORMS:
    return {
      ...state,
      allPlatforms: INITIAL_STATE.allPlatforms,
    };

  default:
    return state;
  }
}
