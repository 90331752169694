/* eslint-disable camelcase */
import { callAPI } from '@parkly/shared/api/base';
import { BLOCKED_BY_TYPE } from 'config/constants';

function validGetBlockedUsersResult(param) {
  const result = param?.res?.data;
  const {
    by_car_number: byCarNumber,
    by_phone: byPhone,
  } = result || {};

  const transformedByCarNumber = byCarNumber?.map(({
    car_number: carNumber,
    ...props
  }) => ({
    carNumber,
    ...props,
  }));

  return {
    byCarNumber: transformedByCarNumber,
    byPhone,
  };
}

export async function getBlockedUsers({
  facilityId,
}) {
  if (!facilityId) {
    console.error('No facilityId in getBlockedUsers!');
    return {
      error: 'No facilityId in addBlockedUsers!',
    };
  }

  const endpoint = `facility/${facilityId}/blocked_users`;

  return callAPI(endpoint)
    .then((res) => validGetBlockedUsersResult({
      res,
    }));
}

export async function addBlockedUsers({
  facilityId,
  type,
  carNumber,
  phoneNumber,
  comment,
}) {
  if (!facilityId) {
    console.error('No facilityId in addBlockedUsers!');
    return {
      error: 'No facilityId in addBlockedUsers!',
    };
  }

  const endpoint = `facility/${facilityId}/blocked_user`;
  const method = 'post';

  const data = type === BLOCKED_BY_TYPE.carNumber
    ? {
      car_number: carNumber,
    }
    : {
      phone: phoneNumber,
    };

  const result = await callAPI(
    endpoint,
    method,
    {
      type,
      comment,
      ...data,
    },
  );

  return result?.data;
}

export async function updateBlockedUser({
  facilityId,
  blockedUserId,
  type,
  carNumber,
  phoneNumber,
  comment,
}) {
  if (!facilityId) {
    console.error('No facilityId in addBlockedUsers!');
    return {
      error: 'No facilityId in addBlockedUsers!',
    };
  }

  const endpoint = `facility/${facilityId}/blocked_user/${blockedUserId}`;
  const method = 'put';

  const data = type === BLOCKED_BY_TYPE.carNumber
    ? {
      car_number: carNumber,
    }
    : {
      phone: phoneNumber,
    };

  const result = await callAPI(
    endpoint,
    method,
    {
      type,
      comment,
      ...data,
    },
  );

  return result?.data;
}

export async function removeBlockedUser({
  facilityId,
  blockedUserId,
}) {
  if (!facilityId) {
    console.error('No facilityId in addBlockedUsers!');
    return {
      error: 'No facilityId in addBlockedUsers!',
    };
  }

  const endpoint = `facility/${facilityId}/blocked_user/${blockedUserId}`;
  const method = 'delete';

  const result = await callAPI(
    endpoint,
    method,
  );

  return result?.data;
}

export default {
  getBlockedUsers,
};
