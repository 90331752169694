import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 5,
    marginRight: '5vw',
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: 10,
  },
}));

export default {
  useStyles,
};
