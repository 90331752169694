export function formatRights({
  rules,
  allPlatformsData,
}) {
  const commonRights = rules
    .filter((rule) => {
      const { checked, isRuleCommon } = rule || {};

      return checked && isRuleCommon;
    })
    .map((rule) => {
      const { checked, ruleId } = rule || {};

      if (checked) {
        return ruleId;
      }

      return null;
    });

  const rightPlatforms = allPlatformsData.reduce(
    (accum, current, index) => {
      const currentId = (current || {}).id;
      const facilityName = `facility_id_${currentId}`;

      const idRules = rules
        .filter((rule) => {
          const { isRuleCommon } = rule || {};

          return !isRuleCommon;
        })
        .map((ruleList) => {
          const currentPlatformRule = ruleList[index];
          const { checked, ruleId } = currentPlatformRule;

          if (checked) {
            return ruleId;
          }

          return null;
        })
        .filter((rule) => !!rule);

      return {
        ...accum,
        [facilityName]: idRules,
      };
    },
    {},
  );

  const rights = {
    ...rightPlatforms,
    common: commonRights,
  };

  return rights;
}

export function getRightsKey(key) {
  if (key === 'common') {
    return key;
  }

  const splitKey = key.split('_') || [];
  const platformId = splitKey[2];

  return platformId;
}

export function checkRightInAllPlatform({
  rights = {},
  checkingRight = '',
}) {
  const list = Object.keys(rights);

  const platformWithRight = list
    .filter((current) => {
      const currentRights = rights[current] || [];
      const isRight = currentRights.includes(checkingRight);

      return isRight;
    })
    // eslint-disable-next-line radix,no-restricted-globals
    .map((id) => (isNaN(id) ? id : Number.parseInt(id)));

  const isThisRight = platformWithRight && platformWithRight.length > 0;

  return {
    isThisRight,
    platformWithRight,
  };
}
