/* eslint-disable camelcase */
import { callAPI } from '@parkly/shared/api/base';

export async function validGetPlatformMobilePaymentsRes({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const mobilePaymentsMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const mobilePaymentsData = curData.map((mobilePaymentItem) => {
    const {
      enter_time,
      exit_time,
      paid_at,
      facility_id,
      id,
      total_cost,
      user_name,
      ticket_number,
    } = mobilePaymentItem;

    return {
      enterTime: enter_time,
      exitTime: exit_time,
      paidAt: paid_at,
      ticketNumber: ticket_number,
      facilityId: facility_id,
      id,
      totalCost: total_cost,
      userName: user_name,
    };
  });

  const result = {
    mobilePaymentsData,
    mobilePaymentsMeta,
  };

  return result;
}

/* api request */

export async function getPlatformMobilePayments({
  id,
  search,
  date,
  sortBy,
  sortDirection,
  page,
}) {
  /* eslint-disable */
  const query = '?'
    + (id ? `facility_id=${id}&` : '')
    + (search ? `query=${search}&` : '')
    + (date ? `date=${date}&` : '')
    + (sortBy ? `sort_by=${sortBy}&` : '')
    + (sortDirection ? `sort_direction=${sortDirection}&` : '')
    + (page && (page !== 1)  ? `page=${page}&` : '')
  /* eslint-enable */

  const queryParams = query.slice(0, -1);

  const endpoint = `mobile-payments${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetPlatformMobilePaymentsRes({
      res,
    }));
}

export default {
  getPlatformMobilePayments,
};
