import {
  GET_OPERATORS,
  GET_ONE_OPERATOR_REQUEST,
  GET_ACTIONS_ONE_OPERATOR_REQUEST,
  ADD_NEW_OPERATOR_REQUEST,
  UPDATE_OPERATOR_REQUEST,
  REMOVE_OPERATOR_REQUEST,
  GET_LIST_FRONT_OFFICE_RIGHTS,
  GET_CURRENT_OPERATOR,
  CLEAR_CACHE_ONE_OPERATOR,
  CLEAR_CACHE_OPERATORS,
} from 'actions/types';

const INITIAL_STATE = {
  operators: {
    loading: false,
    list: [],
  },
  oneOperator: {
    loading: false,
    list: [],
  },
  actionsOneOperator: {
    loading: false,
    list: [],
  },
  addNewOperator: {
    loading: false,
  },
  updateOperator: {
    loading: false,
  },
  removeOperator: {
    loading: false,
  },
  rightList: {
    loading: false,
    list: [],
  },
  currentOperator: {
    loading: false,
    data: null,
    userRights: {},
  },
};

function updateActionsOneOperatorList({
  list,
  newActions,
  operatorId,
}) {
  if (!newActions) {
    return list;
  }

  const {
    data,
    meta,
  } = newActions;

  const newList = [
    ...list
      .slice(0, 5)
      .filter((item = {}) => {
        const currentId = (item || {}).operatorId;
        const isFilter = currentId !== operatorId;

        return isFilter;
      }),
    {
      actions: data,
      meta,
      operatorId,
    },
  ];

  return newList;
}

function updateOneOperatorList({
  list,
  newOperator,
  error,
}) {
  const { id } = newOperator || error || {};

  if (!id) {
    return list;
  }

  const filteredList = list
    .slice(0, 5)
    .filter((item = {}) => {
      const currentId = (item || {}).id;
      const isFilter = currentId !== id;

      return isFilter;
    });

  const newList = [
    ...filteredList,
    newOperator || error,
  ];

  return newList;
}

function updateOperatorsList({
  list,
  newOperators,
  platformSign,
}) {
  if (!newOperators) {
    return list;
  }

  const newList = [
    ...list
      .slice(0, 5)
      .filter((item = {}) => {
        const currentSign = (item || {}).platformSign;
        const isFilter = currentSign !== platformSign;

        return isFilter;
      }),
    {
      platformSign,
      operatorsList: newOperators,
    },
  ];

  return newList;
}

function getNewOperatorState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

function getUpdateOperatorState({
  prevState,
  payload,
}) {
  return {
    loading: (prevState || {}).loading,
    ...payload,
  };
}

export default function operators(state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_OPERATORS:
    return {
      ...state,
      operators: {
        ...state.operators,
        loading: action.payload.loading,
        list: updateOperatorsList({
          list: state.operators.list,
          newOperators: action.payload.data,
          platformSign: action.payload.platformSign,
        }),
      },
    };

  case GET_ONE_OPERATOR_REQUEST:
    return {
      ...state,
      oneOperator: {
        ...state.oneOperator,
        loading: action.payload.loading,
        list: updateOneOperatorList({
          list: state.oneOperator.list,
          newOperator: action.payload.operator,
          error: action.payload.error,
        }),
      },
    };

  case GET_ACTIONS_ONE_OPERATOR_REQUEST:
    return {
      ...state,
      actionsOneOperator: {
        ...state.oneOperator,
        loading: action.payload.loading,
        list: updateActionsOneOperatorList({
          list: state.actionsOneOperator.list,
          newActions: action.payload.actions,
          operatorId: action.payload.operatorId,
        }),
      },
    };

  case ADD_NEW_OPERATOR_REQUEST:
    return {
      ...state,
      addNewOperator: getNewOperatorState({
        prevState: state.addNewOperator,
        payload: action.payload,
      }),
    };

  case UPDATE_OPERATOR_REQUEST:
    return {
      ...state,
      updateOperator: getUpdateOperatorState({
        prevState: state.updateOperator,
        payload: action.payload,
      }),
    };

  case REMOVE_OPERATOR_REQUEST:
    return {
      ...state,
      removeOperator: {
        ...state.removeOperator,
        ...action.payload,
      },
    };

  case GET_LIST_FRONT_OFFICE_RIGHTS:
    return {
      ...state,
      rightList: {
        ...state.rightList,
        ...action.payload,
      },
    };

  case GET_CURRENT_OPERATOR:
    return {
      ...state,
      currentOperator: {
        ...state.currentOperator,
        ...action.payload,
      },
    };

  case CLEAR_CACHE_ONE_OPERATOR:
    return {
      ...state,
      oneOperator: INITIAL_STATE.oneOperator,
    };

  case CLEAR_CACHE_OPERATORS:
    return {
      ...state,
      operators: INITIAL_STATE.operators,
    };

  default:
    return state;
  }
}
