import { getBlockedUsers } from 'api/blockedUsers';
import {
  GET_BLOCKED_USERS,
} from './types';

export function getBlockedUsersAction(param) {
  function getBlockedUsersRequest(payload) {
    return { type: GET_BLOCKED_USERS, payload };
  }

  return (dispatch) => {
    dispatch(getBlockedUsersRequest({
      loading: true,
      param,
    }));

    getBlockedUsers(param)
      .then(
        (data) => {
          dispatch(getBlockedUsersRequest({
            loading: false,
            data,
            param,
          }));
        },
        (error) => {
          dispatch(getBlockedUsersRequest({
            loading: false,
            param,
            error,
          }));
        },
      );
  };
}

export default {
  getBlockedUsersAction,
};
