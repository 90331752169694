import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import PrivateRoute from 'components/templates/containers/PrivateRoute';
import OneOperator from 'components/pages/OneOperator';
import AllSubscriptionsPage from 'components/pages/AllSubscriptionsPage';
import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';

import { PATH_PAGES } from 'config/constants';
import { getAllPlatforms } from 'actions';

const propTypes = {};

function SubscriptionsPage({
  allPlatforms,
  currentOperator,
  getAllPlatformsReq = () => {},
}) {
  useEffect(
    () => {
      const { loading: isAllPltLoading } = allPlatforms || {};
      if (!isAllPltLoading && !allPlatforms) {
        getAllPlatformsReq();
      }
    },
    // eslint-disable-next-line
    [JSON.stringify(allPlatforms)],
  );

  const { userRights } = currentOperator || {};
  const { isReadActBookList, isReadHistBookList } = userRights || {};
  const isReadSubscriptionsList = isReadActBookList || isReadHistBookList;

  const redirectStr = isReadSubscriptionsList
    ? PATH_PAGES.subscriptionsAll
    : PATH_PAGES.notEnoughRights;

  const isLoading = !(allPlatforms || {}).platforms;

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute exact path={PATH_PAGES.subscriptionsAll} component={AllSubscriptionsPage} />
      <PrivateRoute
        exact
        path={PATH_PAGES.oneSubscription}
        component={OneOperator}
      />
      <Redirect to={redirectStr} />
    </ParentPageContainer>
  );
}

SubscriptionsPage.propTypes = propTypes;

function mapStateToProps(state) {
  const { operators, platform } = state || {};
  const { allPlatforms } = platform || {};
  const { currentOperator } = operators || {};

  return {
    allPlatforms,
    currentOperator,
  };
}

const ConnectedSubscriptionsPage = connect(
  mapStateToProps,
  {
    getAllPlatformsReq: getAllPlatforms,
  },
)(SubscriptionsPage);

export default ConnectedSubscriptionsPage;
