import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import InfoIconMini from '@parkly/shared/components/atoms/icons/InfoIconMini';
import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import TabPanel from '@parkly/shared/components/molecules/TabPanel';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomTimePicker from '@parkly/shared/components/atoms/CustomTimePicker';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';

import { SCHEDULE_SETTINGS_TYPES, SCHEDULE_SETTINGS_TAB_TYPES } from 'config/constants';
import { getScheduleTimeStr, checkDateValid } from '@parkly/shared/helpers/time';

import { useStyles, useStylesTimeScheduleEditor } from './styles';

/* help functions */

function checkIsChanged(settings) {
  const {
    initSettings = {},
    timeStart,
    timeEnd,
    parkSpace,
    isDisabled,
    tariffId,
  } = settings || {};

  return timeStart === initSettings.timeStart
    && timeEnd === initSettings.timeEnd
    && parseInt(parkSpace, 10) === parseInt(initSettings.parkSpace, 10)
    && !!isDisabled === !!initSettings.isDisabled
    && tariffId === initSettings.tariffId;
}

function getSettings({
  type,
  dayWithRule,
  defaultParkSpace,
  defTimeStart,
  defTimeEnd,
  defTariffObj,
}) {
  const defTimeStartStr = getScheduleTimeStr(defTimeStart);
  const defTimeEndStr = getScheduleTimeStr(defTimeEnd);

  if (type === SCHEDULE_SETTINGS_TYPES.default) {
    const settings = {
      timeStart: defTimeStartStr,
      timeEnd: defTimeEndStr,
      parkSpace: defaultParkSpace,
      tariffId: (defTariffObj || {}).id,
    };

    return {
      ...settings,
      initSettings: settings,
    };
  }

  const {
    isAvailable = true,
    places = {},
    schedule = {},
  } = dayWithRule || {};

  const defSettigs = {
    isDisabled: !isAvailable,
    defTimeStartStr,
    defTimeEndStr,
    defParkSpace: defaultParkSpace,
    timeStart: getScheduleTimeStr(schedule.availableFrom || defTimeStart),
    timeEnd: getScheduleTimeStr(schedule.availableTo || defTimeEnd),
    parkSpace: places.count,
    tariffId: (schedule || {}).tariffId,
  };

  return {
    ...defSettigs,
    initSettings: defSettigs,
  };
}

function checkValidation({
  parkSpace,
  timeEnd,
  timeStart,
  tariffId,
  tariffs = [],
}) {
  const timeStartMoment = moment(timeStart, 'HH:mm');
  const timeEndMoment = moment(timeEnd, 'HH:mm');

  const isTimeStartValid = checkDateValid(timeStartMoment);
  const isTimeEndValid = checkDateValid(timeEndMoment);
  const isParkSpaceValid = Number.isInteger(parseInt(parkSpace, 10));

  const tariff = tariffs.find(({ id: curTarId }) => curTarId === tariffId);
  const isTariffIdValid = tariff && Number.isInteger(parseInt(tariffId, 10));

  const isValid = isTimeStartValid && isTimeEndValid && isParkSpaceValid && isTariffIdValid;

  return {
    isTimeStartValid,
    isTimeEndValid,
    isParkSpaceValid,
    isTariffIdValid,
    isValid,
  };
}

function getTabs({
  tab = SCHEDULE_SETTINGS_TAB_TYPES.time,
  type,
  t = (f) => f,
}) {
  if (tab === SCHEDULE_SETTINGS_TAB_TYPES.parkSpaceOnly) {
    return [
      {
        label: t('platforms.emptySpaces'),
      },
    ];
  }

  if (type === SCHEDULE_SETTINGS_TYPES.default) {
    return [
      {
        label: t('platforms.workSchedule'),
      },
      {
        label: t('platforms.emptySpaces'),
      },
    ];
  }

  return [
    {
      label: t('platforms.workSchedule'),
    },
    {
      label: t('platforms.emptySpaces'),
    },
    {
      label: t('platforms.tariff'),
    },
  ];
}

/* Components */

function ParkSpaceScheduleEditor({
  type = 'day',
  tab,
  isDisabled = false,
  parkSpace = 0,
  defParkSpace,
  errors,
  scheduleLinkStr,
  updateSettings = () => {},
}) {
  const classes = useStylesTimeScheduleEditor();
  const { t } = useTranslation();

  const baseTimeStr = `${t('platforms.baseSchedule')}: ${defParkSpace}`;

  const getClassCntTime = (isError) => `${classes.timeContainer} ${isError ? classes.error : ''}`;

  function handleSettingsChange(event) {
    updateSettings(event.target.name, event.target.value || event.target.checked);
  }

  const parkSpaceChanger = (
    <div className={classes.timesContainer}>
      <div className={getClassCntTime((errors || {}).parkSpace)}>
        <TextField
          name="parkSpace"
          required
          error={(errors || {}).parkSpace}
          disabled={isDisabled}
          className={classes.textFieldParkSpace}
          onChange={handleSettingsChange}
          type="number"
          label={t('others.places')}
          value={parkSpace}
          inputProps={{
            min: 0,
            max: 100000000,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  );

  if (type === 'default') {
    return (
      <div className={classes.container}>
        {parkSpaceChanger}
      </div>
    );
  }

  if (type === 'day' && tab === SCHEDULE_SETTINGS_TAB_TYPES.parkSpaceOnly) {
    return (
      <div className={classes.container}>
        <div className={classes.baseContainer}>
          <InfoIconMini />
          <Typography className={classes.base}>
            {baseTimeStr}
          </Typography>
        </div>

        {parkSpaceChanger}

        <div className={classes.makeDayOffContainer}>
          <CustomLink
            href={scheduleLinkStr}
          >
            {t('analytics.switchToSchedulePage')}
          </CustomLink>

        </div>

      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.baseContainer}>
        <InfoIconMini />
        <Typography className={classes.base}>
          {baseTimeStr}
        </Typography>
      </div>

      {parkSpaceChanger}

      <div className={classes.makeDayOffContainer}>
        <Typography>
          {t('platforms.makeThisDayOff')}
        </Typography>
        <Switch
          name="isDisabled"
          className={classes.switch}
          checked={isDisabled}
          onChange={handleSettingsChange}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>

    </div>
  );
}

function TariffEditor({
  tariffId = 1,
  tariffs = [],
  errors,
  updateSettings = () => {},
}) {
  const classes = useStylesTimeScheduleEditor();
  const { t } = useTranslation();

  const items = tariffs.map(({ id, name }) => ({ id, title: name }));

  function handleSettingsChange(event) {
    updateSettings(event.target.name, event.target.value || event.target.checked);
  }

  return (
    <div className={classes.container}>
      <ItemSelectors
        label={t('platforms.tariff')}
        error={!!(errors || {}).tariff}
        name="tariffId"
        currentValue={tariffId}
        items={items}
        onChange={handleSettingsChange}
      />
    </div>
  );
}

/* Main component */

function ChangeScheduleTabs({
  type = 'day',
  tab = SCHEDULE_SETTINGS_TAB_TYPES.time,
  dayWithRule,
  defaultParkSpace,
  defTimeStart,
  defTimeEnd,
  defTariffObj,
  loading,
  scheduleLinkStr,
  tariffs,
  handleApplyChanges = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [settings, setSettings] = useState(getSettings({
    type,
    dayWithRule,
    defaultParkSpace,
    defTimeStart,
    defTimeEnd,
    defTariffObj,
  }));
  const [errors, setErrors] = useState({
    timeStart: false,
    timeEnd: false,
    parkSpace: false,
  });
  const [tabValue, setTabValue] = useState(
    (tab === SCHEDULE_SETTINGS_TAB_TYPES.parkSpace)
      ? 1
      : tab === SCHEDULE_SETTINGS_TAB_TYPES.tariff
        ? 2
        : 0,
  );
  useEffect(
    () => {
      setSettings(getSettings({
        type,
        dayWithRule,
        defaultParkSpace,
        defTimeStart,
        defTimeEnd,
        defTariffObj,
      }));
    },
    // eslint-disable-next-line
    [defaultParkSpace, defTimeStart, defTimeEnd]
  );

  function onApplyBtnClick(e) {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    const {
      isDisabled,
      parkSpace,
      timeEnd,
      timeStart,
      tariffId,
    } = settings;

    const {
      isTimeStartValid,
      isTimeEndValid,
      isParkSpaceValid,
      isTariffIdValid,
      isValid,
    } = checkValidation({
      parkSpace,
      timeEnd,
      timeStart,
      tariffId,
      tariffs,
    });

    if (isValid) {
      handleApplyChanges({
        dayWithRule,
        type,
        parkSpace,
        timeEnd,
        timeStart,
        tariffId,
        isAvailable: !isDisabled,
      });
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        timeStart: !isTimeStartValid,
        timeEnd: !isTimeEndValid,
        parkSpace: !isParkSpaceValid,
        tariff: !isTariffIdValid,
      }));

      if (isTimeStartValid && isTimeEndValid && !isParkSpaceValid
        && isTimeStartValid && tabValue !== 1) {
        setTabValue(1);
      }

      if ((!isTimeStartValid || !isTimeEndValid) && isParkSpaceValid
        && isTimeStartValid && tabValue !== 0) {
        setTabValue(0);
      }

      if (isTimeStartValid && isTimeEndValid && isParkSpaceValid
          && !isTariffIdValid && tabValue !== 2) {
        setTabValue(2);
      }
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function updateSettings(name, value) {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  }

  function resetToDefault() {
    setSettings((prevSettings) => ({
      ...prevSettings,
      timeStart: getScheduleTimeStr(defTimeStart),
      timeEnd: getScheduleTimeStr(defTimeEnd),
      parkSpace: defaultParkSpace,
      tariffId: (defTariffObj || {}).id,
      isDisabled: false,
    }));
  }

  const {
    isDisabled, timeStart, timeEnd, parkSpace,
    defTimeStartStr, defTimeEndStr,
    defParkSpace, tariffId,
  } = settings;

  const isUnchanged = checkIsChanged(settings);
  const isApplyBtnDisable = loading || isUnchanged;

  const tabs = getTabs({
    tab,
    type,
    t,
  });

  return (
    <div className={classes.root}>
      <form
        className={classes.form}
        onSubmit={onApplyBtnClick}
        autoComplete="off"
      >
        <>
          <CustomTabBar
            tabs={tabs}
            tabValue={tabValue}
            handleParamChange={handleTabChange}
          />
          {(tab !== SCHEDULE_SETTINGS_TAB_TYPES.parkSpaceOnly) && (
            <TabPanel
              className={classes.tabPanel}
              value={tabValue}
              index={0}
            >
              <CustomTimePicker {...{
                type,
                isDisabled,
                timeStart,
                timeEnd,
                defTimeStartStr,
                defTimeEndStr,
                errors,
                updateSettings,
                resetToDefault,
              }}
              />
            </TabPanel>
          )}
          <TabPanel
            className={classes.tabPanel}
            value={tabValue}
            index={(tab === SCHEDULE_SETTINGS_TAB_TYPES.parkSpaceOnly)
              ? 0
              : 1}
          >
            <ParkSpaceScheduleEditor {...{
              tab,
              type,
              isDisabled,
              parkSpace,
              defParkSpace,
              errors,
              scheduleLinkStr,
              updateSettings,
            }}
            />
          </TabPanel>
          {(tab !== SCHEDULE_SETTINGS_TAB_TYPES.parkSpaceOnly)
          && (type !== SCHEDULE_SETTINGS_TYPES.default) && (
            <TabPanel
              className={classes.tabPanel}
              value={tabValue}
              index={2}
            >
              <TariffEditor {...{
                tariffId,
                tariffs,
                errors,
                updateSettings,
              }}
              />
            </TabPanel>
          )}
        </>
        {loading && (
          <CircularIndeterminate
            type="fullPage"
            style={{
              minHeight: 235,
              marginLeft: -24,
              top: 0,
            }}
            blur
          />
        )}
        <CustomBtn
          type="submit"
          disabled={isApplyBtnDisable}
          color="primary"
          className={classes.applyBtn}
        >
          {t('platforms.apply')}
        </CustomBtn>
      </form>
    </div>
  );
}

export default ChangeScheduleTabs;
