import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  keyboardDatePicker: {
    '& .MuiInputBase-root': {
      justifyContent: 'center',
      height: 40,
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.greyC.buttonGrey}`,
      borderRadius: 6,
      paddingLeft: 12,
    },
    '& .MuiInputBase-root.Mui-focused': {
      borderColor: theme.palette.blueC.blue,
    },
    '& .MuiInputBase-root.Mui-error': {
      borderColor: '#ff1744',
    },

    '& ::before': {
      display: 'none',
    },
    '& ::after': {
      display: 'none',
    },

    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: -13,
      left: 6,
      padding: '0 5px',
      backgroundColor: theme.palette.background.default,
    },

    '& .MuiIconButton-root': {
      padding: 7,
    },
    '& .MuiInputBase-input': {
      textTransform: 'capitalize',
    },
    '& .MuiInputBase-input::placeholder': {
      textTransform: 'none',
    },

    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
  },
  datePickerWithoutKeyBord: {
    '& .MuiInputBase-root.Mui-focused': {
      borderColor: theme.palette.greyC.buttonGrey,
    },
  },
  datePickerWithArrowBtns: {
    '& .MuiInputBase-root': {
      borderRadius: 0,
    },
  },
  datePickerWithError: {
    '& .MuiInputBase-root': {
      backgroundColor: 'red',
    },
  },
  dpContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& div': {
      flexGrow: 1,
    }
    // maxWidth: 280,
  },
}));

export const useStylesNextPrevBtn = makeStyles((theme) => ({
  main: {
    height: 40,
    width: 42,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& :hover svg': {
      fill: theme.palette.greyC.lightGrey,
    },

    border: `1px  solid ${theme.palette.greyC.buttonGrey}`,
    borderRight: 0,
    borderRadius: '6px 0 0 6px',
  },
  isBack: {
    borderRight: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderLeft: 0,
    borderRadius: '0 6px 6px 0',
  },
}));

export default {
  useStyles,
  useStylesNextPrevBtn,
};
