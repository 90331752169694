import React from "react";

function TimeIconMini({
    size = 16,
    fill = '#ADB6C9',
    ...props
}) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 14A6 6 0 108 2a6 6 0 000 12z"
        stroke={fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5v3l2 2"
        stroke={fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TimeIconMini;
