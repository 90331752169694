import React from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';

import { useStyles } from './styles';

function HeaderWithSelectorAndBtn({
  selSelectorValue = {},
  headerSelectors = [],
  isBtn = true,
  selectorName = 'selPlatformId',
  btnTextKey = 'operators.addOperator',
  onBtnClick = () => {},
  updateSettings = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  function handleSelectorChange(event) {
    updateSettings(event.target.name, event.target.value);
  }

  return (
    <div className={classes.container}>
      <ItemSelectors
        selectorsType="secondary"
        name={selectorName}
        currentValue={selSelectorValue}
        classNameForm={classes.itemSelectorsForm}
        className={classes.itemSelectors}
        items={headerSelectors}
        onChange={handleSelectorChange}
      />
      {isBtn && (
        <CustomBtn
          onClick={onBtnClick}
          btnType="secondary"
          className={classes.addOperatorBtn}
        >
          {t(btnTextKey)}
        </CustomBtn>
      )}
    </div>
  );
}

export default HeaderWithSelectorAndBtn;
