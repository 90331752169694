import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  primary: {

  },
  primaryBlue: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      // color: theme.palette.greyC.mediumGrey,
    },
    '&:disabled': {
      border: 'unset',
    },
  },
  primaryGreen: {
    backgroundColor: theme.palette.greenC.green4,
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    border: `1px solid ${theme.palette.greenC.green4}`,
    '&:hover': {
      backgroundColor: theme.palette.greenC.green4,
      // color: theme.palette.greyC.mediumGrey,
    },
    '&:disabled': {
      border: 'unset',
    },
  },
  primaryRed: {
    backgroundColor: theme.palette.redC.red3,
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    border: `1px solid ${theme.palette.redC.red3}`,
    '&:hover': {
      backgroundColor: theme.palette.redC.red3,
      // color: theme.palette.greyC.mediumGrey,
    },
    '&:disabled': {
      border: 'unset',
    },
  },
  secondary: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
    '&:hover': {
      backgroundColor: theme.palette.greyC.lightGrey,
      // color: theme.palette.greyC.mediumGrey,
    },
  },
}));

export default {
  useStyles,
};
