import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';

const white = '#fff';
const blue = '#004BF6';
const darkBlue = '#1D3262';

const gray = '#ECEFF3';
const gray2 = '#ADB6C9';
const lightGrey = '#F6F7FB';
const buttonGrey = '#DDE0E8';
const mediumGrey = '#CCD3E1';
const darkGrey = '#7E8AA7';

const errorRed = '#F34E4E';
const red1 = '#ffeaea';
const red2 = '#FF9494';
const red3 = '#F34E4E';
const red4 = '#FFEBEB';
const red5 = '#F6C4C4';
const red6 = '#DB8989';
const red7 = '#C00202';

const green1 = '#E3FFF2';
const green2 = '#75E4AF';
const green3 = '#43D37D';
const green4 = '#00D88A';
const green5 = '#00C77F';
const green6 = '#00B775';
const green7 = '#00C27C';
const green8 = '#C4F0DB';
const green9 = '#9BDDBD';
const green10 = '#00C294';

const blue1 = '#E9EFFF';
const blue2 = '#598CFF';
const blue3 = '#C6D4F4';
const blue4 = '#95A9D6';
const blue5 = '#3E79FF';
const blue6 = '#E4EDFF';
const blue7 = '#0035AF';

const theme = createTheme({
  palette: {
    primary: {
      main: blue,
      contrastText: white,
    },
    secondary: {
      main: lightGrey,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: white,
    },
    text: {
      primary: darkBlue,
      blue,
    },
    greyC: {
      lightGrey,
      buttonGrey,
      darkGrey,
      gray,
      gray2,
      mediumGrey,
    },
    redC: {
      errorRed,
      red1,
      red2,
      red3,
      red4,
      red5,
      red6,
      red7,
    },
    greenC: {
      green1,
      green2,
      green3,
      green4,
      green5,
      green6,
      green7,
      green8,
      green9,
      green10,
    },
    blueC: {
      darkBlue,
      blue,
      blue1,
      blue2,
      blue3,
      blue4,
      blue5,
      blue6,
      blue7,
    },
  },
  typography: {
    fontFamily: [
      'Lato',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    htmlFontSize: 16,
    fontSize12: '0.857rem',
    fontSize14: '1rem',
    fontSize16: '1.143rem',
  },
}, ruRU);

export default theme;
