import React from 'react';

function GuardedIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <g
        clipPath="url(#prefix__clip0)"
        stroke="#7E8AA7"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8 2L2.5 4.545V7.5c0 3.027 3 6 5.5 7 2.5-1 5.5-4.018 5.5-7.045V4.5L8 2z" />
        <path d="M6 7.833L7.667 9.5 10 6.5" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GuardedIcon;
