import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import SignInPage from '@parkly/shared/components/pages/SignInPage';
import SignOutPage from '@parkly/shared/components/pages/SignOutPage';
import AccessRedirect from '@parkly/shared/components/pages/AccessRedirect';
import RedirectToProd from '@parkly/shared/components/templates/RedirectToProd';

import AppContent from 'components/pages/AppContent';

import 'react-toastify/dist/ReactToastify.css';

import {
  checkIsStaging,
  checkIsAccessToStaging,
} from '@parkly/shared/helpers/others';
import theme from '@parkly/shared/config/styles/theme';
import { DEFAULT_PATH_PAGES } from '@parkly/shared/config/constants';

function App() {
  const isStaging = checkIsStaging();

  if (isStaging) {
    if (window.location.pathname === DEFAULT_PATH_PAGES.accessRedirect) {
      return <AccessRedirect home={DEFAULT_PATH_PAGES.home} />;
    }

    const isAccessToStaging = checkIsAccessToStaging();

    if (!isAccessToStaging) {
      return <RedirectToProd />;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          <Route path={DEFAULT_PATH_PAGES.login} component={SignInPage} />
          <Route path={DEFAULT_PATH_PAGES.logout} component={SignOutPage} />
          <PrivateRoute path="/" component={AppContent} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
