import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 87,
    borderBottom: `1px solid ${theme.palette.greyC.buttonGrey}`,
    paddingTop: 25,
    paddingBottom: 25,
  },
  titleStr: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    fontWeight: 'bold',
  },
  titleCont: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingRight: 25,
  },
  base: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
}));

export default {
  useStyles,
};
