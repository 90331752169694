import React from 'react';

function BackIcon({
  size = 24,
  stroke = '#1D3262',
  isForward = false,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      style={{
        transform: isForward ? 'rotate(180deg)' : '',
      }}
      {...props}
    >
      <path
        d="M19 12H5M11 18l-6-6 6-6"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BackIcon;
