import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import HeaderWithSelectorAndBtn from '@parkly/shared/components/organisms/HeaderWithSelectorAndBtn';
import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import TabPanel from '@parkly/shared/components/molecules/TabPanel';
import BlockedUsersTable from 'components/templates/BlockedUsersTable';

import { useFetchedReduxData } from '@parkly/shared/helpers/hooks';
import {
  extractCurrentOperatorFromState,
  getBlockedUsersExtractor,
  extractAllPlatformsFromState,
} from 'helpers';
import { getBlockedUsersAction } from 'actions';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';

import { useStyles } from './styles';

const TABS = [
  {
    id: 'byCarNumber',
    label: 'По номеру машины',
  },
  {
    id: 'byPhoneNumber',
    label: 'По номеру телефона',
  },
];

/* Help functions */

function getHeaderSelectors({
  allPlatforms,
  currentOperator,
}) {
  const { userRights } = currentOperator || {};
  const { isAdmin } = userRights || {};
  const readBlockedUsersPlatformList = (userRights || {})[`list${RIGHT_KEYS.readBlockedUsers}`] || [];

  const { platforms } = allPlatforms || {};
  const { data } = platforms || {};

  return (data || [])
    .map(({ id, title }) => ({
      id,
      title,
    }))
    .filter(({ id }) => isAdmin || readBlockedUsersPlatformList.includes(id));
}

function getInitFacilityId({
  facilities,
  currentOperator,
}) {
  const { userRights } = currentOperator || {};
  const { isAdmin } = userRights || {};

  if (isAdmin) {
    return facilities[0]?.id;
  }

  const readBlockedUsersPlatformList = (userRights || {})[`list${RIGHT_KEYS.readBlockedUsers}`] || [];

  return readBlockedUsersPlatformList[0];
}

/* Main component */

const propTypes = {};

function BlockedUsersPageAll() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { content: currentOperator } = useFetchedReduxData({
    stateDataExtractor: extractCurrentOperatorFromState,
  });
  const { content: allPlatforms } = useFetchedReduxData({
    stateDataExtractor: extractAllPlatformsFromState,
  });
  const facilities = allPlatforms.platforms.data;
  const [settings, setSettings] = useState({
    selFacilityId: getInitFacilityId({ facilities, currentOperator }),
    tabValue: 0,
  });
  useEffect(
    // eslint-disable-next-line no-use-before-define
    () => { updateSettings('selFacilityId', getInitFacilityId({ facilities, currentOperator })) },
    // eslint-disable-next-line
    [JSON.stringify(facilities)],
  );

  const { content: blockedUsers } = useFetchedReduxData({
    stateDataExtractor: getBlockedUsersExtractor,
    getAction: getBlockedUsersAction,
    param: { facilityId: settings.selFacilityId },
    updTimeSec: 0,
  });

  const headerSelectors = getHeaderSelectors({
    allPlatforms,
    currentOperator,
  });

  const {
    selFacilityId,
    tabValue,
  } = settings;

  const {
    loading: isLoading,
    data,
  } = blockedUsers || {};

  const {
    byCarNumber,
    byPhone,
  } = data || {};

  const { userRights } = currentOperator || {};
  const isCreateUpdateBlockedUsers = (userRights || {})[`is${RIGHT_KEYS.createUpdateBlockedUsers}`];

  function updateSettings(name, value) {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  }
  function onAddBtnClick() {
    history.push(PATH_PAGES.addNewBlockedUser);
  }

  function onClick({
    id,
  }) {
    const path = PATH_PAGES.blockedUsersItem
      .replace(':facilityId', selFacilityId)
      .replace(':id', id);

    history.push(path);
  }

  return (
    <Container
      className={classes.container}
      maxWidth="md"
    >
      <HeaderWithBackBtn
        title={t('blockedUsers.blockedUsers')}
      />
      <div className={classes.pageBody}>
        {!isLoading && (
          <>
            <HeaderWithSelectorAndBtn {...{
              headerSelectors,
              isBtn: isCreateUpdateBlockedUsers,
              selectorName: 'selFacilityId',
              selSelectorValue: selFacilityId,
              btnTextKey: 'blockedUsers.addToBlocked',
              updateSettings,
              onBtnClick: onAddBtnClick,
            }}
            />
            <CustomTabBar
              className={classes.tabBar}
              tabs={TABS}
              tabValue={tabValue}
              handleParamChange={updateSettings}
            />
            <TabPanel
              className={classes.tabPanel}
              value={tabValue}
              index={0}
            >
              <BlockedUsersTable
                tableTitle="Номер машины"
                noDataTitle="Пока нет заблокированных пользователей по номеру машины"
                dataList={byCarNumber}
                onClick={onClick}
              />
            </TabPanel>
            <TabPanel
              className={classes.tabPanel}
              value={tabValue}
              index={1}
            >
              <BlockedUsersTable
                tableTitle="Номер телефона"
                noDataTitle="Пока нет заблокированных пользователей по номеру телефона"
                dataList={byPhone}
                onClick={onClick}
              />
            </TabPanel>
          </>
        )}
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
      </div>
    </Container>
  );
}

BlockedUsersPageAll.propTypes = propTypes;

export default BlockedUsersPageAll;
