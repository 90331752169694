import React  from 'react';
import { connect } from 'react-redux';

import {
  getActionsOneOperatorAction,
  getOperatorsAction,
} from 'actions';
import OperatorActionsPage from '@parkly/shared/components/pages/OperatorActionsPage';

function mapStateToProps(state) {
  const { operators } = state || {};
  return {
    operators: (operators || {}).operators,
    actionsOneOperator: (operators || {}).actionsOneOperator,
  };
}

const ConnectedOperatorActionsPage = connect(
  mapStateToProps,
  {
    getOperatorActionsReq: getActionsOneOperatorAction,
    getOperatorsReq: getOperatorsAction,
  },
)(OperatorActionsPage);

export default ConnectedOperatorActionsPage;
