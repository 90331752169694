import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import StatusIcon from 'components/molecules/StatusIcon';
import SortedBigFirstIcon from '@parkly/shared/components/atoms/icons/SortedBigFirstIcon';
import SortedSmallFirstIcon from '@parkly/shared/components/atoms/icons/SortedSmallFirstIcon';
import PhoneIconBtn from '@parkly/shared/components/molecules/PhoneIconBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';

import { getOnePlatformAction } from 'actions';
import { BOOKINGS_SORT_TYPES } from 'config/constants';
import { transformPassCode } from 'helpers/others';
import { formatPhoneNumber } from '@parkly/shared/helpers/others';
import { getHumanizeDuration } from '@parkly/shared/helpers';

import { useStyles } from './styles';

/* help function */

function getHeaders({
  t,
  isCostColumn,
  isConnectWithDriverColumn,
  isShowPhone,
}) {
  const headers = [
    {
      id: BOOKINGS_SORT_TYPES.id,
      label: t('bookings.idBookings'),
    },
    {
      isSorted: true,
      id: BOOKINGS_SORT_TYPES.checkIn,
      label: t('bookings.checkIn'),
    },
    {
      isSorted: true,
      id: BOOKINGS_SORT_TYPES.checkOut,
      label: t('bookings.checkOut'),
    },
    {
      id: 'duration',
      label: t('bookings.duration'),
    },
    {
      id: BOOKINGS_SORT_TYPES.carNumber,
      label: t('bookings.stateCar'),
    },
    {
      id: BOOKINGS_SORT_TYPES.status,
      label: t('bookings.status'),
    },
    {
      id: BOOKINGS_SORT_TYPES.userName,
      label: t('bookings.name'),
    },
    isCostColumn
      ? {
        id: 'cost',
        label: t('bookings.cost'),
      }
      : null,
    isShowPhone
      ? {
        id: 'phone',
        label: t('bookings.phone'),
      }
      : null,
    isConnectWithDriverColumn
      ? {
        id: 'communicationBtn',
        label: '',
      }
      : null,
  ];

  return headers.filter((item) => !!item);
}

function getRows(bookingsData = []) {
  const result = bookingsData.map((bookItem) => {
    const {
      id: reservationId,
      carBrand,
      carNumber,
      passCode,
      facilityId,
      // placeGroupId,
      // placeOptions,
      reservationFinishTime,
      reservationStartTime,
      status,
      userName,
      totalCost,
      phone,
      carInfo,
    } = bookItem;

    return {
      reservationId,
      id: passCode,
      dateIn: moment(reservationStartTime),
      dateOut: moment(reservationFinishTime),
      carStateNumber: carNumber,
      carBrand,
      carInfo,
      status,
      name: userName,
      facilityId,
      totalCost,
      phone,
    };
  });

  return result;
}

const propTypes = {
  bookingsData: PropTypes.arrayOf(PropTypes.object),
  communicationRequestList: PropTypes.arrayOf(PropTypes.object),
  headerSortSettings: PropTypes.objectOf(PropTypes.object),
  selBookings: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number,
  ]),
  isCostColumn: PropTypes.bool,
  isConnectWithDriverColumn: PropTypes.bool,
  setSelBookings: PropTypes.func,
  communicationBtnClick: PropTypes.func,
  sortingSettingsChange: PropTypes.func,
  showCarInfoClick: PropTypes.func,
};

function BookingsTable({
  bookingsData,
  communicationRequestList,
  headerSortSettings = {},
  selBookings,
  isCostColumn = false,
  isConnectWithDriverColumn = false,
  setSelBookings = () => {},
  communicationBtnClick = () => {},
  sortingSettingsChange = () => {},
  showCarInfoClick = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const rows = getRows(bookingsData);
  const isBookingsData = bookingsData && (bookingsData || []).length > 0;

  const isShowPhone = isBookingsData && !!rows[0].phone;

  const headers = getHeaders({
    t,
    isCostColumn,
    isConnectWithDriverColumn,
    isShowPhone,
  });

  function createHeaderClickHandler(id, isSorted) {
    return function headerClickHandler() {
      if (!id || !isSorted) {
        return;
      }
      sortingSettingsChange({
        headerNameId: id,
      });
    };
  }

  function createBookingsClickHandler(id) {
    return function headerBookingsClickHandler() {
      if (selBookings === id) {
        setSelBookings(null);
        return;
      }

      setSelBookings(id);
    };
  }
  function createCommunicationBtnClickHandler({
    passCodeId,
    reservationId,
    isDisableSendNewComReq,
  }) {
    return function onCommunicationBtnClick(event) {
      event.stopPropagation();
      event.preventDefault();

      if (isDisableSendNewComReq) {
        return;
      }

      communicationBtnClick({
        passCodeId,
        reservationId,
      });
    };
  }

  function createShowCarInfoClickHandler({
    carInfo,
  }) {
    return function onShowCarInfoClick(event) {
      event.stopPropagation();
      event.preventDefault();

      showCarInfoClick({
        carInfo,
      });
    };
  }

  return (
    <Paper className={classes.root}>
      {!isBookingsData && (
        <div className={classes.noDataContainer}>
          <Typography
            className={classes.noDataStr}
          >
            {t('bookings.noDataYet')}
          </Typography>
        </div>
      )}
      {isBookingsData && (
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id, isSorted }) => {
                  const sortingRule = headerSortSettings[id || ''] || {};
                  const isSort = sortingRule === 'sort';
                  const isSortReverse = sortingRule === 'sortReverse';

                  return (
                    <TableCell
                      key={id}
                      align="left"
                      className={isSorted ? classes.clickable : ''}
                      onClick={createHeaderClickHandler(id, isSorted)}
                    >
                      <div className={classes.headerContainer}>
                        <Typography className={classes.headerStr}>
                          {label}
                        </Typography>
                        {isSort && (
                          <SortedSmallFirstIcon className={classes.sortIcon} />
                        )}
                        {isSortReverse && (
                          <SortedBigFirstIcon className={classes.sortIcon} />
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index, array) => {
                const {
                  reservationId,
                  id,
                  dateIn,
                  dateOut,
                  carBrand,
                  carStateNumber,
                  status,
                  name,
                  totalCost,
                  carInfo,
                  phone,
                } = row || {};
                const key = id;

                const currency = t('others.currency');
                const totalCostStr = `${(+totalCost || '').toLocaleString()} ${currency}`;

                const durationTime = moment.duration(dateOut.diff(dateIn));
                const duration = getHumanizeDuration(durationTime.asMilliseconds());

                const dateInTimeStr = dateIn.format('HH:mm');
                const dateInDateStr = dateIn.format('DD.MM.yyyy');
                const dateOutTimeStr = dateOut.format('HH:mm');
                const dateOutDateStr = dateOut.format('DD.MM.yyyy');

                const phoneStr = formatPhoneNumber(phone) || phone;

                const isLast = index === (array.length - 1);

                const tableRowClass = [
                  classes.tableRow,
                  isLast ? classes.tableRowLast : '',
                  (selBookings === id) ? classes.selectedBookings : '',
                ];

                const currentComReq = (communicationRequestList || []).find(
                  ({ reservationId: curRsrvId }) => curRsrvId === reservationId,
                );
                const isDisableSendNewComReq = !!currentComReq && !(currentComReq || {}).error;

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                    onClick={createBookingsClickHandler(id)}
                    hover
                  >
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.bookingsIdStr}>
                        {transformPassCode(id)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.timeStr}>
                        {dateInTimeStr}
                      </Typography>
                      <Typography className={classes.dateStr}>
                        {dateInDateStr}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.timeStr}>
                        {dateOutTimeStr}
                      </Typography>
                      <Typography className={classes.dateStr}>
                        {dateOutDateStr}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.durationStr}>
                        {duration}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.carBrand}>
                        {carBrand}
                      </Typography>
                      {carInfo ? (
                        <CustomLink
                          className={classes.carNumberStr}
                          onClick={createShowCarInfoClickHandler({ carInfo })}
                        >
                          {carStateNumber}
                        </CustomLink>
                      ) : (
                        <Typography className={classes.carNumberStr}>
                          {carStateNumber}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <StatusIcon
                        statusKey={status}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.nameStr}>
                        {name}
                      </Typography>
                    </TableCell>
                    {isCostColumn && (
                      <TableCell
                        align="left"
                      >
                        <Typography className={classes.totalCost}>
                          {totalCostStr}
                        </Typography>
                      </TableCell>
                    )}
                    {isShowPhone && (
                      <TableCell
                        align="left"
                      >
                        <Typography className={classes.totalCost}>
                          {phoneStr}
                        </Typography>
                      </TableCell>
                    )}
                    {isConnectWithDriverColumn && (
                      <TableCell
                        align="left"
                      >
                        <Tooltip
                          title={isDisableSendNewComReq ? 'Вы уже отпарвили запрос' : 'Связаться с водителем'}
                          PopperProps={{
                            className: classes.popper,
                          }}
                        >
                          <div className={classes.phoneIconBtnCnt}>
                            <PhoneIconBtn
                              disabled={isDisableSendNewComReq}
                              onClick={createCommunicationBtnClickHandler({
                                reservationId,
                                passCodeId: id,
                                isDisableSendNewComReq,
                              })}
                            />
                          </div>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

BookingsTable.propTypes = propTypes;

function mapStateToProps(state) {
  const { platform } = state || {};
  const { allPlatforms, onePlatform } = platform || {};
  return {
    allPlatforms,
    onePlatform,
  };
}

const ConnectedBookingsTable = connect(
  mapStateToProps,
  {
    getOnePlatformReq: getOnePlatformAction,
  },
)(BookingsTable);

export default ConnectedBookingsTable;
