import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import BackIcon from '@parkly/shared/components/atoms/icons/BackIcon';

import { useStyles } from './styles';

const CustomIconButton = withStyles(() => ({
  root: {
    backgroundColor: 'rgba(0, 26, 84, 0.08)',
    height: 32,
    width: 32,
    padding: 0,
    marginRight: 20,
  },
}))(IconButton);

const propTypes = {
  title: PropTypes.string,
  isBackBtn: PropTypes.bool,
};

function HeaderWithBackBtn({
  title = '',
  isBackBtn = true,
  children = null,
  backUrl
}) {
  const classes = useStyles();
  const history = useHistory();

  function onBackBtnClick() {
    history.goBack();
  }

  return (
    <div className={classes.container}>
      {isBackBtn && (
        <CustomIconButton onClick={onBackBtnClick} aria-label="back">
          <BackIcon />
        </CustomIconButton>
      )}
      <Typography className={classes.title} component="h1">
        {title}
      </Typography>
      {children}
    </div>
  );
}
HeaderWithBackBtn.propTypes = propTypes;

export default HeaderWithBackBtn;
