import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGIN_LOGOUT_PROGRESS,
} from '@parkly/shared/actions/types';

const INITIAL_STATE = {
  loading: false,
  loggedIn: false,
  user: null,
};

export default function authentication(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_LOGOUT_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        user: action.user,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
