import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';
import Container from '@material-ui/core/Container';
import Pagination from '@material-ui/lab/Pagination';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import SubscriptionSelectors from 'components/organisms/SubscriptionSelectors';
import SubscriptionsTable from 'components/templates/SubscriptionsTable';

import { getAllPlatforms } from 'actions';
import { getSubscriptionsAction } from 'actions/subscriptions';
import { SUBSCRIPTION_UPDATE_PERIOD } from 'config/constants';

import { useStyles } from './styles';

/* help functions */

function getInitParams({
  match,
}) {
  const { params } = match || {};
  const { id } = params || {};
  const isId = !!id || id === 0;

  return {
    selPlatformValue: isId ? id : 'unselect',
    selStatusValue: [],
    page: 1,
  };
}

function prepareParam({
  params,
}) {
  const {
    selPlatformValue,
    selStatusValue,
    page,
  } = params || {};

  const reqParam = {
    platformSign: selPlatformValue,
    status: selStatusValue,
    page,
  };

  return reqParam;
}

function requestSubscriptions({
  reqParam,
  getSubscriptionsReq = () => {},
}) {
  throttle(
    getSubscriptionsReq,
    300,
  )(reqParam);
}

function getCurrentSubscriptions({
  subscriptionsState,
  params,
}) {
  const subscriptionsList = (subscriptionsState || {}).list || [];

  const preparedReqParam = prepareParam({
    params,
  });
  const currentStrParam = JSON.stringify(preparedReqParam);

  const subscriptions = subscriptionsList.find((item) => {
    const { strParam } = item || {};

    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    subscriptions,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

/* Main component */

const propTypes = {

};

function AllSubscriptionsPage({
  allPlatforms = {},
  match,
  subscriptionsState,
  getAllPlatformsReq = () => {},
  getSubscriptionsReq = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [params, setParams] = useState(getInitParams({
    match,
  }));
  const [prevParams, setPrevParams] = useState('');

  useEffect(
    () => {
      const { loading, platforms } = allPlatforms;
      const { data } = platforms || {};
      const isPlatformsLoaded = !!data;

      if (isPlatformsLoaded || loading) {
        return;
      }

      getAllPlatformsReq();
    },
    // eslint-disable-next-line
    [],
  );
  useEffect(
    () => {
      const {
        subscriptions,
        reqParam,
        currentStrParam,
      } = getCurrentSubscriptions({
        subscriptionsState,
        params,
      });

      if (currentStrParam !== prevParams) {
        const nowTime = new Date().getTime();
        const { timeStamp = nowTime } = subscriptions || {};

        if (!subscriptions || (nowTime - timeStamp) / 1000 > SUBSCRIPTION_UPDATE_PERIOD) {
          requestSubscriptions({
            reqParam,
            getSubscriptionsReq,
          });

          setPrevParams(currentStrParam);
        }
      }
    },
    // eslint-disable-next-line
    [params],
  );

  const isLoading = (subscriptionsState || {}).loading;

  const { id } = (match || {}).params || {};
  const isId = !!id || id === 0;

  const {
    subscriptions,
  } = getCurrentSubscriptions({
    subscriptionsState,
    params,
  });

  const {
    subscriptionsData,
    subscriptionsMeta,
  } = (subscriptions || {}).data || {};

  const {
    currentPage = 1,
    lastPage = 1,
  } = subscriptionsMeta || {};

  const {
    selPlatformValue,
    selStatusValue,
  } = params;

  const handleParamChange = (paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  };
  function onPageChange(_, pageNumber) {
    handleParamChange('page', pageNumber);
  }

  return (
    <Container className={classes.container}>
      <HeaderWithBackBtn
        title={t('subscriptions.subscriptions')}
        isBackBtn={isId}
      />
      <div className={classes.pageBody}>
        <SubscriptionSelectors {...{
          allPlatforms,
          selPlatformValue,
          selStatusValue,
          handleParamChange,
        }}
        />
        {!isLoading && (
          <>
            <SubscriptionsTable
              isShowFacilityName={selPlatformValue === 'unselect'}
              subscriptionsData={subscriptionsData}
            />
            {lastPage > 1 && (
              <div className={classes.paginationContainer}>
                <Pagination
                  className={classes.pagination}
                  name="page"
                  page={currentPage}
                  onChange={onPageChange}
                  count={lastPage}
                  color="primary"
                />
              </div>
            )}
          </>
        )}
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
      </div>
    </Container>
  );
}

AllSubscriptionsPage.propTypes = propTypes;

function mapStateToProps(state) {
  const { platform, operators, subscriptions } = state || {};
  const { allPlatforms } = platform || {};
  const { currentOperator } = operators || {};
  const { subscriptions: subscriptionsState } = subscriptions || {};

  return {
    allPlatforms,
    subscriptionsState,
    currentOperator,
  };
}

const ConnectedAllSubscriptionsPage = connect(
  mapStateToProps,
  {
    getAllPlatformsReq: getAllPlatforms,
    getSubscriptionsReq: getSubscriptionsAction,
  },
)(AllSubscriptionsPage);

export default ConnectedAllSubscriptionsPage;
