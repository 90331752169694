import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 30,
  },
  title: {
    color: theme.palette.greyC.darkGrey,
    marginRight: 16,
  },
  value: {
    fontWeight: 'bold',
    marginRight: 12,
  },
}));

export default {
  useStyles,
};
