import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
    maxWidth: 'unset',
  },
  boxBasic: {
    borderRadius: 6,
    padding: 24,
    marginTop: 30,
    backgroundColor: theme.palette.greyC.lightGrey,
  },
}));

export default {
  useStyles,
};
