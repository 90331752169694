import React from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown';

import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import OptionWithIcon from '@parkly/shared/components/molecules/OptionWithIcon';
import PriceList from 'components/organisms/PriceList';
import ImgMultipleCarousel from '@parkly/shared/components/molecules/ImgMultipleCarousel';

import { createMailLink, formatPhoneNumber } from '@parkly/shared/helpers/others';

import { isEmpty } from 'lodash';
import { useStyles } from './styles';

const propTypes = {

};

function BasePlatformInfo({
  platformId,
  platformName = '',
  options,
  findEntranceInstructions = '',
  parkingInstructions,
  phone,
  tariffList,
  photos,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const phoneStr = phone ? formatPhoneNumber(phone) : phone;

  function onWriteToUsClick() {
    const email = process.env.REACT_APP_SUPPORT_EMAIL;
    const subject = `Внести изменения в информацию о площадке ${platformName} (${platformId})`;
    const message = '';

    const mailLink = createMailLink({
      email,
      subject,
      message,
    });

    document.location.href = mailLink;
  }

  return (
    <Box className={classes.boxBasic}>
      <Typography variant="h2" className={classes.title}>
        {t('platforms.baseInfo')}
      </Typography>
      <Grid className={classes.gridContainer} container spacing={3}>
        <GridTitleBase
          title={t('platforms.tel')}
        >
          <Typography className={classes.titleStr}>
            {phoneStr || '-'}
          </Typography>
        </GridTitleBase>
        <GridTitleBase
          title={t('platforms.bookOptions')}
          style={{ paddingBottom: 15 }}
        >
          <div className={classes.optionsContainer}>
            {(options || []).map((option) => (
              <OptionWithIcon
                key={option}
                option={option}
              />
            ))}
          </div>
        </GridTitleBase>
        <GridTitleBase
          title={t('platforms.howFindParkingSpace')}
        >
          <ReactMarkdown
            children={findEntranceInstructions || ' - '}
            className={classes.markdown}
          />
        </GridTitleBase>
        <GridTitleBase
          title={t('platforms.howPark')}
        >
          <ReactMarkdown
            children={parkingInstructions || ' - '}
            className={classes.markdown}
          />
        </GridTitleBase>
        <GridTitleBase
          title={t('platforms.picture')}
        >
          <ImgMultipleCarousel
            imgList={photos}
          />
        </GridTitleBase>
        {!isEmpty(tariffList) && (
          <GridTitleBase
            title={t('platforms.priceList')}
          >
            <Grid className={classes.gridContainer} container spacing={3}>
              <PriceList {...{
                tariffList,
              }}
              />
            </Grid>
          </GridTitleBase>
        )}
        <GridTitleBase
          style={{ borderBottom: 0 }}
        >
          <Grid className={classes.gridContainer} container spacing={3}>
            <Box className={classes.boxWriteToUS}>
              <Typography>
                {t('platforms.writeToUs')}
              </Typography>
              <CustomBtn
                btnType="secondary"
                className={classes.writeToUsBtn}
                onClick={onWriteToUsClick}
              >
                {t('platforms.writeToUsBtn')}
              </CustomBtn>
            </Box>
          </Grid>
        </GridTitleBase>
      </Grid>

    </Box>
  );
}

BasePlatformInfo.propTypes = propTypes;

export default BasePlatformInfo;
