import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';

import { COOKIE_NAMES } from '@parkly/shared/config/constants';

const propTypes = {};

function AccessRedirect({ home }) {
  useEffect(
    () => {
      document.cookie = `${COOKIE_NAMES.accessToStaging}=true; secure`;

      window.location.href = home;
    },
    [home],
  );

  return <div />;
}

AccessRedirect.propTypes = propTypes;

export default AccessRedirect;
