// import React from 'react';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';

const CustomLink = withStyles((theme) => ({
  root: {
    color: theme.palette.text.blue,
    textDecoration: 'underline',
    fontSize: theme.typography.fontSize14,
    cursor: 'pointer',

    '&:visited': {
      color: theme.palette.text.blue,
    },
    '&:hover': {
      color: theme.palette.blueC.blue5,
      textDecoration: 'none',
    },
  },
}))(Link);

export default CustomLink;
