import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogMain: {
    '& .MuiPaper-root': {
      width: 332,
    },
  },
  titleStr: {
    fontWeight: 'bold',
    fontSize: theme.typography.fontSize16,
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  dialogContentStr: {
  },
  btnDisagree: {
    minWidth: 100,
  },
  btnAgree: {
    minWidth: 100,
    marginLeft: 12,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  dialogActions: {
    marginTop: 8,
    padding: 24,
  },
}));

export default {
  useStyles,
};
