import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 32,
  },
  tableContainer: {

  },
  headerStr: {
    color: theme.palette.greyC.darkGrey,
  },
  ruleNameStr: {

  },
  cellFirstColumn: {
    paddingLeft: 0,
  },
}));

export default {
  useStyles,
};
