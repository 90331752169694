import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {

  },
  priceRect: {
    fill: theme.palette.blueC.blue6,
  },
  label: {
    fontSize: theme.typography.fontSize14,
    color: theme.palette.blueC.darkBlue,
  },
  line: {
    stroke: theme.palette.blueC.blue,
    strokeWidth: 2,
  },
  axisYLabel: {
    fontSize: theme.typography.fontSize14,
    color: theme.palette.blueC.darkBlue,
    'text-anchor': 'end',
  },
  xAxis: {
    '& path': {
      stroke: 'rgba(0, 26, 84, 0.00)',
    },
    '& line': {
      stroke: 'rgba(0, 26, 84, 0.08)',
    },
    '& text': {
      fontSize: theme.typography.fontSize14,
      color: theme.palette.blueC.darkBlue,
    },
  },
}));

export default {
  useStyles,
};
