import { STATE_NAMES } from 'config/constants';

export function getCurrentPlatform({
  onePlatform,
  platformId,
}) {
  const {
    list,
    loading: isLoading,
  } = onePlatform || {};
  const currentPlatform = (list || []).find(
    (item = {}) => +(item.data || {}).id === +platformId,
  );

  return {
    currentPlatform,
    isLoading,
  };
}

export function extractReportsFromState(state) {
  const { documents } = state || {};
  const { reports } = documents || {};

  return reports;
}

export function extractCurrentOperatorFromState(state) {
  return state?.operators?.currentOperator;
}

export function extractAllPlatformsFromState(state) {
  return state?.platform?.allPlatforms;
}

export function getBlockedUsersExtractor({ facilityId }) {
  if (!facilityId) {
    return () => null;
  }

  return function extractBlockedUsersFromState(state) {
    const blockedUsersState = state.blockedUsers?.[STATE_NAMES.blockedUsers];
    const { list, loading } = blockedUsersState || {};
    const strParam = JSON.stringify({ facilityId });
    const item = (list || []).find(({ strParam: strParamItem }) => strParamItem === strParam);

    return {
      loading,
      ...item,
    };
  };
}

export default {
  getCurrentPlatform,
  extractReportsFromState,
};
