export function updateStoreReqResList(
  list,
  newData,
  reqParam,
) {
  if (!newData) {
    return list;
  }

  const stringifyParam = JSON.stringify(reqParam);

  const newList = [
    ...list
      .slice(0, 5)
      .filter((item = {}) => {
        const { strParam } = item || {};
        const isFilter = strParam !== stringifyParam;

        return isFilter;
      }),
    {
      data: newData,
      strParam: stringifyParam,
      timeStamp: new Date().getTime(),
    },
  ];

  return newList;
}

export function updateStoreReqRes(
  state,
  name,
  action,
) {
  if (action.payload.error) {
    return {
      ...state,
      [name]: {
        ...state[name],
        loading: action.payload.loading,
        error: action.payload.error,
      },
    };
  }

  const data = action.payload.param
    ? {
      list: updateStoreReqResList(
        state[name].list,
        action.payload.data,
        action.payload.param,
      ),
    }
    : {
      data: action.payload.data,
      timeStamp: new Date().getTime(),
    };

  return {
    ...state,
    [name]: {
      ...state[name],
      loading: action.payload.loading,
      ...data,
    },
  };
}

export default {
  updateStoreReqResList,
  updateStoreReqRes,
};
