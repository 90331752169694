import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import HeaderWithSelectorAndBtn from '@parkly/shared/components/organisms/HeaderWithSelectorAndBtn';
import StarIcon from '@parkly/shared/components/atoms/icons/StarIcon';
import ActiveCircleIcon from '@parkly/shared/components/atoms/icons/ActiveCircleIcon';

import { getOperatorsAction } from 'actions';
import { PATH_PAGES } from 'config/constants';

import { useStyles } from './styles';

function getHeaderSelPlatformId({
  allPlatforms,
  currentOperator,
}) {
  const { userRights } = currentOperator || {};
  const { isAdmin, readOperatorsListPlatforms } = userRights || {};

  const { platforms } = allPlatforms || {};
  const { data } = platforms || {};

  const headerPlatformsSelectors = (data || [])
    .filter(({ id }) => {
      const isEnoughRight = isAdmin || readOperatorsListPlatforms.includes(id);

      return isEnoughRight;
    });

  const isAll = headerPlatformsSelectors
    && data && headerPlatformsSelectors.length === (data || []).length;

  const headerSelectors = isAll
    ? 'all'
    : (headerPlatformsSelectors[0] || {}).id;

  return headerSelectors;
}

function getHeaderSelectors({
  allPlatforms,
  currentOperator,
  t = () => {},
}) {
  const { userRights } = currentOperator || {};
  const { isAdmin, readOperatorsListPlatforms } = userRights || {};

  const { platforms } = allPlatforms || {};
  const { data } = platforms || {};

  const headerPlatformsSelectors = (data || [])
    .map(({ id, title }) => ({
      id,
      title,
    }))
    .filter(({ id }) => {
      const isEnoughRight = isAdmin || readOperatorsListPlatforms.includes(id);

      return isEnoughRight;
    });

  const isAll = headerPlatformsSelectors && data && headerPlatformsSelectors.length === (data || []).length;

  const headerSelectors = isAll
    ? [
      {
        id: 'all',
        title: t('operators.allPlatforms'),
      },
      ...headerPlatformsSelectors,
    ]
    : headerPlatformsSelectors;

  return headerSelectors;
}

const propTypes = {};

function AllOperators({
  allPlatforms,
  operators,
  currentOperator,
  getOperatorsReq = () => {},
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [settings, setSettings] = useState({
    selPlatformId: getHeaderSelPlatformId({
      allPlatforms,
      currentOperator,
    }),
  });

  useEffect(
    () => {
      const { selPlatformId } = settings || {};

      if (selPlatformId || selPlatformId === 0) {
        const operatorsDataList = (operators || {}).list || [];
        const currentOperators = operatorsDataList.find(
          ({ platformSign }) => platformSign === selPlatformId,
        );

        if (currentOperators) {
          return;
        }

        getOperatorsReq({
          platformSign: selPlatformId,
        });
      }
    },
    // eslint-disable-next-line
    [settings.selPlatformId, ((currentOperator || {}).userRights || {})],
  );

  const {
    selPlatformId,
  } = settings;

  const isLoading = (allPlatforms || {}).loading || (operators || {}).loading;

  const operatorsDataList = (operators || {}).list || [];
  const currentOperators = operatorsDataList.find(
    ({ platformSign }) => platformSign === selPlatformId,
  );

  /* HiddenParklyAdmin - feature for debug. So, we can add admin, but other
  users will not see this admin  */
  const operatorsList = ((currentOperators || {}).operatorsList || [])
    .filter(({ name }) => name.indexOf('HiddenParklyAdmin') < 0);

  const { userRights } = currentOperator || {};
  const {
    isAdmin,
    isCreateUpdOperators,
    isReadOperatorsInfo,
    isReadOperatorsActions,
  } = userRights || {};

  const headerSelectors = getHeaderSelectors({
    allPlatforms,
    currentOperator,
    t,
  });

  function onAddOperatorBtnClick() {
    if (!isCreateUpdOperators) {
      return;
    }
    history.push(PATH_PAGES.addNewOperator);
  }
  function createHandlerTableRowClick(id, isReadCurOperatorInfo) {
    const linkStr = PATH_PAGES.oneOperator.replace(':id', id);
    return function handleTableRowClick() {
      if (!isReadCurOperatorInfo) {
        return;
      }

      history.push(linkStr);
    };
  }
  function updateSettings(name, value) {
    setSettings((oldSettings) => ({
      ...oldSettings,
      [name]: value,
    }));
  }
  function createActionBtnClickHandler(id) {
    return function onActionBtnClick(event) {
      event.stopPropagation();
      event.preventDefault();
      if (!isReadOperatorsActions) {
        return;
      }

      history.push(PATH_PAGES.operatorActions.replace(':operatorId', id));
    };
  }
  // function createMoreBtnClickHandler(id) {
  //   return function onMoreBtnClick(event) {
  //     // event.stopPropagation();
  //     // event.preventDefault();
  //   };
  // }

  return (
    <Container
      className={classes.container}
      maxWidth="md"
    >
      <HeaderWithBackBtn
        title={t('operators.operators')}
        isBackBtn={false}
      />
      <div className={classes.pageBody}>
        {!isLoading && (
          <>
            <HeaderWithSelectorAndBtn {...{
              headerSelectors,
              selSelectorValue: selPlatformId,
              updateSettings,
              isBtn: isCreateUpdOperators,
              onBtnClick: onAddOperatorBtnClick,
            }}
            />
            {!isLoading && operatorsList && (
              <TableContainer className={classes.tableContainer} component={Paper}>
                <Table className={classes.table} aria-label="sticky table">
                  <TableBody className={classes.tableBody}>
                    {operatorsList.map((curOperator = {}, index, array) => {
                      const key = curOperator.id;
                      const {
                        id,
                        login,
                        name,
                        status,
                      } = curOperator || {};
                      const isCurOperatorAdmin = (curOperator || {}).isAdmin;

                      const isReadCurOperatorInfo = isAdmin || isReadOperatorsInfo;
                      const isLast = (array.length - 1) === index;

                      const tableRowClass = [
                        classes.tableRow,
                        isReadCurOperatorInfo ? '' : classes.tableRowNoRight,
                        isLast ? classes.tableRowLast : '',
                      ];

                      return (
                        <TableRow
                          className={tableRowClass.join(' ')}
                          key={key}
                          name={id}
                          hover
                          tabIndex={-1}
                          onClick={createHandlerTableRowClick(id, isReadCurOperatorInfo)}
                        >
                          <TableCell
                            align="left"
                            className={`${classes.tableCell} classes.operatorTitle`}
                          >
                            <div className={`${classes.containerInCell}`}>
                              <div className={`${classes.isAdmin}`}>
                                {isCurOperatorAdmin && (
                                  <StarIcon />
                                )}
                              </div>
                              <Typography className={classes.operatorTitleStr}>
                                {name}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableCell}
                          >
                            <div className={`${classes.containerInCell}`}>
                              <Typography>
                                {login}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableCell}
                          >
                            <div className={`${classes.containerInCell}`}>
                              <ActiveCircleIcon
                                className={classes.statusIcon}
                                /* eslint-disable-next-line eqeqeq */
                                active={status == 1}
                              />
                              <Typography className={classes.statusName}>
                                {/* eslint-disable-next-line eqeqeq */}
                                {status == 1 ? t('operators.active') : t('operators.notActive')}
                              </Typography>
                            </div>
                          </TableCell>
                          {isReadOperatorsActions && (
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              <div className={`${classes.containerInCell}`}>
                                <CustomBtn
                                  onClick={createActionBtnClickHandler(id)}
                                  btnType="secondary"
                                  className={`${classes.actionBtn}`}
                                >
                                  {t('operators.actions')}
                                </CustomBtn>
                              </div>
                            </TableCell>
                          )}
                          {/* isReadCurOperatorInfo && (
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              <div className={`${classes.containerInCell} ${classes.moreCell}`}>
                                <IconButton
                                  onClick={createMoreBtnClickHandler(id)}
                                >
                                  <MoreIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          ) */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
      </div>
    </Container>
  );
}

AllOperators.propTypes = propTypes;

function mapStateToProps(state) {
  const { operators, platform } = state || {};
  const { allPlatforms } = platform || {};
  const { currentOperator } = operators || {};

  return {
    allPlatforms,
    operators: (operators || {}).operators,
    currentOperator,
  };
}

const ConnectedAllOperators = connect(
  mapStateToProps,
  {
    getOperatorsReq: getOperatorsAction,
  },
)(AllOperators);

export default ConnectedAllOperators;
