import { COOKIE_NAMES } from '@parkly/shared/config/constants';
import moment from 'moment-timezone';

export function getCookie(name) {
  const matches = document.cookie.match(new RegExp(
    // eslint-disable-next-line no-useless-escape
    `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function checkIsAccessToStaging() {
  const accessCookies = getCookie(COOKIE_NAMES.accessToStaging);

  return accessCookies === 'true';
}

export function validateINN(inn) {
  const number = inn.replace(/\s/g, '');
  if (!/^([0-9]{10}|[0-9]{12})$/.test(number)) { return false; }

  if (number.length === 10) {
    const parse = number.match(/^(.{9})(.)$/);
    const n = parse[1].split('');

    const controlCode = parse[2];
    const checkCode = ((2 * n[0] + 4 * n[1] + 10 * n[2] + 3 * n[3]
      + 5 * n[4] + 9 * n[5] + 4 * n[6] + 6 * n[7] + 8 * n[8]) % 11) % 10;

    if (checkCode !== parseInt(controlCode, 10)) {
      return false;
    }
  } else {
    const parse = number.match(/^(.{10})(.)(.)$/);
    const n = parse[1].split('');
    const controlCode1 = parse[2];
    const controlCode2 = parse[3];
    const checkCode1 = ((7 * n[0] + 2 * n[1] + 4 * n[2] + 10 * n[3]
      + 3 * n[4] + 5 * n[5] + 9 * n[6] + 4 * n[7] + 6 * n[8] + 8 * n[9]) % 11) % 10;
    const checkCode2 = ((3 * n[0] + 7 * n[1] + 2 * n[2] + 4 * n[3] + 10 * n[4]
      + 3 * n[5] + 5 * n[6] + 9 * n[7] + 4 * n[8] + 6 * n[9] + 8 * checkCode1) % 11) % 10;

    if (
      checkCode1 !== parseInt(controlCode1, 10)
      || checkCode2 !== parseInt(controlCode2, 10)
    ) {
      return false;
    }
  }

  return true;
}

/* --- format --- */

export function formatPhoneNumber(str = '') {
  const cleaned = (`${str}`).replace(/\D/g, '');
  const cut = cleaned.slice(0, 11);
  const cutWithCode = cut[0] !== '7'
    ? `7${cut}`
    : cut;
  // when user tries to remove ")"
  const cutStr = str.indexOf('(') > 0 && str.indexOf(')') < 0 && cleaned.length === 4
    ? cutWithCode.slice(0, -1)
    : cutWithCode;

  const match = cutStr.match(/^(\d{1})?(\d{3})?(\d{3})?(\d{2})?(\d{2})?(\d{1})?$/);

  if (match) {
    const intlCode = '+7 ';
    const match2 = match[2] ? `(${match[2]})` : '';
    const match3 = match[3] ? `${match[3]}` : '';
    // eslint-disable-next-line no-nested-ternary
    const match4 = match[4] && match[2] && match[3]
      ? `${match[4]}`
      : match[4] ? match[4] : '';

    return [intlCode, match2, match3, match4, match[5], match[6]]
      .filter((item) => !!item)
      .join(' ');
  }

  return '+7 ';
}

export function cleanPhone(phone) {
  return (phone || '').replace(/\D/g, '');
}

const initialPhoneValue = '+7';
export function formatPhoneNumberInput(textParam = '') {
  const text = textParam.replace(/[\D]/g, '');
  if (text.length < 1) {
    return '';
  } else if (text.length < 2) {
    if (!['8', '+', '+7', '7'].includes(text)) {
      return `${initialPhoneValue}\xa0${text}`;
    } else {
      return initialPhoneValue;
    }
  } else if (text.length < 5) {
    return `${initialPhoneValue}\xa0(${text.substring(1, 4)}`;
  } else if (text.length < 8) {
    return `${initialPhoneValue}\xa0(${text.substring(1, 4)})\xa0${text.substring(4, 7)}`;
  } else if (text.length < 10) {
    return `${initialPhoneValue}\xa0(${text.substring(1, 4)})\xa0${text.substring(4, 7)}\xa0${text.substring(7, 9)}`;
  } else if (text.length < 12) {
    return `${initialPhoneValue}\xa0(${text.substring(1, 4)})\xa0${text.substring(4, 7)}\xa0${text.substring(
      7,
      9,
    )}\xa0${text.substring(9, 11)}`;
  } else {
    return text;
  }
}

export function formatPhoneNumber2(str) {
  // Filter only numbers from the input
  const cleaned = (`${str}`).replace(/\D/g, '');

  // Check if the input is of correct
  const match = cleaned.match(/^(\d{1})?(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    const intlCode = (match[1] ? '+7 ' : '');
    return [intlCode, ' ', match[2], ' ', match[3], ' ', match[4], ' ', match[5]].join('');
  }

  return null;
}

/* --- */

export function detectRussianLanguage(str = '') {
  const code = str.charCodeAt(0);

  if (!code) {
    return false;
  }

  if ((code < 1104) && (code > 1040)) {
    return true;
  }

  return false;
}

export function checkProduction() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return false;
  }

  return true;
}

export function checkIsStaging() {
  const isProduction = checkProduction();
  const isFullProd = process.env.REACT_APP_IS_FULL_PRODUCTION === 'true';

  return isProduction && !isFullProd;
}

export function createMailLink({
  email = '',
  subject = '',
  message = '',
}) {
  const encodedSubject = encodeURIComponent(subject);
  const encodedMessage = encodeURIComponent(message);

  const result = `mailto:${email}?subject=${encodedSubject}&body=${encodedMessage}`;

  return result;
}

export function plural(n, forms) {
  return n % 10 === 1 && n % 100 !== 11 ? forms[0] : (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? forms[1] : forms[2]);
}

export function getPopoverPosition(popoverPosition) {
  if (popoverPosition === 'right') {
    return {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    };
  }

  return {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };
}

export function getActionsList({
  actionsOneOperator,
  selOperatorId,
}) {

  const { list } = actionsOneOperator || {};
  const selOperatorActions = (list || []).find((operatorActions) => {
      const { operatorId } = operatorActions || {};
      return operatorId === selOperatorId;
    });

  const {
    actions = [],
    meta,
  } = selOperatorActions || {};

  const page = (meta || {}).currentPage;
  const maxPage = (meta || {}).lastPage;

  const transformedActions = actions.map((oneAction) => {
    const {
      id,
      date,
      title,
      oldValues,
      newValues,
      operatorId,
      operatorName,
    } = oneAction || {};

    return {
      id,
      date: moment(date),
      title,
      oldValues,
      newValues,
      operatorId,
      operatorName,
    };
  });

  return {
    selOperatorActions,
    actionsList: transformedActions,
    page,
    maxPage,
  };
}

export default {
  plural,
  createMailLink,
  formatPhoneNumber,
  formatPhoneNumber2,
  checkProduction,
  getPopoverPosition,
};
