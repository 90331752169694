import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import InfoIconMini from '@parkly/shared/components/atoms/icons/InfoIconMini';

import { transformPassCode } from 'helpers';

import { useStyles } from './styles';

const propTypes = {
  open: PropTypes.bool,
  reservationId: PropTypes.number,
  passCodeId: PropTypes.number,
  handleClose: PropTypes.func,
  handleAgree: PropTypes.func,
};

function DialogSendMsg({
  open,
  passCodeId,
  reservationId,
  handleClose = () => {},
  handleAgree = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [values, setValues] = useState({
    msgBody: '',
  });

  const agreeTitle = t('others.send');
  const disagreeTitle = t('others.cancel');
  const dialogTitleStr = t('others.attention');

  const {
    msgBody = '',
  } = values;

  const handleChanges = (event) => {
    const propName = event.target.name;
    const { value } = event.target;

    setValues((prevSettings) => ({
      ...prevSettings,
      [propName]: value,
    }));
  };

  const formAgreeHandler = (event) => {
    event.preventDefault();
    event.nativeEvent.preventDefault();

    handleAgree({
      reservationId,
      msg: msgBody,
    });
  };

  return (
    <Dialog
      className={classes.dialogMain}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form
        className={classes.form}
        onSubmit={formAgreeHandler}
        autoComplete="off"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography className={classes.titleStr}>
            {dialogTitleStr}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.dialogContentDescription} id="alert-dialog-description">
            Водителю с ID бронирования
            {' '}
            <b>{transformPassCode(passCodeId)}</b>
            {' '}
            будет отправлен запрос для связи с вами. Вы уверены?
          </Typography>
          <Typography className={classes.dialogContentMsgTitle} id="alert-dialog-description">
            {t('bookings.message')}
          </Typography>
          <CustomTextInput
            className={classes.msgBodyInput}
            name="msgBody"
            value={msgBody}
            type="secondary"
            required
            autoComplete="off"
            inputProps={{
              maxLength: 144,
            }}
            multiline
            placeholder={t('bookings.msgPlaceholder')}
            onChange={handleChanges}
          />
          <div className={classes.dialogContentInfoCnt}>
            <InfoIconMini className={classes.infoIconMini} />
            <Typography className={classes.dialogContentInfo} id="alert-dialog-description">
              Укажите ваш номер телефона или другой способ связи, если хотите, чтобы клиент вам ответил
            </Typography>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {disagreeTitle && (
            <CustomBtn
              btnType="secondary"
              onClick={handleClose}
              className={classes.btnDisagree}
            >
              {disagreeTitle}
            </CustomBtn>
          )}
          {agreeTitle && (
            <CustomBtn
              color="primary"
              type="submit"
              className={classes.btnAgree}
            >
              {agreeTitle}
            </CustomBtn>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}

DialogSendMsg.propTypes = propTypes;

export default DialogSendMsg;
