import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import 'moment/locale/ru';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';

import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';

import { SUBSCRIPTION_STATUS_KEYS } from 'config/constants';

import { useStyles } from './styles';

const statusList = [
  {
    id: SUBSCRIPTION_STATUS_KEYS.paid,
    label: 'Оформление',
  },
  {
    id: SUBSCRIPTION_STATUS_KEYS.active,
    label: 'Активные',
  },
  {
    id: [SUBSCRIPTION_STATUS_KEYS.finished, SUBSCRIPTION_STATUS_KEYS.stopped].join(','),
    label: 'Архивный',
  },
];

const propTypes = {};

function SubscriptionSelectors({
  allPlatforms = {},
  selPlatformValue = 'unselect',
  selStatusValue = [],
  handleParamChange = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { platforms = {} } = allPlatforms || {};

  function handleChanges(event) {
    const propName = event.target.name;
    const { value } = event.target;
    handleParamChange(propName, value);
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item md={3} sm={6} xs={12}>
        <ItemSelectors
          name="selPlatformValue"
          selectorsType="secondary"
          currentValue={selPlatformValue}
          items={(platforms || {}).data || []}
          defaultItem={t('bookings.platformDoNotSelect')}
          onChange={handleChanges}
          className={classes.select}
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <ItemSelectors
          multiple
          displayEmpty
          name="selStatusValue"
          selectorsType="secondary"
          className={classes.selectStatus}
          currentValue={selStatusValue}
          onChange={handleChanges}
          input={(
            <Input
              placeholder={t('bookings.statusDoNotSelect')}
            />
          )}
          renderValue={(selected) => {
            if (selected.length < 1) {
              return (
                <Typography className={classes.unSelectStr}>
                  {t('bookings.statusDoNotSelect')}
                </Typography>
              );
            }

            const selectedLabels = selected.map((statusKey) => {
              const current = statusList.find(({ id }) => id === statusKey);
              const { label = '' } = current || {};

              return label;
            });

            return (
              <Typography className={classes.selectedStatusStr}>
                {selectedLabels.join(', ')}
              </Typography>
            );
          }}
        >
          {statusList.map((current = {}) => {
            const { label, id } = current;

            return (
              <MenuItem
                key={id}
                value={id}
                className={classes.statusItem}
              >
                <Checkbox color="primary" checked={selStatusValue.includes(id)} />
                <Typography className={classes.statusItemStr}>
                  {label}
                </Typography>
              </MenuItem>
            );
          })}
        </ItemSelectors>
      </Grid>
    </Grid>
  );
}

SubscriptionSelectors.propTypes = propTypes;

export default SubscriptionSelectors;
