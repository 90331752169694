import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';

import WorkScheduleItem from 'components/molecules/WorkScheduleItem';
import ChangeIconBtn from '@parkly/shared/components/atoms/ChangeIconBtn';

import { SCHEDULE_SETTINGS_TYPES } from 'config/constants';

import { useStyles } from './styles';

/* help functions */

function getChangeIconBtnIconProps({
  weekRule,
  classes,
}) {
  const isWeekRule = !!weekRule;

  if (!isWeekRule) {
    return {
      className: '',
    };
  }
  const {
    dayData,
  } = weekRule || {};
  const {
    isAvailable = true,
  } = dayData || {};

  const weekClass = isAvailable ? classes.changeIconBtnWeekRule : classes.changeIconBtnDayOff;

  return {
    className: weekClass,
  };
}

function getChangeIconBtnClass({
  index,
  hoveredColumn,
  classes,
}) {
  const visibilityClass = (index === hoveredColumn) ? classes.visible : classes.hidden;

  return `${classes.headerIcon} ${visibilityClass}`;
}

function getHeaderBtnClass({
  weekRule,
  classes,
  index,
  isRightUpdateSchedule,
}) {
  const isWeekRule = !!weekRule;

  const defClassList = [
    classes.headerSubContainer,
    isRightUpdateSchedule ? '' : classes.noEnoughRight,
    (index === 0) ? classes.headerFirst : '',
    (index === 6) ? classes.headerLast : '',
  ];
  const defClass = defClassList.join(' ');

  if (!isWeekRule) {
    return defClass;
  }

  const {
    dayData,
  } = weekRule || {};
  const {
    isAvailable,
  } = dayData || {};

  const weekClass = isAvailable ? classes.headerWeekRule : classes.headerWeekRuleDayOff;

  return `${defClass}  ${weekClass}`;
}

function checkWeekRule(dayData) {
  const { schedule, places} = dayData || {};

  const isScheduleWeekRule = (schedule || {}).type === SCHEDULE_SETTINGS_TYPES.week;
  const isParkSpaceWeekRule = (places || {}).type === SCHEDULE_SETTINGS_TYPES.week;

  const isScheduleDefRule = (schedule || {}).type === SCHEDULE_SETTINGS_TYPES.default;
  const isParkSpaceDefRule = (places || {}).type === SCHEDULE_SETTINGS_TYPES.default;

  return {
    isScheduleWeekRule,
    isParkSpaceWeekRule,

    isScheduleDefRule,
    isParkSpaceDefRule,
  };
}

function checkWeekDayRule(tableDays, index) {
  const lastI = tableDays.length - 1;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= lastI; i++) {
    const day = tableDays[i][index];
    const { dayData, isEmpty } = day || {};

    // eslint-disable-next-line
    if (!dayData || isEmpty) continue;

    const {
      isScheduleWeekRule,
      isParkSpaceWeekRule,
      isScheduleDefRule,
      isParkSpaceDefRule,
    } = checkWeekRule(dayData);

    if (isScheduleWeekRule || isParkSpaceWeekRule) {
      return {
        weekDay: index + 1,
        dayData,
      };
    }

    if (isScheduleDefRule && isParkSpaceDefRule) {
      return null;
    }
  }

  return null;
}

function getWeekRules(tableDays) {
  const weekExample = tableDays[1];

  const weekRule = weekExample
    .map((_, index) => {
      const weekDayRule = checkWeekDayRule(tableDays, index);

      return weekDayRule;
    })
    .filter((item) => !!item);

  return weekRule;
}

/* Main components */

const propTypes = {
  isRightUpdateSchedule: PropTypes.bool,
  tableDays: PropTypes.arrayOf(PropTypes.array),
  isHideTable: PropTypes.bool,

  openChangeScheduleModal: PropTypes.func,
};

function WorkScheduleTable({
  isRightUpdateSchedule = false,
  tableDays = [],
  isHideTable = false,

  openChangeScheduleModal = () => {},
}) {
  const classes = useStyles();
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const weekRules = getWeekRules(tableDays);
  const weekDaysName = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  function createOnMouseEnterHeaderItem(index) {
    return function onMouseEnterHeaderItem() {
      setHoveredColumn(index);
    };
  }
  function onMouseLeaveHeaderItem() {
    setHoveredColumn(null);
  }

  function createOnWeekRuleChangeHandler({
    weekRule,
    weekDayIndex,
  }) {
    return function onWeekRuleChangeClick(tab = 'time') {
      if (!isRightUpdateSchedule) {
        return;
      }

      const { dayData } = weekRule || {};
      const { date } = dayData || {};
      const momentDate = date
        ? moment(date)
        : moment().startOf('week').add(weekDayIndex, 'days');
      openChangeScheduleModal({
        type: SCHEDULE_SETTINGS_TYPES.week,
        tab,
        momentDate,
      });
    };
  }
  function createOnDayRuleChangeHandler(day) {
    return function onDayRuleChangeClick(tab = 'time') {
      if (!isRightUpdateSchedule) {
        return;
      }

      const { momentDay } = day || {};

      openChangeScheduleModal({
        type: SCHEDULE_SETTINGS_TYPES.day,
        tab,
        momentDate: momentDay,
      });
    };
  }

  return (
    <Fade in={!isHideTable}>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="summary table">
          <TableHead>
            <TableRow>
              {weekDaysName.map((weekDay, index) => {
                const weekRule = weekRules.find(
                  (weekDayItem) => index === (weekDayItem.weekDay - 1),
                );
                const btnClass = getHeaderBtnClass({
                  index,
                  weekRule,
                  classes,
                  isRightUpdateSchedule,
                });

                return (
                  <TableCell
                    key={weekDay}
                    className={classes.header}
                    onMouseEnter={createOnMouseEnterHeaderItem(index)}
                    onMouseLeave={onMouseLeaveHeaderItem}
                    align="center"
                  >
                    <Button
                      className={btnClass}
                      onClick={createOnWeekRuleChangeHandler({
                        weekRule,
                        weekDayIndex: index,
                      })}
                    >
                      <Typography className={classes.headerStr}>
                        {weekDay}
                      </Typography>
                      {isRightUpdateSchedule && (
                        <ChangeIconBtn
                          type="div"
                          className={getChangeIconBtnClass({
                            index,
                            hoveredColumn,
                            classes,
                          })}
                          editIconProps={getChangeIconBtnIconProps({
                            weekRule,
                            classes,
                          })}
                        />
                      )}
                    </Button>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDays.map((weekDays, indexRow, arrayRow) => {
              const key = `week${indexRow}`;
              const isLastRow = (arrayRow.length - 1) === indexRow;

              return (
                <TableRow key={key}>
                  {weekDays.map((day = {}, index) => (
                    <WorkScheduleItem
                      key={day.dayNumber}
                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                      {...{
                        index,
                        isLastRow,
                        hoveredColumn,
                        isRightUpdateSchedule,
                        onChange: createOnDayRuleChangeHandler(day),
                        ...day,
                      }}
                    />
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fade>
  );
}

WorkScheduleTable.propTypes = propTypes;

export default WorkScheduleTable;
