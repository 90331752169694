import axios from 'axios';
import { toast } from 'react-toastify';

import { callAPI } from '@parkly/shared/api/base';
import { showApiErrorMsg, getApiUrl } from '@parkly/shared/helpers/api';

const apiHost = getApiUrl();

export async function login(loginName, password) {
  const url = `${apiHost}/login`;

  const headers = {
    'Content-Type': 'application/json',
  };

  return new Promise((resolve, reject) => {
    axios.post(
      url,
      {
        login: loginName,
        password,
      },
      { headers },
    )
      .then((response) => {
        const { data } = response || {};
        // eslint-disable-next-line camelcase
        const { access_token, success } = data || {};

        if (success) {
          const user = {
            token: access_token,
          };
          resolve(user);
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            success,
          });
        }
      })
      .catch((error) => {
        const { response } = error || {};
        const { status } = response || {};

        if (status === 401) {
          toast.error('Не верный логин или пароль');
        } else {
          showApiErrorMsg(error);
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          error,
        });
      });
  });
}

export function logout() {
  const endpoint = 'logout';

  return callAPI(endpoint);
}

export default {
  login,
  logout,
};
