import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import GuardedIcon from '@parkly/shared/components/atoms/icons/GuardedIcon';
import CarWashIcon from '@parkly/shared/components/atoms/icons/CarWashIcon';
import EvStation from '@parkly/shared/components/atoms/icons/EvStation';
import CameraIcon from '@parkly/shared/components/atoms/icons/CameraIcon';
import UndergroundParkingIcon from '@parkly/shared/components/atoms/icons/UndergroundParkingIcon';
import WiFiIcon from '@parkly/shared/components/atoms/icons/WiFiIcon';

import { useStyles } from './styles';

function getOptionIcon(code) {
  switch (code) {
  case 'guarded':
    return (
      <GuardedIcon />
    );
  case 'car_wash':
    return (
      <CarWashIcon />
    );
  case 'charge_ev':
    return (
      <EvStation />
    );
  case 'wifi':
    return (
      <WiFiIcon />
    );
  case 'underground':
    return (
      <UndergroundParkingIcon />
    );
  case 'cctv':
    return (
      <CameraIcon />
    );
  default:
    return <div style={{ width: 16 }} />;
  }
}

function OptionWithIcon({
  option,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (typeof option === 'string') {
    return (
      <div className={classes.container}>
        {getOptionIcon(option)}
        <Typography className={classes.name}>
          {t(`platforms.icons.${option}`)}
        </Typography>
      </div>
    );
  }

  return null;
}

export default OptionWithIcon;
