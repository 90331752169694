import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import CustomDialog from '@parkly/shared/components/templates/CustomDialog';
import SupportIcon from '@parkly/shared/components/atoms/icons/SupportIcon';
import PhoneIcon from '@parkly/shared/components/atoms/icons/PhoneIcon';

import { SUPPORT_PHONE_STR } from '@parkly/shared/config/constants';

import { useStyles } from './styles';

const propTypes = {
  supportLink: PropTypes.string,
};

function SupportSection({
  supportLink = '/',
}) {
  const classes = useStyles();

  const theme = useTheme();
  const { t } = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onSupportBtnClick = () => {
    window.location.href = supportLink;
  };

  const onCallSupportBtnClick = () => {
    setIsDialogOpen(true);
  };

  const onCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const phoneComponent = (
    <Typography className={classes.phoneStr}>
      {SUPPORT_PHONE_STR}
    </Typography>
  );

  return (
    <div className={classes.container}>
      <Tooltip
        title="Позвонить в поддержку"
        PopperProps={{
          className: classes.popper,
        }}
      >
        <IconButton
          className={classes.button}
          onClick={onCallSupportBtnClick}
          aria-label="Поддержка"
        >
          <PhoneIcon
            fill={theme.palette.primary.main}
            stroke={theme.palette.primary.contrastText}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Написать письмо в поддержку"
        PopperProps={{
          className: classes.popper,
        }}
      >
        <IconButton
          className={classes.button}
          onClick={onSupportBtnClick}
          aria-label="Поддержка"
        >
          <SupportIcon />
        </IconButton>
      </Tooltip>
      <CustomDialog
        open={isDialogOpen}
        handleClose={onCloseDialog}
        dialogTitle={t('support.dialog.title')}
        disagreeTitle={t('others.done')}
        dialogContent={phoneComponent}
      />
    </div>
  );
}

SupportSection.propTypes = propTypes;

export default SupportSection;
