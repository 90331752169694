import { makeStyles } from '@material-ui/core/styles';

export const drawerWidth = 103;

export const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    zIndex: 1150,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    zIndex: 998,
  },

}));

export default {
  drawerWidth,
  useStyles,
};
