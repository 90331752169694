import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import { useStyles } from './styles';

const DefaultCustomBtn = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    height: 40,
    padding: 0,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 6,
    fontSize: theme.typography.fontSize14,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    width: 'auto',
  },
}))(Button);

function getClassName({
  btnType,
  className,
  classes = {},
}) {
  if (btnType === 'secondary') {
    return `${classes.secondary} ${className}`;
  }

  if (btnType === 'primaryBlue') {
    return `${classes.primaryBlue} ${className}`;
  }

  if (btnType === 'primaryGreen') {
    return `${classes.primaryGreen} ${className}`;
  }

  if (btnType === 'primaryRed') {
    return `${classes.primaryRed} ${className}`;
  }

  return className;
}

const propTypes = {
  btnType: PropTypes.string,
  className: PropTypes.string,
};

function CustomBtn({
  btnType = 'default',
  className = '',
  ...props
}) {
  const classes = useStyles();
  const ourClassName = getClassName({
    btnType,
    className,
    classes,
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DefaultCustomBtn {...{
      variant: 'contained',
      className: ourClassName,
      ...props,
    }}
    />
  );
}

CustomBtn.propTypes = propTypes;

export default CustomBtn;
