import React from 'react';

function BookingsIcon({
  fill = '#fff',
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M16.997 11.25a.746.746 0 10-1.056 1.056l3.753 3.753a.746.746 0 101.056-1.056l-3.753-3.753z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51 18.052c-.68.68-.68 1.783 0 2.463l1.407 1.407c.706.706 1.728.596 2.387.222.945-.535 1.918-.348 2.409.143.612.613.722 1.495.17 2.398-.407.666-.51 1.693.195 2.398l1.407 1.407c.68.68 1.783.68 2.463 0L28.49 13.948c.68-.68.68-1.783 0-2.463l-1.407-1.407c-.705-.705-1.732-.602-2.398-.195-.903.552-1.785.442-2.398-.17-.612-.613-.722-1.495-.17-2.398.407-.666.51-1.693-.195-2.398L20.515 3.51a1.741 1.741 0 00-2.463 0L3.51 18.052zm1.056 1.408a.249.249 0 010-.352L19.108 4.566a.249.249 0 01.352 0l1.407 1.407a.29.29 0 01.078.207.665.665 0 01-.102.357c-.862 1.411-.775 3.068.389 4.231 1.163 1.164 2.82 1.251 4.231.389a.665.665 0 01.357-.102.29.29 0 01.207.078l1.407 1.407a.249.249 0 010 .352L12.892 27.434a.249.249 0 01-.352 0l-1.407-1.407a.29.29 0 01-.078-.207.665.665 0 01.102-.357c.862-1.411.775-3.068-.389-4.231-1.035-1.035-2.76-1.202-4.2-.386a.743.743 0 01-.382.1c-.108-.004-.172-.038-.213-.079L4.566 19.46z"
        fill={fill}
      />
    </svg>
  );
}

export default BookingsIcon;
