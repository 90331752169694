/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { DEFAULT_PATH_PAGES } from '@parkly/shared/config/constants';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';

const PAGES_RIGHTS_MAP = {
  [PATH_PAGES.blockedUsersAll]: RIGHT_KEYS.readBlockedUsers,
  [PATH_PAGES.blockedUsersItem]: RIGHT_KEYS.readBlockedUsers,
  [PATH_PAGES.addNewBlockedUser]: RIGHT_KEYS.createUpdateBlockedUsers,
};

function comparePath({
  path,
  targetPath,
}) {
  if (Array.isArray(path)) {
    return path.includes(targetPath);
  }

  return path === targetPath;
}

function checkIsEnoughRights({
  path,
  userRights = {},
}) {
  const isAllPlatforms = comparePath({
    path,
    targetPath: PATH_PAGES.platformsAll,
  });
  if (isAllPlatforms) {
    return userRights.isReadAllPlatforms;
  }

  const isOnePlatform = comparePath({
    path,
    targetPath: PATH_PAGES.onePlatform,
  });
  if (isOnePlatform) {
    return userRights.isReadSomePlatform;
  }

  const isDocumentsPage = comparePath({
    path,
    targetPath: PATH_PAGES.documents,
  });
  if (isDocumentsPage) {
    return userRights.isReadReportDocuments;
  }

  if (typeof path === 'string') {
    const rightName = PAGES_RIGHTS_MAP[path];

    if (rightName) {
      const isRightPropName = `is${rightName}`;
      return userRights[isRightPropName];
    }
  }

  return true;
}

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any.isRequired,
  isAuth: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentOperator: PropTypes.object.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

const PrivateRoute = ({
  component: Component,
  // roles = [],
  isAuth = false,
  currentOperator,
  path,
  ...rest
}) => {
  const { data, userRights } = currentOperator || {};
  const { isAdmin } = data || {};

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (!isAuth) {
          return (
            <Redirect to={{
              pathname: DEFAULT_PATH_PAGES.login,
              // eslint-disable-next-line react/prop-types
              state: { from: props.location },
            }}
            />
          );
        }

        const isEnoughRights = isAdmin || checkIsEnoughRights({
          path,
          userRights,
        });

        if (!isEnoughRights) {
          return <Redirect to={{ pathname: PATH_PAGES.notEnoughRights }} />;
        }

        return (
          <Component {...props} />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = propTypes;

function mapStateToProps(state) {
  const { loggedIn } = state.auth || {};
  const { operators } = state || {};
  const { currentOperator } = operators || {};

  return {
    isAuth: !!loggedIn,
    currentOperator,
  };
}

const connectedPrivateRoute = connect(mapStateToProps)(PrivateRoute);

export default connectedPrivateRoute;
