import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { checkRightInAllPlatform } from '@parkly/shared/helpers/right';

export function getLinkStrPlatform({
  isReadAllPlatforms,
  isReadSomePlatform,
  readPlatformInfoRightPlatforms,
}) {
  if (isReadAllPlatforms) {
    return PATH_PAGES.platforms;
  }

  if (isReadSomePlatform) {
    const platformId = (readPlatformInfoRightPlatforms || [])[0];

    if (platformId || platformId === 0) {
      return `${PATH_PAGES.platforms}/${platformId}`;
    }
  }

  return '/';
}

export function getLinkStrAnalytics({
  isReadAllPlatforms,
  isReadSomePlatform,
  readPlatformInfoRightPlatforms,
}) {
  if (isReadAllPlatforms) {
    return PATH_PAGES.analytics;
  }

  if (isReadSomePlatform) {
    const platformId = (readPlatformInfoRightPlatforms || [])[0];

    if (platformId || platformId === 0) {
      return `${PATH_PAGES.analytics}/${platformId}`;
    }
  }

  return '/';
}

export function getLinkStrOperators({
  isReadOperatorsList,
  isCreateUpdOperators,
  isReadOperatorsInfo,
  isReadOperatorsActions,
}) {
  if (isReadOperatorsList) {
    return PATH_PAGES.operators;
  }

  if (isCreateUpdOperators) {
    return PATH_PAGES.operators;

    // if (operatorId || operatorId === 0) {
    //   return `${PATH_PAGES.operators}/${operatorId}`;
    // }
  }

  if (isReadOperatorsInfo) {
    return PATH_PAGES.operators;
  }

  if (isReadOperatorsActions) {
    return PATH_PAGES.operators;
  }

  return PATH_PAGES.notEnoughRights;
}

export function getUserRights(operator) {
  const { isAdmin, rights } = operator || {};

  if (isAdmin) {
    return {
      isAdmin,
      isPlatformsBtn: true,
      isReadAllPlatforms: true,
      isReadSomePlatform: true,
      readPlatformInfoRightPlatforms: [],
      isReadOperatorsList: true,
      readOperatorsListPlatforms: [],
      isCreateUpdOperators: true,
      createUpdOperatorsPlatforms: [],
      isReadOperatorsInfo: true,
      readOperatorsInfoPlatforms: [],
      isReadActBookList: true,
      readActBookLisPlatforms: [],
      isReadHistBookList: true,
      readHistBookLisPlatforms: [],
      isUpdSchedule: true,
      updSchedulePlatforms: [],
      isReadOperatorsActions: true,
      readOperatorsActionsPlatforms: [],
      isReadAnalyticsDistributionByDuration: true,
      readAnalyticsDistributionByDuration: [],
      isReadAnalyticsDuration: false,
      readAnalyticDuration: [],
      isReadReportDocuments: true,
      readReportDocuments: [],
      [`is${RIGHT_KEYS.readBlockedUsers}`]: true,
      [`list${RIGHT_KEYS.readBlockedUsers}`]: [],
      [`is${RIGHT_KEYS.createUpdateBlockedUsers}`]: true,
      [`list${RIGHT_KEYS.createUpdateBlockedUsers}`]: [],
      [`is${RIGHT_KEYS.connectWithDriver}`]: true,
      [`list${RIGHT_KEYS.connectWithDriver}`]: [],
    };
  }

  const { common } = rights || {};

  // TODO можно оптимизировать через список прав RIGHT_KEYS

  const isReadAllPlatforms = (common || []).includes(RIGHT_KEYS.readPlatformsList);
  const readPlatformInfoRights = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.readPlatformInfo,
  }) || {};
  const isReadSomePlatform = readPlatformInfoRights.isThisRight;
  const readPlatformInfoRightPlatforms = readPlatformInfoRights.platformWithRight;

  const readOperatorsListRights = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.readOperatorsList,
  }) || {};
  const isReadOperatorsList = readOperatorsListRights.isThisRight;
  const readOperatorsListPlatforms = readOperatorsListRights.platformWithRight;

  const readOperatorsInfoRights = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.readOperatorsInfo,
  }) || {};
  const isReadOperatorsInfo = readOperatorsInfoRights.isThisRight;
  const readOperatorsInfoPlatforms = readOperatorsInfoRights.platformWithRight;

  const createUpdOperatorsRights = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.createChangeOperator,
  }) || {};
  const isCreateUpdOperators = createUpdOperatorsRights.isThisRight;
  const createUpdOperatorsPlatforms = createUpdOperatorsRights.platformWithRight;

  const readActBookListRights = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.readActiveBookingsList,
  }) || {};
  const isReadActBookList = readActBookListRights.isThisRight;
  const readActBookLisPlatforms = readActBookListRights.platformWithRight;

  const readHistBookListRights = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.readHistoryBookingsList,
  }) || {};
  const isReadHistBookList = readHistBookListRights.isThisRight;
  const readHistBookLisPlatforms = readHistBookListRights.platformWithRight;

  const updScheduleRights = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.changeSchedule,
  }) || {};
  const isUpdSchedule = updScheduleRights.isThisRight;
  const updSchedulePlatforms = updScheduleRights.platformWithRight;

  const readOperatorsActionsRights = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.readOperatorsActions,
  }) || {};
  const isReadOperatorsActions = readOperatorsActionsRights.isThisRight;
  const readOperatorsActionsPlatforms = readOperatorsActionsRights.platformWithRight;

  const {
    isThisRight: isReadAnalyticsDistributionByDuration,
    platformWithRight: readAnalyticsDistributionByDuration,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.readDistributionByDuration,
  }) || {};

  const {
    isThisRight: isReadAnalyticsDuration,
    platformWithRight: readAnalyticDuration,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.readAnalyticDuration,
  }) || {};

  const {
    isThisRight: isReadReportDocuments,
    platformWithRight: readReportDocuments,
  } = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.readReportDocuments,
  }) || {};

  const readBlockedUsersRights = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.readBlockedUsers,
  }) || {};
  const createUpdBlockedUsersRights = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.createUpdateBlockedUsers,
  }) || {};

  const connectWithDriverRights = checkRightInAllPlatform({
    rights,
    checkingRight: RIGHT_KEYS.connectWithDriver,
  }) || {};

  return {
    isAdmin,

    isReadAllPlatforms,
    isReadSomePlatform,
    readPlatformInfoRightPlatforms,

    isReadOperatorsList,
    readOperatorsListPlatforms,
    isCreateUpdOperators,
    createUpdOperatorsPlatforms,
    isReadOperatorsInfo,
    readOperatorsInfoPlatforms,

    isReadActBookList,
    readActBookLisPlatforms,
    isReadHistBookList,
    readHistBookLisPlatforms,

    isUpdSchedule,
    updSchedulePlatforms,

    isReadOperatorsActions,
    readOperatorsActionsPlatforms,

    isReadAnalyticsDistributionByDuration,
    readAnalyticsDistributionByDuration,

    isReadAnalyticsDuration,
    readAnalyticDuration,

    isReadReportDocuments,
    readReportDocuments,

    [`is${RIGHT_KEYS.readBlockedUsers}`]: readBlockedUsersRights.isThisRight,
    [`list${RIGHT_KEYS.readBlockedUsers}`]: readBlockedUsersRights.platformWithRight,

    [`is${RIGHT_KEYS.createUpdateBlockedUsers}`]: createUpdBlockedUsersRights.isThisRight,
    [`list${RIGHT_KEYS.createUpdateBlockedUsers}`]: createUpdBlockedUsersRights.platformWithRight,

    [`is${RIGHT_KEYS.connectWithDriver}`]: connectWithDriverRights.isThisRight,
    [`list${RIGHT_KEYS.connectWithDriver}`]: connectWithDriverRights.platformWithRight,
  };
}

export default {
  checkRightInAllPlatform,
  getUserRights,
  getLinkStrPlatform,
  getLinkStrAnalytics,
  getLinkStrOperators,
};
