import React from 'react';

function Logo({
  circleFill = '#00D88A',
  backgroundFill = '#004BF6',
  letterFill = '#FFFFFF',
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 80 80"
      {...props}
    >
      <rect width="80" height="80" fill={backgroundFill} rx="19.081" />
      <path
        fill={circleFill}
        d="M32.588 36A7.412 7.412 0 1140 43.412h-7.412V36z"
      />
      <path
        fill={letterFill}
        fillRule="evenodd"
        d={"M19 58.235V36c0-11.598 9.402-21 21-21s21 9.402 21 21-9.402 21-21 21h-7.412v-6.794H40c7.846 0 14.206" +
        "-6.36 14.206-14.206 0-7.846-6.36-14.206-14.206-14.206-7.846 0-14.206 6.36-14.206 14.206v23.47a4.323 4." +
        "323 0 01-4.323 4.324H19v-5.559z"}
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Logo;
