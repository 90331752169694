/* eslint-disable camelcase */
import { callAPI } from '@parkly/shared/api/base';
import { RIGHT_KEYS } from 'config/constants';
import { formatRights, getRightsKey } from '@parkly/shared/helpers';

function transformOperator(platform) {
  const {
    id,
    is_admin,
    login,
    name,
    status,
  } = platform;

  return {
    id,
    isAdmin: is_admin,
    login,
    name,
    status,
  };
}

function getNewOperatorBody({
  nameAndRole,
  login,
  password,
  comment,
  status,
  adminStatus,
  rules,
  allPlatformsData,
}) {
  const body = {
    is_admin: adminStatus,
    name: nameAndRole,
    login,
    password,
    comment,
    status,
    rights: adminStatus
      ? null
      : formatRights({
        rules,
        allPlatformsData,
      }),
  };

  return body;
}

function getUpdateOperatorBody({
  nameAndRole,
  login,
  password,
  comment,
  status,
  adminStatus,
  rules,
  allPlatformsData,
}) {
  // eslint-disable-next-line
  let body = {
    is_admin: adminStatus,
    name: nameAndRole,
    login,
    comment,
    status,
    rights: adminStatus
      ? null
      : formatRights({
        rules,
        allPlatformsData,
      }),
  };

  if (password && password.length > 0) {
    body.password = password;
  }

  return body;
}

/* transform and validations */

export async function validGetOperators(result) {
  const { res } = result || {};
  const { data } = res || {};

  const dataList = (data || {}).data || [];

  return dataList.map((item) => transformOperator(item));
}

export async function validGetOneOperator(result) {
  const { res } = result || {};
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    is_admin,
    login,
    name,
    rights,
    comment,
    status,
  } = resultData;

  const rightKeys = Object.keys(rights || {});

  const rightsObject = rightKeys.reduce(
    (acc, key = '') => {
      const rightKey = getRightsKey(key);

      return {
        ...acc,
        [rightKey]: (rights || {})[key],
      };
    },
    {},
  );

  return {
    id,
    isAdmin: is_admin,
    login,
    comment: comment || '',
    name,
    rights: rightsObject,
    status,
  };
}

export async function validGetActionsOneOperator({ res }) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    last_page,
    per_page,
    total,
  } = curMeta || {};

  const transformedData = (curData || []).map((action) => {
    const {
      id,
      date,
      title,
      oldValues,
      newValues,
      operator,
    } = action || {};

    const {
      id: operatorId,
      name: operatorName,
    } = operator || {};

    return {
      id,
      date,
      title,
      oldValues,
      newValues,
      operatorId,
      operatorName,
    };
  });

  return {
    data: transformedData,
    meta: {
      currentPage: current_page,
      lastPage: last_page,
      perPage: per_page,
      total,
    },
  };
}

async function validAddNewOperator(result) {
  const { res } = result || {};
  const { data } = res || {};

  const {
    success,
    operator_id,
  } = data;

  return {
    success,
    operatorId: operator_id,
  };
}

async function validListFrontOfficeRight() {
  return [
    {
      id: 'read_facilities_list',
      title: 'Просмотр списка площадок',
      isCommon: true,
    },
    {
      id: 'read_facilities_info',
      title: 'Просмотр информации о площадке',
    },
    // {
    //   id: 'update_facilities_info',
    //   title: 'Вносить изменения в информацию о площадке',
    // },
    {
      id: 'update_facilities_schedule_and_park_spaces',
      title: 'Управление графиком работы и количеством доступных мест',
    },
    {
      id: 'create_and_update_operators',
      title: 'Создание и редактирование операторов',
    },
    {
      id: 'read_operators_list',
      title: 'Просмотр списка операторов',
    },
    {
      id: 'read_operators_info',
      title: 'Просмотр информации о операторе',
    },
    {
      id: 'read_operators_actions',
      title: 'Просмотр списка действий операторов',
    },
    {
      id: 'read_active_bookings_list',
      title: 'Просмотр списка активных бронирований',
    },
    {
      id: 'read_history_bookings_list',
      title: 'Просмотр истории бронирований',
    },
    {
      id: RIGHT_KEYS.connectWithDriver,
      title: 'Возможность связаться с водителем со страницы бронирований',
    },
    {
      id: 'read_analytic_distribution_by_duration',
      title: 'Аналитика загруженности мест',
    },
    {
      id: 'read_analytic_duration',
      title: 'Аналитика длительности бронирования',
      default: false,
      hidden: true,
    },
    {
      id: 'read_report_documents',
      title: 'Доступ к отчетам',
    },
    {
      id: RIGHT_KEYS.readBlockedUsers,
      title: 'Доступ к спискам заблокированных пользователей',
    },
    {
      id: RIGHT_KEYS.createUpdateBlockedUsers,
      title: 'Управление заблокированными пользователями',
    },
  ];
}

/* API requests */

export async function removeOperator({
  operatorId,
}) {
  const endpoint = `operators/${operatorId}`;

  const method = 'DELETE';

  return callAPI(endpoint, method)
    .then((res) => validAddNewOperator({
      res,
    }));
}

export async function updateOperator({
  nameAndRole,
  login,
  password,
  comment,
  status,
  adminStatus,
  rules,
  allPlatformsData,
  operatorId,
}) {
  const endpoint = `operators/${operatorId}`;

  const method = 'put';

  const data = getUpdateOperatorBody({
    nameAndRole,
    login,
    password,
    comment,
    status,
    adminStatus,
    rules,
    allPlatformsData,
  });

  return callAPI(endpoint, method, data)
    .then((res) => validAddNewOperator({
      res,
    }));
}

export async function addNewOperator({
  nameAndRole,
  login,
  password,
  comment,
  status,
  adminStatus,
  rules,
  allPlatformsData,
}) {
  const endpoint = 'operators';

  const method = 'post';

  const data = getNewOperatorBody({
    nameAndRole,
    login,
    password,
    comment,
    status,
    adminStatus,
    rules,
    allPlatformsData,
  });

  return callAPI(endpoint, method, data)
    .then((res) => validAddNewOperator({
      res,
    }));
}

export async function getActionsOneOperator({
  id,
  date,
  page,
}) {
  /* eslint-disable */
  const query = '?'
    + (id ? `operatorId=${id}&` : '')
    + (date ? `date=${date}&` : '')
    + (page ? `page=${page}&` : '')
  /* eslint-enable */

  const queryParams = query.slice(0, -1);

  const endpoint = `operators/journal${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetActionsOneOperator({
      res,
    }));
}

export async function getOneOperator({
  id,
}) {
  // if (!id && id !== 0) {
  //   return;
  // }
  const endpoint = `operators/${id}`;

  return callAPI(endpoint)
    .then((res) => validGetOneOperator({
      res,
    }));
}

export async function getOperators({
  platformId,
} = {}) {
  const isPlatformId = !!platformId || platformId === 0;
  const platformReqStr = isPlatformId ? `?facility_id=${platformId}` : '';

  const endpoint = `operators${platformReqStr}`;

  return callAPI(endpoint)
    .then((res) => validGetOperators({
      res,
    }));
}

export async function getListFrontOfficeRights() {
  return validListFrontOfficeRight();

  // const endpoint = `operators${platformReqStr}`;
  //
  // return callAPI(endpoint)
  //   .then((res) => validGetOperators({
  //     res,
  //   }));
}

export default {
  getOperators,
  getOneOperator,
  updateOperator,
  getActionsOneOperator,
  addNewOperator,
  removeOperator,
  getListFrontOfficeRights,
};
