import React from 'react';

function InfoIconMini(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 14A6 6 0 108 2a6 6 0 000 12z"
        stroke="#7E8AA7"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 11V7.5h-1m1 3.5h-1m1 0h1"
        stroke="#7E8AA7"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5h.007"
        stroke="#7E8AA7"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InfoIconMini;
