import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
  },
  tableContainer: {
    marginTop: 24,
  },
  tableBody: {

  },
  table: {

  },
  tableCell: {
    padding: '5px 10px 5px 0px',
  },
  actionBtn: {

  },
  tableRow: {
    paddingRight: 16,
    cursor: 'pointer',
    '&.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.greyC.lightGrey,
    },
  },
  tableRowNoRight: {
    cursor: 'default',
  },
  tableRowLast: {
    borderBottom: 'none',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  containerInCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 62,
  },
  moreCell: {
    justifyContent: 'flex-end',
  },
  operatorTitle: {

  },
  operatorTitleStr: {
    fontWeight: 'bold',
  },
  isAdmin: {
    minWidth: 48,
    display: 'flex',
    justifyContent: 'center',
  },
  statusIcon: {
    marginRight: 8,
  },
  statusName: {

  },
}));

export default {
  useStyles,
};
