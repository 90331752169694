/* eslint-disable camelcase */
import { callAPI } from '@parkly/shared/api/base';

export async function validGetPlatformBookingsRes({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const bookingsMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const bookingsData = curData.map((bookItem) => {
    const {
      car_number,
      car_brand: carBrand,
      car_info,
      enter_time,
      exit_time,
      facility_id,
      id,
      pass_code,
      phone_id,
      phone,
      place_group_id,
      place_options,
      reservation_finish_time,
      reservation_start_time,
      status,
      total_cost,
      user_name,
    } = bookItem;

    return {
      carBrand,
      carNumber: car_number,
      carInfo: car_info,
      enterTime: enter_time,
      exitTime: exit_time,
      facilityId: facility_id,
      id,
      passCode: pass_code,
      phoneId: phone_id,
      phone,
      placeGroupId: place_group_id,
      placeOptions: place_options,
      reservationFinishTime: reservation_finish_time,
      reservationStartTime: reservation_start_time,
      status,
      totalCost: total_cost,
      userName: user_name,
    };
  });

  const result = {
    bookingsData,
    bookingsMeta,
  };

  return result;
}

export async function validSendCommunicationRequestRes({
  res,
}) {
  const result = {
    res,
  };

  return result;
}

/* api request */

export async function getPlatformBookings({
  id,
  type,
  search,
  status,
  date,
  passCode,
  sortBy,
  sortDirection,
  page,
}) {
  /* eslint-disable */
  const query = '?'
    + (id ? `facility_id=${id}&` : '')
    + (type ? `type=${type}&` : '')
    + (search ? `query=${search}&` : '')
    + (status ? `status=${status}&` : '')
    + (date ? `date=${date}&` : '')
    + (passCode ? `pass_code=${passCode}&` : '')
    + (sortBy ? `sort_by=${sortBy}&` : '')
    + (sortDirection ? `sort_direction=${sortDirection}&` : '')
    + (page && (page !== 1)  ? `page=${page}&` : '')
  /* eslint-enable */

  const queryParams = query.slice(0, -1);

  const endpoint = `reservations${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetPlatformBookingsRes({
      res,
    }));
}

export async function sendCommunicationRequest({
  reservationId,
  message = '',
}) {
  const endpoint = 'reservations/send_message';

  const data = {
    reservation_id: reservationId,
    message,
  };

  return callAPI(
    endpoint,
    'POST',
    data,
  )
    .then((res) => validSendCommunicationRequestRes({
      res,
    }));
}

export async function getUpcomingBookingNotification() {
  return callAPI('reservations/upcoming')
    .then((res) => {
      const { data } = res;

      return data;
    });
}

export default {
  getPlatformBookings,
  sendCommunicationRequest,
  getUpcomingBookingNotification,
};
