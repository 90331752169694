import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  default: {
    '& .MuiFilledInput-root': {
      borderRadius: 6,
      backgroundColor: theme.palette.greyC.lightGrey,
    },
    '& .MuiFilledInput-root.Mui-error': {
      backgroundColor: theme.palette.redC.red4,
    },

    '& .MuiFilledInput-input': {
      padding: '22px 12px 9px 12px',
    },
    '& ::before': {
      display: 'none',
    },
    '& .MuiFilledInput-underline.Mui-focused:after': {
      transform: 'scale(0.99)',
    },
    '& .MuiInputLabel-filled': {
      color: theme.palette.greyC.darkGrey,
      transform: 'translate(12px, 17px) scale(1)',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(12px, 6px) scale(0.85)',
    },

    '& .MuiFilledInput-multiline': {
      padding: 0,
    },
  },
  secondary: {
    margin: 0,
    '& .MuiFilledInput-input': {
      padding: 0,
      paddingLeft: 12,
      height: 38,
    },
    '& .MuiFilledInput-root': {
      border: `1px solid ${theme.palette.greyC.buttonGrey}`,
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiFilledInput-root.Mui-focused': {
      borderColor: theme.palette.blueC.blue,
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(12px, -6px) scale(0.85)',
      backgroundColor: theme.palette.primary.contrastText,
      border: `3px solid ${theme.palette.primary.contrastText}`,
    },
    '& .MuiInputLabel-filled': {
      transform: 'translate(12px, 13px) scale(1)',
    },
    '& .MuiFilledInput-root.Mui-focused:after': {
      display: 'none',
    },
  },
  pasFormControl: {
    borderRadius: 6,
    marginTop: 16,
    marginBottom: 8,
  },
  pasFilledInput: {
    width: '100%',
    backgroundColor: theme.palette.greyC.lightGrey,
    '&.Mui-error': {
      backgroundColor: theme.palette.redC.red4,
    },
    '&.MuiFilledInput-root:before': {
      display: 'none',
    },
    '&.MuiFilledInput-root:focus': {
      backgroundColor: theme.palette.greyC.lightGrey,
    },
    '&.MuiFilledInput-root:hover': {
      backgroundColor: theme.palette.greyC.lightGrey,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.greyC.lightGrey,
    },
    '& .MuiFilledInput-input': {
      padding: '22px 12px 9px 12px',
    },
  },
  pasLabel: {
    '&.MuiInputLabel-filled': {
      transform: 'translate(12px, 17px) scale(1)',
      color: theme.palette.greyC.darkGrey,
    },
    '&.MuiInputLabel-shrink': {
      transform: 'translate(12px, 6px) scale(0.85)',
    },
  },
}));

export default {
  useStyles,
};
