import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    marginTop: 32,
  },
  tableContainer: {
    marginTop: 32,
  },
  table: {

  },
  tableBody: {

  },
  nameStr: {

  },
  paginationContainer: {
    marginTop: 20,
    display: 'flex',
    width: '100%',
  },
  pagination: {
    margin: 'auto',
  },
  actionContainer: {
    display: 'flex',
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: 500,
  },
  actionTitle: {
    color: theme.palette.greyC.darkGrey,
  },
}));

export const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    // cursor: 'pointer',
  },
  tableCell: {

  },
  dateStr: {

  },
  operatorNameStr: {

  },
  descriptionTableRow: {
    margin: 0,
    padding: 0,
  },
  descriptionCell: {
    margin: 0,
    padding: 0,
  },
  lastRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  descriptionBox: {
    margin: 0,
    padding: 16,
    backgroundColor: theme.palette.greyC.lightGrey,
  },
  descriptionGridItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflowX: 'auto',
  },
  descriptionContainer: {

  },
  descriptionStr: {

  },
  arrowRight: {
    padding: 16,
    width: '100%',
    textAlign: 'center',
  },
}));

export default {
  useStyles,
  useRowStyles,
};
