import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {

  },
  tabPanel: {
    '& .MuiBox-root': {
      padding: 0,
    },
  },
}));

export default {
  useStyles,
};
