import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  boxBasic: {
    borderRadius: 6,
    padding: 24,
    marginTop: 30,
    backgroundColor: theme.palette.greyC.lightGrey,
    minHeight: 104,
  },
  headerBox: {
    margin: 0,
  },
  firstHeaderBoxItem: {
    padding: '0!important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    borderRight: `1px solid ${theme.palette.greyC.buttonGrey}`,
  },
}));

export default {
  useStyles,
};
