import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CongestionAnalyticsChart from 'components/templates/CongestionAnalyticsChart';
import DistributionByDurationChart from 'components/templates/DistributionByDurationChart';
import ScheduleChanger from 'components/templates/ScheduleChanger';
import CustomDatePicker from '@parkly/shared/components/atoms/CustomDatePicker';
import CustomRangeDatePicker from '@parkly/shared/components/molecules/CustomRangeDatePicker';
import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import TabPanel from '@parkly/shared/components/molecules/TabPanel';

import { getPlatformAnalyticsReqAction, getPlatDrtnAnalyticsReqAction } from 'actions';
import {
  SCHEDULE_SETTINGS_TYPES,
  SCHEDULE_SETTINGS_TAB_TYPES,
  ANALYTICS_UPDATE_PERIOD,
} from 'config/constants';

import { useStyles } from './styles';

/* help functions */

function getPlatformId({
  match,
  allPlatforms,
}) {
  const { params } = match || {};
  const { id } = params || {};

  if (id || id === 0) {
    return id;
  }

  const { platforms } = allPlatforms;
  const { data } = platforms || {};
  const firstPlatform = (data || [])[0];
  const { id: firstPlatformId } = firstPlatform || {};

  return firstPlatformId;
}

function getCurrentAnalytics({
  platformAnalytics,
  platformId,
  dayTime,
}) {
  const day = dayTime.format('YYYY-MM-DD');
  const {
    loading: isLoading,
    list: platformAnalyticsList,
  } = platformAnalytics || {};

  const currentStrParam = JSON.stringify({
    id: platformId,
    day,
  });

  const analyticsDataReqRes = platformAnalyticsList.find((currentAnalyticsItem) => {
    const { strParam } = currentAnalyticsItem || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    isLoading,
    analyticsDataReqRes,
  };
}

function getCurrentDurationAnalytics({
  platformDurationAnalytics,
  platformId,
  startDate,
  endDate,
}) {
  const {
    loading: isLoading,
    list = [],
  } = platformDurationAnalytics || {};

  const startDateStr = (startDate || moment()).format('YYYY-MM-DD');
  const endDateStr = (endDate || moment()).format('YYYY-MM-DD');

  const currentStrParam = JSON.stringify({
    id: platformId,
    startDate: startDateStr,
    endDate: endDateStr,
  });

  const analyticsDurationDataReqRes = list.find((currentAnalyticsItem) => {
    const { strParam } = currentAnalyticsItem || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    isLoading,
    analyticsDurationDataReqRes,
  };
}

function getTabs({
  t = () => {},
  isReadAnalyticsDuration,
  isReadAnalyticsDistributionByDuration,
}) {
  const rowTab = [
    {
      id: 1,
      label: t('analytics.busyChart'),
      isExist: isReadAnalyticsDistributionByDuration,
    },
    {
      id: 2,
      label: t('analytics.distributionByBookingDuration'),
      isExist: isReadAnalyticsDuration,
    },
  ];

  const result = rowTab
    .filter(({ isExist }) => isExist)
    .map(({ label, id }) => ({ label, id }));

  return result;
}

/* Main component */

const propTypes = {
};

function AnalyticsPage({
  platformAnalytics,
  platformDurationAnalytics,
  allPlatforms,
  currentOperator,
  match,
  getPlatformAnalyticsReq = () => {},
  getPlatformDurationAnalyticsReq = () => {},
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [dayTime, setDayTime] = useState(moment());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [durationParam, setDurationParam] = useState({
    startDate: moment(),
    endDate: moment(),
  });

  useEffect(
    () => {
      const day = dayTime.format('YYYY-MM-DD');

      const platformId = getPlatformId({
        match,
        allPlatforms,
      });

      const {
        analyticsDataReqRes,
        isLoading,
      } = getCurrentAnalytics({
        platformAnalytics,
        platformId,
        dayTime,
      });

      const nowTime = new Date().getTime();
      const { timeStamp = nowTime } = analyticsDataReqRes || {};

      const isUpdTime = (nowTime - timeStamp) / 1000 < ANALYTICS_UPDATE_PERIOD;
      // eslint-disable-next-line no-mixed-operators
      if (isLoading || analyticsDataReqRes && isUpdTime) {
        return;
      }

      getPlatformAnalyticsReq({
        id: platformId,
        day,
      });
    },
    // eslint-disable-next-line
    [match.params.id, dayTime],
  );

  useEffect(
    () => {
      const {
        startDate,
        endDate,
      } = durationParam;

      const startDateStr = startDate.format('YYYY-MM-DD');
      const endDateStr = endDate.format('YYYY-MM-DD');

      const platformId = getPlatformId({
        match,
        allPlatforms,
      });

      const {
        analyticsDurationDataReqRes,
        isLoading,
      } = getCurrentDurationAnalytics({
        platformDurationAnalytics,
        platformId,
        startDate,
        endDate,
      });

      const nowTime = new Date().getTime();
      const { timeStamp = nowTime } = analyticsDurationDataReqRes || {};

      const isUpdTime = (nowTime - timeStamp) / 1000 < ANALYTICS_UPDATE_PERIOD;
      // eslint-disable-next-line no-mixed-operators
      if (isLoading || analyticsDurationDataReqRes && isUpdTime) {
        return;
      }

      getPlatformDurationAnalyticsReq({
        id: platformId,
        startDate: startDateStr,
        endDate: endDateStr,
      });
    },
    // eslint-disable-next-line
    [match.params.id, durationParam],
  );

  const platformId = getPlatformId({
    match,
    allPlatforms,
  });

  const {
    analyticsDataReqRes,
    isLoading,
  } = getCurrentAnalytics({
    platformAnalytics,
    platformId,
    dayTime,
  });

  const { data: analyticsData } = analyticsDataReqRes || {};

  const {
    analyticsDurationDataReqRes,
    isLoading: isDurationLoading,
  } = getCurrentDurationAnalytics({
    platformDurationAnalytics,
    platformId,
    startDate: (durationParam || {}).startDate,
    endDate: (durationParam || {}).endDate,
  });
  const { data: analyticsDurationData } = analyticsDurationDataReqRes || {};

  const { userRights } = currentOperator || {};
  const {
    isAdmin,
    updSchedulePlatforms,
    isReadAnalyticsDuration,
    isReadAnalyticsDistributionByDuration,
  } = userRights || {};
  const isRightUpdateSchedule = isAdmin || (updSchedulePlatforms || []).includes(+platformId);

  const tabs = getTabs({
    t,
    isReadAnalyticsDuration,
    isReadAnalyticsDistributionByDuration,
  });

  function handleDateChanges(date) {
    setDayTime(date);
  }
  function onAlertClick() {
    if (!isRightUpdateSchedule) {
      return;
    }
    setIsModalOpen(true);
  }
  function handleCloseModal() {
    setIsModalOpen(false);
  }
  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  function handleDateRangeUpd({
    startDate = moment(),
    endDate = moment(),
  }) {
    setDurationParam((prevState) => ({
      ...prevState,
      startDate: startDate || moment(),
      endDate: endDate || moment(),
    }));
  }

  return (
    <Container className={classes.containerPage}>
      <Box className={classes.headerContainer}>
        <HeaderWithBackBtn
          title={t('analytics.analytics')}
          isBackBtn={false}
        />
        <div className={classes.leftHeader}>
          <CustomTabBar
            tabs={tabs}
            tabValue={tabValue}
            handleParamChange={handleTabChange}
          />
        </div>
      </Box>
      <TabPanel
        className={classes.tabPanel}
        value={tabValue}
        index={tabs.findIndex(({ id }) => id === 1)}
      >
        <div className={classes.monthSelContainer}>
          <CustomDatePicker
            format="dd, D MMMM, YYYY"
            value={dayTime}
            onChange={handleDateChanges}
          />
        </div>
        <div
          className={classes.chartContainer}
        >
          <CongestionAnalyticsChart {...{
            analyticsData,
            isLoading,
            onAlertClick,
          }}
          />
        </div>
      </TabPanel>
      <TabPanel
        className={classes.tabPanel}
        value={tabValue}
        index={tabs.findIndex(({ id }) => id === 2)}
      >
        <div className={classes.monthSelContainer}>
          <CustomRangeDatePicker
            initialStartDate={(durationParam || {}).startDate}
            initialEndDate={(durationParam || {}).endDate}
            onUpdate={handleDateRangeUpd}
          />
        </div>
        <div
          className={classes.chartContainer}
        >
          <DistributionByDurationChart {...{
            isLoading: isDurationLoading,
            data: analyticsDurationData,
          }}
          />
        </div>
      </TabPanel>
      <ScheduleChanger
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        momentDate={dayTime}
        platformId={platformId}
        type={SCHEDULE_SETTINGS_TYPES.day}
        tab={SCHEDULE_SETTINGS_TAB_TYPES.parkSpaceOnly}
      />
    </Container>
  );
}

AnalyticsPage.propTypes = propTypes;

function mapStateToProps(state) {
  const { analytics, platform, operators } = state || {};
  const { platformAnalytics, platformDurationAnalytics } = analytics || {};
  const { allPlatforms } = platform || {};
  const { currentOperator } = operators || {};

  return {
    platformAnalytics,
    platformDurationAnalytics,
    allPlatforms,
    currentOperator,
  };
}

const ConnectedWorkSchedule = connect(
  mapStateToProps,
  {
    getPlatformAnalyticsReq: getPlatformAnalyticsReqAction,
    getPlatformDurationAnalyticsReq: getPlatDrtnAnalyticsReqAction,
  },
)(AnalyticsPage);

export default ConnectedWorkSchedule;
