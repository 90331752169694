import React from 'react';
import Typography from '@material-ui/core/Typography';

import ChangeIconBtn from '@parkly/shared/components/atoms/ChangeIconBtn';

import { useStyles } from './styles';

const propTypes = {

};

function ChangeableValueAndTitle({
  title = '',
  value = '',
  isChangeable = true,
  onChangeClick = () => {},
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.value}>
        {value}
      </Typography>
      {isChangeable && (
        <ChangeIconBtn
          onClick={onChangeClick}
        />
      )}
    </div>
  );
}
ChangeableValueAndTitle.propTypes = propTypes;

export default ChangeableValueAndTitle;
