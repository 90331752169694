import React from 'react';

function PaidIcon(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <rect
        x={1}
        y={1}
        width={16}
        height={16}
        rx={8}
        stroke="#43D37D"
        strokeWidth={1.5}
      />
      <path
        d="M7.636 13.5v-2m0-2.5V5H10a2 2 0 012 2v0a2 2 0 01-2 2H7.636zm0 0v2.5m0 0H6m1.636 0h3.273"
        stroke="#43D37D"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PaidIcon;
