import React from 'react';

function MobilePaymentsIconActive({
  fill = '#fff',
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={32} height={32} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0.25C1.48122 0.25 0.25 1.48122 0.25 3V4V7V11C0.25 12.5188 1.48122 13.75 3 13.75H17C18.5188 13.75 19.75 12.5188 19.75 11V7V4V3C19.75 1.48122 18.5188 0.25 17 0.25H3ZM18.25 4H1.75V7H18.25V4Z"
        fill={fill}
      />
    </svg>

  );
}

export default MobilePaymentsIconActive;
