import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

const propTypes = {};

function RedirectToProd() {
  useEffect(
    () => {
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_URL_PRODUCTION;
      }, 2000);
    },
    [],
  );

  return (
    <Container style={{
      minHeight: '98vh',
      paddingTop: '20%',
    }}
    >
      <Typography
        variant="h1"
        style={{
          width: '100%',
          textAlign: 'center',
          fontSize: 28,
          fontWeight: 400,
        }}
      >
        Переадресация на https://office.parkly.ru ...
      </Typography>
      <CircularIndeterminate style={{ marginTop: '5%' }} />
    </Container>
  );
}

RedirectToProd.propTypes = propTypes;

export default RedirectToProd;
