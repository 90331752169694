import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';
import Container from '@material-ui/core/Container';
import Pagination from '@material-ui/lab/Pagination';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import MobilePaymentsTable from 'components/templates/MobilePaymentsTable';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import MobilePaymentSelectors from 'components/organisms/MobilePaymentSelectors';

import { getAllPlatforms, getPlatformMobilePaymentsAction } from 'actions';
import { MOBILE_PAYMENTS_UPDATE_PERIOD, RIGHT_KEYS } from 'config/constants';
import { checkDateValid } from '@parkly/shared/helpers/time';

import { useStyles } from './styles';

/* help functions */

function getInitParams({
  match,
}) {
  const { params } = match || {};
  const { id } = params || {};
  const isId = !!id || id === 0;

  return {
    tabValue: 0,
    selPlatformValue: isId ? id : 'unselect',
    searchValue: '',
    selDate: null,
    selStatusValue: [],
    sorting: {},
    page: 1,
  };
}

function getNextSortStatus(status) {
  if (!status) {
    return 'sort';
  }

  if (status === 'sort') {
    return 'sortReverse';
  }

  return null;
}

function prepareParam({
  params,
}) {
  const {
    selPlatformValue,
    searchValue,
    selDate,
    sorting,
    page,
  } = params || {};

  const sortRules = Object.keys(sorting).filter((currentId) => !!sorting[currentId]);
  const sortParam = sortRules.length > 0
    ? {
      sortBy: sortRules.join(','),
      sortDirection: sortRules.map((id) => {
        const rule = sorting[id];
        if (rule === 'sortReverse') {
          return 'desc';
        }

        return 'asc';
      }).join(','),
    }
    : {};

  const isSelDateValid = checkDateValid(selDate);

  const trimmedSearchValue = (searchValue || '').trim();
  const search = trimmedSearchValue; /* && searchValue.length > 2
    ? searchValue
    : undefined; */

  const reqParam = {
    platformSign: selPlatformValue,
    date: isSelDateValid ? selDate : undefined,
    search,
    page,
    ...sortParam,
  };

  return reqParam;
}

function requestPlatformMobilePayments({
  reqParam,
  getPlatformMobilePaymentsReq = () => {},
}) {
  throttle(
    getPlatformMobilePaymentsReq,
    300,
  )(reqParam);
}

function getCurrentMobilePayments({
  platformMobilePayments,
  params,
}) {
  const bookingsList = (platformMobilePayments || {}).list || [];
  const preparedReqParam = prepareParam({
    params,
  });
  const currentStrParam = JSON.stringify(preparedReqParam);
  const bookings = bookingsList.find((currentBookingsItem) => {
    const { strParam } = currentBookingsItem || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    mobilePayments: bookings,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

/* Main component */

const propTypes = {
  allPlatforms: PropTypes.shape({
    loading: PropTypes.bool,
    platforms: PropTypes.any,
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  platformMobilePayments: PropTypes.shape({
    list: PropTypes.array,
  }),
  currentOperator: PropTypes.shape({
    userRights: PropTypes.object,
  }),
  getAllPlatformsReq: PropTypes.func,
  getPlatformMobilePaymentsReq: PropTypes.func,
};

function MobilePaymentsPage({
  allPlatforms = {},
  match,
  platformMobilePayments,
  currentOperator,
  getAllPlatformsReq = () => {},
  getPlatformMobilePaymentsReq = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [params, setParams] = useState(getInitParams({
    match,
  }));

  const [prevParams, setPrevParams] = useState('');

  useEffect(
    () => {
      const { loading, platforms } = allPlatforms;
      const { data } = platforms || {};
      const isPlatformsLoaded = !!data;

      if (isPlatformsLoaded || loading) {
        return;
      }

      getAllPlatformsReq();
    },
    // eslint-disable-next-line
    [],
  );
  useEffect(
    () => {
      const {
        mobilePayments,
        reqParam,
        currentStrParam,
      } = getCurrentMobilePayments({
        platformMobilePayments,
        params,
      });

      if (currentStrParam !== prevParams) {
        const nowTime = new Date().getTime();
        const { timeStamp = nowTime } = mobilePayments || {};

        if (!mobilePayments || (nowTime - timeStamp) / 1000 > MOBILE_PAYMENTS_UPDATE_PERIOD) {
          requestPlatformMobilePayments({
            reqParam,
            getPlatformMobilePaymentsReq,
          });

          setPrevParams(currentStrParam);
        }
      }

      const interval = setInterval(
        () => {
          requestPlatformMobilePayments({
            reqParam,
            getPlatformMobilePaymentsReq,
          });
        },
        MOBILE_PAYMENTS_UPDATE_PERIOD * 1000,
      );

      return () => {
        clearInterval(interval);
      };
    },
    // eslint-disable-next-line
    [params],
  );

  const { userRights } = currentOperator;
  const {
    isReadReportDocuments,
    [`is${RIGHT_KEYS.connectWithDriver}`]: isConnectWithDriver,
  } = userRights;
  const isLoading = (platformMobilePayments || {}).loading;

  const {
    mobilePayments,
  } = getCurrentMobilePayments({
    platformMobilePayments,
    params,
  });

  const {
    mobilePaymentsData,
    mobilePaymentsMeta,
  } = (mobilePayments || {}).data || {};

  const {
    currentPage = 1,
    lastPage = 1,
  } = mobilePaymentsMeta || {};

  const {
    selPlatformValue,
    searchValue,
    selDate,
    sorting,
  } = params;

  const handleParamChange = (paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  };
  function sortingSettingsChange({
    headerNameId,
  }) {
    const newSorting = {
      [headerNameId]: getNextSortStatus(sorting[headerNameId]),
    };

    handleParamChange('sorting', newSorting);
  }
  function onPageChange(_, pageNumber) {
    handleParamChange('page', pageNumber);
  }

  return (
    <Container className={classes.container}>
      <HeaderWithBackBtn
        title={t('mobilePayments.mobilePayments')}
      />
      <div className={classes.pageBody}>
        <MobilePaymentSelectors {...{
          allPlatforms,
          selPlatformValue,
          searchValue,
          selDate,
          handleParamChange,
        }}
        />
        {!isLoading && (
          <>
            <MobilePaymentsTable {...{
              mobilePaymentsData,
              headerSortSettings: sorting,
              isCostColumn: isReadReportDocuments,
              isConnectWithDriverColumn: isConnectWithDriver,
              sortingSettingsChange,
            }}
            />
            {lastPage > 1 && (
              <div className={classes.paginationContainer}>
                <Pagination
                  className={classes.pagination}
                  name="page"
                  page={currentPage}
                  onChange={onPageChange}
                  count={lastPage}
                  color="primary"
                />
              </div>
            )}
          </>
        )}
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
      </div>
    </Container>
  );
}

MobilePaymentsPage.propTypes = propTypes;

function mapStateToProps(state) {
  const { platform, operators, mobilePayments } = state || {};
  const { allPlatforms } = platform || {};
  const { platformMobilePayments } = mobilePayments || {};
  const { currentOperator } = operators || {};

  return {
    allPlatforms,
    platformMobilePayments,
    currentOperator,
  };
}

const ConnectedMobilePaymentsPage = connect(
  mapStateToProps,
  {
    getAllPlatformsReq: getAllPlatforms,
    getPlatformMobilePaymentsReq: getPlatformMobilePaymentsAction,
  },
)(MobilePaymentsPage);

export default ConnectedMobilePaymentsPage;
