import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TitlePrice from '@parkly/shared/components/atoms/TitlePrice';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';

import { useStyles } from './styles';

const propTypes = {
  statisticIncome: PropTypes.shape({
    lastWeek: PropTypes.any,
    prepaid: PropTypes.any,
    thisMonth: PropTypes.any,
    today: PropTypes.any,
  }),
  address: PropTypes.string,
};

function PlatformStatisticHeader({
  statisticIncome = {},
  mapLink,
  address = '',
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    lastWeek,
    prepaid,
    thisMonth,
    today,
  } = statisticIncome;

  const headerItems = [
    {
      title: 'platforms.perDay',
      price: today,
    },
    {
      title: 'platforms.perWeek',
      price: lastWeek,
    },
    {
      title: 'platforms.currentMonth',
      price: thisMonth,
    },
    {
      title: 'platforms.prepaid',
      price: prepaid,
    },
  ];

  return (
    <Box className={classes.boxBasic}>
      <Grid className={classes.headerBox} container spacing={3}>
        <Grid item className={classes.firstHeaderBoxItem} xs={12} sm={4}>
          <Typography>{address}</Typography>
          <CustomLink
            target="_blank"
            href={mapLink || 'http://yandex.ru/maps'}
          >
            {t('platforms.showOnMap')}
          </CustomLink>
        </Grid>
        {headerItems.map(({ title, price }) => (
          <TitlePrice
            key={title}
            xs={12}
            sm={2}
            title={t(title)}
            price={price}
          />
        ))}
      </Grid>
    </Box>
  );
}

PlatformStatisticHeader.propTypes = propTypes;

export default PlatformStatisticHeader;
