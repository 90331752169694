import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import { checkProduction } from '@parkly/shared/helpers/others';

/* Components */

function ErrorComponent() {
  return (
    <h1 style={{ textAlign: 'center' }}>
      Произошла ошибка. Перезагрузите страницу
    </h1>
  );
}

class ErrorBoundaryDev extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  // componentDidCatch(error, errorInfo) {
  //   // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
  //   logErrorToMyService(error, errorInfo);
  // }

  render() {
    const { hasError = false } = this.state || {};

    if (hasError) {
      return (
        <ErrorComponent />
      );
    }

    const { children } = this.props || {};

    return children;
  }
}

/* Main Component */

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

function ErrorBoundary({
  children,
}) {
  if (!checkProduction()) {
    return (
      <ErrorBoundaryDev>
        {children}
      </ErrorBoundaryDev>
    );
  }

  return (
    <Sentry.ErrorBoundary
      fallback={ErrorComponent}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
ErrorBoundary.propTypes = propTypes;

export default ErrorBoundary;
