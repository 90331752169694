import {
  getOperators,
  getOneOperator,
  getActionsOneOperator,
  addNewOperator,
  updateOperator,
  removeOperator,
  getListFrontOfficeRights,
} from 'api/operators';
import { PATH_PAGES } from 'config/constants';
import { getUserRights } from 'helpers';
import {
  GET_ACTIONS_ONE_OPERATOR_REQUEST,
  GET_ONE_OPERATOR_REQUEST,
  GET_OPERATORS,
  ADD_NEW_OPERATOR_REQUEST,
  UPDATE_OPERATOR_REQUEST,
  REMOVE_OPERATOR_REQUEST,
  GET_LIST_FRONT_OFFICE_RIGHTS,
  GET_CURRENT_OPERATOR,
  CLEAR_CACHE_ONE_OPERATOR,
  CLEAR_CACHE_OPERATORS,
} from './types';

export function clearCacheOneOperator() {
  function actionClearCacheOneOperator() {
    return { type: CLEAR_CACHE_ONE_OPERATOR };
  }

  return (dispatch) => {
    dispatch(actionClearCacheOneOperator());
  };
}

export function clearCacheOperators() {
  function actionClearCacheOperators() {
    return { type: CLEAR_CACHE_OPERATORS };
  }

  return (dispatch) => {
    dispatch(actionClearCacheOperators());
  };
}

export function removeOperatorAction({
  operatorId,
  history,
}) {
  function getActionsRemoveOperatorRequest(payload) {
    return { type: REMOVE_OPERATOR_REQUEST, payload };
  }

  return (dispatch) => {
    if (!operatorId) {
      console.error('No operator id!');

      dispatch(getActionsRemoveOperatorRequest({
        loading: false,
        error: 'No operator id!',
      }));
      return;
    }

    dispatch(getActionsRemoveOperatorRequest({
      loading: true,
    }));

    removeOperator({
      operatorId,
    })
      .then(
        (result) => {
          dispatch(getActionsRemoveOperatorRequest({
            loading: false,
            result,
          }));
          dispatch(clearCacheOperators());
          history.push(PATH_PAGES.operators);
        },
        (error) => {
          dispatch(getActionsRemoveOperatorRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function updateOperatorAction({
  nameAndRole,
  login,
  password,
  comment,
  status,
  adminStatus,
  rules,
  allPlatformsData,
  operatorId,
}) {
  function getActionsUpdateOperatorRequest(payload) {
    return { type: UPDATE_OPERATOR_REQUEST, payload };
  }

  return (dispatch) => {
    if (!operatorId) {
      console.error('No operator id!');

      dispatch(getActionsUpdateOperatorRequest({
        loading: false,
        error: 'No operator id!',
      }));
      return;
    }

    dispatch(getActionsUpdateOperatorRequest({
      loading: true,
    }));

    updateOperator({
      nameAndRole,
      login,
      password,
      comment,
      status,
      adminStatus,
      rules,
      allPlatformsData,
      operatorId,
    })
      .then(
        (result) => {
          // eslint-disable-next-line
          getOneOperatorAction({
            id: operatorId,
          })(dispatch);

          dispatch(getActionsUpdateOperatorRequest({
            loading: false,
            result,
          }));

          dispatch(clearCacheOperators());
        },
        (error) => {
          dispatch(getActionsUpdateOperatorRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function addNewOperatorAction({
  nameAndRole,
  login,
  password,
  comment,
  status,
  adminStatus,
  rules,
  allPlatformsData,
  history,
}) {
  function getActionsAddNewOperatorRequest(payload) {
    return { type: ADD_NEW_OPERATOR_REQUEST, payload };
  }

  return (dispatch) => {
    if (!login && !password && !nameAndRole) {
      console.error('No data!');

      dispatch(getActionsAddNewOperatorRequest({
        loading: false,
        error: 'No data!',
      }));
      return;
    }

    dispatch(getActionsAddNewOperatorRequest({
      loading: true,
    }));

    addNewOperator({
      nameAndRole,
      login,
      password,
      comment,
      status,
      adminStatus,
      rules,
      allPlatformsData,
    })
      .then(
        (result) => {
          dispatch(getActionsAddNewOperatorRequest({
            loading: false,
            result,
          }));
          dispatch(clearCacheOperators());
          const { operatorId } = result;

          if (operatorId || operatorId === 0) {
            const url = PATH_PAGES.oneOperator.replace(':id', operatorId);
            history.push(url);
          }
        },
        (error) => {
          dispatch(getActionsAddNewOperatorRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function getActionsOneOperatorAction({
  id,
  date,
  page,
  perPage,
}) {
  function getActionsOneOperatorRequest(payload) {
    return { type: GET_ACTIONS_ONE_OPERATOR_REQUEST, payload };
  }

  return (dispatch) => {
    dispatch(getActionsOneOperatorRequest({
      loading: true,
    }));

    const apiParam = {
      id,
      date: date ? date.format('YYYY-MM-DD') : date,
      page,
      perPage,
    };

    getActionsOneOperator(apiParam)
      .then(
        (actions) => {
          dispatch(getActionsOneOperatorRequest({
            loading: false,
            actions,
            operatorId: id,
          }));
        },
        (error) => {
          dispatch(getActionsOneOperatorRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function getOneOperatorAction({
  id,
}) {
  function getOneOperatorRequest(payload) { return { type: GET_ONE_OPERATOR_REQUEST, payload }; }

  return (dispatch) => {
    if (!id && id !== 0) {
      console.error('No operator id in getOneOperatorAction!');

      dispatch(getOneOperatorRequest({
        loading: false,
        error: 'No operator id in getOneOperatorAction!',
      }));
      return;
    }

    dispatch(getOneOperatorRequest({
      loading: true,
    }));

    getOneOperator({
      id,
    })
      .then(
        (operator) => {
          dispatch(getOneOperatorRequest({
            loading: false,
            operator,
          }));
        },
        (error) => {
          dispatch(getOneOperatorRequest({
            loading: false,
            error: {
              id,
              error,
            },
          }));
        },
      );
  };
}

export function getOperatorsAction({
  platformSign = 'all',
} = {}) {
  function getOperatorsRequest(payload) {
    return { type: GET_OPERATORS, payload };
  }

  const isAll = platformSign === 'all';
  const param = isAll
    ? undefined
    : {
      platformId: platformSign,
    };

  return (dispatch) => {
    dispatch(getOperatorsRequest({
      loading: true,
    }));

    getOperators(param)
      .then(
        (data) => {
          dispatch(getOperatorsRequest({
            loading: false,
            data,
            platformSign,
          }));
        },
        (error) => {
          dispatch(getOperatorsRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function getCurrentOperator({
  username,
} = {}) {
  function getCurrentOperatorRequest(payload) {
    return { type: GET_CURRENT_OPERATOR, payload };
  }
  function getOperatorsRequest(payload) {
    return { type: GET_OPERATORS, payload };
  }
  function getOneOperatorRequest(payload) { return { type: GET_ONE_OPERATOR_REQUEST, payload }; }

  return (dispatch) => {
    dispatch(getCurrentOperatorRequest({
      loading: true,
    }));

    getOperators()
      .then(
        (data) => {
          dispatch(getOperatorsRequest({
            loading: false,
            data,
            platformSign: 'all',
          }));

          const operatorInfo = (data || []).find((item) => (item || {}).login === username);
          const { id } = operatorInfo || {};

          getOneOperator({
            id,
          })
            .then(
              (operator) => {
                dispatch(getOneOperatorRequest({
                  loading: false,
                  operator,
                }));

                const userRights = getUserRights(operator);
                dispatch(getCurrentOperatorRequest({
                  loading: false,
                  data: operator,
                  userRights,
                }));
              },
              (error) => {
                dispatch(getOneOperatorRequest({
                  loading: false,
                  error,
                }));
              },
            );
        },
        (error) => {
          dispatch(getOperatorsRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function getListFrontOfficeRightsAction() {
  function getListFrontOfficeRightsRequest(payload) {
    return { type: GET_LIST_FRONT_OFFICE_RIGHTS, payload };
  }

  return (dispatch) => {
    dispatch(getListFrontOfficeRightsRequest({
      loading: true,
    }));

    getListFrontOfficeRights()
      .then(
        (list) => {
          dispatch(getListFrontOfficeRightsRequest({
            loading: false,
            list,
          }));
        },
        (error) => {
          dispatch(getListFrontOfficeRightsRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export default {
  getOperatorsAction,
  getActionsOneOperatorAction,
  removeOperatorAction,
  getListFrontOfficeRightsAction,
  getCurrentOperator,
};
