import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import { LOGOUT } from '@parkly/shared/actions/types';
import { LOCALSTORAGE_KEYS } from '@parkly/shared/config/constants';
import auth from '@parkly/shared/reducers/auth';
import platform from './platform';
import bookings from './bookings';
import mobilePayments from './mobilePayments';
import operators from './operators';
import analytics from './analytics';
import documents from './documents';
import blockedUsers from './blockedUsers';
import subscriptions from './subscriptions';

const appReducer = combineReducers({
  auth,
  platform,
  bookings,
  mobilePayments,
  operators,
  analytics,
  documents,
  blockedUsers,
  subscriptions,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    storage.removeItem(`persist:${LOCALSTORAGE_KEYS.persist}`);

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
