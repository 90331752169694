import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  emptyTableCell: {
    minWidth: 156,
    minHeight: 136,
  },
  emptyTableCellLast: {
    borderRight: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderRadius: '0 0 6px 0',
  },
  emptyTableCellFirst: {
    borderLeft: `1px solid ${theme.palette.greyC.buttonGrey}`,
  },
  dayTableCell: {
    minWidth: 156,
    height: 136,
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
    padding: 12,
  },
  subContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  hoveredStyle: {
    backgroundColor: theme.palette.greyC.lightGrey,
  },
  dayNumber: {
    fontSize: theme.typography.fontSize14,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  dayNumbCurrentDay: {
    borderRadius: '50%',
    backgroundColor: theme.palette.blueC.blue,
    color: theme.palette.background.default,
    width: 35,
    height: 35,
    marginTop: -9,
    marginLeft: -9,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  parkSpaceOrTimeContainer: {
    minWidth: 'unset',
    borderRadius: 6,
    padding: '3px 4px ',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 8,
    textTransform: 'none',
    color: theme.palette.greyC.darkGrey,
    '&:hover': {
      color: theme.palette.blueC.darkBlue,
    },
  },
  parkSpaceOrTimeContainerDayOff: {
    color: theme.palette.redC.errorRed,
    '&:hover': {
      color: theme.palette.redC.red7,
    },
  },
  parkSpaceOrTimeContainerDayRule: {
    color: theme.palette.blueC.blue,
    '&:hover': {
      color: theme.palette.blueC.blue7,
    },
  },
  parkSpaceOrTimeContainerWeekRule: {
    color: theme.palette.greenC.green10,
    '&:hover': {
      color: theme.palette.greenC.green4,
    },
  },
  value: {
    marginLeft: 5,
    fontSize: theme.typography.fontSize12,
  },
  noEnoughRight: {
    cursor: 'default',
  },
  tableCellLastRow: {
    borderBottom: 'none',
  },
}));

export default {
  useStyles,
};
