import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    marginTop: 30,
  },
  bodyMain: {

  },
  form: {

  },
  inputItem: {
    marginTop: 24,
  },
  twoInput: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  twoInputItem: {
    width: '48%',
  },
  twoInputItemFullWidth: {
    width: '100%',
  },
  statusSelectorsForm: {
    marginTop: 28,
  },
  statusSelectors: {

  },
  statusSelectorsLabel: {

  },
  divider: {
    marginTop: 32,
    backgroundColor: theme.palette.greyC.gray,
  },
  btnAddOrSave: {
    zIndex: 1,
    paddingLeft: 25,
    paddingRight: 25,
    color: 'white',
    width: 'auto',
    backgroundColor: theme.palette.greenC.green4,
    '&:hover': {
      backgroundColor: theme.palette.greenC.green5,
    },
  },
  btnRemove: {
    marginLeft: -18,
    paddingLeft: 25,
    paddingRight: 25,
    color: theme.palette.greyC.darkGrey,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      boxShadow: 'none',
    },
  },
  facilitiesContainer: {
    marginTop: 5,
  },
  tableContainer: {},
  headerStr: {},
  checkboxError: {
    backgroundColor: '#FFEBEB',
  },
  makeAdminContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 25,
  },
  makeAdminStr: {
    marginBottom: 4,
  },
  switch: {

  },
  btnSettings: {
    minWidth: 120,
    marginLeft: 8,
  },
}));

export default {
  useStyles,
};
