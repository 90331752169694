import React from 'react';

function LogOutIcon({
  fill='#fff',
  size = 32,
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M13 26H8.333a2.394 2.394 0 01-1.65-.65A2.17 2.17 0 016 23.777V8.222c0-.59.246-1.154.683-1.571A2.394 2.394 0 018.333 6H13M20 22l6-6-6-6M25 16H13"
        stroke={fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LogOutIcon;
