/* eslint-disable prefer-template */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ParkSignIconMini from '@parkly/shared/components/atoms/icons/ParkSignIconMini';
import TimeIconMini from '@parkly/shared/components/atoms/icons/TimeIconMini';
import RubleSignIcon from '@parkly/shared/components/atoms/icons/RubleSignIcon';

import { getScheduleTimeStr } from '@parkly/shared/helpers/time';
import { SCHEDULE_SETTINGS_TYPES } from 'config/constants';

import { useStyles } from './styles';

function getIconColor(dayRule, weekRule, theme) {
  if (dayRule) {
    return theme.palette.blueC.blue;
  }

  if (weekRule) {
    return theme.palette.greenC.green10;
  }

  return undefined;
}

function getAdditionalTimeParkClass(dayRule, weekRule, classes) {
  if (dayRule) {
    return classes.parkSpaceOrTimeContainerDayRule;
  }

  if (weekRule) {
    return classes.parkSpaceOrTimeContainerWeekRule;
  }

  return '';
}

function getClassTimeParkContainer(
  dayRule,
  weekRule,
  classes,
  isRightUpdateSchedule,
  isChangeDisable,
) {
  const rightClass = (!isRightUpdateSchedule || isChangeDisable) ? classes.noEnoughRight : '';
  const mainClass = classes.parkSpaceOrTimeContainer;

  return `${mainClass} ${getAdditionalTimeParkClass(dayRule, weekRule, classes)} ${rightClass}`;
}

const propTypes = {
  index: PropTypes.number,
  isLastRow: PropTypes.bool,
  hoveredColumn: PropTypes.number,
  isRightUpdateSchedule: PropTypes.bool,
  isEmpty: PropTypes.bool,
  dayNumber: PropTypes.number,
  dayData: PropTypes.shape({
    isAvailable: PropTypes.bool,
    places: PropTypes.object,
    schedule: PropTypes.object,
    tariff: PropTypes.object,
  }),
  isPast: PropTypes.bool,
  isCurrentDay: PropTypes.bool,
  onChange: PropTypes.func,
};

function WorkScheduleItem({
  index,
  isLastRow,
  hoveredColumn,
  isRightUpdateSchedule,
  isEmpty,
  dayNumber,
  dayData,
  isPast,
  isCurrentDay,
  onChange = () => {},
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  if (isEmpty) {
    const emptyCellClass = [
      classes.emptyTableCell,
      (index === 0) ? classes.emptyTableCellFirst : '',
      (index === 6) ? classes.emptyTableCellLast : '',
      isLastRow ? classes.tableCellLastRow : '',
    ];

    return (
      <TableCell
        key={dayNumber}
        className={emptyCellClass.join(' ')}
        scope="row"
        align="center"
      />
    );
  }

  const {
    isAvailable,
    places = {},
    schedule = {},
  } = dayData || {};

  const isTimeWeekRule = schedule.type === SCHEDULE_SETTINGS_TYPES.week;
  const isParkSpaceWeekRule = places.type === SCHEDULE_SETTINGS_TYPES.week;
  const isTariffWeekRule = isTimeWeekRule;

  const isTimeDayRule = schedule.type === SCHEDULE_SETTINGS_TYPES.day;
  const isParkSpaceDayRule = places.type === SCHEDULE_SETTINGS_TYPES.day;
  const isTariffDayRule = isTimeDayRule;

  const isDayOff = !isAvailable;

  const isHovered = index === hoveredColumn;
  const isChangeDisable = isPast;
  const tableCellClass = [
    classes.dayTableCell,
    isHovered ? classes.hoveredStyle : '',
    isLastRow ? classes.tableCellLastRow : '',
  ];
  const classNameStr = tableCellClass.join(' ');

  const timeClassStyle = getClassTimeParkContainer(
    isTimeDayRule,
    isTimeWeekRule,
    classes,
    isRightUpdateSchedule,
    isChangeDisable,
  );
  const parkSpaceClassStyle = getClassTimeParkContainer(
    isParkSpaceDayRule,
    isParkSpaceWeekRule,
    classes,
    isRightUpdateSchedule,
    isChangeDisable,
  );
  const tariffClassStyle = [
    (!isRightUpdateSchedule || isChangeDisable) ? classes.noEnoughRight : '',
    classes.parkSpaceOrTimeContainer,
    getAdditionalTimeParkClass(isTariffDayRule, isTariffWeekRule, classes),
  ];

  const iconColorTime = getIconColor(isTimeDayRule, isTimeWeekRule, theme);
  const iconColorParkSpace = getIconColor(isParkSpaceDayRule, isParkSpaceWeekRule, theme);
  const iconColorTariff = getIconColor(isTariffDayRule, isTariffWeekRule, theme);

  const timeStart = getScheduleTimeStr(schedule.availableFrom);
  const timeEnd = getScheduleTimeStr(schedule.availableTo);
  const timeStr = `${timeStart} - ${timeEnd}`;
  const parkSpace = places.count;
  const tariffName = (schedule || {}).tariff;

  const rightClass = (!isRightUpdateSchedule || isChangeDisable) ? classes.noEnoughRight : '';
  const dayOffClassList = [
    classes.parkSpaceOrTimeContainer,
    classes.parkSpaceOrTimeContainerDayOff,
    rightClass,
  ];

  function clickHandler(type) {
    return function onClick() {
      if (!isRightUpdateSchedule || isChangeDisable) {
        return;
      }
      onChange(type);
    };
  }

  return (
    <TableCell
      key={dayNumber}
      className={classNameStr}
      scope="row"
      align="center"
    >
      <div className={classes.subContainer}>
        <Typography
          className={`${classes.dayNumber} ${isCurrentDay ? classes.dayNumbCurrentDay : ''}`}
        >
          {dayNumber}
        </Typography>
        {isDayOff && (
          <Button
            className={dayOffClassList.join(' ')}
            onClick={clickHandler('dayOff')}
          >
            <TimeIconMini fill={theme.palette.redC.errorRed} />
            <Typography className={classes.value}>{t('platforms.dayOff')}</Typography>
          </Button>
        )}
        {!isDayOff && (
          <>
            <Button
              className={timeClassStyle}
              onClick={clickHandler('time')}
            >
              <TimeIconMini fill={iconColorTime} />
              <Typography className={classes.value}>{timeStr}</Typography>
            </Button>
            <Button
              className={parkSpaceClassStyle}
              onClick={clickHandler('parkSpace')}
            >
              <ParkSignIconMini fill={iconColorParkSpace} />
              <Typography className={classes.value}>{parkSpace}</Typography>
            </Button>
            <Button
              className={tariffClassStyle.join(' ')}
              onClick={clickHandler('tariff')}
            >
              <RubleSignIcon fill={iconColorTariff} />
              <Typography className={classes.value}>{tariffName}</Typography>
            </Button>
          </>
        )}
      </div>
    </TableCell>
  );
}

WorkScheduleItem.propTypes = propTypes;

export default WorkScheduleItem;
