import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import ChevronDownIcon from '@parkly/shared/components/atoms/icons/ChevronDownIcon';

import { useStyles } from './styles';

function getClassNames({
  selectorsType = 'default',
  classes,
  className = '',
  classNameForm = '',
  classNameLabel = '',
  label,
}) {
  const isLabel = !!label;
  const selectClassName = isLabel
    ? classes.selectLabelClass
    : '';

  if (selectorsType === 'secondary') {
    return {
      classNameContainer: `${classes.select} ${selectClassName} ${classes.secSelect} ${className}`,
      classNameFormContainer: `${classes.formControl} ${classes.secFormControl} ${classNameForm}`,
      classNameInputLabel: `${classes.label} ${classNameLabel}`,
    };
  }

  const classNameContainer = `${classes.select} ${className}`;
  const classNameFormContainer = `${classes.formControl} ${classNameForm}`;
  const classNameInputLabel = `${classes.label} ${classNameLabel}`;

  return {
    classNameContainer,
    classNameFormContainer,
    classNameInputLabel,
  };
}

function ItemSelectors({
  selectorsType = 'default',
  className = '',
  classNameForm = '',
  classNameLabel = '',
  label,
  name,
  currentValue,
  defaultItem,
  items = [],
  children = null,
  onChange = () => {},
  ...props
}) {
  const classes = useStyles();

  const {
    classNameContainer,
    classNameFormContainer,
    classNameInputLabel,
  } = getClassNames({
    selectorsType,
    classes,
    className,
    classNameForm,
    classNameLabel,
    label,
  });
  const labelId = `labelId${label}`;

  return (
    <FormControl
      className={classNameFormContainer}
    >
      {label && (
        <InputLabel
          id={labelId}
          className={classNameInputLabel}
        >
          {label}
        </InputLabel>
      )}
      <Select
        labelId={labelId}
        name={name}
        className={classNameContainer}
        value={currentValue}
        onChange={onChange}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          className: classes.selectMenu,
        }}
        IconComponent={ChevronDownIcon}
        {...props}
      >
        {children}
        {!children && defaultItem && (
          <MenuItem className={classes.menuItem} value="unselect">
            <Typography className={classes.unSelectStr}>
              {defaultItem}
            </Typography>
          </MenuItem>
        )}
        {!children && (items || []).map((current) => {
          const { id, value, title, subTitle } = current;

          return (
            <MenuItem
              key={`${name} + ${id || value}`}
              value={value || id}
              className={classes.menuItem}
            >
              <Typography className={classes.selectedStr}>
                {title}
                {subTitle && (
                  <>
                    {' '}
                    <span>
                    {subTitle}
                  </span>
                  </>
                )}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default ItemSelectors;
