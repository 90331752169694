import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogMain: {
    '& .MuiPaper-root': {
      width: 332,
    },
  },
  titleStr: {
    fontWeight: 'bold',
    fontSize: theme.typography.fontSize16,
  },
  form: {},
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  dialogContentDescription: {
    borderBottom: `1px solid ${theme.palette.greyC.gray}`,
    paddingBottom: 18,
  },
  dialogContentMsgTitle: {
    marginBottom: 9,
    marginTop: 22,
    fontWeight: 'bold',
    fontSize: theme.typography.fontSize14,
  },
  dialogContentInfoCnt: {
    marginTop: 12,
    display: 'inline-flex',
  },
  infoIconMini: {
    flexShrink: 0,
    marginRight: 6,
  },
  dialogContentInfo: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
    lineHeight: '14.4px',
    paddingRight: 14,
  },
  dialogContentStr: {
  },
  dialogContentMsg: {
    marginTop: 12,
  },
  msgBodyInput: {
    '& .MuiInputBase-input': {
      padding: 12,
      minHeight: 64,
    },
  },
  msgNumberInput: {
  },
  msgNumberContainer: {
    marginTop: 2,
    padding: 12,
    paddingTop: 0,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  msgNumberTitle: {
    width: 195,
  },
  btnDisagree: {
    paddingLeft: 32,
    paddingRight: 32,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  btnAgree: {
    paddingLeft: 32,
    paddingRight: 32,
    marginLeft: 12,
  },
  dialogActions: {
    marginTop: 8,
    padding: 24,
  },
}));

export default {
  useStyles,
};
