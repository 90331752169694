import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 24,
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderRadius: 6,
  },
  table: {
    paddingLeft: 8,
  },
  row: {
  },
  headerTime: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  headerWeekday: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  emphHeader: {
    backgroundColor: theme.palette.greyC.lightGrey,
    fontWeight: 'bold',
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
  },
  emphCiel: {
    backgroundColor: theme.palette.greyC.lightGrey,
  },
  emphCielLast: {
    backgroundColor: theme.palette.greyC.lightGrey,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
  },
  header: {
    padding: '34px 0',
    paddingLeft: 24,
  },
  lastRow: {
    border: 'none',
  },
  rowTitleText: {
    fontSize: theme.typography.fontSize12,
  },
  everyday: {

  },
  weekday: {
    color: theme.palette.greenC.green7,
  },
  day: {
    color: theme.palette.blueC.blue,
  },
  dayOff: {
    color: theme.palette.redC.red3,
  },
  divider: {
    margin: '-4px 0',
  },
  currentDay: {
    fontWeight: 700,
  },
}));

export default {
  useStyles,
};
