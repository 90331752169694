import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { useStyles } from './styles';

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function TitlePrice({
  className = '',
  title = '',
  price = '',
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const currency = t('others.currency');
  const priceNumber = !price ? 0 : +price;
  const roundedPrice = Math.round(priceNumber);
  const priceStr = `${roundedPrice} ${currency}`;
  return (
    <Grid item className={`${classes.container} ${className}`} {...props}>
      <div className={classes.subContainer}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.price}>{priceStr}</Typography>
      </div>
    </Grid>
  );
}

TitlePrice.propTypes = propTypes;

export default TitlePrice;
