import {
  GET_REPORTS,
} from 'actions/types';
import { updateStoreReqRes } from '@parkly/shared/helpers';

const INITIAL_STATE = {
  reports: {
    loading: false,
    data: [],
  },
};

export default function analytics(state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_REPORTS:
    return updateStoreReqRes(
      state,
      'reports',
      action,
    );

  default:
    return state;
  }
}
