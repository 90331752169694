import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import CustomLink from '@parkly/shared/components/atoms/CustomLink';

import { getNext7Days, getScheduleTimeStr } from '@parkly/shared/helpers/time';
import { PATH_PAGES, PLATFORM_TYPES } from 'config/constants';

import { useStyles } from './styles';

const dateFormat = 'D MMM';
const CEIL_TYPES = {
  schedule: 'schedule',
  emptySpaces: 'emptySpaces',
  bookings: 'bookings',
  mobilePayments: 'mobilePayments',
};

function createData(type, name, linkStr, data, isLink) {
  return {
    type, name, linkStr, data, isLink,
  };
}

function ScheduleSumTableCell({
  className,
  type,
  item,
  isCurrentDay,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const defaultNode = <TableCell align="left" />;

  if (!item) {
    return defaultNode;
  }

  if (type === CEIL_TYPES.schedule) {
    const {
      availableFrom,
      availableTo,
      isAvailable,
    } = item || {};
    const ruleType = (item || {}).type || '';

    const fromTime = !availableFrom
      ? '?'
      : getScheduleTimeStr(availableFrom);

    const toTime = !availableTo
      ? '?'
      : getScheduleTimeStr(availableTo);

    const divider = '-';
    const ruleTypeClasses = (classes[ruleType.replace('_', '')] || '');
    const classNameSchedule = `${ruleTypeClasses} ${isAvailable ? '' : classes.dayOff} ${className}`;

    return (
      <TableCell
        className={classNameSchedule}
        align="left"
      >
        {!isAvailable && (
          <Typography
            className={isCurrentDay ? classes.currentDay : ''}
          >
            {t('platforms.dayOff')}
          </Typography>
        )}
        {isAvailable && (
          <>
            <Typography
              className={isCurrentDay ? classes.currentDay : ''}
            >
              {fromTime}
            </Typography>
            <Typography
              className={`${classes.divider} ${isCurrentDay ? classes.currentDay : ''}`}
            >
              {divider}
            </Typography>
            <Typography
              className={isCurrentDay ? classes.currentDay : ''}
            >
              {toTime}
            </Typography>
          </>
        )}
      </TableCell>
    );
  }

  if (type === CEIL_TYPES.emptySpaces) {
    const {
      count,
      isAvailable,
    } = item || {};
    const ruleType = (item || {}).type || '';

    const ruleTypeClasses = (classes[ruleType.replace('_', '')] || '');
    const classNameEmptySpaces = `${ruleTypeClasses} ${className} ${isAvailable ? '' : classes.dayOff}`;

    return (
      <TableCell
        className={classNameEmptySpaces}
        align="left"
      >
        <Typography
          className={isCurrentDay ? classes.currentDay : ''}
        >
          {isAvailable ? count : 'Нет'}
        </Typography>
      </TableCell>
    );
  }

  if ([CEIL_TYPES.bookings, CEIL_TYPES.mobilePayments].includes(type)) {
    const { count } = item || {};

    return (
      <TableCell
        className={className}
        align="left"
      >
        <Typography
          className={isCurrentDay ? classes.currentDay : ''}
        >
          {count}
        </Typography>
      </TableCell>
    );
  }

  return defaultNode;
}

function ScheduleSummary({
  platformType,
  availablePlaces = [],
  schedules = [],
  reservations = [],
  mobilePayments = [],
  platformId,
  userRights,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const next7Days = useMemo(
    () => getNext7Days(),
    [],
  );

  const hasReservation = [
    PLATFORM_TYPES.reservation,
    PLATFORM_TYPES.all,
  ].includes(platformType);
  const hasMobilePayments = [
    PLATFORM_TYPES.mobilePayment,
    PLATFORM_TYPES.all,
  ].includes(platformType);

  const headerRow = next7Days.map((time) => {
    const weekDayName = time.format('dd');
    const dayMonth = time.format(dateFormat).slice(0, -1);
    return {
      time,
      weekDayName,
      dayMonth,
    };
  });

  const schedulesRow = hasReservation ? headerRow.map(({ dayMonth }) => {
    const current = schedules.find(({ date }) => {
      const formatedDate = moment(date).format(dateFormat).slice(0, -1);

      return formatedDate === dayMonth;
    });

    return current;
  }) : null;

  const availablePlacesRow = hasReservation ? headerRow.map(({ dayMonth }) => {
    const current = availablePlaces.find(({ date }) => {
      const formatedDate = moment(date).format(dateFormat).slice(0, -1);

      return formatedDate === dayMonth;
    });

    return current;
  }) : null;

  const bookingsRow = hasReservation ? headerRow.map(({ dayMonth }) => {
    const current = reservations.find(({ date }) => {
      const formatedDate = moment(date).format(dateFormat).slice(0, -1);

      return formatedDate === dayMonth;
    });

    return current;
  }) : null;

  const mobilePaymentsRow = hasMobilePayments ? headerRow.map(({ dayMonth }) => {
    const current = mobilePayments.find(({ date }) => {
      const formatedDate = moment(date).format(dateFormat).slice(0, -1);

      return formatedDate === dayMonth;
    });

    return current;
  }) : null;

  const scheduleLink = PATH_PAGES.platformSchedule.replace(':id', platformId);
  const bookingsLink = PATH_PAGES.bookingsId.replace(':id', platformId);
  const mobilePaymentsLink = PATH_PAGES.mobilePaymentsId.replace(':id', platformId);
  const { isReadHistBookList, isReadActBookList } = userRights || {};
  const isBookingsLink = isReadHistBookList || isReadActBookList;

  const rows = useMemo(() => {
    const r = [];

    if (hasReservation) {
      r.push(
        createData(CEIL_TYPES.schedule, t('platforms.workSchedule'), scheduleLink, schedulesRow, true),
        createData(CEIL_TYPES.emptySpaces, t('platforms.emptySpaces'), scheduleLink, availablePlacesRow, true),
        createData(CEIL_TYPES.bookings, t('platforms.bookings'), bookingsLink, bookingsRow, isBookingsLink),
      );
    }

    if (hasMobilePayments) {
      r.push(
        createData(CEIL_TYPES.mobilePayments, t('platforms.mobilePayments'), mobilePaymentsLink, mobilePaymentsRow, isBookingsLink),
      );
    }

    return r;
  }, [
    availablePlacesRow, bookingsLink, bookingsRow, hasMobilePayments,
    hasReservation, isBookingsLink, mobilePaymentsLink,
    mobilePaymentsRow, scheduleLink, schedulesRow, t,
  ]);

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} aria-label="summary table">
        <TableHead>
          <TableRow>
            <TableCell style={{
              width: '25%',
              paddingLeft: 8,
            }}
            />
            {headerRow.map(({ weekDayName, dayMonth, time }, index) => (
              <TableCell
                className={(index === 0) ? classes.emphHeader : ''}
                key={time}
                align="left"
              >
                <Typography className={`${classes.headerTime} ${classes.headerWeekday}`}>
                  {weekDayName}
                </Typography>
                <Typography className={classes.headerTime}>
                  {dayMonth}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, indexRow) => {
            const {
              name, linkStr, data, type, isLink,
            } = row;
            const isLastRow = indexRow === (rows.length - 1);

            function getClassName(index) {
              if (index === 0) {
                if (isLastRow) {
                  return `${classes.emphCielLast} ${classes.lastRow}`;
                }

                return classes.emphCiel;
              }

              if (isLastRow) {
                return classes.lastRow;
              }

              return '';
            }

            return (
              <TableRow
                className={classes.row}
                key={type}
              >
                <TableCell
                  className={`${classes.header} ${isLastRow ? classes.lastRow : ''}`}
                  component="th"
                  scope="row"
                  sm={6}
                >
                  {isLink && (
                    <CustomLink href={linkStr}>
                      {name}
                    </CustomLink>
                  )}
                  {!isLink && (
                    <Typography>
                      {name}
                    </Typography>
                  )}
                </TableCell>
                {data.map((item, index) => (
                  <ScheduleSumTableCell
                    className={getClassName(index)}
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={`${type} ${index}`}
                    {...{
                      item,
                      type,
                      isCurrentDay: index === 0,
                    }}
                  />
                )) }
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ScheduleSummary;
