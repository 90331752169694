import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import reducers from 'reducers';
import { checkProduction } from '@parkly/shared/helpers/others';
import { updateRehydratedPersisit } from 'helpers/others';
import { LOCALSTORAGE_KEYS } from '@parkly/shared/config/constants';

const appVer = process.env.REACT_APP_VERSION;

const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => inboundState,
  // transform state being rehydrated
  (outboundState, key) => {
    const transfState = updateRehydratedPersisit(outboundState, key);

    return {
      ...transfState,
    };
  },
  { blacklist: [] },
);

const persistConfig = {
  key: LOCALSTORAGE_KEYS.persist,
  version: appVer,
  storage,
  transforms: [SetTransform],
  migrate: (state) => {
    const { _persist } = state || {};
    const { version } = _persist || {};
    const isSameVersion = version === appVer;

    const newState = isSameVersion
      ? state
      : {
        auth: (state || {}).auth,
      };

    return Promise.resolve(newState);
  },
};

const persistedReducer = persistReducer(
  persistConfig,
  reducers,
);

const middleware = checkProduction()
  ? [thunkMiddleware]
  : [thunkMiddleware, createLogger()];

const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware),
);

const persistor = persistStore(store);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('../reducers', () => {
    // eslint-disable-next-line global-require
    const nextRootReducer = require('../reducers/index').default;
    store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
  });
}

export { store, persistor };

export default store;
