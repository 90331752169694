import { TOOLTIP_CONTAINER_ID } from '@parkly/shared/config/constants';
import { select } from 'd3';

function getTooltipXY(currentEvent, tooltipNode) {
  const tooltipSize = tooltipNode.node().getBoundingClientRect();
  // const chatCntSize = document.querySelector(`#${CHART_CONTAINER_ID}`).getBoundingClientRect();

  const top = currentEvent.clientY - 10 - tooltipSize.height;
  const left = currentEvent.clientX + 10 - tooltipSize.width / 2;

  // if ((left + tooltipSize.width / 2) >= chatCntSize.width) {
  //   left = currentEvent.clientX - 10 - tooltipSize.width;
  // }

  return {
    top: `${top}px`,
    left: `${left}px`,
  };
}

export function hideTooltip() {
  select(`#${TOOLTIP_CONTAINER_ID}`)
    .style('visibility', 'hidden');
}
export function showTooltip(currentEvent, html) {
  if (!html) return;

  const tooltipNode = select(`#${TOOLTIP_CONTAINER_ID}`);

  const tooltipContainer = tooltipNode.select('.tooltipContainer')
    .html(html);

  const tooltipPos = getTooltipXY(currentEvent, tooltipContainer);

  tooltipNode
    .style('top', tooltipPos.top)
    .style('left', tooltipPos.left)
    .style('visibility', 'visible');
}

export default {
  hideTooltip,
  showTooltip,
};
