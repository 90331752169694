import * as React from 'react';

function CalendarIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      <path
        d="M17.444 5H6.556C5.696 5 5 5.696 5 6.556v10.888C5 18.304 5.696 19 6.556 19h10.888c.86 0
        1.556-.696 1.556-1.556V6.556C19 5.696 18.304 5 17.444 5zM15 3v4M9 3v4M5 11h14"
        stroke="#7E8AA7"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CalendarIcon;
