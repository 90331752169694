import React from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

import { RIGHT_KEYS } from 'config/constants';

import { useStyles } from './styles';

/* help functions */

function getRuleSettingsList(list) {
  return list;
}

function updateRuleList({
  indexRule,
  platformSign,
  rules = [],
  checked,
  isCommon,
}) {
  if (isCommon) {
    const newRule = {
      ...rules[indexRule],
      checked,
    };
    const newRules = [
      ...rules.slice(0, indexRule),
      newRule,
      ...rules.slice(indexRule + 1),
    ];

    return newRules;
  }

  if (platformSign === 'all') {
    const newRules = rules.map((platformRuleList = [], index) => {
      if (index === indexRule) {
        const newPlatformRuleList = platformRuleList.map((rule) => ({
          ...rule,
          checked,
        }));

        return newPlatformRuleList;
      }

      return platformRuleList;
    });

    return newRules;
  }

  // eslint-disable-next-line
  let newRules = [
    ...rules,
  ];

  newRules[indexRule][platformSign] = {
    ...newRules[indexRule][platformSign],
    checked,
  };

  return newRules;
}

function checkDisable(ruleId) {
  return ruleId === RIGHT_KEYS.createChangeOperator || ruleId === RIGHT_KEYS.createUpdateBlockedUsers;
}

const propTypes = {
};

function OperatorSettingsTable({
  allPlatforms = [],
  rules = [],
  ruleList = [],
  updateSettings = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { platforms } = allPlatforms || {};
  const allPlatformsData = (platforms || {}).data || [];
  const ruleSettingsList = getRuleSettingsList(ruleList);

  const isEverywhere = allPlatformsData.length > 1;
  const isFullWidth = allPlatformsData.length > 2;

  function createOnCheckedChangeHandler({
    indexRule,
    platformSign,
    isCommon,
  }) {
    return function onCheckedChange(event) {
      const { checked } = event.target;
      const newRuleList = updateRuleList({
        indexRule,
        platformSign,
        rules,
        checked,
        isCommon,
      });

      updateSettings('rules', newRuleList);
    };
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={isFullWidth ? 12 : 8}>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.cellFirstColumn}
                  align="left"
                >
                  <Typography className={classes.headerStr}>
                    {t('operators.accessRight')}
                  </Typography>
                </TableCell>
                {isEverywhere && (
                  <TableCell
                    align="left"
                  >
                    <Typography className={classes.headerStr}>
                      {t('operators.everywhere')}
                    </Typography>
                  </TableCell>
                )}
                {allPlatformsData.map(({ id, title }) => (
                  <TableCell
                    key={id}
                    align="left"
                  >
                    <Typography className={classes.headerStr}>
                      {title}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ruleSettingsList.map((row, indexRule) => {
                const {
                  id: currentRuleId,
                  hidden,
                  title,
                } = row || {};
                const key = currentRuleId;

                if (hidden) {
                  return null;
                }

                const currentRule = rules[indexRule];
                const isCommon = (typeof currentRule === 'object') && currentRule.isRuleCommon;

                const currentRuleList = currentRule || [];
                const allChecked = isCommon
                  ? currentRule.checked
                  : currentRuleList.reduce(
                    (acc, current) => {
                      const ruleChecked = (current || {}).checked;
                      return acc && ruleChecked;
                    },
                    true,
                  );

                return (
                  <TableRow
                    key={key}
                  >
                    <TableCell
                      align="left"
                      className={classes.cellFirstColumn}
                    >
                      <Typography className={classes.ruleNameStr}>
                        {title}
                      </Typography>
                    </TableCell>
                    {isEverywhere && (
                      <TableCell
                        align="left"
                      >
                        <Checkbox
                          color="primary"
                          checked={allChecked}
                          disabled={checkDisable(currentRuleId)}
                          onChange={createOnCheckedChangeHandler({
                            indexRule,
                            platformSign: 'all',
                            isCommon,
                          })}
                        />
                      </TableCell>
                    )}
                    {allPlatformsData.map(({ id }, platformIndex) => {
                      const ruleChecked = (currentRuleList[platformIndex] || {}).checked;

                      return (
                        <TableCell
                          key={id}
                          align="left"
                        >
                          {(!isCommon || !isEverywhere) && (
                            <Checkbox
                              color="primary"
                              disabled={checkDisable(currentRuleId)}
                              checked={isCommon ? currentRule.checked : ruleChecked}
                              onChange={createOnCheckedChangeHandler({
                                indexRule,
                                platformSign: platformIndex,
                                isCommon,
                              })}
                            />
                          )}
                        </TableCell>
                      );
                    })}

                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {!isFullWidth && (
        <Grid item xs={12} sm={4} />
      )}
    </Grid>
  );
}

OperatorSettingsTable.propTypes = propTypes;

export default OperatorSettingsTable;
