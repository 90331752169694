import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme } from '@material-ui/core/styles';

import LeftPageMenu from 'components/organisms/LeftPageMenu';
import SupportSection from '@parkly/shared/components/templates/SupportSection';

import { createMailLink } from '@parkly/shared/helpers/others';
import { drawerWidth, useStyles } from './styles';

/* help functions */

const propTypes = {

};

function MainNavigation({
  currentOperator,
  allPlatforms,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const { platforms } = allPlatforms || {};
  const { data: platformsList } = platforms || {};
  const pltfmsTitle = (platformsList || []).length > 1 ? 'Площадки' : 'Площадка';
  const platformsName = (platformsList || []).reduce(
    (acc, { title, id }) => `${acc} "${title}", (${id})`,
    '',
  );

  const {
    userRights,
    data: currentUserData,
  } = currentOperator || {};
  const {
    name: currentUserName,
    id: currentUserId,
  } = currentUserData || {};

  const email = process.env.REACT_APP_SUPPORT_EMAIL;
  const subject = `${pltfmsTitle}${platformsName}. Обращение от  "${currentUserName}", (${currentUserId})`;
  const message = '';

  const supportLink = createMailLink({
    email,
    subject,
    message,
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {'  '}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <LeftPageMenu
            userRightFlags={userRights}
            drawerWidth={drawerWidth}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <LeftPageMenu
            userRightFlags={userRights}
            drawerWidth={drawerWidth}
            variant="permanent"
            open
          />
        </Hidden>
      </nav>
      <SupportSection {...{
        supportLink,
      }}
      />
    </>
  );
}

MainNavigation.propTypes = propTypes;

function mapStateToProps(state) {
  const { loggedIn } = state.auth || {};
  const { operators } = state || {};
  const { currentOperator } = operators || {};

  const { platform } = state || {};
  const { allPlatforms } = platform || {};

  return {
    isAuth: !!loggedIn,
    currentOperator,
    allPlatforms,
  };
}

const ConnectedMainNavigation = connect(mapStateToProps)(MainNavigation);

export default ConnectedMainNavigation;
