import React from 'react';

function DeclinedIcon(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <rect
        x={1}
        y={1}
        width={16}
        height={16}
        rx={8}
        stroke="#F34E4E"
        strokeWidth={1.5}
      />
      <path
        d="M6 6l3 3m0 0l3 3M9 9l-3 3m3-3l3-3"
        stroke="#F34E4E"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default DeclinedIcon;
