import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';

import Collapse from '@material-ui/core/Collapse';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import OperatorSettingsTable from 'components/templates/OperatorSettingsTable';
import CustomDialog from '@parkly/shared/components/templates/CustomDialog';
import UserLogSummary from 'components/organisms/UserLogSummary';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import {
  getActionsList,
} from '@parkly/shared/helpers';


import {
  getOneOperatorAction,
  addNewOperatorAction,
  updateOperatorAction,
  removeOperatorAction,
  getActionsOneOperatorAction,
  getListFrontOfficeRightsAction,
} from 'actions';
import { PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import { useStyles } from './styles';

/* help functions */

function getRuleSettingsList(rightList) {
  const { list } = rightList || {};

  return list || [];
}

function getOneOperator({
  oneOperator,
  match,
}) {
  const operatorId = ((match || {}).params || {}).id;

  const { list } = oneOperator || {};
  const currentOperator = (list || []).find(({ id } = {}) => {
    // eslint-disable-next-line
    const isNeeded = ('' + id) === ('' + operatorId);

    return isNeeded;
  });

  return {
    operatorId,
    currentOperator,
  };
}

function getSettings({
  allPlatforms,
  rightList,
  oneOperator,
  match,
}) {
  const ruleList = getRuleSettingsList(rightList);
  const { platforms } = allPlatforms || {};
  const allPlatformsData = (platforms || {}).data || [];

  const {
    operatorId,
    currentOperator,
  } = getOneOperator({
    oneOperator,
    match,
  });
  const isNewOperatorMode = operatorId === 'new';

  if (isNewOperatorMode) {
    return {
      nameAndRole: '',
      login: '',
      password: '',
      comment: '',
      status: 1,
      adminStatus: false,
      rules: ruleList.map((ruleListObject) => {
        const {
          id: ruleId,
          isCommon: isRuleCommon,
          default: checkedDefault = false,
        } = ruleListObject || {};

        if (isRuleCommon) {
          return {
            isRuleCommon,
            ruleId,
            checked: checkedDefault,
          };
        }

        return allPlatformsData.map(() => {
          const checked = ruleId === RIGHT_KEYS.readActiveBookingsList || checkedDefault;

          return {
            isRuleCommon,
            ruleId,
            checked,
          };
        });
      }),
    };
  }

  const {
    rights = {},
    // id,
    isAdmin = false,
    login = '',
    name = '',
    status = 1,
    password = '',
    comment = '',
  } = currentOperator || {};

  const rules = ruleList.map((ruleListObject) => {
    const ruleId = ruleListObject.id;
    const isRuleCommon = ruleListObject.isCommon;

    if (isRuleCommon) {
      const commonRights = rights.common || [];
      const checked = commonRights.includes(ruleId);

      return {
        isRuleCommon,
        ruleId,
        checked,
      };
    }

    const platformsRightsSettings = allPlatformsData.map((platform) => {
      const platformIdStr = `${(platform || {}).id}`;
      const platformRights = rights[platformIdStr] || [];
      const checked = platformRights.includes(ruleId);

      return {
        ruleId,
        platformId: platformIdStr,
        checked,
      };
    });

    return platformsRightsSettings;
  });

  return {
    nameAndRole: name,
    login,
    password,
    comment,
    status,
    adminStatus: isAdmin,
    rules,
  };
}

function getErrors({
  addNewOperator,
  updateOperator,
}) {
  return {
    nameAndRole: false,
    login: false,
    password: false,
    comment: false,
    status: false,
    adminStatus: false,
  };
}

const propTypes = {
  allPlatforms: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  addNewOperator: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  updateOperator: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  actionsOneOperator: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  rightList: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  currentOperator: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  oneOperator: PropTypes.shape({
    loading: PropTypes.bool,
    list: PropTypes.array,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any.isRequired,
    }).isRequired,
  }).isRequired,
  getOneOperatorsReq: PropTypes.func,
  addNewOperatorReq: PropTypes.func,
  updOperatorReq: PropTypes.func,
  removeOperatorReq: PropTypes.func,
  getOperatorActionsReq: PropTypes.func,
  getListFrontOfficeRightsReq: PropTypes.func,
};

function OneOperator({
  allPlatforms,
  oneOperator,
  match,
  addNewOperator,
  updateOperator,
  actionsOneOperator,
  rightList,
  currentOperator,
  getOneOperatorsReq = () => {},
  addNewOperatorReq = () => {},
  updOperatorReq = () => {},
  removeOperatorReq = () => {},
  getOperatorActionsReq = () => {},
  getListFrontOfficeRightsReq = () => {},
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [settings, setSettings] = useState(getSettings({
    allPlatforms,
    oneOperator,
    match,
    rightList,
  }));
  const [errors, setErrors] = useState(getErrors({
    addNewOperator,
    updateOperator,
  }));
  const [isAdminSettings, setIsAdminSettings] = useState(false);
  const [isRemoveDialog, setIsRemoveDialog] = useState(false);

  useEffect(
    () => {
      const {
        loading,
        list = [],
      } = rightList || {};

      if (loading || list.length > 0) {
        return;
      }

      getListFrontOfficeRightsReq();
    },
    // eslint-disable-next-line
    [rightList],
  );
  useEffect(
    () => {
      const operatorId = ((match || {}).params || {}).id;
      const isNewOperatorMode = operatorId === 'new';

      if (!isNewOperatorMode) {
        setSettings(getSettings({
          allPlatforms,
          oneOperator,
          rightList,
          match,
        }));
      }
    },
    // eslint-disable-next-line
    [allPlatforms, rightList, oneOperator, ((match || {}).params || {}).id],
  );
  useEffect(
    () => {
      const {
        operatorId,
        currentOperator: thisOperator,
      } = getOneOperator({
        oneOperator,
        match,
      });
      const isNewOperatorMode = operatorId === 'new';

      const { loading: isOneOperatorLoading } = oneOperator || {};
      if (!isOneOperatorLoading && !thisOperator && operatorId && !isNewOperatorMode) {
        getOneOperatorsReq({
          id: operatorId,
        });

        const {
          selOperatorActions,
        } = getActionsList({
          actionsOneOperator,
          selOperatorId: operatorId,
        });

        const isActionRight = ((thisOperator || {}).userRights || {}).isReadOperatorsActions;
        if (isActionRight && !selOperatorActions) {
          getOperatorActionsReq({
            id: operatorId,
          });
        }
      }
    },
    // eslint-disable-next-line
    [oneOperator, ((match || {}).params || {}).id],
  );
  useEffect(
    () => {
      const addErrors = (addNewOperator || {}).error;
      const updateErrors = (updateOperator || {}).error;
      const error = updateErrors || addErrors;

      if (error) {
        const { response } = error || {};
        const { data } = response || {};
        const backErrors = (data || {}).errors || {};

        setErrors((prevErrors) => ({
          ...prevErrors,
          ...backErrors,
        }));
      }
    },
    // eslint-disable-next-line
    [addNewOperator, updateOperator],
  );

  const { userRights } = currentOperator || {};
  const {
    isCreateUpdOperators,
    isReadOperatorsActions,
  } = userRights || {};

  const {
    operatorId,
  } = getOneOperator({
    match,
  });
  const isNewOperatorMode = operatorId === 'new';

  const {
    actionsList,
  } = getActionsList({
    actionsOneOperator,
    selOperatorId: operatorId,
    t,
  });
  const isActionsLoading = (actionsOneOperator || {}).loading;

  const ruleList = getRuleSettingsList(rightList);
  const statusList = [
    {
      id: 1,
      title: t('operators.active'),
    },
    {
      id: 2,
      title: t('operators.notActive'),
    },
  ];

  const isAddNewLoading = (addNewOperator || {}).loading;

  const isLoading = isAddNewLoading
    || (oneOperator || {}).loading
    || (allPlatforms || {}).loading
    || (addNewOperator || {}).loading
    || (updateOperator || {}).loading;

  const {
    nameAndRole,
    login,
    password,
    comment,
    status,
    adminStatus,
    rules,
  } = settings;

  const currentOperatorId = ((currentOperator || {}).data || {}).id;
  const isRightToDelete = (`${operatorId}`) !== (`${currentOperatorId}`)
    && (currentOperatorId || currentOperatorId === 0);

  const isSaveBtnDisabled = !login
    || !nameAndRole
    || (isNewOperatorMode && !password)
    || isAddNewLoading;

  function updateSettings(propName, value) {
    if (!isCreateUpdOperators) {
      return;
    }
    setSettings((prevSettings) => ({
      ...prevSettings,
      [propName]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [propName]: false,
    }));
  }
  function handleChanges(event) {
    if (!isCreateUpdOperators) {
      return;
    }

    const propName = event.target.name;
    const { value, checked } = event.target;
    const newValue = event.target.type !== 'checkbox' ? value : checked;

    updateSettings(propName, newValue);
  }
  function onSettingsBtnClick() {
    setIsAdminSettings((prev) => !prev);
  }
  function openRemoveDialog() {
    setIsRemoveDialog(true);
  }
  function handleCloseRemoveDialog() {
    setIsRemoveDialog(false);
  }
  function handleAgreeRemoveDialog() {
    removeOperatorReq({
      operatorId,
      history,
    });
    setIsRemoveDialog(false);
  }
  function onWatchActionsBtnClick() {
    history.push(PATH_PAGES.operatorActions.replace(':operatorId', operatorId));
  }
  function onAddOrSaveBtnClick(e) {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    const { platforms } = allPlatforms || {};
    const allPlatformsData = (platforms || {}).data || [];

    if (isNewOperatorMode) {
      addNewOperatorReq({
        ...settings,
        allPlatformsData,
        history,
      });

      return;
    }

    updOperatorReq({
      ...settings,
      allPlatformsData,
      operatorId,
    });
  }

  return (
    <Container
      className={classes.container}
      maxWidth={false}
    >
      <HeaderWithBackBtn
        title={isNewOperatorMode ? t('operators.addOperator') : t('operators.operator')}
      />

      {!isLoading && (
        <>
          <form
            className={classes.form}
            onSubmit={onAddOrSaveBtnClick}
            autoComplete="off"
          >
            <Grid className={classes.pageBody} container spacing={2}>
              <Grid item className={classes.bodyMain} xs={12} sm={8}>
                <CustomTextInput
                  className={classes.inputItem}
                  required
                  error={errors.nameAndRole}
                  name="nameAndRole"
                  label={t('operators.nameAndRole')}
                  value={nameAndRole}
                  autoComplete="off"
                  inputProps={{
                    maxLength: 250,
                  }}
                  onChange={handleChanges}
                />
                <div className={classes.twoInput}>
                  <CustomTextInput
                    className={isCreateUpdOperators
                      ? classes.twoInputItem
                      : classes.twoInputItemFullWidth}
                    required
                    error={errors.login}
                    name="login"
                    inputProps={{
                      maxLength: 100,
                    }}
                    type="text"
                    label={t('operators.login')}
                    value={login}
                    autoComplete="new-login"
                    onChange={handleChanges}
                  />
                  {isCreateUpdOperators && (
                    <CustomTextInput
                      className={classes.twoInputItem}
                      required={isNewOperatorMode}
                      error={errors.password}
                      name="password"
                      label={isNewOperatorMode ? t('operators.password') : t('operators.newPassword')}
                      type="passwordMightVisible"
                      value={password}
                      autoComplete="new-password"
                      inputProps={{
                        minLength: 8,
                        maxLength: 32,
                      }}
                      onChange={handleChanges}
                    />
                  )}
                </div>
                <CustomTextInput
                  className={classes.inputItem}
                  error={errors.comment}
                  name="comment"
                  label={t('operators.comment')}
                  value={comment}
                  autoComplete="off"
                  multiline
                  onChange={handleChanges}
                />
                <ItemSelectors
                  classNameForm={classes.statusSelectorsForm}
                  className={classes.statusSelectors}
                  classNameLabel={classes.statusSelectorsLabel}
                  label={t('operators.status')}
                  error={!!errors.status}
                  name="status"
                  currentValue={status}
                  items={statusList}
                  onChange={handleChanges}
                />

                <Divider className={classes.divider} />

                <Grid container className={classes.makeAdminContainer}>
                  <Grid item sm={3} xs={6} style={{ maxWidth: 157 }}>
                    <Typography
                      className={classes.makeAdminStr}
                    >
                      {t('operators.admin')}
                    </Typography>
                  </Grid>
                  <Grid item sm={9} xs={6}>
                    <Switch
                      name="adminStatus"
                      className={classes.switch}
                      checked={adminStatus}
                      onChange={handleChanges}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                </Grid>

                {!adminStatus && (
                  <Grid container className={classes.makeAdminContainer}>
                    <Grid item sm={3} xs={6} style={{ maxWidth: 157 }}>
                      <Typography
                        className={classes.makeAdminStr}
                      >
                        {t('operators.accessRights')}
                      </Typography>
                    </Grid>
                    <Grid item sm={9} xs={6}>
                      <CustomBtn
                        btnType="secondary"
                        className={classes.btnSettings}
                        onClick={onSettingsBtnClick}
                      >
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {isAdminSettings
                          ? t('operators.hide')
                          : isCreateUpdOperators
                            ? t('operators.tune')
                            : t('operators.show')}
                      </CustomBtn>
                    </Grid>
                  </Grid>
                )}

                {(!isAdminSettings || adminStatus) && (
                  <Divider className={classes.divider} />
                )}
              </Grid>
              <Grid item className={classes.bodyInfo} xs={12} sm={4}>
                {!isNewOperatorMode && isReadOperatorsActions && (
                  <UserLogSummary
                    actionsList={actionsList}
                    isActionsLoading={isActionsLoading}
                    onWatchActionsBtnClick={onWatchActionsBtnClick}
                  />
                )}
              </Grid>
            </Grid>

            <Collapse
              in={isAdminSettings && !adminStatus}
              timeout="auto"
              unmountOnExit
            >
              <OperatorSettingsTable {...{
                rightList,
                allPlatforms,
                ruleList,
                rules,
                updateSettings,
              }}
              />
            </Collapse>
            {isCreateUpdOperators && (
              <Grid container className={classes.makeAdminContainer}>
                <Grid item xs={12} sm={8}>
                  <Grid container className={classes.makeAdminContainer}>
                    <Grid
                      item
                      sm={isNewOperatorMode ? 12 : 3}
                      style={isNewOperatorMode ? {} : { maxWidth: 157 }}
                    >
                      <CustomBtn
                        type="submit"
                        className={classes.btnAddOrSave}
                        disabled={isSaveBtnDisabled}
                      >
                        {t('operators.save')}
                      </CustomBtn>
                    </Grid>
                    {!isNewOperatorMode && isRightToDelete && (
                      <Grid item sm={9}>
                        <CustomBtn
                          className={classes.btnRemove}
                          onClick={openRemoveDialog}
                        >
                          {t('operators.removeOperator')}
                        </CustomBtn>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} />
              </Grid>
            )}
          </form>
          <CustomDialog
            open={isRemoveDialog}
            handleAgree={handleAgreeRemoveDialog}
            handleClose={handleCloseRemoveDialog}
            dialogContent={t('operators.operatorRemoveQuestion')}
            agreeTitle={t('operators.remove')}
            disagreeTitle={t('others.cancel')}
          />
        </>
      )}
      {isLoading && (
        <CircularIndeterminate style={{ minHeight: 600 }} />
      )}
    </Container>
  );
}

OneOperator.propTypes = propTypes;

function mapStateToProps(state) {
  const { platform, operators } = state || {};
  const { allPlatforms, onePlatform } = platform || {};
  const {
    addNewOperator,
    oneOperator,
    updateOperator,
    actionsOneOperator,
    rightList,
    currentOperator,
  } = operators || {};

  return {
    allPlatforms,
    onePlatform,
    addNewOperator,
    oneOperator,
    updateOperator,
    actionsOneOperator,
    rightList,
    currentOperator,
  };
}

const ConnectedOneOperator = connect(
  mapStateToProps,
  {
    getOneOperatorsReq: getOneOperatorAction,
    addNewOperatorReq: addNewOperatorAction,
    updOperatorReq: updateOperatorAction,
    removeOperatorReq: removeOperatorAction,
    getOperatorActionsReq: getActionsOneOperatorAction,
    getListFrontOfficeRightsReq: getListFrontOfficeRightsAction,
  },
)(OneOperator);

export default ConnectedOneOperator;
