import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  viewer: {

  },
  image: {
    cursor: 'pointer',
  },
}));

export default {
  useStyles,
};
