import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/src/constants';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import BackIcon from '@parkly/shared/components/atoms/icons/BackIcon';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';
import ForOrBackWord from '@parkly/shared/components/atoms/icons/ForOrBackWord';

import { useStyles } from './styles';
import './react_dates_overrides.css';

const propTypes = {
};

function CustomRangeDatePicker({
  initialStartDate,
  initialEndDate,
  autoFocusEndDate,
  renderCalendarInfo: renderCalendarInfoProp,
  daysViolatingMinNightsCanBeClicked,
  minimumNights = 0,
  onUpdate = () => {},
}) {
  const classes = useStyles();
  const [state, setState] = useState({
    errorMessage: null,
    focusedInput: autoFocusEndDate ? END_DATE : START_DATE,
    startDate: initialStartDate,
    endDate: initialEndDate,
    showDayPickerRangeController: false,
  });

  const {
    errorMessage,
    focusedInput,
    startDate,
    endDate,
    showDayPickerRangeController,
    anchorEl,
  } = state;

  const startDateString = startDate && startDate.format('YYYY.MM.DD');
  const endDateString = endDate && endDate.format('YYYY.MM.DD');
  const renderCalendarInfo = errorMessage
    ? () => <div>{errorMessage}</div>
    : renderCalendarInfoProp;

  function updState(newState = {}) {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }

  function onFocusChange(newFocusedInput) {
    updState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !newFocusedInput ? START_DATE : newFocusedInput,
    });
  }

  function onOutsideClick() {
    updState({
      showDayPickerRangeController: false,
    });

    onUpdate({
      startDate: (state || {}).startDate,
      endDate: (state || {}).endDate,
    });
  }

  function onInputClick(event) {
    updState({
      showDayPickerRangeController: true,
      anchorEl: event.currentTarget,
    });
  }

  function onDatesChange({ startDate: newStartDate, endDate: newEndDate }) {
    let doesNotMeetMinNights = false;
    if (daysViolatingMinNightsCanBeClicked && newStartDate && newEndDate) {
      const dayDiff = newEndDate.diff(newStartDate.clone().startOf('day').hour(12), 'days');
      doesNotMeetMinNights = dayDiff < minimumNights && dayDiff >= 0;
    }
    updState({
      startDate: newStartDate,
      endDate: doesNotMeetMinNights ? null : newEndDate,
      errorMessage: doesNotMeetMinNights
        ? 'Не соблюдается минимальный период'
        : null,
    });

    /* if (newStartDate && newEndDate) {
      onOutsideClick();
    } */
  }

  return (
    <div className={classes.container}>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div
        className={classes.label}
        onClick={onInputClick}
      >
        <Typography
          className={[classes.startDate, startDateString ? '' : classes.patern].join(' ')}
        >
          {startDateString || 'гггг.мм.дд'}
        </Typography>
        <BackIcon
          stroke="#7E8AA7"
          isForward
        />
        <Typography className={[classes.endDate, endDateString ? '' : classes.patern].join(' ')}>
          {endDateString || 'гггг.мм.дд'}
        </Typography>
        <IconButton
          className={classes.calendarIconBtn}
        >
          <CalendarIcon className={classes.calendarIcon} />
        </IconButton>

      </div>
      <Popover
        className={classes.container}
        open={showDayPickerRangeController}
        anchorEl={anchorEl}
        onClose={onOutsideClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DayPickerRangeController
          onDatesChange={onDatesChange}
          onFocusChange={onFocusChange}
          hideKeyboardShortcutsPanel
          numberOfMonths={2}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          minimumNights={minimumNights}
          renderCalendarInfo={renderCalendarInfo}
          daySize={36}
          navPrev={(
            <IconButton style={{
              position: 'absolute',
              top: 12,
              left: 18,
              height: 40,
              width: 42,
              padding: 0,
            }}
            >
              <ForOrBackWord isBack hideCircle />
            </IconButton>
          )}
          navNext={(
            <IconButton style={{
              position: 'absolute',
              top: 12,
              right: 18,
              height: 40,
              width: 42,
              padding: 0,
            }}
            >
              <ForOrBackWord hideCircle />
            </IconButton>
          )}
        />
      </Popover>
    </div>
  );
}

CustomRangeDatePicker.propTypes = propTypes;

export default CustomRangeDatePicker;
