import moment from 'moment-timezone';

export function updateRehydratedPersisit(storeState, key) {
  const state = storeState || {};

  switch (key) {
  case 'auth':
    return {
      ...state,
      loading: false,
    };

  case 'platform':
    return {
      ...state,
      allPlatforms: {
        ...(state.allPlatforms || {}),
        loading: false,
      },
      onePlatform: {
        ...(state.onePlatform || {}),
        loading: false,
      },
      platformSchedules: {
        ...(state.platformSchedules || {}),
        loading: false,
      },
      updPlatformScheduleRes: {
        ...(state.updPlatformScheduleRes || {}),
        list: [],
        loading: false,
      },
    };

  case 'bookings':
    return {
      ...state,
      platformBookings: {
        ...(state.platformBookings || {}),
        loading: false,
      },
    };

  case 'operators':
    return {
      ...state,
      actionsOneOperator: {
        ...(state.actionsOneOperator || {}),
        loading: false,
      },
      addNewOperator: {
        ...(state.addNewOperator || {}),
        loading: false,
      },
      currentOperator: {
        ...(state.currentOperator || {}),
        loading: false,
      },
      oneOperator: {
        ...(state.oneOperator || {}),
        loading: false,
      },
      operators: {
        ...(state.operators || {}),
        loading: false,
      },
      removeOperator: {
        ...(state.removeOperator || {}),
        loading: false,
      },
      rightList: {
        ...(state.rightList || {}),
        loading: false,
      },
      updateOperator: {
        ...(state.updateOperator || {}),
        loading: false,
      },
    };

  default:
    return state;
  }
}

/* eslint-disable */
export const deepDiffMapper = (function () {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    map(obj1, obj2) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw 'Invalid argument. Function given, object expected.';
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          data: obj1 === undefined ? obj2 : obj1,
        };
      }

      const diff = {};
      for (var key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }

        let value2;
        if (obj2[key] !== undefined) {
          value2 = obj2[key];
        }

        diff[key] = this.map(obj1[key], value2);
      }
      for (var key in obj2) {
        if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
          continue;
        }

        diff[key] = this.map(undefined, obj2[key]);
      }

      return diff;
    },
    compareValues(value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED;
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED;
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction(x) {
      return Object.prototype.toString.call(x) === '[object Function]';
    },
    isArray(x) {
      return Object.prototype.toString.call(x) === '[object Array]';
    },
    isDate(x) {
      return Object.prototype.toString.call(x) === '[object Date]';
    },
    isObject(x) {
      return Object.prototype.toString.call(x) === '[object Object]';
    },
    isValue(x) {
      return !this.isObject(x) && !this.isArray(x);
    },
  };
}());
/* eslint-enable */

export function transformPassCode(code) {
  if (!code) {
    return '';
  }

  const newCode = code.toString()
    .match(/.{1,3}/g)
    .join(' ');

  return newCode;
}

export default {
  transformPassCode,
  updateRehydratedPersisit,
  deepDiffMapper,
};
