import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

import InfoIconMini from '@parkly/shared/components/atoms/icons/InfoIconMini';

import { useStylesTimeScheduleEditor } from './styles';

/* help functions */

function roundTime(time) {
  if (!time) {
    return '';
  }

  const timeSplited = time.split(':');
  const hour = parseInt(timeSplited[0] || '00', 10);
  const minutes = parseInt(timeSplited[1] || '00', 10);
  const roundMinutes = (parseInt((minutes + 7.5) / 15, 10) * 15) % 60;
  const rMinutesStr = roundMinutes < 10 ? `0${roundMinutes}` : roundMinutes;
  const roundHour = (minutes > 52 ? hour + 1 : hour) % 24;
  const rHourStr = roundHour < 10 ? `0${roundHour}` : roundHour;

  return `${rHourStr}:${rMinutesStr}`;
}

function CustomTimePicker({
  type = 'day',
  isDisabled = false,
  timeStart,
  timeEnd,
  defTimeStartStr,
  defTimeEndStr,
  errors,
  updateSettings = () => {},
  resetToDefault = () => {},
}) {
  const classes = useStylesTimeScheduleEditor();
  const { t } = useTranslation();
  const baseTimeStr = `${t('platforms.baseSchedule')}: ${defTimeStartStr} – ${defTimeEndStr}`;

  const getClassCntTime = (isError) => `${classes.timeContainer} ${isError ? classes.error : ''}`;

  function handleSettingsChange(event) {
    updateSettings(event.target.name, event.target.value || event.target.checked);
  }
  function onBlur() {
    const roundedTimeStartStr = roundTime(timeStart);
    if (timeStart !== roundedTimeStartStr) {
      updateSettings('timeStart', roundedTimeStartStr);
    }

    const roundedTimeEndStr = roundTime(timeEnd);
    if (timeEnd !== roundedTimeEndStr) {
      updateSettings('timeEnd', roundedTimeEndStr);
    }
  }

  const timeChanger = (
    <div className={classes.timesContainer}>
      <div className={getClassCntTime((errors || {}).timeStart)}>
        <TextField
          name="timeStart"
          required
          error={(errors || {}).timeStart}
          disabled={isDisabled}
          className={classes.textFieldTime}
          onChange={handleSettingsChange}
          label={t('others.from')}
          type="time"
          value={timeStart}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 180, // 5 min
          }}
          onBlur={onBlur}
        />
      </div>
      <div className={classes.textFieldDivider} />
      <div className={getClassCntTime((errors || {}).timeEnd)}>
        <TextField
          name="timeEnd"
          required
          error={(errors || {}).timeEnd}
          disabled={isDisabled}
          className={classes.textFieldTime}
          onChange={handleSettingsChange}
          label={t('others.before')}
          type="time"
          value={timeEnd}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          onBlur={onBlur}
        />
      </div>
    </div>
  );

  if (type === 'default') {
    return (
      <div className={classes.container}>
        {timeChanger}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.baseContainer}>
        <InfoIconMini />
        <Typography className={classes.base}>
          {baseTimeStr}
        </Typography>
      </div>

      {timeChanger}

      <div className={classes.makeDayOffContainer}>
        <Typography>
          {t('platforms.makeThisDayOff')}
        </Typography>
        <Switch
          name="isDisabled"
          className={classes.switch}
          checked={isDisabled}
          onChange={handleSettingsChange}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>

      <Button
        className={classes.btnLikeLink}
        onClick={resetToDefault}
      >
        {t('platforms.bakeToDefaultSchedule')}
      </Button>

    </div>
  );
}

export default CustomTimePicker;
