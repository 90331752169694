/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Pagination from '@material-ui/lab/Pagination';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import OperatorActionsHeader from '@parkly/shared/components/organisms/OperatorActionsHeader';

import { checkDateValid } from '@parkly/shared/helpers/time';
import { getActionsList } from '@parkly/shared/helpers/others';

import { useRowStyles, useStyles } from './styles';


function stringifyValue(value) {
  const stringifyVal = JSON.stringify(value, null, 2);
  const valueStr = stringifyVal
    .replace(/\{/g, '')
    .replace(/\}/g, '')
    .replace(/\[/g, '')
    .replace(/\]/g, '');

  return valueStr;
}

function Row(props) {
  // const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const {
    row,
    isLast,
  } = props || {};
  const {
    date,
    title,
    operatorName,
    newValues,
    oldValues,
  } = row || {};

  const dateStr = date.format('DD.MM.YYYY, HH:mm');

  // const oldValueStr = stringifyValue(oldValues);
  // const newValuesStr = stringifyValue(newValues);

  return (
    <>
      <TableRow
        className={`${classes.root} ${classes.descriptionTableRow}`}
        // className={`classes.root}
        // onClick={() => setOpen(!open)}
      >
        <TableCell className={`${classes.tableCell}`}>
          <Typography className={classes.dateStr}>
            {dateStr}
          </Typography>
        </TableCell>
        <TableCell className={`${classes.tableCell}`}>
          <Typography className={classes.operatorNameStr}>
            {operatorName}
          </Typography>
        </TableCell>
        <TableCell className={`${classes.tableCell}`}>
          <Typography className={classes.nameStr}>
            {title}
          </Typography>
        </TableCell>
        {/*<TableCell className={`${classes.tableCell}`} align="right">*/}
        {/*  <IconButton*/}
        {/*    aria-label="expand row"*/}
        {/*    size="small"*/}
        {/*    onClick={() => setOpen(!open)}*/}
        {/*  >*/}
        {/*    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}*/}
        {/*  </IconButton>*/}
        {/*</TableCell>*/}
      </TableRow>
      <TableRow
        className={`${classes.descriptionTableRow} ${isLast ? classes.lastRow : ''}`}
      >
        <TableCell
          className={classes.descriptionCell}
          colSpan={6}
        >
          {/*<Collapse in={open} timeout="auto" unmountOnExit>*/}
          {/*  <Box margin={1} className={classes.descriptionBox}>*/}
          {/*    <ReactDiffViewer*/}
          {/*      oldValue={oldValueStr}*/}
          {/*      newValue={newValuesStr}*/}
          {/*      hideLineNumbers*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*</Collapse>*/}
        </TableCell>
      </TableRow>
    </>
  );
}

/* help functions */

function getInitSettings({
  query,
}) {
  const operatorId = query.get('id');
  const isUrlId = !!operatorId || operatorId === 0;

  const selOperatorId = isUrlId ? operatorId : 0;

  return {
    selOperatorId,
    selDate: null,
    page: 1,
    maxPage: 1,
  };
}

function prepareParam({
  settings,
}) {
  const { selOperatorId, selDate, page } = settings;

  const isSelDateValid = checkDateValid(selDate);
  const reqParam = {
    id: selOperatorId,
    page,
    perPage: 20,
    date: isSelDateValid ? selDate : undefined,
  };

  return reqParam;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const propTypes = {};

function OperatorActionsPage({
  actionsOneOperator,
  getOperatorActionsReq = () => {},
  operators,
  getOperatorsReq = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();

  const [settings, setSettings] = useState(getInitSettings({
    operators,
    query,
  }));
  const [prevParams, setPrevParams] = useState(JSON.stringify(prepareParam({
    settings: {},
  })));

  useEffect(() => getOperatorsReq(), [getOperatorsReq]);

  useEffect(
    () => {
      const reqParam = prepareParam({
        settings,
      });

      const stringifyParam = JSON.stringify(reqParam);

      if (stringifyParam !== prevParams) {
        getOperatorActionsReq(reqParam);

        setPrevParams(stringifyParam);
      }
    },
    // eslint-disable-next-line
    [settings],
  );

  const {
    selOperatorId,
    selDate,
  } = settings;

  const allOperatorsData = (operators || {}).data || [];

  const allOperatorsList = [
    { id: 0, name: 'Все операторы' },
    ...allOperatorsData,
  ];

  const {
    actionsList,
    page,
    maxPage,
  } = useMemo(
    () => getActionsList({
      actionsOneOperator,
      selOperatorId,
      t,
    }),
    // eslint-disable-next-line
    [actionsOneOperator, selOperatorId],
  );

  const isHeaderLoading = (operators || {}).loading;
  const isLoading = (actionsOneOperator || {}).loading || isHeaderLoading;

  const isZeroActions = actionsList.length === 0;

  function updateSettings(name, value) {
    if (name === 'selOperatorId') {
      if (value) {
        query.set('id', value)
      } else {
        query.delete('id')
      }
      history.replace({
        search: '?' + query.toString()
      })
    }

    setSettings((oldSettings) => ({
      ...oldSettings,
      page: 1,
      [name]: value,
    }));
  }
  function onPageChange(_, pageNumber) {
    updateSettings('page', pageNumber);
  }

  return (
    <Container
      className={classes.container}
    >
      <HeaderWithBackBtn
        title={t('operators.actions')}
      />
      <div className={classes.pageBody}>
        {!isHeaderLoading && (
          <OperatorActionsHeader {...{
            allOperatorsList,
            selOperatorId,
            selDate,
            updateSettings,
          }}
          />
        )}
        {!isLoading && (
          <>
            {isZeroActions && (
              <div className={classes.actionContainer}>
                <Typography
                  className={classes.actionTitle}
                >
                  {t('operators.noActionsYet')}
                </Typography>
              </div>
            )}
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table aria-label="collapsible table" className={classes.table}>
                <TableBody className={classes.tableBody}>
                  {actionsList.map(
                    (row, index, array) => (
                      <Row
                        key={row.id}
                        row={row}
                        isLast={index === (array.length - 1)}
                      />
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={classes.paginationContainer}>
              {maxPage > 1 && (
                <Pagination
                  className={classes.pagination}
                  name="page"
                  page={page}
                  onChange={onPageChange}
                  count={maxPage}
                  color="primary"
                />
              )}
            </div>
          </>
        )}
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
      </div>
    </Container>
  );
}
OperatorActionsPage.prototype = propTypes;

export default OperatorActionsPage;
