import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  containerPage: {
    padding: 0,
    maxWidth: 'unset',
  },
  container: {
    marginTop: 35,
    padding: '0 0.75px',
    borderRadius: 6,
    borderBottom: `1px solid ${theme.palette.greyC.buttonGrey}`,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  leftHeader: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  rightHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  monthSelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  prevNextBtnsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  table: {

  },
  header: {
    padding: 0,
  },
  headerSubContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: 6,
    backgroundColor: theme.palette.greyC.lightGrey,
    '&:hover': {
      backgroundColor: theme.palette.greyC.gray,
    },
    borderRadius: 0,
    textTransform: 'none',
  },
  headerFirst: {
    borderRadius: '6px 0 0 0',
  },
  headerLast: {
    borderRadius: '0 6px 0 0',
  },
  headerWeekRule: {
    backgroundColor: theme.palette.greenC.green1,
    '&:hover': {
      backgroundColor: theme.palette.greenC.green8,
    },
    '& .MuiTouchRipple-rippleVisible': {
      color: theme.palette.greenC.green9,
    },
  },
  headerWeekRuleDayOff: {
    backgroundColor: theme.palette.redC.red1,
    '&:hover': {
      backgroundColor: theme.palette.redC.red5,
    },
    '& .MuiTouchRipple-rippleVisible': {
      color: theme.palette.redC.red6,
    },
  },
  noEnoughRight: {
    cursor: 'default',
  },
  headerStr: {
    color: theme.palette.greyC.darkGrey,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  headerIcon: {
    position: 'absolute',
    top: 0,
    right: 10,
  },
  emptyTableCell: {
    minWidth: 156,
    minHeight: 136,
  },
  dayTableCell: {
    minWidth: 156,
    height: 136,
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
  },

  visible: {
    visibility: 'visible',
  },
  hidden: {
    visibility: 'hidden',
  },
  changeIconBtnDayOff: {
    '& path': {
      stroke: theme.palette.redC.red2,
    },
  },
  changeIconBtnWeekRule: {
    '& path': {
      stroke: theme.palette.greenC.green2,
    },
  },
  chartContainer: {
    width: 1097,
    minHeight: 425,
    marginTop: 35,
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderRadius: 8,
  },
  tabPanel: {},
}));

export default {
  useStyles,
};
