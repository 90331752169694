import React from 'react';

function UndergroundParkingIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.223 3.053a.75.75 0 10-1.36-.634L2.07 6.264a.75.75 0 00.363.997l3.845 1.793a.75.75 0 00.634-1.36L4.708
        6.668l8.635-3.143a.75.75 0 00-.513-1.41L4.195 5.257l1.028-2.204zm8.249 7.155a.75.75 0 00-.496-1.416l-10
        3.5a.75.75 0 00.496 1.416l10-3.5z"
        fill="#72809F"
      />
    </svg>
  );
}

export default UndergroundParkingIcon;
