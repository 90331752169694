import React from 'react';
import PropTypes from 'prop-types';

import CongestionBarChart from 'components/organisms/CongestionBarChart';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import { CHART_CONTAINER_ID } from '@parkly/shared/config/constants';

import { useStyles } from './styles';

const propTypes = {
  analyticsData: PropTypes.shape({
    data: PropTypes.array,
  }),
  isLoading: PropTypes.bool,
  onAlertClick: PropTypes.func,
};

const CongestionAnalyticsChart = React.memo(({
  analyticsData = {},
  isLoading,
  onAlertClick = () => {},
}) => {
  const classes = useStyles();
  const { data: analyticsDataList = [] } = analyticsData || {};

  return (
    <div
      className={classes.chartContainer}
      id={CHART_CONTAINER_ID}
    >
      <CongestionBarChart
        barChartData={analyticsDataList}
        onAlertClick={onAlertClick}
      />
      {isLoading && (analyticsDataList.length < 1) && (
        <CircularIndeterminate style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: 400,
          width: 1060,
        }}
        />
      )}
    </div>
  );
});

CongestionAnalyticsChart.propTypes = propTypes;

export default CongestionAnalyticsChart;
