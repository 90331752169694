import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  changeIconBtnDayOff: {
    '& path': {
      stroke: theme.palette.redC.red2,
    },
  },
  changeIconBtnWeekRule: {
    '& path': {
      stroke: theme.palette.greenC.green2,
    },
  },
  visible: {
    visibility: 'visible',
  },
  hidden: {
    visibility: 'hidden',
  },
  headerIcon: {
    position: 'absolute',
    top: 0,
    right: 10,
  },
  headerSubContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: 6,
    backgroundColor: theme.palette.greyC.lightGrey,
    '&:hover': {
      backgroundColor: theme.palette.greyC.gray,
    },
    borderRadius: 0,
    textTransform: 'none',
  },
  noEnoughRight: {
    cursor: 'default',
  },
  headerFirst: {
    borderRadius: '6px 0 0 0',
  },
  headerLast: {
    borderRadius: '0 6px 0 0',
  },
  headerWeekRule: {
    backgroundColor: theme.palette.greenC.green1,
    '&:hover': {
      backgroundColor: theme.palette.greenC.green8,
    },
    '& .MuiTouchRipple-rippleVisible': {
      color: theme.palette.greenC.green9,
    },
  },
  headerWeekRuleDayOff: {
    backgroundColor: theme.palette.redC.red1,
    '&:hover': {
      backgroundColor: theme.palette.redC.red5,
    },
    '& .MuiTouchRipple-rippleVisible': {
      color: theme.palette.redC.red6,
    },
  },
  container: {
    marginTop: 35,
    padding: '0 0.75px',
    borderRadius: 6,
    borderBottom: `1px solid ${theme.palette.greyC.buttonGrey}`,
  },
  table: {},
  header: {
    padding: 0,
  },
  headerStr: {
    color: theme.palette.greyC.darkGrey,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },

}));

export default {
  useStyles,
};
