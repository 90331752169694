import React from 'react';

function AnalyticsIcon({
  size = 32,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M6 19.5h4V26H6v-6.5zM14 13h4v13h-4V13zM22 6h4v20h-4z"
        stroke="#FFFFFF"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AnalyticsIcon;
