import React from 'react';

function OperatorsIcons({
  fill='#fff',
  size = 32,
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" {...props}>
      <circle cx={11} cy={11.667} r={3} stroke={fill} strokeWidth={1.5} />
      <circle
        cx={21.667}
        cy={11.667}
        r={3}
        stroke={fill}
        strokeWidth={1.5}
      />
      <path
        d="M14.75 22A4.25 4.25 0 0119 17.75h5A4.25 4.25 0 0128.25 22v1c0 .69-.56 1.25-1.25 1.25H16c-.69 0-1.25-.56-1.25-1.25v-1z"
        stroke={fill}
        strokeWidth={1.5}
      />
      <path
        d="M13.5 18.5h-5A3.5 3.5 0 005 22v1a.5.5 0 00.5.5h9.603l.866 1.5H5.5a2 2 0 01-2-2v-1a5 5 0 015-5h5c1.31 0 2.502.504 3.394 1.328l-1.144.991a3.486 3.486 0 00-2.25-.819z"
        fill={fill}
      />
    </svg>
  );
}

export default OperatorsIcons;
