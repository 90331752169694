import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { SUBSCRIPTION_STATUS_KEYS } from 'config/constants';

import { useStyles } from './styles';

/* help function */

function getHeaders({
  t,
  isShowFacilityName,
}) {
  const headers = [
    isShowFacilityName ? {
      id: 'facilityName',
      label: t('subscriptions.facilityName'),
    } : null,
    {
      id: 'tariffName',
      label: t('subscriptions.tariff'),
    },
    {
      id: 'startTime',
      label: t('subscriptions.startTime'),
    },
    {
      id: 'finishTime',
      label: t('subscriptions.finishTime'),
    },
    {
      id: 'status',
      label: t('subscriptions.status'),
    },
    {
      id: 'car',
      label: t('subscriptions.car'),
    },
    {
      id: 'userName',
      label: t('subscriptions.user'),
    },
  ];

  return headers.filter((item) => !!item);
}

function getStatusStr({ t, status }) {
  switch (status) {
    case SUBSCRIPTION_STATUS_KEYS.paid:
      return t('subscriptions.paidStatus');
    case SUBSCRIPTION_STATUS_KEYS.active:
    case SUBSCRIPTION_STATUS_KEYS.stopped:
      return t('subscriptions.activeStatus');
    case SUBSCRIPTION_STATUS_KEYS.finished:
      return t('subscriptions.finishedStatus');
  }
}

function getRows(subscriptionsData = []) {
  const result = subscriptionsData.map((item) => {
    const {
      id,
      startTime,
      finishTime,
      status,
      tariffName,
      userName,
      carBrand,
      carNumber,
      facilityName,
    } = item;

    return {
      id,
      startTime: moment(startTime),
      finishTime: moment(finishTime),
      tariffName,
      userName,
      status,
      carBrand,
      carNumber,
      facilityName,
    };
  });

  return result;
}

const propTypes = {
  subscriptionsData: PropTypes.arrayOf(PropTypes.object),
  isShowFacilityName: PropTypes.bool,
};

function SubscriptionsTable({
  subscriptionsData,
  isShowFacilityName = false,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const headers = getHeaders({ t, isShowFacilityName });

  const rows = getRows(subscriptionsData);
  const isSubscriptionsData = subscriptionsData && (subscriptionsData || []).length > 0;

  return (
    <Paper className={classes.root}>
      {!isSubscriptionsData && (
        <div className={classes.noDataContainer}>
          <Typography
            className={classes.noDataStr}
          >
            {t('subscriptions.noDataYet')}
          </Typography>
        </div>
      )}
      {isSubscriptionsData && (
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id }) => (
                  <TableCell
                    key={id}
                    align="left"
                  >
                    <div className={classes.headerContainer}>
                      <Typography className={classes.headerStr}>
                        {label}
                      </Typography>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index, array) => {
                const {
                  id,
                  startTime,
                  finishTime,
                  tariffName,
                  userName,
                  status,
                  carBrand,
                  carNumber,
                  facilityName,
                } = row || {};
                const key = id;

                const dateInTimeStr = startTime.isValid() ? startTime.format('HH:mm') : '-';
                const dateInDateStr = startTime.isValid() ? startTime.format('DD.MM.yyyy') : '-';
                const dateOutTimeStr = finishTime.isValid() ? finishTime.format('HH:mm') : '-';
                const dateOutDateStr = finishTime.isValid() ? finishTime.format('DD.MM.yyyy') : '-';

                const isLast = index === (array.length - 1);

                const tableRowClass = [
                  classes.tableRow,
                  isLast ? classes.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                    hover
                  >
                    {isShowFacilityName && (
                      <TableCell
                        align="left"
                      >
                        <Typography className={classes.tariffNameStr}>
                          {facilityName || '-'}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.tariffNameStr}>
                        {tariffName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.timeStr}>
                        {dateInTimeStr}
                      </Typography>
                      <Typography className={classes.dateStr}>
                        {dateInDateStr}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.timeStr}>
                        {dateOutTimeStr}
                      </Typography>
                      <Typography className={classes.dateStr}>
                        {dateOutDateStr}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.statusStr}>
                        {getStatusStr({ t, status })}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.carBrand}>
                        {carBrand || '-'}
                      </Typography>
                      <Typography className={classes.carNumberStr}>
                        {carNumber || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.nameStr}>
                        {userName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

SubscriptionsTable.propTypes = propTypes;

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state) {
  return {};
}

const ConnectedBookingsTable = connect(
  mapStateToProps,
  {},
)(SubscriptionsTable);

export default ConnectedBookingsTable;
