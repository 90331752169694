import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import 'moment/locale/ru';

import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomDatePicker from '@parkly/shared/components/atoms/CustomDatePicker';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';

import { useStyles } from './styles';

const propTypes = {

};

function MobilePaymentSelectors({
  allPlatforms = {},
  selDate = null,
  selPlatformValue = 'unselect',
  searchValue,
  handleParamChange = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { platforms = {} } = allPlatforms || {};

  function handleChanges(event) {
    const propName = event.target.name;
    const { value } = event.target;
    handleParamChange(propName, value);
  }
  function handleDateChanges(date) {
    handleParamChange('selDate', date);
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item md={4} sm={6} xs={12}>
        <ItemSelectors
          name="selPlatformValue"
          selectorsType="secondary"
          currentValue={selPlatformValue}
          items={(platforms || {}).data || []}
          defaultItem={t('mobilePayments.platformDoNotSelect')}
          onChange={handleChanges}
          className={classes.select}
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <CustomTextInput
          type="secondary"
          className={classes.searchField}
          name="searchValue"
          placeholder={t('mobilePayments.searchPlaceholder')}
          value={searchValue}
          onChange={handleChanges}
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <CustomDatePicker
          className={classes.datePicker}
          allowKeybord
          value={selDate}
          onChange={handleDateChanges}
        />
      </Grid>
    </Grid>
  );
}

MobilePaymentSelectors.propTypes = propTypes;

export default MobilePaymentSelectors;
