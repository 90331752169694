import { toast } from 'react-toastify';
import { detectRussianLanguage } from '@parkly/shared/helpers/others';

export function getApiUrl() {
  const url = process.env.REACT_APP_API_URL;
  const useOnlyHttps = process.env.REACT_APP_USE_ONLY_HTTPS === 'true';

  // eslint-disable-next-line prefer-destructuring
  const protocol = useOnlyHttps
    ? 'https:'
    : window.location.protocol;

  return `${protocol}//${url}`;
}

function getErrorMsgFromList(errors) {
  if (!errors) {
    return null;
  }

  const errorsList = Object.values(errors || {});

  // eslint-disable-next-line consistent-return
  const errMsgList = errorsList.find((msgList) => {
    const msgStr = (msgList || []).find((msg) => detectRussianLanguage(msg));
    return !!msgStr;
  });
  const msgStr = (errMsgList || []).find((msg) => detectRussianLanguage(msg));

  if (msgStr) {
    return msgStr;
  }

  return null;
}

export function getApiErrorMsg(error) {
  const { response } = error || {};
  const { status } = response || {};

  const responseData = (response || {}).data;

  const {
    errors = {},
  } = responseData || {};
  const errorMsg = getErrorMsgFromList(errors);

  if (errorMsg) {
    return errorMsg;
  }

  const {
    message = '',
  } = responseData || {};
  const isRussian = detectRussianLanguage(message);

  if (isRussian) {
    return message;
  }

  if (status === 401) {
    return 'Ошибка авторизации';
  }

  if (!response) {
    return 'Ошибка связи';
  }

  return 'Упс! Что-то пошло не так.';
}

export function showApiErrorMsg(error) {
  const errorMsg = getApiErrorMsg(error);
  toast.error(errorMsg);
}

export default {
  getApiUrl,
  showApiErrorMsg,
};
