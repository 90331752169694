import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: 20,
    width: 20,
    cursor: 'pointer',
  },
  popper: {
    '& .MuiTooltip-tooltip': {
      fontSize: theme.typography.fontSize12,
      margin: '10px 0',
      backgroundColor: theme.palette.greyC.darkGrey,
    },
  },
}));

export default {
  useStyles,
};
