import * as React from 'react';

function SortedBigFirstIcon(props) {
  return (
    <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none">
      <path d="M3 12h3v-2H3v2zM9 9V7H3v2h6zM13 6H3V4h10v2z" fill="#7E8AA7" />
    </svg>
  );
}

export default SortedBigFirstIcon;
