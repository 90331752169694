import React from 'react';

function PhoneIcon({
  fill = '#fff',
  stroke = '#1D3262',
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <circle cx={20} cy={20} r={19.5} fill={fill} />
      <path
        d="M28 23.98v2.408a1.604 1.604 0 01-1.094 1.527 1.613 1.613 0 01-.66.079 15.941 15.941 0 01-6.943-2.465 15.672
        15.672 0 01-4.827-4.818 15.868 15.868 0 01-2.47-6.96 1.603 1.603 0 01.953-1.614 1.61 1.61 0
        01.648-.137h2.414a1.61 1.61 0 011.609 1.381c.102.77.29 1.528.563 2.256a1.602 1.602 0 01-.362
        1.694l-1.022 1.02a12.86 12.86 0 004.827 4.817l1.022-1.02a1.61 1.61 0 011.697-.36c.73.271 1.488.46
        2.26.561A1.61 1.61 0 0128 23.98z"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PhoneIcon;
