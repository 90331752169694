import { getPlatformMobilePayments } from 'api/mobilePayments';
import { GET_PLATFORM_MOBILE_PAYMENTS } from './types';

export function getPlatformMobilePaymentsAction(param) {
  const {
    platformSign,
    date,
    search,
    sortBy,
    sortDirection,
    page,
  } = param || {};

  const platformId = platformSign === 'unselect' ? undefined : platformSign;
  const apiParam = {
    id: platformId,
    date: date ? date.format('YYYY-MM-DD') : date,
    search,
    sortBy,
    sortDirection,
    page,
  };

  function getPlatformMobilePaymentsRequest(payload) {
    return { type: GET_PLATFORM_MOBILE_PAYMENTS, payload };
  }

  return (dispatch) => {
    dispatch(getPlatformMobilePaymentsRequest({
      loading: true,
    }));

    getPlatformMobilePayments(apiParam)
      .then(
        (platformMobilePayments) => {
          dispatch(getPlatformMobilePaymentsRequest({
            loading: false,
            platformMobilePayments,
            param,
          }));
        },
        (error) => {
          dispatch(getPlatformMobilePaymentsRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export default {
  getPlatformMobilePaymentsAction,
};
