/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

function RubleSignIcon({
  size = 16,
  fill = '#ADB6C9',
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M5 13v-2m0-3V3h3.5A2.5 2.5 0 0111 5.5v0A2.5 2.5 0 018.5 8H5zm0 0H3m2 0v3m0 0H3m2 0h2.5"
        stroke={fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RubleSignIcon;
