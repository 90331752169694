import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import 'moment/locale/ru';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';

import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomDatePicker from '@parkly/shared/components/atoms/CustomDatePicker';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import StatusIcon from 'components/molecules/StatusIcon';

import { STATUS_KEYS } from 'config/constants';

import { useStyles } from './styles';

const propTypes = {

};

function BookingSelectors({
  allPlatforms = {},
  selDate = null,
  selPlatformValue = 'unselect',
  selStatusValue = [],
  searchValue,
  handleParamChange = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { platforms = {} } = allPlatforms || {};

  function handleChanges(event) {
    const propName = event.target.name;
    const { value } = event.target;
    handleParamChange(propName, value);
  }
  function handleDateChanges(date) {
    handleParamChange('selDate', date);
  }

  const statusList = [
    {
      id: STATUS_KEYS.paid,
      label: 'Оплачено',
    },
    {
      id: STATUS_KEYS.rejected,
      label: 'Отклонено',
    },
    {
      id: STATUS_KEYS.notPaid,
      label: 'Не оплачено',
    },
  ];

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item md={3} sm={6} xs={12}>
        <ItemSelectors
          name="selPlatformValue"
          selectorsType="secondary"
          currentValue={selPlatformValue}
          items={(platforms || {}).data || []}
          defaultItem={t('bookings.platformDoNotSelect')}
          onChange={handleChanges}
          className={classes.select}
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <ItemSelectors
          multiple
          displayEmpty
          name="selStatusValue"
          selectorsType="secondary"
          className={classes.selectStatus}
          currentValue={selStatusValue}
          onChange={handleChanges}
          input={(
            <Input
              placeholder={t('bookings.statusDoNotSelect')}
            />
          )}
          renderValue={(selected) => {
            if (selected.length < 1) {
              return (
                <Typography className={classes.unSelectStr}>
                  {t('bookings.statusDoNotSelect')}
                </Typography>
              );
            }

            const selectedLabels = selected.map((statusKey) => {
              const current = statusList.find(({ id }) => id === statusKey);
              const { label = '' } = current || {};

              return label;
            });

            return (
              <Typography className={classes.selectedStatusStr}>
                {selectedLabels.join(', ')}
              </Typography>
            );
          }}
        >
          {statusList.map((current = {}) => {
            const { label, id } = current;

            return (
              <MenuItem
                key={id}
                value={id}
                className={classes.statusItem}
              >
                <Checkbox color="primary" checked={selStatusValue.includes(id)} />
                <div style={{
                  width: 21,
                  height: 18,
                }}
                >
                  <StatusIcon
                    statusKey={id}
                  />
                </div>
                <Typography className={classes.statusItemStr}>
                  {label}
                </Typography>
              </MenuItem>
            );
          })}
        </ItemSelectors>
      </Grid>
      <Grid item md={2} sm={6} xs={12}>
        <CustomDatePicker
          className={classes.datePicker}
          allowKeybord
          value={selDate}
          onChange={handleDateChanges}
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <CustomTextInput
          type="secondary"
          className={classes.searchField}
          name="searchValue"
          placeholder={t('bookings.idBookingsStateNumber')}
          value={searchValue}
          onChange={handleChanges}
        />
      </Grid>
    </Grid>
  );
}

BookingSelectors.propTypes = propTypes;

export default BookingSelectors;
