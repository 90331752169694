import moment from 'moment-timezone';
import humanizeDuration from 'humanize-duration';
import { plural } from './others';

moment.tz.setDefault('Europe/Moscow');

export function getNext7Days() {
  const currentDate = moment();

  const days = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= 6; i++) {
    days.push(moment(currentDate).add(i, 'days'));
  }

  return days;
}

/* eslint-disable-next-line prefer-const */
let decCache = [];
export function decOfNum(number, titles) {
  const decCases = [2, 0, 1, 1, 1, 2];

  if (!decCache[number]) {
    decCache[number] = (number % 100 > 4 && number % 100 < 20)
      ? 2
      : decCases[Math.min(number % 10, 5)];
  }
  return titles[decCache[number]];
}

export function getScheduleTimeStr(time) {
  const timeM = moment(time);
  const result = timeM.format('HH:mm');

  return result;
}

export function checkDateValid(date) {
  // eslint-disable-next-line no-underscore-dangle
  const isValid = !!date && !!((date || {})._isValid);

  return isValid;
}

export function getHumanizeDuration(milliseconds, units = ['d', 'h']) {
  return humanizeDuration(milliseconds, {
    language: 'ru',
    units,
    delimiter: ' ',
    round: true,
    spacer: ' ',
  });
}

export function getHumanizeDurationShort(milliseconds, units = ['d', 'h']) {
  return humanizeDuration(milliseconds, {
    language: 'shortRu',
    units,
    delimiter: ' ',
    round: true,
    spacer: ' ',
    languages: {
      shortRu: {
        y: () => 'г.',
        mo: () => 'м.',
        w: () => 'нед.',
        d: () => 'д',
        h: () => 'ч',
        m: () => 'м',
        s: () => 'сек.',
        ms: () => 'мсек.',
      },
    },
  });
}

export function durationFormat(milliseconds, format = '') {
  const seconds = parseInt((milliseconds / 1000) % 60, 10);
  const minutes = parseInt(((milliseconds / (1000 * 60)) % 60), 10);
  const hours = parseInt((milliseconds / (1000 * 60 * 60)) % 24, 10);
  const days = parseInt(milliseconds / (1000 * 60 * 60 * 24), 10);

  return format
    .replace('dd', days.toString().padStart(2, '0'))
    .replace('hh', hours.toString().padStart(2, '0'))
    .replace('mm', minutes.toString().padStart(2, '0'))
    .replace('ss', seconds.toString().padStart(2, '0'))

    .replace('d', days)
    .replace('h', hours)
    .replace('m', minutes)
    .replace('s', seconds)

    .replace('DD', `${days} ${plural(days, ['день', 'дня', 'дней'])}`)
    .replace('HH', `${hours} ${plural(hours, ['час', 'часа', 'часов'])}`)
    .replace('MM', `${minutes} ${plural(minutes, ['минута', 'минуты', 'минут'])}`)
    .replace('SS', `${seconds} ${plural(seconds, ['секунда', 'секунды', 'секунд'])}`)

    .replace('D', days > 0 ? `${days}д.` : '')
    .replace('H', hours > 0 ? `${hours}ч.` : '')
    .replace('M', minutes > 0 ? `${minutes}мин.` : '')
    .replace('S', seconds > 0 ? `${seconds}с.` : '');
}

export function getRoundedTime({
  hour,
  minutes,
  isRoundDown = false,
}) {
  const addMinutes = isRoundDown ? 0 : 7.5;
  const roundMinutes = (parseInt((minutes + addMinutes) / 15, 10) * 15) % 60;
  const newHour = minutes > 52 ? hour + 1 : hour;
  const roundHour = newHour % 24;

  const additionalDate = Math.trunc(newHour / 24);

  return {
    roundMinutes,
    roundHour,
    additionalDate,
  };
}

export function roundDateTime(
  dateTime,
  isRoundDown = false,
) {
  // eslint-disable-next-line no-underscore-dangle
  if ((dateTime || {})._isAMomentObject) {
    const {
      roundMinutes: nMin,
      roundHour: nHour,
      additionalDate: addDate,
    } = getRoundedTime({
      hour: dateTime.get('hour'),
      minutes: dateTime.get('minute'),
      isRoundDown,
    });

    const newDateTime = dateTime.clone();
    newDateTime.set('hour', nHour);
    newDateTime.set('minute', nMin);
    newDateTime.set('date', dateTime.get('date') + addDate);

    return newDateTime;
  }

  if (!dateTime) {
    return '';
  }

  const time = dateTime.slice(11);

  const timeSplited = time.split(':');
  const hour = parseInt(timeSplited[0] || '00', 10);
  const minutes = parseInt(timeSplited[1] || '00', 10);
  const {
    roundMinutes,
    roundHour,
    additionalDate,
  } = getRoundedTime({
    hour,
    minutes,
    isRoundDown,
  });
  const rMinutesStr = roundMinutes < 10 ? `0${roundMinutes}` : roundMinutes;
  const rHourStr = roundHour < 10 ? `0${roundHour}` : roundHour;

  const date = dateTime.slice(0, 10);
  const rDate = additionalDate > 0
    ? moment(date).add(additionalDate, 'days').format('YYYY-MM-DD')
    : date;

  return `${rDate}T${rHourStr}:${rMinutesStr}`;
}

export function checkMinMaxTimeAndGetValid({
  value,
  min,
  max,
  format = 'YYYY-MM-DDTHH:mm',
}) {
  if (!value) {
    return {
      isValid: false,
    };
  }

  const strValue = moment(value).format(format);
  const strMin = moment(min).format(format);
  const strMax = moment(max).format(format);

  if (min) {
    const isValid = strMin === strValue || moment(strMin).isBefore(
      strValue,
    );
    const nearestValidValue = isValid
      ? undefined
      : moment(min);

    if (!isValid || (isValid && !max)) {
      return {
        isValid,
        nearestValidValue,
      };
    }
  }
  if (max) {
    const isValid = strMax === strValue || moment(strMax).isAfter(
      strValue,
    );
    const nearestValidValue = isValid
      ? undefined
      : moment(max);

    return {
      isValid,
      nearestValidValue,
    };
  }

  return {
    isValid: true,
  };
}

const nativeTimeFormat = 'YYYY-MM-DDTHH:mm';
export function toNativeTimeFormat(time) {
  if (!time) {
    return '';
  }

  const result = moment(time).format(nativeTimeFormat);

  return result;
}

export default {
  checkMinMaxTimeAndGetValid,
  roundDateTime,
  getNext7Days,
  decOfNum,
  getScheduleTimeStr,
  checkDateValid,
};
