import React from 'react';

function LogoIcon({
  className,
  width = 106,
  height = 32,
  fill = '#004BF6',
  alt = 'Parkly',
  id = 'LogoIcon',
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 106 32"
      id={id}
    >
      <title id={id}>
        {alt}
      </title>
      <path
        d={'M7.906 15.91c0-2.37 1.93-4.29 4.312-4.29 2.382 0 4.312 1.92 4.312 4.29s-1.93 '
        + '4.29-4.312 4.29H7.906v-4.29z'}
        fill="#00D88A"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d={'M0 28.782V15.911C0 9.197 5.47 3.754 12.217 3.754c6.747 0 12.217 5.443 12.217 '
        + '12.157s-5.47 12.156-12.217 12.156H7.905v-3.933h4.312c4.564 0 8.264-3.682 8.264-8.'
        + '223 0-4.542-3.7-8.224-8.264-8.224-4.564 0-8.264 3.682-8.264 8.224v13.586A2.509 2.5'
        + '09 0 011.437 32H0v-3.218zM35.932 7.15c2.191 0 3.848.977 5.016 2.406l.374-2.048h3.5'
        + '93V24.67h-3.593l-.404-2.01c-1.166 1.409-2.814 2.368-4.986 2.368-4.96 0-8.983-4.002'
        + '-8.983-8.939 0-4.936 4.022-8.938 8.983-8.938zm.18 3.576c2.877 0 5.21 2.321 5.21 5.'
        + '185v.357c0 2.863-2.333 5.184-5.21 5.184-2.878 0-5.21-2.32-5.21-5.184v-.357c0-2.864'
        + ' 2.332-5.185 5.21-5.185z'}
        clipRule="evenodd"
      />
      <path
        fill={fill}
        d={'M48.508 15.551v9.12h3.952v-8.762c0-3.95 2.875-5.363 5.75-5.363h2.515V7.328c-2.516'
        + '-.358-6.827-.177-8.265 2.326l-.359-2.146h-3.593v8.043zM82.643 0h3.593v24.67h-3.593V0z'}
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d={'M67.194 0H63.6v7.15h3.594V0zm-.001 7.15H63.6v17.52h3.593v-5.325l2.235-2.107 6.39 '
        + '7.433h4.31l-8.133-9.853 8.133-7.667h-5.03l-7.905 7.374V7.151zM106 7.15h-4.312l-4.369'
        + ' 10.774-4.255-10.773h-4.312l6.642 15.521-1.246 3.07c-.542 1.34-1.733 1.789-3.096 1.7'
        + '89v3.26c4.15.381 5.833-2.69 7.141-6.032L106 7.151z'}
        clipRule="evenodd"
      />
    </svg>
  );
}

export default LogoIcon;
