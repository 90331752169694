import React from 'react';

function CameraIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <rect
        x={-0.75}
        y={0.75}
        width={7.5}
        height={7.5}
        rx={1.25}
        transform="matrix(-1 0 0 1 9.25 3.5)"
        stroke="#72809F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 7.65L13.75 5v6L10 8.35"
        stroke="#72809F"
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CameraIcon;
