import React from 'react';

function WiFiIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.494 6.164A8.363 8.363 0 018 4.1c2.028 0 3.986.735 5.506 2.064a.75.75 0 10.988-1.128A9.863 9.863 0 008
        2.6c-2.39 0-4.7.866-6.494 2.436a.75.75 0 00.988 1.128zM4.49 8.468A5.37 5.37 0 018 7.15a5.37 5.37 0 013.51
        1.318.75.75 0 10.98-1.136A6.87 6.87 0 008 5.65a6.87 6.87 0 00-4.49 1.682.75.75 0 10.98 1.136zM8 10.25c-.53
        0-1.06.193-1.52.576a.75.75 0 11-.96-1.152C6.23 9.081 7.095 8.75 8 8.75c.904 0 1.77.331 2.48.924a.75.75 0
        11-.96 1.152c-.46-.383-.99-.576-1.52-.576zm0 1.505a1 1 0 100 2h.008a1 1 0 100-2H8z"
        fill="#7E8AA7"
      />
    </svg>
  );
}

export default WiFiIcon;
