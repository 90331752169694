import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';

import ForOrBackWord from '@parkly/shared/components/atoms/icons/ForOrBackWord';
import CalendarIcon from '@parkly/shared/components/atoms/icons/CalendarIcon';

import { useStyles, useStylesNextPrevBtn } from './styles';

/* Help functions */

function getClassNames({
  className = '',
  classes = {},
  allowKeybord,
  showArrowBtns,
  error = false,
}) {
  const kdpClassList = [
    classes.keyboardDatePicker,
    allowKeybord ? '' : classes.datePickerWithoutKeyBord,
    showArrowBtns ? classes.datePickerWithArrowBtns : '',
    error ? classes.datePickerWithError : '',
    showArrowBtns ? '' : className,
  ];
  const kdpClassName = kdpClassList.join(' ');

  return {
    kdpClassName,
  };
}

/* Components */

function NextPrevIconBtn({
  className = '',
  isBack = false,
  onClick = () => {},
}) {
  const classes = useStylesNextPrevBtn();

  const classList = [
    classes.main,
    isBack ? '' : classes.isBack,
    className,
  ];
  const classNameIconBtn = classList.join(' ');

  return (
    <IconButton
      className={classNameIconBtn}
      onClick={onClick}
      aria-label={isBack ? 'back' : 'forward'}
    >
      <ForOrBackWord
        isBack={isBack}
        hideCircle
      />
    </IconButton>
  );
}

/* Main functions */

function CustomDatePicker({
  className = '',
  value = null,
  format = 'DD.MM.yyyy',
  disableToolbar = true,
  placeholder,
  variant = 'inline',
  allowKeybord = false,
  showArrowBtns = true,
  arrowBtnsStep = 'day',
  onChange = () => {},
  error = false,
  ...props
}) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const {
    kdpClassName,
  } = getClassNames({
    className,
    classes,
    allowKeybord,
    showArrowBtns,
    error,
  });

  function onClick(e) {
    if (allowKeybord) {
      const isInput = e.target.nodeName.toLowerCase() === 'input';
      if (isInput) {
        return;
      }
    }
    e.nativeEvent.target.blur();
    setIsOpen(true);
  }
  function onKeyDown(e) {
    if (allowKeybord) {
      return;
    }
    e.preventDefault();
  }
  function onClose() {
    setIsOpen(false);
  }
  function onNextBtnClick() {
    const newValue = value
      ? value.clone().add(1, arrowBtnsStep)
      : moment();
    onChange(newValue);
  }
  function onPrevBtnClick() {
    const newValue = value
      ? value.clone().add(-1, arrowBtnsStep)
      : moment();
    onChange(newValue);
  }

  const datePicker = (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="ru">
      <KeyboardDatePicker
        autoOk
        open={isOpen}
        className={kdpClassName}
        disableToolbar={disableToolbar}
        variant={variant}
        format={format}
        placeholder={placeholder || t('others.ddMmYyyy')}
        value={value}
        onChange={onChange}
        onClick={onClick}
        invalidLabel="Ошибка"
        onClose={onClose}
        InputProps={{
          onKeyDown,
        }}
        keyboardIcon={<CalendarIcon />}
        // labelFunc={(date) => {
        //   // eslint-disable-next-line no-underscore-dangle
        //   const isValid = (date || {})._isValid;
        //
        //   if (isValid) {
        //     return moment(date).format(format);
        //   }
        //
        //   // eslint-disable-next-line no-underscore-dangle
        //   const str = (date || {})._i;
        //   return str;
        // }}
        // InputAdornmentProps={{ position: 'start' }}
        invalidDateMessage="Неверный формат"
        maxDateMessage="Больше максимального"
        minDateMessage="Меньше минимального"
      />
    </MuiPickersUtilsProvider>
  );

  if (!showArrowBtns) {
    return datePicker;
  }

  const dpContainerClassList = [
    classes.dpContainer,
    className,
  ];
  const dpContainerClassName = dpContainerClassList.join(' ');

  return (
    <div className={dpContainerClassName}>
      <NextPrevIconBtn
        onClick={onPrevBtnClick}
        isBack
      />
      {datePicker}
      <NextPrevIconBtn
        onClick={onNextBtnClick}
      />
    </div>
  );
}

export default CustomDatePicker;
