import {
  GET_PLATFORM_BOOKINGS,
  SEND_COMMUNICATION_REQUEST,
} from 'actions/types';

const INITIAL_STATE = {
  platformBookings: {
    loading: false,
    list: [],
  },
  communicationRequest: {
    list: [],
  },
};

function updatePlatformBookingsList({
  list,
  newPlatformBookings,
  param,
}) {
  if (!newPlatformBookings) {
    return list;
  }

  const stringifyParam = JSON.stringify(param);

  const newList = [
    ...list
      .slice(0, 5)
      .filter((item = {}) => {
        const { strParam } = item || {};
        const isFilter = strParam !== stringifyParam;

        return isFilter;
      }),
    {
      data: newPlatformBookings,
      strParam: stringifyParam,
      timeStamp: new Date().getTime(),
    },
  ];

  return newList;
}

function updateCommunicationRequestList({
  list,
  payload,
}) {
  if (!payload) {
    return list;
  }

  const {
    reservationId,
  } = payload;

  const newList = [
    ...list
      .slice(0, 25)
      .filter((item = {}) => {
        const { reservationId: currentReservationId } = item || {};
        const isFilter = currentReservationId !== reservationId;

        return isFilter;
      }),
    {
      ...payload,
    },
  ];

  return newList;
}

export default function bookings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PLATFORM_BOOKINGS:
      return {
        ...state,
        platformBookings: {
          ...state.platformBookings,
          loading: action.payload.loading,
          list: updatePlatformBookingsList({
            list: state.platformBookings.list,
            newPlatformBookings: action.payload.platformBookings,
            param: action.payload.param,
          }),
        },
      };
    case SEND_COMMUNICATION_REQUEST:
      return {
        ...state,
        communicationRequest: {
          ...state.communicationRequest,
          list: updateCommunicationRequestList({
            list: state.communicationRequest.list,
            payload: action.payload,
          }),
        },
      };

    default:
      return state;
  }
}
