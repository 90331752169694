import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import Container from '@material-ui/core/Container';

import PrivateRoute from 'components/templates/containers/PrivateRoute';
import AllPlatforms from 'components/pages/AllPlatforms';
import OnePlatform from 'components/pages/OnePlatform';
import WorkSchedule from 'components/pages/WorkSchedule';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import { connect } from 'react-redux';
import { getAllPlatforms } from 'actions';
import { PATH_PAGES } from 'config/constants';

import { useStyles } from './styles';

const propTypes = {
  getAllPlatformsReq: PropTypes.func.isRequired,
  allPlatforms: PropTypes.shape({
    loading: PropTypes.bool,
    platforms: PropTypes.any,
  }),
};

function PlatformsPage({
  allPlatforms = {},
  getAllPlatformsReq = () => {},
}) {
  const classes = useStyles();

  useEffect(
    () => {
      const { loading, platforms } = allPlatforms;
      const { data } = platforms || {};
      const isPlatformsLoaded = !!data;

      if (isPlatformsLoaded || loading) {
        return;
      }

      getAllPlatformsReq();
    },
    // eslint-disable-next-line
    [],
  );

  const { loading, platforms } = allPlatforms;
  const { data } = platforms || {};
  const isRedirectToFirstPlatform = !loading && data && data.length <= 1;
  const firstPlatform = (data || [])[0];
  const { id } = firstPlatform || {};
  const path = `${PATH_PAGES.platforms}/${id}`;

  const isLoader = !(platforms && data);

  return (
    <Container className={classes.container}>
      {isLoader && (
        <CircularIndeterminate
          type="fullPage"
          style={{ position: 'initial' }}
        />
      )}
      {!isLoader && (
        <Switch>
          <PrivateRoute exact path={PATH_PAGES.platformsAll} component={AllPlatforms} />
          <PrivateRoute exact path={PATH_PAGES.platformSchedule} component={WorkSchedule} />
          <PrivateRoute exact path={PATH_PAGES.onePlatform} component={OnePlatform} />
          {isRedirectToFirstPlatform && (
            <Redirect to={path} />
          )}
          <Redirect to={PATH_PAGES.platformsAll} />
        </Switch>
      )}
    </Container>
  );
}

PlatformsPage.propTypes = propTypes;

function mapStateToProps(state) {
  const { platform } = state || {};
  const { allPlatforms } = platform || {};
  return {
    allPlatforms,
  };
}

const ConnectedPlatformsPage = connect(
  mapStateToProps,
  {
    getAllPlatformsReq: getAllPlatforms,
  },
)(PlatformsPage);

export default ConnectedPlatformsPage;
