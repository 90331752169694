import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  itemSelectors: {
  },
  itemSelectorsForm: {
    maxWidth: 476,
    width: 'auto',
    flexGrow: 1,
  },
  addOperatorBtn: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: 16,
  },
  datePicker: {
    marginLeft: 16,
  },
}));

export default {
  useStyles,
};
