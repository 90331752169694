import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

function NoRightsPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container
      className={classes.container}
      maxWidth="md"
    >
      <Typography variant="h4" className={classes.title}>
        {t('others.noEnoughRights')}
      </Typography>
    </Container>
  );
}

export default NoRightsPage;
