import React from 'react';

function EvStation(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M7.5 9v5L10 7H7.5V2L5 9h2.5z"
        fill="#7E8AA7"
        stroke="#7E8AA7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EvStation;
