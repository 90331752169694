export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const LOGIN_LOGOUT_PROGRESS = 'LOGIN_LOGOUT_PROGRESS';

export const CLEAR_CACHE_ALL_PLATFORMS = 'CLEAR_CACHE_ALL_PLATFORMS';
export const CLEAR_CACHE_ONE_PLATFORMS = 'CLEAR_CACHE_ONE_PLATFORMS';
export const GET_PLATFORMS_REQUEST = 'GET_PLATFORMS_REQUEST';
export const GET_ONE_PLATFORM_REQUEST = 'GET_ONE_PLATFORM_REQUEST';

export const CLEAR_CACHE_PLATFORM_SCHEDULE = 'CLEAR_CACHE_PLATFORM_SCHEDULE';
export const GET_PLATFORM_SCHEDULE_REQUEST = 'GET_PLATFORM_SCHEDULE_REQUEST';
export const UPDATE_PLATFORM_SCHEDULE_REQUEST = 'UPDATE_PLATFORM_SCHEDULE_REQUEST';

export const GET_PLATFORM_BOOKINGS = 'GET_PLATFORM_BOOKINGS';
export const SEND_COMMUNICATION_REQUEST = 'SEND_COMMUNICATION_REQUEST';

export const GET_PLATFORM_MOBILE_PAYMENTS = 'GET_PLATFORM_MOBILE_PAYMENTS';

export const CLEAR_CACHE_ONE_OPERATOR = 'CLEAR_CACHE_ONE_OPERATOR';
export const CLEAR_CACHE_OPERATORS = 'CLEAR_CACHE_OPERATORS';
export const GET_OPERATORS = 'GET_OPERATORS';
export const GET_ONE_OPERATOR_REQUEST = 'GET_ONE_OPERATOR_REQUEST';
export const GET_ACTIONS_ONE_OPERATOR_REQUEST = 'GET_ACTIONS_ONE_OPERATOR_REQUEST';
export const ADD_NEW_OPERATOR_REQUEST = 'ADD_NEW_OPERATOR_REQUEST';
export const UPDATE_OPERATOR_REQUEST = 'UPDATE_OPERATOR_REQUEST';
export const REMOVE_OPERATOR_REQUEST = 'REMOVE_OPERATOR_REQUEST';
export const GET_LIST_FRONT_OFFICE_RIGHTS = 'GET_LIST_FRONT_OFFICE_RIGHTS';
export const GET_CURRENT_OPERATOR = 'GET_CURRENT_OPERATOR';

export const CLEAR_CACHE_ONE_SUBSCRIPTION = 'CLEAR_CACHE_ONE_SUBSCRIPTION';
export const CLEAR_CACHE_SUBSCRIPTIONS = 'CLEAR_CACHE_SUBSCRIPTIONS';
export const GET_ONE_SUBSCRIPTION = 'GET_ONE_SUBSCRIPTION';
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';

export const GET_PLATFORM_ANALYTICS = 'GET_PLATFORM_ANALYTICS';
export const GET_PLATFORM_DURATION_ANALYTICS = 'GET_PLATFORM_DURATION_ANALYTICS';

export const GET_REPORTS = 'GET_REPORTS';

export const GET_BLOCKED_USERS = 'GET_BLOCKED_USERS';

export default {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGIN_LOGOUT_PROGRESS,

  CLEAR_CACHE_ALL_PLATFORMS,
  CLEAR_CACHE_ONE_PLATFORMS,
  GET_PLATFORMS_REQUEST,
  GET_ONE_PLATFORM_REQUEST,

  CLEAR_CACHE_PLATFORM_SCHEDULE,
  GET_PLATFORM_SCHEDULE_REQUEST,
  SEND_COMMUNICATION_REQUEST,

  CLEAR_CACHE_ONE_OPERATOR,
  CLEAR_CACHE_OPERATORS,
  GET_PLATFORM_BOOKINGS,
  GET_PLATFORM_MOBILE_PAYMENTS,
  UPDATE_PLATFORM_SCHEDULE_REQUEST,
  GET_OPERATORS,
  GET_ACTIONS_ONE_OPERATOR_REQUEST,
  ADD_NEW_OPERATOR_REQUEST,
  UPDATE_OPERATOR_REQUEST,
  REMOVE_OPERATOR_REQUEST,

  GET_PLATFORM_ANALYTICS,
  GET_PLATFORM_DURATION_ANALYTICS,

  GET_REPORTS,
  GET_BLOCKED_USERS,
};
