import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import SortedBigFirstIcon from '@parkly/shared/components/atoms/icons/SortedBigFirstIcon';
import SortedSmallFirstIcon from '@parkly/shared/components/atoms/icons/SortedSmallFirstIcon';

import { MOBILE_PAYMENTS_SORT_TYPES } from 'config/constants';

import { useStyles } from './styles';

/* help function */

function getHeaders({
  t,
  isCostColumn,
}) {
  const headers = [
    {
      id: MOBILE_PAYMENTS_SORT_TYPES.ticketNumber,
      label: t('mobilePayments.ticketNumber'),
    },
    {
      isSorted: true,
      id: MOBILE_PAYMENTS_SORT_TYPES.checkIn,
      label: t('mobilePayments.checkIn'),
    },
    {
      isSorted: true,
      id: MOBILE_PAYMENTS_SORT_TYPES.checkOut,
      label: t('mobilePayments.checkOut'),
    },
    {
      id: MOBILE_PAYMENTS_SORT_TYPES.userName,
      label: t('mobilePayments.name'),
    },
    {
      isSorted: true,
      id: MOBILE_PAYMENTS_SORT_TYPES.paidAt,
      label: t('mobilePayments.paidAt'),
    },
    isCostColumn
      ? {
        id: MOBILE_PAYMENTS_SORT_TYPES.totalCost,
        label: t('mobilePayments.totalCost'),
      }
      : null,
  ];

  return headers.filter((item) => !!item);
}

function getRows(mobilePaymentsData = []) {
  const result = mobilePaymentsData.map((mobilePaymentItem) => {
    const {
      id,
      ticketNumber,
      enterTime,
      exitTime,
      facilityId,
      userName,
      totalCost,
      paidAt,
    } = mobilePaymentItem;

    return {
      id,
      ticketNumber,
      dateIn: moment(enterTime),
      dateOut: moment(exitTime),
      paidAt: moment(paidAt),
      name: userName,
      facilityId,
      totalCost,
    };
  });

  return result;
}

const propTypes = {
  mobilePaymentsData: PropTypes.arrayOf(PropTypes.object),
  headerSortSettings: PropTypes.objectOf(PropTypes.object),
  isCostColumn: PropTypes.bool,
  sortingSettingsChange: PropTypes.func,
};

function MobilePaymentsTable({
  mobilePaymentsData,
  headerSortSettings = {},
  isCostColumn = false,
  sortingSettingsChange = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const headers = getHeaders({ t, isCostColumn });

  const rows = getRows(mobilePaymentsData);
  const isMobilePaymentsData = mobilePaymentsData && (mobilePaymentsData || []).length > 0;

  function createHeaderClickHandler(id, isSorted) {
    return function headerClickHandler() {
      if (!id || !isSorted) {
        return;
      }
      sortingSettingsChange({
        headerNameId: id,
      });
    };
  }

  return (
    <Paper className={classes.root}>
      {!isMobilePaymentsData && (
        <div className={classes.noDataContainer}>
          <Typography
            className={classes.noDataStr}
          >
            {t('mobilePayments.noDataYet')}
          </Typography>
        </div>
      )}
      {isMobilePaymentsData && (
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {headers.map(({ label, id, isSorted }) => {
                  const sortingRule = headerSortSettings[id || ''] || {};
                  const isSort = sortingRule === 'sort';
                  const isSortReverse = sortingRule === 'sortReverse';

                  return (
                    <TableCell
                      key={id}
                      align="left"
                      className={isSorted ? classes.clickable : ''}
                      onClick={createHeaderClickHandler(id, isSorted)}
                    >
                      <div className={classes.headerContainer}>
                        <Typography className={classes.headerStr}>
                          {label}
                        </Typography>
                        {isSort && (
                          <SortedSmallFirstIcon className={classes.sortIcon} />
                        )}
                        {isSortReverse && (
                          <SortedBigFirstIcon className={classes.sortIcon} />
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index, array) => {
                const {
                  id,
                  ticketNumber,
                  dateIn,
                  dateOut,
                  name,
                  totalCost,
                  paidAt,
                } = row || {};
                const key = id;

                const currency = t('others.currency');
                const totalCostStr = `${(+totalCost || '').toLocaleString()} ${currency}`;

                const dateInTimeStr = dateIn.isValid() ? dateIn.format('HH:mm') : '-';
                const dateInDateStr = dateIn.isValid() ? dateIn.format('DD.MM.yyyy') : '-';
                const dateOutTimeStr = dateOut.isValid() ? dateOut.format('HH:mm') : '-';
                const dateOutDateStr = dateOut.isValid() ? dateOut.format('DD.MM.yyyy') : '-';

                const paidAtTimeStr = paidAt.isValid() ? paidAt.format('HH:mm') : '-';
                const paidAtDateStr = paidAt.isValid() ? paidAt.format('DD.MM.yyyy') : '-';

                const isLast = index === (array.length - 1);

                const tableRowClass = [
                  classes.tableRow,
                  isLast ? classes.tableRowLast : '',
                ];

                return (
                  <TableRow
                    key={key}
                    className={tableRowClass.join(' ')}
                    hover
                  >
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.bookingsIdStr}>
                        {ticketNumber}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.timeStr}>
                        {dateInTimeStr}
                      </Typography>
                      <Typography className={classes.dateStr}>
                        {dateInDateStr}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.timeStr}>
                        {dateOutTimeStr}
                      </Typography>
                      <Typography className={classes.dateStr}>
                        {dateOutDateStr}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.nameStr}>
                        {name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography className={classes.timeStr}>
                        {paidAtTimeStr}
                      </Typography>
                      <Typography className={classes.dateStr}>
                        {paidAtDateStr}
                      </Typography>
                    </TableCell>
                    {isCostColumn && (
                      <TableCell
                        align="left"
                      >
                        <Typography className={classes.totalCost}>
                          {totalCostStr}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

MobilePaymentsTable.propTypes = propTypes;

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state) {
  return {};
}

const ConnectedBookingsTable = connect(
  mapStateToProps,
  {},
)(MobilePaymentsTable);

export default ConnectedBookingsTable;
