import React from 'react';

function StarIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 1l1.79 4.537 4.867.3-3.761 3.104 1.218 4.722L8 11.045l-4.114 2.618 1.218-4.722-3.761-3.104 4.867-.3L8 1z"
        fill="#7E8AA7"
        stroke="#7E8AA7"
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default StarIcon;
