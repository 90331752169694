import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import PaidIcon from '@parkly/shared/components/atoms/icons/PaidIcon';
import DeclinedIcon from '@parkly/shared/components/atoms/icons/DeclinedIcon';
import CanceledIcon from '@parkly/shared/components/atoms/icons/CanceledIcon';
import EndedIcon from '@parkly/shared/components/atoms/icons/EndedIcon';
import UnPaidIcon from '@parkly/shared/components/atoms/icons/UnPaidIcon';

import { STATUS_KEYS } from 'config/constants';
import { useStyles } from './styles';

function StatusIcon({
  statusKey,
  containerClassName,
}) {
  const classes = useStyles();
  const containerClassNameStr = `${classes.container} ${containerClassName}`;

  switch (statusKey) {
  case STATUS_KEYS.paid:
    return (
      <Tooltip
        title="Оплачено"
        PopperProps={{
          className: classes.popper,
        }}
      >
        <div className={containerClassNameStr}>
          <PaidIcon />
        </div>
      </Tooltip>
    );

  case STATUS_KEYS.rejected:
  case STATUS_KEYS.deleted:
    return (
      <Tooltip
        title="Отклонено"
        PopperProps={{
          className: classes.popper,
        }}
      >
        <div className={containerClassNameStr}>
          <DeclinedIcon />
        </div>
      </Tooltip>
    );

  case STATUS_KEYS.cancelled:
    return (
      <Tooltip
        title="Отменено"
        PopperProps={{
          className: classes.popper,
        }}
      >
        <div className={containerClassNameStr}>
          <CanceledIcon />
        </div>
      </Tooltip>
    );

  case STATUS_KEYS.completed:
    return (
      <Tooltip
        title="Завершено"
        PopperProps={{
          className: classes.popper,
        }}
      >
        <div className={containerClassNameStr}>
          <EndedIcon />
        </div>
      </Tooltip>
    );

  case STATUS_KEYS.notPaid:
  case STATUS_KEYS.paidRejected:
    return (
      <Tooltip
        title="Не оплачено"
        PopperProps={{
          className: classes.popper,
        }}
      >
        <div className={containerClassNameStr}>
          <UnPaidIcon />
        </div>
      </Tooltip>
    );

  default:
    return null;
  }
}

export default StatusIcon;
