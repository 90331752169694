import { GET_PLATFORM_MOBILE_PAYMENTS } from 'actions/types';

const INITIAL_STATE = {
  platformMobilePayments: {
    loading: false,
    list: [],
  },
};

function updatePlatformMobilePaymentsList({
  list,
  newPlatformMobilePayments,
  param,
}) {
  if (!newPlatformMobilePayments) {
    return list;
  }

  const stringifyParam = JSON.stringify(param);

  const newList = [
    ...list
      .slice(0, 5)
      .filter((item = {}) => {
        const { strParam } = item || {};
        const isFilter = strParam !== stringifyParam;

        return isFilter;
      }),
    {
      data: newPlatformMobilePayments,
      strParam: stringifyParam,
      timeStamp: new Date().getTime(),
    },
  ];

  return newList;
}

export default function mobilePayments(state = INITIAL_STATE, action) {
  switch (action.type) {
  case GET_PLATFORM_MOBILE_PAYMENTS:
    return {
      ...state,
      platformMobilePayments: {
        ...state.platformMobilePayments,
        loading: action.payload.loading,
        list: updatePlatformMobilePaymentsList({
          list: state.platformMobilePayments.list,
          newPlatformMobilePayments: action.payload.platformMobilePayments,
          param: action.payload.param,
        }),
      },
    };

  default:
    return state;
  }
}
