export function checkIsPhoneValid(phone) {
  return Number.isInteger(+phone) && phone.length === 11;
}

const PLATE_NUMBER_REGEXP =
  /^(([АВЕКМНОРСТУХABEKMHOPCTYX]\d{3}[АВЕКМНОРСТУХABEKMHOPCTYX]{1,2})(\d{2,3})|(\d{4}[АВЕКМНОРСТУХABEKMHOPCTYX]{2})(\d{2})|(\d{3}(C?D|[ТНМВКЕTHMBKE])\d{3})(\d{2})|([ТСКTCK][АВЕКМНОРСТУХABEKMHOPCTYX]{2}\d{3})(\d{2})|([АВЕКМНОРСТУХABEKMHOPCTYX]{2}\d{3}[АВЕКМНОРСТУХABEKMHOPCTYX])(\d{2})|([АВЕКМНОРСТУХABEKMHOPCTYX]\d{4})(\d{2})|(\d{3}[АВЕКМНОРСТУХABEKMHOPCTYX])(\d{2})|(\d{4}[АВЕКМНОРСТУХABEKMHOPCTYX])(\d{2})|([АВЕКМНОРСТУХABEKMHOPCTYX]{2}\d{4})(\d{2})|([АВЕКМНОРСТУХABEKMHOPCTYX]{2}\d{3})(\d{2,3})|(^Т[АВЕКМНОРСТУХABEKMHOPCTYX]{2}\d{3}\d{2,3}))$/iu;

export function checkIsCarNumberValid(carNumber) {
  return !!carNumber && PLATE_NUMBER_REGEXP.test(carNumber);
}
