/* eslint-disable camelcase */
import { callAPI } from '@parkly/shared/api/base';
import moment from 'moment-timezone';

async function validGetPlatformAnalyticsRes({
  res,
}) {
  const { data: resData } = res || {};
  const { data } = resData || {};

  const parsedData = (data || []).map((item) => {
    const {
      reserved_spots: reservedSpots,
      time_start: timeStart,
      total_spots: totalSpots,
    } = item || {};

    return {
      reservedSpots,
      timeStart,
      parsedTime: moment(timeStart),
      totalSpots,
      emptySpots: totalSpots - reservedSpots,
    };
  });

  return {
    data: parsedData,
  };
}

/* api request */

export async function getPlatformAnalytics({
  id,
  day,
}) {
  const endpoint = 'reservation_statistics';
  const method = 'post';

  const data = {
    facility_id: id,
    date: day,
  };

  return callAPI(endpoint, method, data)
    .then((res) => validGetPlatformAnalyticsRes({
      res,
    }));
}

export async function getPlatformDurationAnalytics({
  id,
  startDate,
  endDate,
}) {
  /*const endpoint = 'reservation_statistics';
  const method = 'post';

  const data = {
    facility_id: id,
    date: day,
  };

  return callAPI(endpoint, method, data)
    .then((res) => validGetPlatformBookingsRes({
      res,
    }));*/

  let result = [];

  for (let i = 0; i < 171; i++) {
    result[i] = {
      duration: i + 1,
      count: Math.round(Math.random() * 10),
      averPrice: Math.round((300 - i) / 2),
    };
  }

  return result;
}

export default {
  getPlatformAnalytics,
  getPlatformDurationAnalytics,
};
