/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

function ParkSignIconMini({
  size = 16,
  fill = '#ADB6C9',
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.328 6.372c.267.183.542.37.619.914.064.517.09 1.037.08 1.558-.008.39-.041.777-.099
        1.162v2.024c0 .338-.106.45-.425.45h-.838c-.318 0-.425-.113-.425-.45V11.02H3.79v1.009c0
         .337-.105.45-.425.45h-.839c-.316 0-.424-.112-.424-.45v-2.016a9.31 9.31 0 01-.1-1.17 10.7
         10.7 0 01.08-1.564c.078-.547.353-.734.62-.916.21-.142.414-.282.518-.587.198-.585.783-2.031
         1.535-2.462C5.221 3.053 7.104 3 8.03 3c.926 0 2.824.053 3.28.315.761.43 1.298 1.877 1.5
         2.469.105.305.31.445.519.588zM6.148 4.5a1 1 0 00-.895.553l-.39.778a.5.5 0
         00.412.722l1.614.116c.76.054 1.521.054 2.28 0l1.614-.116a.5.5 0 00.412-.722l-.39-.778a1 1
         0 00-.894-.553H6.147zM4.122 7.652l1.483.38a.478.478 0 01-.142.94L3.96 8.894a.5.5 0
         01-.474-.524l.012-.26a.5.5 0 01.624-.46zm6.33.38l1.483-.38a.5.5 0 01.623.46l.013.26a.5.5 0
         01-.474.523l-1.503.076a.478.478 0 01-.143-.94z"
        fill={fill}
      />
    </svg>
  );
}

export default ParkSignIconMini;
