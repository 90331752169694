/* eslint-disable camelcase */
import { callAPI } from '@parkly/shared/api/base';

/* transform and validations */

function transformPlatform(props) {
  const {
    address,
    id,
    title,
    type,
    monthly_income,
    today_places_count,
    today_reservations,
    today_mobile_payments,
    today_schedule,
  } = props;
  const {
    available_from,
    available_to,
  } = today_schedule || {};

  return {
    address,
    id,
    title,
    type,
    monthlyIncome: monthly_income,
    todayPlacesCount: today_places_count,
    todayReservations: today_reservations,
    todayMobilePayments: today_mobile_payments,
    todaySchedule: {
      availableFrom: available_from,
      availableTo: available_to,
    },
  };
}

export async function validGetPlatformsRes(result) {
  const { data } = result || {};

  return {
    data: (data.data || []).map((item) => transformPlatform(item)),
  };
}

export async function validGetOnePlatformRes(result) {
  const { data } = result || {};

  const {
    address,
    id,
    name,
    type: platformType,
    parking_guide_instructions,
    options,
    parking_instructions,
    phone,
    phone_extra,
    photos,
    price_list,
    statistic,
    ya_map_link,
  } = (data || {}).data;

  const {
    available_places,
    income = {},
    reservations,
    mobile_payments,
    schedules,
  } = statistic;

  const schedulesTransformed = schedules.map(({
    available_from,
    available_to,
    date,
    is_available,
    type,
  }) => ({
    availableFrom: available_from,
    availableTo: available_to,
    date,
    isAvailable: is_available,
    type,
  }));
  const availablePlaces = available_places.map(({
    date,
    count,
    type,
  }) => {
    const { isAvailable } = schedulesTransformed
      .find((schItem) => ((schItem || {}).date === date)) || {};

    return {
      date,
      count,
      type,
      isAvailable,
    };
  });

  const tPriceListRow = (price_list || []).reduce(
    (acc, priceListItem) => {
      const { tariff } = priceListItem || {};

      if (!acc[tariff]) {
        acc[tariff] = [];
      }

      acc[tariff].push(priceListItem);

      return acc;
    },
    {},
  );
  const transformedTariffList = (Object.values(tPriceListRow) || [])
    .map((list) => {
      const firstItem = (list || [])[0];
      const { tariff } = firstItem || {};

      const tPriceList = (list || []).map((item) => {
        const {
          commission,
          id: priceId,
          max_hours,
          min_hours,
          minimal_cost,
          tariff_type,
        } = item || {};

        return {
          id: priceId,
          minimalCost: minimal_cost,
          commission,
          minHours: min_hours,
          maxHours: max_hours,
          tariffType: tariff_type,
        };
      });

      return {
        name: tariff,
        tPriceList,
      };
    });

  const transformedPhotos = (photos || []).map((photo) => {
    const {
      link,
    } = photo || {};
    const photoId = (photo || {}).id;

    return {
      id: photoId,
      source: link,
      link,
      alt: '',
    };
  });

  return {
    data: {
      address,
      id,
      name,
      options,
      type: platformType,
      findEntranceInstructions: parking_guide_instructions,
      parkingInstructions: parking_instructions,
      phone,
      phoneExtra: phone_extra,
      photos: transformedPhotos,
      mapLink: ya_map_link,
      tariffList: transformedTariffList,
      statistic: {
        availablePlaces,
        income: {
          lastWeek: income.last_week,
          prepaid: income.prepaid,
          thisMonth: income.this_month,
          today: income.today,
        },
        reservations,
        mobilePayments: mobile_payments,
        schedules: schedulesTransformed,
      },
    },
  };
}

/* API requests */

export async function getPlatforms() {
  const endpoint = 'facility';

  return callAPI(endpoint)
    .then((res) => validGetPlatformsRes(res));
}

export async function getOnePlatform({
  id,
}) {
  const endpoint = `facility/${id}`;

  return callAPI(endpoint)
    .then((res) => validGetOnePlatformRes(res));
}

export default {
  getPlatforms,
  getOnePlatform,
};
