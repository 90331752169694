import React, { useEffect } from 'react';
import { Redirect, Route, Switch, } from 'react-router-dom';
import { connect } from 'react-redux';

import PlatformsPage from 'components/pages/PlatformsPage';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import OperatorsPage from 'components/pages/OperatorsPage';
import PageContainer from '@parkly/shared/components/templates/containers/PageContainer';
import MainNavigation from 'components/templates/MainNavigation';
import BookingsPage from 'components/pages/BookingsPage';
import SubscriptionsPage from 'components/pages/SubscriptionsPage';
import MobilePaymentsPage from 'components/pages/MobilePaymentsPage';
import NoRightsPage from '@parkly/shared/components/pages/NoRightsPage';
import AnalyticsPage from 'components/pages/AnalyticsPage';
import BlockedUsersPage from 'components/pages/BlockedUsersPage';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import { getCurrentOperator } from 'actions';
import { PATH_PAGES } from 'config/constants';
import { getLinkStrOperators, getLinkStrPlatform } from 'helpers';

function getRedirectPath({
  currentOperator,
}) {
  const { data, userRights } = currentOperator || {};
  const { isAdmin } = data || {};

  if (isAdmin) {
    return PATH_PAGES.platforms;
  }

  const {
    isReadAllPlatforms,
    isReadSomePlatform,
    readPlatformInfoRightPlatforms,

    isReadOperatorsList,
    isCreateUpdOperators,
    isReadOperatorsInfo,

    isReadActBookList,
    isReadHistBookList,

    isReadOperatorsActions,
  } = userRights;

  if (isReadActBookList || isReadHistBookList) {
    return PATH_PAGES.bookings;
  }

  if (isReadAllPlatforms || isReadSomePlatform) {
    return getLinkStrPlatform({
      isReadAllPlatforms,
      isReadSomePlatform,
      readPlatformInfoRightPlatforms,
    });
  }

  if (isReadOperatorsList || isReadOperatorsInfo || isCreateUpdOperators || isReadOperatorsActions) {
    return getLinkStrOperators({
      isReadOperatorsList,
      isCreateUpdOperators,
      isReadOperatorsInfo,
      isReadOperatorsActions,
    });
  }

  return PATH_PAGES.notEnoughRights;
}

function AppContent({
  user,
  currentOperator,
  getCurrentOperatorReq = () => {},
}) {
  useEffect(
    () => {
      const { username } = user || {};

      const {
        loading,
        data: currentOperatorData,
      } = currentOperator || {};

      const isCurrentOperatorLoaded = !!currentOperatorData;

      if (isCurrentOperatorLoaded || loading) {
        return;
      }

      getCurrentOperatorReq({
        username,
      });
    },
    // eslint-disable-next-line
    [],
  );

  const currentOperatorData = (currentOperator || {}).data;
  const isCurrentOperatorLoaded = !!currentOperatorData;

  return (
    <PageContainer
      navigation={<MainNavigation />}
    >
      {!isCurrentOperatorLoaded && (
        <CircularIndeterminate style={{ minHeight: 600 }} />
      )}
      {isCurrentOperatorLoaded && (
        <Switch>
          <Route path={PATH_PAGES.notEnoughRights} component={NoRightsPage} />
          <PrivateRoute path={PATH_PAGES.operators} component={OperatorsPage} />
          <PrivateRoute path={PATH_PAGES.platforms} component={PlatformsPage} />
          <PrivateRoute path={PATH_PAGES.bookingsId} component={BookingsPage} />
          <PrivateRoute path={PATH_PAGES.bookings} component={BookingsPage} />
          <PrivateRoute path={PATH_PAGES.subscriptions} component={SubscriptionsPage} />
          <PrivateRoute path={PATH_PAGES.mobilePaymentsId} component={MobilePaymentsPage} />
          <PrivateRoute path={PATH_PAGES.mobilePayments} component={MobilePaymentsPage} />
          <PrivateRoute path={PATH_PAGES.analytics} component={AnalyticsPage} />
          <PrivateRoute path={PATH_PAGES.analyticsId} component={AnalyticsPage} />
          <PrivateRoute path={PATH_PAGES.blockedUsers} component={BlockedUsersPage} />
          <Redirect to={getRedirectPath({ currentOperator })} />
        </Switch>
      )}
    </PageContainer>
  );
}

function mapStateToProps(state) {
  const { operators } = state || {};
  const { user } = state.auth || {};
  const { currentOperator } = operators || {};

  return {
    user,
    currentOperator,
  };
}

const ConnectedAppContent = connect(
  mapStateToProps,
  {
    getCurrentOperatorReq: getCurrentOperator,
  },
)(AppContent);

export default ConnectedAppContent;
