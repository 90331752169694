import React from 'react';

function ForOrBackWord({
  isBack = false,
  hideCircle = false,
  ...props
}) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      {!hideCircle && (
        <circle cx={16} cy={16} r={15.5} fill="#fff" stroke="#DDE0E8" />
      )}
      <path
        d={isBack ? 'M18 21l-5-5 5-5' : 'M14 21l5-5-5-5'}
        stroke="#1D3262"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ForOrBackWord;
