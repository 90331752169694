import {
  getPlatforms, getOnePlatform,
} from 'api/platform';
import {
  getPlatformSchedule, updPlatformSchedule, updPlatformParkSpace,
} from 'api/platformSchedule';

import {
  GET_PLATFORMS_REQUEST,
  GET_ONE_PLATFORM_REQUEST,
  GET_PLATFORM_SCHEDULE_REQUEST,
  UPDATE_PLATFORM_SCHEDULE_REQUEST,
  CLEAR_CACHE_PLATFORM_SCHEDULE,
  CLEAR_CACHE_ONE_PLATFORMS,
  CLEAR_CACHE_ALL_PLATFORMS,
} from './types';

export function clearCacheAllPlatforms() {
  function clearCacheAllPlatformsRequest() {
    return { type: CLEAR_CACHE_ALL_PLATFORMS };
  }

  return (dispatch) => {
    dispatch(clearCacheAllPlatformsRequest());
  };
}

export function clearCacheOnePlatforms() {
  function clearCacheOnePlatformsRequest() {
    return { type: CLEAR_CACHE_ONE_PLATFORMS };
  }

  return (dispatch) => {
    dispatch(clearCacheOnePlatformsRequest());
  };
}

export function clearCachePlatformsSchedule() {
  function clearCachePlatformScheduleRequest() {
    return { type: CLEAR_CACHE_PLATFORM_SCHEDULE };
  }

  return (dispatch) => {
    dispatch(clearCachePlatformScheduleRequest());
  };
}

export function updPlatformScheduleAction({
  updId = new Date().getTime(),
  platformId,
  month,
  type,

  parkSpace,

  timeEnd,
  timeStart,
  isAvailable,
  tariffId,

  weekDay,
  dayDate,

  isTariffDefault,
  isParkSpaceDefault,
  isTimeDefault,

  isParkSpaceChanged,
  isTimeChanged,
  isAvailableChanged,
  isTariffChanged,

  placesRuleId,
  timeRuleId,
  placesRuleType,
  timeRuleType,
}) {
  function updPlatformScheduleRequest(payload) {
    return { type: UPDATE_PLATFORM_SCHEDULE_REQUEST, payload };
  }

  return (dispatch) => {
    function successRequest(updPlatformScheduleRes) {
      // eslint-disable-next-line no-use-before-define
      getPlatformScheduleAction({
        id: platformId,
        month,
      })(
        dispatch,
        () => {
          dispatch(updPlatformScheduleRequest({
            updId,
            loading: false,
            updPlatformScheduleRes,
          }));
        },
        () => {
          dispatch(clearCachePlatformsSchedule());
          // eslint-disable-next-line no-use-before-define
          dispatch(getAllPlatforms());
          dispatch(clearCacheOnePlatforms());
        },
      );
    }

    function failRequest(error) {
      dispatch(updPlatformScheduleRequest({
        updId,
        loading: false,
        error,
      }));
    }

    // schedule changes
    if (isTimeChanged || isAvailableChanged || isTariffChanged) {
      const param = {
        isScheduleChanges: true, // for local schedule upd
        platformId,
        type,

        isTimeDefault,
        isTariffDefault,

        dayDate,
        weekDay,
        timeEnd,
        timeStart,
        isAvailable,
        tariffId,

        timeRuleId,
        timeRuleType,
      };

      dispatch(updPlatformScheduleRequest({
        updId,
        loading: true,
        param,
      }));

      updPlatformSchedule(param)
        .then(
          (updPlatformScheduleRes) => {
            successRequest(updPlatformScheduleRes);
          },
          (error) => {
            failRequest(error);
          },
        );
    }

    // park space changes
    if (isParkSpaceChanged) {
      const param = {
        isParkSpaceChanges: true, // for local schedule upd
        dayDate,
        weekDay,
        platformId,
        type,
        parkSpace,
        isParkSpaceDefault,

        placesRuleId,
        placesRuleType,
      };

      dispatch(updPlatformScheduleRequest({
        updId,
        loading: true,
        param,
      }));

      updPlatformParkSpace(param)
        .then(
          (updPlatformScheduleRes) => {
            successRequest(updPlatformScheduleRes);
          },
          (error) => {
            failRequest(error);
          },
        );
    }
  };
}

export function getPlatformScheduleAction({
  id,
  month,
}) {
  function getPlatformScheduleRequest(payload) {
    return { type: GET_PLATFORM_SCHEDULE_REQUEST, payload };
  }

  return (
    dispatch,
    calback = () => {},
    calbeforeSuccsess = () => {},
  ) => {
    dispatch(getPlatformScheduleRequest({
      loading: true,
    }));

    getPlatformSchedule({
      id,
      month,
    })
      .then(
        (platformSchedule) => {
          calbeforeSuccsess();
          dispatch(getPlatformScheduleRequest({
            loading: false,
            platformSchedule,
          }));
          calback();
        },
        (error) => {
          dispatch(getPlatformScheduleRequest({
            loading: false,
            error,
          }));
          calback();
        },
      );
  };
}

export function getAllPlatforms() {
  function getPlatformsRequest(payload) { return { type: GET_PLATFORMS_REQUEST, payload }; }

  return (dispatch) => {
    dispatch(getPlatformsRequest({
      loading: true,
    }));

    getPlatforms()
      .then(
        (platforms) => {
          dispatch(getPlatformsRequest({
            loading: false,
            platforms,
          }));
        },
        (error) => {
          dispatch(getPlatformsRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function getOnePlatformAction({
  id,
}) {
  function getOnePlatformRequest(payload) { return { type: GET_ONE_PLATFORM_REQUEST, payload }; }

  return (dispatch) => {
    if (!id && id !== 0) {
      dispatch(getOnePlatformRequest({
        loading: false,
        error: '',
      }));
      return;
    }

    dispatch(getOnePlatformRequest({
      loading: true,
    }));

    getOnePlatform({
      id,
    })
      .then(
        (platform) => {
          dispatch(getOnePlatformRequest({
            loading: false,
            platform,
          }));
        },
        (error) => {
          dispatch(getOnePlatformRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export default {
  clearCacheAllPlatforms,
  clearCacheOnePlatforms,
  clearCachePlatformsSchedule,
  getAllPlatforms,
  getOnePlatformAction,
};
