import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  containerPage: {
    padding: 0,
    maxWidth: 'unset',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftHeader: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  rightHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  currentMonth: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
    marginLeft: 13,
    fontWeight: 'bold',
    fontSize: '1.15rem',
  },
  monthSelContainer: {
    marginLeft: 32,
    display: 'flex',
    alignItems: 'center',
  },
  prevNextBtnsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  emptyTableCell: {
    minWidth: 156,
    minHeight: 136,
  },
  dayTableCell: {
    minWidth: 156,
    height: 136,
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
  },
  datePicker: {
    maxWidth: '235px!important',
  },
}));

export default {
  useStyles,
};
