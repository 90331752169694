import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: 0,
    marginLeft: 0,
    maxWidth: 'unset',
  },
  pageBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  paginationContainer: {
    marginTop: 60,
    marginBottom: 20,
    display: 'flex',
    width: '100%',
  },
  pagination: {
    margin: 'auto',
  },
}));

export default {
  useStyles,
};
