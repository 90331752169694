import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import CompanyLogoWithName from '@parkly/shared/components/molecules/CompanyLogoWithName';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';

import { login } from '@parkly/shared/actions/auth';
import { useFormFields } from '@parkly/shared/helpers';

import { useStyles } from './styles';

function Copyright() {
  const year = new Date().getFullYear();
  const copyrightStr = `${year} ООО «Паркли» | partner@parkly.ru`;

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {copyrightStr}
    </Typography>
  );
}

function SignInPage({
  isAuth,
  loading,
  login = () => {},
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [fields, errors, onChange, setErrors] = useFormFields({
    initValues: {
      username: '',
      password: '',
    },
  });
  const classes = useStyles();

  function handleSubmit(e) {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    const {
      username,
      password,
    } = fields || {};

    if (!username) {
      setErrors({
        username: !username,
      });

      return;
    }

    if (!password) {
      setErrors({
        password: !password,
      });

      return;
    }

    login(username, password, history);
  }

  if (isAuth) {
    return (
      <Redirect to={{
        pathname: '/',
      }}
      />
    );
  }

  return (
    <Container
      style={{
        maxWidth: 318,
      }}
      component="main"
      maxWidth="xs"
    >
      <div className={classes.paper}>
        <CompanyLogoWithName />
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <CustomTextInput
            onChange={onChange}
            required
            label={t('signIn.login')}
            name="username"
            error={errors.username}
            value={fields.username}
            autoComplete="email"
            autoFocus
          />
          <CustomTextInput
            onChange={onChange}
            required
            name="password"
            error={errors.password}
            value={fields.password}
            label={t('signIn.password')}
            type="password"
            autoComplete="current-password"
          />
          <CustomBtn
            type="submit"
            fullWidth
            disabled={loading}
            color="primary"
            className={classes.submit}
          >
            {t('signIn.enter')}
          </CustomBtn>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

function mapStateToProps(state) {
  const { loggedIn, loading } = state.auth || {};
  return {
    isAuth: loggedIn,
    loading,
  };
}

const connectedSignInPage = connect(
  mapStateToProps,
  {
    login,
  },
)(SignInPage);

export default connectedSignInPage;
