import React from "react";

function SitesIconActive({
  fill = '#fff',
  size = 32,
  ...props
}) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3.917c-5.329 0-8.67 5.756-6.026 10.383l5.375 9.405a.75.75 0 001.302 0l5.375-9.405C24.67 9.673 21.329 3.917 16 3.917zm0 8.75a2 2 0 100-4 2 2 0 000 4z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.928 20l2.684 4.698a2.75 2.75 0 004.776 0L21.072 20h.814a1.5 1.5 0 011.229.64l3.117 4.453a1 1 0 01-.82 1.574H6.588a1 1 0 01-.819-1.574l3.117-4.453a1.5 1.5 0 011.23-.64h.813z"
        fill={fill}
      />
      <path
        d="M13.612 24.698l-.434.248.434-.248zM10.928 20l.434-.248a.5.5 0 00-.434-.252v.5zm7.46 4.698l.434.248-.434-.248zM21.072 20v-.5a.5.5 0 00-.434.252l.434.248zm2.043.64l.41-.287-.41.287zm3.117 4.453l-.41.287.41-.287zm-20.464 0l.41.287-.41-.287zm3.117-4.453l.41.287-.41-.287zm5.162 3.81l-2.685-4.698-.868.496 2.684 4.698.869-.496zM16 25.583a2.25 2.25 0 01-1.953-1.133l-.869.496A3.25 3.25 0 0016 26.583v-1zm1.954-1.133A2.25 2.25 0 0116 25.583v1a3.25 3.25 0 002.822-1.637l-.868-.496zm2.684-4.698l-2.684 4.698.868.496 2.684-4.698-.868-.496zm.434.748h.814v-1h-.814v1zm.814 0a1 1 0 01.819.427l.82-.574a2 2 0 00-1.64-.853v1zm.819.427l3.117 4.453.82-.573-3.118-4.454-.82.573zm3.117 4.453a.5.5 0 01-.41.787v1c1.214 0 1.925-1.366 1.23-2.36l-.82.573zm-.41.787H6.588v1h18.826v-1zm-18.825 0a.5.5 0 01-.41-.787l-.819-.573c-.695.994.016 2.36 1.23 2.36v-1zm-.41-.787l3.118-4.453-.82-.574-3.117 4.453.82.574zm3.118-4.453a1 1 0 01.82-.427v-1a2 2 0 00-1.64.853l.82.573zm.82-.427h.813v-1h-.814v1z"
        fill={fill}
      />
    </svg>
  )
}

export default SitesIconActive;
