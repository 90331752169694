import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';
import Container from '@material-ui/core/Container';
import Pagination from '@material-ui/lab/Pagination';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import CustomTabBar from '@parkly/shared/components/molecules/CustomTabBar';
import BookingSelectors from 'components/organisms/BookingSelectors';
import BookingsTable from 'components/templates/BookingsTable';
import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import DialogSendMsg from 'components/templates/DialogSendMsg';
import DialogShowCarInfo from 'components/templates/DialogShowCarInfo';

import { getAllPlatforms, getPlatformBookingsAction, sendCommunicationRequestAction } from 'actions';
import { BOOKINGS_UPDATE_PERIOD, RIGHT_KEYS, STATUS_KEYS } from 'config/constants';

import { useUpcomingReservationNotification } from 'helpers/hooks';
import { checkDateValid } from '@parkly/shared/helpers/time';
import { useStyles } from './styles';

/* help functions */

function getInitParams({
  match,
}) {
  const { params } = match || {};
  const { id } = params || {};
  const isId = !!id || id === 0;

  return {
    tabValue: 0,
    selPlatformValue: isId ? id : 'unselect',
    searchValue: '',
    selDate: null,
    selStatusValue: [],
    sorting: {},
    page: 1,
  };
}

function getNextSortStatus(status) {
  if (!status) {
    return 'sort';
  }

  if (status === 'sort') {
    return 'sortReverse';
  }

  return null;
}

function getTabs({
  currentOperator,
  t,
}) {
  const { userRights } = currentOperator || {};
  const { isReadHistBookList, isReadActBookList } = userRights || {};

  const currentTab = {
    id: 'current',
    label: t('bookings.current'),
  };
  const plannedTab = {
    id: 'planned',
    label: t('bookings.planned'),
  };
  const historyTab = {
    id: 'history',
    label: t('bookings.history'),
  };

  if (isReadHistBookList && isReadActBookList) {
    return [
      currentTab,
      plannedTab,
      historyTab,
    ];
  }

  if (isReadHistBookList) {
    return [
      historyTab,
    ];
  }

  if (isReadActBookList) {
    return [
      currentTab,
      plannedTab,
    ];
  }

  return [];
}

function withStatusCancelled(selStatusValue) {
  const isCancelled = selStatusValue.includes(STATUS_KEYS.rejected);
  if (isCancelled) {
    return [
      ...selStatusValue,
      STATUS_KEYS.deleted,
    ];
  }

  return selStatusValue;
}

function withStatusNoPaid(selStatusValue) {
  const isNotPaid = selStatusValue.includes(STATUS_KEYS.notPaid);
  if (isNotPaid) {
    return [
      ...selStatusValue,
      STATUS_KEYS.paidRejected,
    ];
  }

  return selStatusValue;
}

function getStatus(selStatusValue) {
  const withCanceled = withStatusCancelled(selStatusValue);
  const withNotPaid = withStatusNoPaid(withCanceled);

  return withNotPaid;
}

function prepareParam({
  params,
  tabs,
}) {
  const {
    selPlatformValue,
    tabValue,
    searchValue,
    selDate,
    selStatusValue,
    sorting,
    page,
  } = params || {};

  const sortRules = Object.keys(sorting).filter((currentId) => !!sorting[currentId]);
  const sortParam = sortRules.length > 0
    ? {
      sortBy: sortRules.join(','),
      sortDirection: sortRules.map((id) => {
        const rule = sorting[id];
        if (rule === 'sortReverse') {
          return 'desc';
        }

        return 'asc';
      }).join(','),
    }
    : {};

  const isSelDateValid = checkDateValid(selDate);

  const currentTab = tabs[tabValue || 0] || {};
  const type = currentTab.id;

  const status = getStatus(selStatusValue);

  const trimmedSearchValue = (searchValue || '').trim();
  const search = trimmedSearchValue; /* && searchValue.length > 2
    ? searchValue
    : undefined; */

  const reqParam = {
    platformSign: selPlatformValue,
    status,
    date: isSelDateValid ? selDate : undefined,
    search,
    page,
    type,
    ...sortParam,
  };

  return reqParam;
}

function requestPlatformBooking({
  reqParam,
  getPlatformBookingsReq = () => {},
}) {
  throttle(
    getPlatformBookingsReq,
    300,
  )(reqParam);
}

function getCurrentBookings({
  platformBookings,
  params,
  tabs,
}) {
  const bookingsList = (platformBookings || {}).list || [];
  const preparedReqParam = prepareParam({
    params,
    tabs,
  });
  const currentStrParam = JSON.stringify(preparedReqParam);
  const bookings = bookingsList.find((currentBookingsItem) => {
    const { strParam } = currentBookingsItem || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  return {
    bookings,
    reqParam: preparedReqParam,
    currentStrParam,
  };
}

/* Main component */

const propTypes = {
  allPlatforms: PropTypes.shape({
    loading: PropTypes.bool,
    platforms: PropTypes.any,
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  platformBookings: PropTypes.shape({
    list: PropTypes.array,
  }),
  communicationRequest: PropTypes.shape({
    list: PropTypes.array,
  }),
  currentOperator: PropTypes.shape({
    userRights: PropTypes.object,
  }),
  getAllPlatformsReq: PropTypes.func,
  getPlatformBookingsReq: PropTypes.func,
  sendCommunicationRequestReq: PropTypes.func,
};

function BookingsPage({
  allPlatforms = {},
  match,
  platformBookings,
  communicationRequest,
  currentOperator,
  getAllPlatformsReq = () => {},
  getPlatformBookingsReq = () => {},
  sendCommunicationRequestReq = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [params, setParams] = useState(getInitParams({
    match,
  }));
  const [selBookings, setSelBookings] = useState(null);
  const tabs = useMemo(
    () => getTabs({
      currentOperator,
      t,
    }),
    // eslint-disable-next-line
    [currentOperator]
  );
  const [prevParams, setPrevParams] = useState('');
  const [modalOption, setModalOption] = useState({
    isModalOpen: false,
  });
  const [modalCarInfoOption, setModalCarInfoOption] = useState({
    isModalCarInfoOpen: false,
  });

  const soundNode = useUpcomingReservationNotification();

  useEffect(
    () => {
      const { loading, platforms } = allPlatforms;
      const { data } = platforms || {};
      const isPlatformsLoaded = !!data;

      if (isPlatformsLoaded || loading) {
        return;
      }

      getAllPlatformsReq();
    },
    // eslint-disable-next-line
    [],
  );
  useEffect(
    () => {
      const {
        bookings,
        reqParam,
        currentStrParam,
      } = getCurrentBookings({
        platformBookings,
        params,
        tabs,
      });

      if (currentStrParam !== prevParams) {
        const nowTime = new Date().getTime();
        const { timeStamp = nowTime } = bookings || {};

        if (!bookings || (nowTime - timeStamp) / 1000 > BOOKINGS_UPDATE_PERIOD) {
          requestPlatformBooking({
            reqParam,
            getPlatformBookingsReq,
          });

          setPrevParams(currentStrParam);
        }
      }

      const interval = setInterval(
        () => {
          requestPlatformBooking({
            reqParam,
            getPlatformBookingsReq,
          });
        },
        BOOKINGS_UPDATE_PERIOD * 1000,
      );

      return () => {
        clearInterval(interval);
      };
    },
    // eslint-disable-next-line
    [params],
  );

  const { userRights } = currentOperator;
  const {
    isReadReportDocuments,
    [`is${RIGHT_KEYS.connectWithDriver}`]: isConnectWithDriver,
  } = userRights;
  const isLoading = (platformBookings || {}).loading;

  const { id } = (match || {}).params || {};
  const isId = !!id || id === 0;

  const {
    bookings,
  } = getCurrentBookings({
    platformBookings,
    params,
    tabs,
  });

  const {
    bookingsData,
    bookingsMeta,
  } = (bookings || {}).data || {};

  const {
    currentPage = 1,
    lastPage = 1,
  } = bookingsMeta || {};

  const communicationRequestList = (communicationRequest || {}).list || [];

  const {
    tabValue,
    selPlatformValue,
    searchValue,
    selStatusValue,
    selDate,
    sorting,
  } = params;

  const {
    isModalOpen,
    clickedPassCodeId,
    clickedReservationId,
  } = modalOption;

  const {
    isModalCarInfoOpen,
    carInfo: clickedCarInfo,
  } = modalCarInfoOption;

  const handleParamChange = (paramName, newValue) => {
    if (params[paramName] === newValue) {
      return;
    }

    setParams((prevState) => ({
      ...prevState,
      page: 1,
      [paramName]: newValue,
    }));
  };
  function sortingSettingsChange({
    headerNameId,
  }) {
    const newSorting = {
      [headerNameId]: getNextSortStatus(sorting[headerNameId]),
    };

    handleParamChange('sorting', newSorting);
  }
  function onPageChange(_, pageNumber) {
    handleParamChange('page', pageNumber);
  }
  function communicationBtnClick({
    passCodeId,
    reservationId,
  }) {
    setModalOption((prevModalOption) => ({
      ...prevModalOption,
      isModalOpen: true,
      clickedPassCodeId: passCodeId,
      clickedReservationId: reservationId,
    }));
  }
  function handleCloseModal() {
    setModalOption((prevModalOption) => ({
      ...prevModalOption,
      isModalOpen: false,
    }));
  }
  function handleCommunicationAgree({
    reservationId,
    msg,
  }) {
    setModalOption((prevModalOption) => ({
      ...prevModalOption,
      isModalOpen: false,
      clickedPassCodeId: null,
      clickedReservationId: null,
    }));

    sendCommunicationRequestReq({
      reservationId,
      message: msg,
    });
  }
  function showCarInfoClick({
    carInfo,
  }) {
    setModalCarInfoOption((prevModalCarInfoOption) => ({
      ...prevModalCarInfoOption,
      isModalCarInfoOpen: true,
      carInfo,
    }));
  }
  function handleCloseCarInfoModal() {
    setModalCarInfoOption((prevModalCarInfoOption) => ({
      ...prevModalCarInfoOption,
      isModalCarInfoOpen: false,
    }));
  }

  return (
    <Container className={classes.container}>
      <HeaderWithBackBtn
        title={t('bookings.bookings')}
        isBackBtn={isId}
      />
      {soundNode}
      <div className={classes.pageBody}>
        <CustomTabBar
          tabs={tabs}
          tabValue={tabValue}
          handleParamChange={handleParamChange}
        />
        <BookingSelectors {...{
          allPlatforms,
          selPlatformValue,
          searchValue,
          selStatusValue,
          selDate,
          handleParamChange,
        }}
        />
        {!isLoading && (
          <>
            <BookingsTable {...{
              bookingsData,
              communicationRequestList,
              headerSortSettings: sorting,
              selBookings,
              isCostColumn: isReadReportDocuments,
              isConnectWithDriverColumn: isConnectWithDriver,
              setSelBookings,
              communicationBtnClick,
              sortingSettingsChange,
              showCarInfoClick,
            }}
            />
            {lastPage > 1 && (
              <div className={classes.paginationContainer}>
                <Pagination
                  className={classes.pagination}
                  name="page"
                  page={currentPage}
                  onChange={onPageChange}
                  count={lastPage}
                  color="primary"
                />
              </div>
            )}
          </>
        )}
        {isLoading && (
          <CircularIndeterminate style={{ minHeight: 600 }} />
        )}
      </div>
      {isModalOpen && (
        <DialogSendMsg
          open={isModalOpen}
          passCodeId={clickedPassCodeId}
          reservationId={clickedReservationId}
          handleAgree={handleCommunicationAgree}
          handleClose={handleCloseModal}
        />
      )}
      {isModalCarInfoOpen && (
        <DialogShowCarInfo
          open={isModalCarInfoOpen}
          carInfo={clickedCarInfo}
          handleClose={handleCloseCarInfoModal}
        />
      )}
    </Container>
  );
}

BookingsPage.propTypes = propTypes;

function mapStateToProps(state) {
  const { platform, operators, bookings } = state || {};
  const { allPlatforms } = platform || {};
  const { platformBookings, communicationRequest } = bookings || {};
  const { currentOperator } = operators || {};

  return {
    allPlatforms,
    platformBookings,
    communicationRequest,
    currentOperator,
  };
}

const ConnectedBookingsPage = connect(
  mapStateToProps,
  {
    getAllPlatformsReq: getAllPlatforms,
    getPlatformBookingsReq: getPlatformBookingsAction,
    sendCommunicationRequestReq: sendCommunicationRequestAction,
  },
)(BookingsPage);

export default ConnectedBookingsPage;
