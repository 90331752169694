import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 5,
    display: 'flex',
    gap: '10px',
  },
  button: {
    backgroundColor: theme.palette.blueC.blue,
    height: 56,
    width: 56,
    padding: 0,
    '&.MuiIconButton-root:hover': {
      backgroundColor: theme.palette.blueC.blue,
    },
  },
  popper: {
    '& .MuiTooltip-tooltip': {
      fontSize: theme.typography.fontSize12,
      margin: '8px 20px',
      backgroundColor: theme.palette.greyC.darkGrey,
    },
  },
  phoneStr: {
    fontSize: 32,
  },
}));

export default {
  useStyles,
};
