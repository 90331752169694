import React from 'react';

function CanceledIcon(props) {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
      <rect
        x={1}
        y={1}
        width={16}
        height={16}
        rx={8}
        stroke="#9563FF"
        strokeWidth={1.5}
      />
      <path
        d="M6.778 5h3.313A2.91 2.91 0 0113 7.91v0a2.91 2.91 0 01-2.91 2.908H6m0 0l2.333-2.182M6 10.818L8.333 13"
        stroke="#9563FF"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CanceledIcon;
