export const COOKIE_NAMES = {
  accessToStaging: 'accessToStaging',
};

export const SUPPORT_PHONE_STR = '+7 495 477 47 04';

// seconds
export const BOOKINGS_UPDATE_PERIOD = 15;
export const MOBILE_PAYMENTS_UPDATE_PERIOD = 30;
export const SUBSCRIPTION_UPDATE_PERIOD = 30;
export const ANALYTICS_UPDATE_PERIOD = 60 * 15;

export const BLOCKED_BY_TYPE = {
  carNumber: 'by_car_number',
  phoneNumber: 'by_phone',
};

export const STATE_NAMES = {
  blockedUsers: 'blockedUsers',
  platformAnalytics: 'platformAnalytics',
  platformDurationAnalytics: 'platformDurationAnalytics',
};

export const PATH_PAGES = {
  analytics: '/analytics',
  analyticsId: '/analytics/:id',
  platforms: '/platforms',
  platformsAll: '/platforms/all',
  onePlatform: '/platforms/:id',
  platformSchedule: '/platforms/:id/schedule',
  operators: '/operators',
  operatorsAll: '/operators/all',
  oneOperator: '/operators/:id',
  operatorActions: '/operators/actions',
  addNewOperator: '/operators/new',
  bookings: '/bookings',
  bookingsId: '/bookings/:id',
  mobilePayments: '/mobile-payments',
  mobilePaymentsId: '/mobile-payments/:id',
  documents: '/documents',
  subscriptions: '/subscriptions',
  subscriptionsAll: '/subscriptions/all',
  oneSubscription: '/subscriptions/:id',
  blockedUsers: '/blocked-users',
  blockedUsersAll: '/blocked-users/all',
  blockedUsersItem: '/blocked-users/:facilityId/:id',
  addNewBlockedUser: '/blocked-users/new',
  notEnoughRights: '/not-enough-rights',
};

export const RIGHT_KEYS = {
  readPlatformsList: 'read_facilities_list',
  readPlatformInfo: 'read_facilities_info',
  changeSchedule: 'update_facilities_schedule_and_park_spaces',
  createChangeOperator: 'create_and_update_operators',
  readOperatorsList: 'read_operators_list',
  readOperatorsActions: 'read_operators_actions',
  readActiveBookingsList: 'read_active_bookings_list',
  readHistoryBookingsList: 'read_history_bookings_list',
  readOperatorsInfo: 'read_operators_info',
  readAnalyticDuration: 'read_analytic_duration',
  readDistributionByDuration: 'read_analytic_distribution_by_duration',
  readReportDocuments: 'read_report_documents',
  readBlockedUsers: 'read_blocked_users',
  createUpdateBlockedUsers: 'create_and_update_blocked_users',
  connectWithDriver: 'connect_with_driver',
  readSubscriptions: 'read_subscription_list'
};

export const SCHEDULE_SETTINGS_TAB_TYPES = {
  time: 'time',
  parkSpace: 'parkSpace',
  tariff: 'tariff',
  parkSpaceOnly: 'parkSpaceOnly',
};

export const SCHEDULE_SETTINGS_TYPES = {
  day: 'day',
  week: 'week',
  default: 'default',
};

export const SCHEDULE_SETTINGS_TYPES_BACKEND = {
  day: 'day',
  week: 'week_day',
  default: 'every_day',
};

export const BOOKINGS_SORT_TYPES = {
  id: 'id',
  checkOut: 'finish_time',
  checkIn: 'start_time',
  userName: 'user_name',
  carNumber: 'car_number',
  status: 'status',
};

export const MOBILE_PAYMENTS_SORT_TYPES = {
  ticketNumber: 'ticket_number',
  checkOut: 'exit_time',
  checkIn: 'enter_time',
  userName: 'user_name',
  totalCost: 'total_cost',
  paidAt: 'paid_at',
};

export const SCHEDULE_SETTINGS_TYPES_BACKEND_UPD_REQ = {
  day: 2,
  week: 1,
  default: 0,
};

export const STATUS_KEYS = {
  paid: 2,
  completed: 5,
  rejected: 4,
  cancelled: 3,

  draft: 0,
  hidden: 9,

  deleted: 10,

  notPaid: 1,
  paidRejected: 11,
};

export const SUBSCRIPTION_STATUS_KEYS = {
  paid: 2,
  active: 4,
  finished: 7,
  stopped: 8,
};

export const PLATFORM_TYPES = {
  reservation: 0,
  mobilePayment: 1,
  all: 2,
};

export const TARIFF_TYPES = {
  hours: 'hours',
  days: 'days',
  month: 'month'
}

export const TARIFF_UNIT_TYPES = {
  hours: 'ч',
  days: 'сут',
  month: 'мес'
}

export default {
  ANALYTICS_UPDATE_PERIOD,
  BOOKINGS_UPDATE_PERIOD,
  PATH_PAGES,
  SCHEDULE_SETTINGS_TAB_TYPES,
  SCHEDULE_SETTINGS_TYPES,
  STATUS_KEYS,
  SUBSCRIPTION_STATUS_KEYS,
  PLATFORM_TYPES,
  BOOKINGS_SORT_TYPES,
  MOBILE_PAYMENTS_SORT_TYPES,
};
