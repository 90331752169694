import {
  GET_BLOCKED_USERS,
} from 'actions/types';
import { updateStoreReqRes } from '@parkly/shared/helpers';
import { STATE_NAMES } from 'config/constants';

const INITIAL_STATE = {
  [STATE_NAMES.blockedUsers]: {
    loading: false,
    list: [],
  },
};

export default function blockedUsers(
  state = INITIAL_STATE,
  action,
) {
  switch (action.type) {
  case GET_BLOCKED_USERS:
    return updateStoreReqRes(
      state,
      STATE_NAMES.blockedUsers,
      action,
    );
  default:
    return state;
  }
}
