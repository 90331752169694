import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';

import { logout } from '@parkly/shared/actions/auth';

import { useStyles } from './styles';

function SignOutPage({
  logout = () => {},
}) {
  const classes = useStyles();
  const history = useHistory();

  useEffect(
    () => {
      logout(history);
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <Container
      className={classes.root}
      component="main"
      maxWidth="xs"
    >
      <CircularIndeterminate />
    </Container>
  );
}

const connectedSignOutPage = connect(
  null,
  {
    logout,
  },
)(SignOutPage);

export default connectedSignOutPage;
