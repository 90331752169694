import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  boxBasic: {
    marginTop: 44,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  titleStr: {
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: 80,
  },
  gridContainer: {
    margin: 0,
  },
  priceListItem: {
    minHeight: 38,
    padding: 0,
    paddingTop: 10,
  },
  priceListTitleStr: {
    fontWeight: 'normal',
  },
  boxWriteToUS: {
    backgroundColor: theme.palette.greyC.lightGrey,
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 72,
    marginTop: 25,
    padding: 16,
    width: '100%',
  },
  writeToUsBtn: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    width: 'auto',
  },
  markdown: {
    fontSize: theme.typography.fontSize14,
    '& p:first-child': {
      marginTop: 0,
    },
  },
}));

export default {
  useStyles,
};
