export const LOCALSTORAGE_KEYS = {
  user: 'user',
  persist: 'persist',
  currentFacility: 'currentFacility',
};

export const COOKIE_NAMES = {
  accessToStaging: 'accessToStaging',
};

export const CHART_CONTAINER_ID = 'CHART_CONTAINER_ID';
export const TOOLTIP_CONTAINER_ID = 'tooltip';

export const SUPPORT_PHONE_STR = '+7 495 477 47 04';

export const DEFAULT_PATH_PAGES = {
  home: '/',
  accessRedirect: '/hfs9u',
  login: '/login',
  logout: '/logout',
};

export const MAX_PHONE_NUMBER_LENGTH = '+7 (999) 999 99 99'.length;

export default {
  LOCALSTORAGE_KEYS,
  COOKIE_NAMES,
  SUPPORT_PHONE_STR,
  DEFAULT_PATH_PAGES,
};
