import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  settingsContainer: {
    marginTop: 44,
  },
  settingsList: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 22,
  },
  blockedUsersLink: {
    width: 'fit-content',
  },
}));

export default {
  useStyles,
};
