import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogMain: {
    '& .MuiPaper-root': {
      width: 450,
    },
  },
  titleStr: {
    fontWeight: 'bold',
    fontSize: theme.typography.fontSize16,
  },
  gridContainer: {
    margin: 0,
    paddingRight: 24,
  },
  gridItemContainer: {
    minHeight: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  dialogActions: {
    marginTop: 8,
    padding: 24,
  },
  timeStr: {},
  dateStr: {
    marginTop: 7,
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
}));

export default {
  useStyles,
};
