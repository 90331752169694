import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { useStyles } from './styles';

/* Help functions */

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/* Components */

const CustomAppBar = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
  },
}))(AppBar);

const CustomTabs = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.greyC.buttonGrey}`,
  },
  scrollButtons: {
    color: theme.palette.text.primary,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minWidth: 'unset',
    textTransform: 'none',
    color: theme.palette.greyC.gray2,
    fontWeight: 'bold',
    fontSize: 14,
    padding: 0,
    marginRight: 24,
  },
  selected: {
    color: theme.palette.text.primary,
  },
}))(Tab);

function CustomTabBar({
  className,
  tabs = [],
  tabValue = 0,
  handleParamChange = () => {},
}) {
  const classes = useStyles();

  const customTabsClass = [
    (tabs.length === 1) ? classes.oneTabTabsClass : '',
  ];

  function handleTabChange(event, newValue) {
    handleParamChange('tabValue', newValue);
  }

  return (
    <CustomAppBar
      className={className}
      position="static"
    >
      <CustomTabs
        className={customTabsClass.join(' ')}
        value={tabValue}
        scrollButtons="auto"
        variant="scrollable"
        onChange={handleTabChange}
        aria-label="simple tabs example"
      >
        {tabs.map((tab, index) => {
          const { label, id } = tab || {};

          return (
            <CustomTab key={id || label} label={label} {...a11yProps(id || index)} />
          );
        })}
      </CustomTabs>
    </CustomAppBar>
  );
}

export default CustomTabBar;
