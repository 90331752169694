import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import ChangeScheduleModal from 'components/organisms/ChangeScheduleModal';

import {
  getPlatformScheduleAction,
  updPlatformScheduleAction,
} from 'actions';
import { getScheduleTimeStr } from '@parkly/shared/helpers/time';
import { PATH_PAGES, SCHEDULE_SETTINGS_TYPES } from 'config/constants';

/* help functions */

function getCurrentSchedule({
  platformSchedules,
  momentDate,
  platformId,
}) {
  const monthStr = moment(momentDate).format('YYYY-MM');
  const { list } = platformSchedules || {};
  const currentSchedule = (list || []).find(({ requestParam }) =>
    // eslint-disable-next-line
    +(requestParam || {}).id === +platformId && requestParam.month === monthStr);

  return {
    currentSchedule,
    monthStr,
  };
}

/* Main component */

const propTypes = {
  isOpen: PropTypes.bool,
};

function ScheduleChanger({
  isOpen,
  momentDate,
  platformId,
  tab,
  type,
  handleClose = () => {},

  platformSchedules,
  updPlatformScheduleRes,
  getPlatformScheduleReq = () => {},
  updPlatformScheduleReq = () => {},
}) {
  useEffect(
    () => {
      if (!isOpen) {
        return;
      }

      const {
        currentSchedule,
        monthStr,
      } = getCurrentSchedule({
        platformSchedules,
        momentDate,
        platformId,
      });

      if (!currentSchedule) {
        getPlatformScheduleReq({
          id: platformId,
          month: monthStr,
        });
      }
    },
    // eslint-disable-next-line
    [platformId, momentDate, isOpen],
  );

  if (!isOpen) {
    return null;
  }

  const {
    currentSchedule,
  } = getCurrentSchedule({
    platformSchedules,
    momentDate,
    platformId,
  });
  const { data } = currentSchedule || {};
  const {
    days,
    everyDayPlaces,
    everyDaySchedule,
    tariffs,
  } = data || {};

  const defaultTariffId = (everyDaySchedule || {}).tariffId || 0;
  const defaultParkSpace = (everyDayPlaces || {}).count;
  const defParkSpaceRuleId = (everyDayPlaces || {}).id;
  const defTimeStart = (everyDaySchedule || {}).availableFrom;
  const defTimeEnd = (everyDaySchedule || {}).availableTo;
  const defTariffObj = {
    name: (everyDaySchedule || {}).tariff,
    id: (everyDaySchedule || {}).tariffId,
  };
  const defTimeRuleId = (everyDaySchedule || {}).id;

  const dateStr = moment(momentDate).format('YYYY-MM-DD');
  const dayWithRule = (days || []).find(({ date }) => date === dateStr);

  const scheduleLinkStr = PATH_PAGES.platformSchedule.replace(':id', platformId);

  const isLoading = (updPlatformScheduleRes || {}).loading || !data;

  function handleApplyChanges(changesParam) {
    handleClose();
    const {
      dayWithRule: initDayWithRule,
      type: changesParamType,
      parkSpace,
      timeEnd,
      timeStart,
      tariffId,
      isAvailable,
    } = changesParam || {};

    const defTimeStartStr = getScheduleTimeStr(defTimeStart);
    const defTimeEndStr = getScheduleTimeStr(defTimeEnd);

    const isTariffDefault = defaultTariffId === tariffId;
    const isParkSpaceDefault = defaultParkSpace.toString() === parkSpace.toString();
    const isTimeStartDefault = defTimeStartStr === timeStart;
    const isTimeEndDefault = defTimeEndStr === timeEnd;
    const isTimeDefault = isTimeStartDefault && isTimeEndDefault;

    const month = moment(momentDate).format('YYYY-MM');

    const defaultReqParam = {
      month,
      platformId,
      type: changesParamType,

      parkSpace,
      timeEnd,
      timeStart,
      isAvailable,
      tariffId,

      isParkSpaceDefault,
      isTimeDefault,
      isTariffDefault,
    };

    if (changesParamType === SCHEDULE_SETTINGS_TYPES.default) {
      const isChanged = !isParkSpaceDefault || !isTimeDefault;

      if (!isChanged) {
        return;
      }

      updPlatformScheduleReq({
        ...defaultReqParam,

        placesRuleId: defParkSpaceRuleId,
        timeRuleId: defTimeRuleId,
        isParkSpaceChanged: !isParkSpaceDefault,
        isTimeChanged: !isTimeDefault,
        placesRuleType: SCHEDULE_SETTINGS_TYPES.default,
        timeRuleType: SCHEDULE_SETTINGS_TYPES.default,
      });
    }

    const dayData = initDayWithRule;
    const dayDate = (dayData || {}).date;

    const isInitAvailable = dayData ? (dayData || {}).isAvailable : true;
    const initPlacesCount = ((dayData || {}).places || {}).count;
    const weekPlaces = initPlacesCount === 0
      ? initPlacesCount
      : initPlacesCount || defaultParkSpace;
    const initTimeStart = ((dayData || {}).schedule || {}).availableFrom;
    const initTimeEnd = ((dayData || {}).schedule || {}).availableTo;
    const initTimeStartStr = getScheduleTimeStr(initTimeStart || defTimeStart);
    const initTimeEndStr = getScheduleTimeStr(initTimeEnd || defTimeEnd);
    const initTariffId = ((dayData || {}).schedule || {}).tariffId;

    const initPlacesRuleId = ((dayData || {}).places || {}).id;
    const initPlacesRuleType = ((dayData || {}).places || {}).type;
    const initTimeRuleId = ((dayData || {}).schedule || {}).id;
    const initTimeRuleType = ((dayData || {}).schedule || {}).type;

    const isParkSpaceChanged = weekPlaces.toString() !== parkSpace.toString();
    const isTimeStartChanged = initTimeEndStr !== timeStart;
    const isTimeEndChanged = initTimeStartStr !== timeEnd;
    const isTimeChanged = isTimeStartChanged || isTimeEndChanged;
    const isAvailableChanged = isInitAvailable !== isAvailable;
    const isTariffChanged = initTariffId !== tariffId;

    const isChanged = isParkSpaceChanged || isTimeChanged || isAvailableChanged || isTariffChanged;

    if (!isChanged) {
      return;
    }

    const updParam = {
      ...defaultReqParam,

      isParkSpaceChanged,
      isTimeChanged,
      isAvailableChanged,
      isTariffChanged,

      placesRuleId: initPlacesRuleId,
      timeRuleId: initTimeRuleId,
      placesRuleType: initPlacesRuleType,
      timeRuleType: initTimeRuleType,
    };

    if (changesParamType === SCHEDULE_SETTINGS_TYPES.week) {
      updPlatformScheduleReq({
        ...updParam,
        weekDay: moment(dayDate).isoWeekday(),
      });
    }

    if (changesParamType === SCHEDULE_SETTINGS_TYPES.day) {
      updPlatformScheduleReq({
        ...updParam,
        dayDate,
      });
    }
  }

  return (
    <ChangeScheduleModal
      isOpen={isOpen}
      handleClose={handleClose}
      {...{
        type,
        tab,
        momentDate,
        dayWithRule,
        defaultParkSpace,
        defTimeStart,
        defTimeEnd,
        defTariffObj,
        scheduleLinkStr,
        tariffs,
        loading: isLoading,
        handleApplyChanges,
      }}
    />
  );
}

ScheduleChanger.propTypes = propTypes;

function mapStateToProps(state) {
  const { platform, operators } = state || {};
  const { platformSchedules, updPlatformScheduleRes } = platform || {};
  const { currentOperator } = operators || {};

  return {
    platformSchedules,
    currentOperator,
    updPlatformScheduleRes,
  };
}

const ConnectedScheduleChanger = connect(
  mapStateToProps,
  {
    getPlatformScheduleReq: getPlatformScheduleAction,
    updPlatformScheduleReq: updPlatformScheduleAction,
  },
)(ScheduleChanger);

export default ConnectedScheduleChanger;
