import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomDialog from '@parkly/shared/components/templates/CustomDialog';
import { useDidUpdateEffect } from '@parkly/shared/helpers';

import {
  formatPhoneNumber,
  cleanPhone,
} from '@parkly/shared/helpers/others';
import { checkIsPhoneValid } from '@parkly/shared/helpers/validations';

import {
  checkIsCarNumberValid,
} from 'helpers';
import { BLOCKED_BY_TYPE } from 'config/constants';

import { useStyles } from './styles';

/* Help functions */

function formatValue(name, value) {
  if (name === 'phoneNumber') {
    return formatPhoneNumber(value);
  }

  return value;
}

function getInitSettings({
  facilitiesList,
  initData,
}) {
  const {
    carNumber = '',
    phone: phoneNumber,
    comment,
    facilitiesChecked,
  } = initData || {};

  return {
    carNumber,
    phoneNumber: formatPhoneNumber(phoneNumber || ''),
    comment: comment || '',
    facilitiesChecked: facilitiesChecked || facilitiesList.map(({ id }) => id.toString()),
    blockedType: initData?.carNumber
      ? BLOCKED_BY_TYPE.carNumber
      : BLOCKED_BY_TYPE.phoneNumber,
  };
}

/* Main component */

const propTypes = {
  isRemoveBtn: PropTypes.bool,
  isFacilitySelectorDisabled: PropTypes.bool,
  isSelectorDisabled: PropTypes.bool,
  isSaveBtnDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onRemoveBlockedUser: PropTypes.func,
};

function BlockedUserForm({
  initData,
  facilitiesList = [],
  isRemoveBtn = true,
  isFacilitySelectorDisabled = false,
  isSelectorDisabled = false,
  isSaveBtnDisabled = false,
  isInputsDisabled = false,
  onSubmit = () => {},
  onRemoveBlockedUser = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [settings, setSettings] = useState(getInitSettings({
    facilitiesList,
    initData,
  }));
  const [initSettingsJSON, setInitSettingsJSON] = useState(JSON.stringify(settings));
  const [isRemoveDialog, setIsRemoveDialog] = useState(false);

  useDidUpdateEffect(
    () => {
      const newSettings = getInitSettings({
        facilitiesList,
        initData,
      });
      setSettings(newSettings);
      setInitSettingsJSON(JSON.stringify(newSettings));
    },
    [facilitiesList, initData],
  );

  const blockedByTypeList = [
    {
      id: BLOCKED_BY_TYPE.carNumber,
      title: t('blockedUsers.carNumber'),
    },
    {
      id: BLOCKED_BY_TYPE.phoneNumber,
      title: t('blockedUsers.phoneNumber'),
    },
  ];

  const {
    carNumber,
    phoneNumber,
    comment,
    blockedType,
    facilitiesChecked = [],
  } = settings;

  const isFormBtnDisabled = initSettingsJSON === JSON.stringify(settings);

  function updateSettings(propName, value) {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [propName]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [propName]: false,
    }));
  }
  function handleChanges(event) {
    const propName = event.target.name;
    const { value, checked } = event.target;
    const newValue = event.target.type !== 'checkbox' ? value : checked;
    const formattedValue = formatValue(propName, newValue);

    if (formattedValue === settings[propName]) {
      return;
    }

    updateSettings(propName, formattedValue);
  }
  function openRemoveDialog() {
    setIsRemoveDialog(true);
  }
  function handleCloseRemoveDialog() {
    setIsRemoveDialog(false);
  }
  function handleAgreeRemoveDialog() {
    onRemoveBlockedUser();
    setIsRemoveDialog(false);
  }
  function onFormSubmit(event) {
    event.preventDefault();

    const isValueValid = blockedType === BLOCKED_BY_TYPE.carNumber
      ? checkIsCarNumberValid(carNumber)
      : checkIsPhoneValid(cleanPhone(phoneNumber));
    const isCommentValid = comment?.length < 1255;
    const isFacilitiesListValid = facilitiesChecked.length > 0;
    const isValid = isCommentValid && isValueValid && isFacilitiesListValid;

    if (!isValid) {
      const propValue = blockedType === BLOCKED_BY_TYPE.carNumber
        ? 'carNumber'
        : 'phoneNumber';
      setErrors((prevErrors) => ({
        ...prevErrors,
        [propValue]: !isValueValid,
        comment: !isCommentValid,
        facilitiesChecked: !isFacilitiesListValid,
      }));

      return;
    }

    onSubmit(settings);
  }

  return (
    <>
      <form
        className={classes.form}
        onSubmit={onFormSubmit}
        autoComplete="off"
      >
        <Grid className={classes.pageBody} container spacing={2}>
          <Grid item className={classes.bodyMain} xs={12} sm={8}>
            <ItemSelectors
              classNameForm={classes.statusSelectorsForm}
              className={classes.statusSelectors}
              classNameLabel={classes.statusSelectorsLabel}
              label={t('blockedUsers.blockedType')}
              error={errors.blockedType}
              name="blockedType"
              currentValue={blockedType}
              items={blockedByTypeList}
              disabled={isSelectorDisabled}
              onChange={handleChanges}
            />
            {blockedType === BLOCKED_BY_TYPE.carNumber && (
              <CustomTextInput
                className={classes.inputItem}
                required
                error={errors.carNumber}
                name="carNumber"
                label={t('blockedUsers.carNumber')}
                value={carNumber}
                autoComplete="off"
                disabled={isInputsDisabled}
                inputProps={{
                  maxLength: 250,
                }}
                onChange={handleChanges}
              />
            )}
            {blockedType === BLOCKED_BY_TYPE.phoneNumber && (
              <CustomTextInput
                className={classes.inputItem}
                required
                error={errors.phoneNumber}
                name="phoneNumber"
                label={t('blockedUsers.phoneNumber')}
                value={phoneNumber}
                type="tel"
                autoComplete="off"
                disabled={isInputsDisabled}
                inputProps={{
                  maxLength: 250,
                }}
                onChange={handleChanges}
              />
            )}
            <CustomTextInput
              className={classes.inputItem}
              error={errors.comment}
              name="comment"
              label={t('blockedUsers.comment')}
              value={comment}
              autoComplete="off"
              multiline
              disabled={isInputsDisabled}
              onChange={handleChanges}
            />

            <Grid container className={classes.facilitiesContainer}>
              <TableContainer className={classes.tableContainer}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                      >
                        <Typography className={classes.headerStr}>
                          Площадки
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {facilitiesList.map(({ id, title }) => {
                      const strId = id.toString();
                      const checked = facilitiesChecked.includes(strId);

                      function onChange(event) {
                        const { checked: newChecked } = event.target;

                        const newFacilitiesChecked = (() => {
                          if (!checked && newChecked) {
                            return [
                              ...facilitiesChecked,
                              strId,
                            ];
                          }

                          if (checked && !newChecked) {
                            return facilitiesChecked.filter((itemId) => itemId !== strId);
                          }

                          return facilitiesChecked;
                        })();

                        updateSettings('facilitiesChecked', newFacilitiesChecked);
                      }

                      return (
                        <TableRow key={id}>
                          <TableCell
                            align="left"
                          >
                            <Typography className={classes.headerStr}>
                              {title}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="right"
                          >
                            <Checkbox
                              color="primary"
                              className={errors.facilitiesChecked ? classes.checkboxError : ''}
                              checked={checked}
                              disabled={isFacilitySelectorDisabled}
                              onChange={onChange}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.makeAdminContainer}>
          <Grid item xs={12} sm={8}>
            <Grid container className={classes.makeAdminContainer}>
              <Grid
                item
                sm={!isRemoveBtn ? 12 : 3}
                style={!isRemoveBtn ? {} : { maxWidth: 157 }}
              >
                <CustomBtn
                  type="submit"
                  className={classes.btnAddOrSave}
                  disabled={isSaveBtnDisabled || isFormBtnDisabled}
                >
                  {t('blockedUsers.save')}
                </CustomBtn>
              </Grid>
              {isRemoveBtn && (
                <Grid item sm={9}>
                  <CustomBtn
                    className={classes.btnRemove}
                    onClick={openRemoveDialog}
                  >
                    {t('blockedUsers.removeFromDisabled')}
                  </CustomBtn>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} />
        </Grid>
      </form>
      <CustomDialog
        open={isRemoveDialog}
        handleAgree={handleAgreeRemoveDialog}
        handleClose={handleCloseRemoveDialog}
        dialogContent={t('blockedUsers.removeQuestion')}
        agreeTitle={t('others.remove')}
        disagreeTitle={t('others.cancel')}
      />
    </>
  );
}

BlockedUserForm.propTypes = propTypes;

export default BlockedUserForm;
