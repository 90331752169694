import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import BlockedUserForm from 'components/organisms/BlockedUserForm';

import { useFetchedReduxData } from '@parkly/shared/helpers';
import { extractAllPlatformsFromState } from 'helpers';
import { addBlockedUsers } from 'api/blockedUsers';
import { PATH_PAGES } from 'config/constants';

import { useStyles } from './styles';

const propTypes = {};

function AddNewBlockedUserPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);
  const { content: allPlatforms } = useFetchedReduxData({ stateDataExtractor: extractAllPlatformsFromState });
  const facilities = allPlatforms.platforms.data;
  const facilitiesList = facilities.map(({ id, title }) => ({ id, title }));

  async function onAddNewBtn({
    carNumber,
    phoneNumber,
    comment,
    blockedType,
    facilitiesChecked,
  }) {
    setIsSaveBtnDisabled(true);
    Promise
      .all(facilitiesChecked?.map((id) => addBlockedUsers({
        facilityId: id,
        type: blockedType,
        carNumber,
        phoneNumber,
        comment,
      })))
      .then(() => {
        history.push(PATH_PAGES.blockedUsersAll);
      })
      .catch(() => {
        history.push(PATH_PAGES.blockedUsersAll);
      });
  }

  return (
    <Container
      className={classes.container}
      maxWidth="md"
    >
      <HeaderWithBackBtn
        title={t('blockedUsers.addToBlocked')}
      />
      <BlockedUserForm
        facilitiesList={facilitiesList}
        isRemoveBtn={false}
        isSaveBtnDisabled={isSaveBtnDisabled}
        onSubmit={onAddNewBtn}
      />
    </Container>
  );
}

AddNewBlockedUserPage.propTypes = propTypes;

export default AddNewBlockedUserPage;
