import * as React from 'react';

function EditIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M10.565 3.418a1.427 1.427 0 012.017 2.017l-6.808 6.808L3 13l.756-2.774 6.809-6.808z"
        stroke="#7E8AA7"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EditIcon;
