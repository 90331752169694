import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import EditIcon from '@parkly/shared/components/atoms/icons/EditIcon';

const CustomEditIconButton = withStyles(() => ({
  root: {
    height: 32,
    width: 32,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))(IconButton);

const CustomEditIconBox = withStyles(() => ({
  root: {
    height: 32,
    width: 32,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))(Box);

function ChangeIconBtn({
  type,
  editIconProps = {},
  onChangeClick = () => {},
  ...props
}) {
  if (type === 'div') {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <CustomEditIconBox onClick={onChangeClick} {...props}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <EditIcon {...editIconProps} />
      </CustomEditIconBox>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CustomEditIconButton onClick={onChangeClick} {...props}>
      <EditIcon />
    </CustomEditIconButton>
  );
}

export default ChangeIconBtn;
