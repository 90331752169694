import React from 'react';

import LogoIcon from '@parkly/shared/components/atoms/icons/LogoIcon';
import { useStyles } from './styles';

function CompanyLogoWithName({
  classStyle = '',
  width = 158,
  height = 48,
}) {
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${classStyle}`}>
      <LogoIcon
        width={width}
        height={height}
      />
    </div>
  );
}

export default CompanyLogoWithName;
