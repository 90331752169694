import { LOCALSTORAGE_KEYS } from '@parkly/shared/config/constants';

export function saveUser(user = {}) {
  localStorage.setItem(LOCALSTORAGE_KEYS.user, JSON.stringify(user));
}

export function removeUser() {
  localStorage.removeItem(LOCALSTORAGE_KEYS.user);
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function getCurrentUser() {
  const currentUser = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.user));

  return currentUser;
}

const authenticationService = {
  saveUser,
  removeUser,
  getCurrentUser,
};

export default authenticationService;
