import React from 'react';
// import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import ParentPageContainer from '@parkly/shared/components/templates/ParentPageContainer';
import PrivateRoute from 'components/templates/containers/PrivateRoute';
import BlockedUsersPageAll from 'components/pages/BlockedUsersPageAll';
import OneBlockedUser from 'components/pages/OneBlockedUser';
import AddNewBlockedUserPage from 'components/pages/AddNewBlockedUserPage';

import { useFetchedReduxData } from '@parkly/shared/helpers/hooks';
import { extractAllPlatformsFromState } from 'helpers';
import { getAllPlatforms } from 'actions';

import { PATH_PAGES } from 'config/constants';

const propTypes = {};

function BlockedUsersPage() {
  const { content: allPlatforms } = useFetchedReduxData({
    stateDataExtractor: extractAllPlatformsFromState,
    getAction: getAllPlatforms,
    updTimeSec: 86400,
  });

  const isLoading = allPlatforms?.loading || !allPlatforms.platforms?.data;

  return (
    <ParentPageContainer isLoading={isLoading}>
      <PrivateRoute exact path={PATH_PAGES.blockedUsersAll} component={BlockedUsersPageAll} />
      <PrivateRoute exact path={PATH_PAGES.addNewBlockedUser} component={AddNewBlockedUserPage} />
      <PrivateRoute exact path={PATH_PAGES.blockedUsersItem} component={OneBlockedUser} />
      <Redirect to={PATH_PAGES.blockedUsersAll} />
    </ParentPageContainer>
  );
}

BlockedUsersPage.propTypes = propTypes;

export default BlockedUsersPage;
