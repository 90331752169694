import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  typeFullPage: {
    position: 'fixed',
    width: '100%',
    minHeight: 600,
    height: '100%',
  },
  circular: {
    // margin: 'auto',
  },
  typeFullWidth: {
    width: '100%',
    margin: '20px 0',
  },
  typeFullContainer: {
    position: 'initial',
    width: '100%',
    height: '100%',
  },
  blurBackground: {
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
}));

function getClassByType(classes, type) {
  switch (type) {
  case 'fullPage':
    return classes.typeFullPage;

  case 'fullWidth':
    return classes.typeFullWidth;

  case 'fullContainer':
    return classes.typeFullContainer;

  default:
    return '';
  }
}

function CircularIndeterminate({
  type = 'default',
  style,
  blur = false,
}) {
  const classes = useStyles();

  const typeClass = getClassByType(classes, type);
  const classStr = `${classes.root} ${typeClass} ${blur ? classes.blurBackground : ''}`;

  return (
    <div className={classStr} style={style}>
      <CircularProgress className={classes.circular} />
    </div>
  );
}

export default CircularIndeterminate;
