import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginLeft: 0,
  },
  pageBody: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
  },
  tableWithoutRightRow: {

  },
  tableRow: {
    cursor: 'pointer',
    '&.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.greyC.lightGrey,
    },
  },
  tableRowLast: {
    borderBottom: 'none',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  headerTime: {
    fontWeight: 'bold',
    marginLeft: 'auto',
    fontSize: theme.typography.fontSize16,
  },
  tableContainer: {
    border: `1px solid ${theme.palette.greyC.buttonGrey}`,
    borderRadius: 6,
  },
  headerStr: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
  tableCell: {
  },
  tCellHovered: {
    '&:hover': {
      backgroundColor: theme.palette.greyC.buttonGrey,
      '& a': {
        color: theme.palette.blueC.blue5,
        textDecoration: 'none',
      },
    },
  },
  tableBody: {

  },
  tableCellNameAddress: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'space-between',
  },
  tableCellName: {

  },
  tableCellAddress: {
    color: theme.palette.greyC.darkGrey,
    fontSize: theme.typography.fontSize12,
  },
}));

export default {
  useStyles,
};
