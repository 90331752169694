import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  applyBtn: {

  },
  tabPanel: {
    padding: 0,
    '& .MuiBox-root': {
      padding: 0,
      paddingTop: 24,
      paddingBottom: 24,
    },
  },
  loadingContainer: {
    minHeight: 235,
  },
  form: {},
}));

export const useStylesTimeScheduleEditor = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 235,
    padding: 0,
  },
  baseContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  base: {
    padding: 0,
    marginLeft: 5,
    color: theme.palette.greyC.darkGrey,
    fontSize: 14,
  },
  timesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.greyC.lightGrey,
    padding: 12,
    borderRadius: 6,
  },
  error: {
    backgroundColor: theme.palette.redC.red4,
  },
  textFieldParkSpace: {
    minWidth: 'unset',
    width: 59,
    '& .MuiInputLabel-root': {
      color: theme.palette.greyC.darkGrey,
      fontSize: 14,
      textTransform: 'capitalize',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(0.8571)',
    },
  },
  textFieldDivider: {
    backgroundColor: theme.palette.greyC.darkGrey,
    margin: 9,
    width: 8,
    height: 1,
  },
  makeDayOffContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
  },
  switch: {
    marginLeft: 10,
  },
  btnLikeLink: {
    color: theme.palette.text.blue,
    textDecoration: 'underline',
    textTransform: 'none',
    fontSize: 14,
    minWidth: 'unset',
    width: 'max-content',
    padding: 0,
    marginTop: 40,
    justifyContent: 'flex-start',
  },
}));

export default {
  useStyles,
  useStylesTimeScheduleEditor,
};
