import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import 'fontsource-roboto';
import ErrorBoundary from '@parkly/shared/components/templates/containers/ErrorBoundary';

import { store, persistor } from 'store';
import {
  checkIsStaging,
  checkProduction,
} from '@parkly/shared/helpers/others';
import App from './App';
import 'helpers/i18n';
import * as serviceWorker from './serviceWorker';
import 'index.css';
import '@parkly/shared/config/fonts/Lato/lato.css';

if (checkProduction()) {
  const isStaging = checkIsStaging();

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `ParklyOffice@${process.env.REACT_APP_VERSION}`,
    environment: isStaging ? 'staging' : 'production',
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
