import React from 'react';

function CloseIcon(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <circle cx={16} cy={16} r={16} fill="#001A54" fillOpacity={0.08} />
      <path
        d="M20.5 11.5l-9 9M11.5 11.5l9 9"
        stroke="#1D3262"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CloseIcon;
