/* eslint-disable camelcase */
import { callAPI } from '@parkly/shared/api/base';

export async function validGetSubscriptions({
  res,
}) {
  const { data } = res || {};
  const curData = (data || {}).data || [];
  const curMeta = (data || {}).meta || {};

  const {
    current_page,
    from,
    last_page,
    path,
    per_page,
    to,
    total,
  } = curMeta || {};

  const subscriptionsMeta = {
    currentPage: current_page,
    from,
    lastPage: last_page,
    path,
    perPage: per_page,
    to,
    total,
  };

  const subscriptionsData = curData.map((item) => {
    const {
      id,
      tariffName,
      carNumber,
      carBrand,
      startTime,
      finishTime,
      status,
      userName,
      facilityName,
    } = item;

    return {
      id,
      tariffName,
      carNumber,
      carBrand,
      startTime,
      finishTime,
      status,
      userName,
      facilityName,
    };
  });

  const result = {
    subscriptionsData,
    subscriptionsMeta,
  };

  return result;
}

export async function validGetOneSubscription(result) {
  const { res } = result || {};
  const { data } = res || {};
  const resultData = (data || {}).data || {};

  const {
    id,
    tariffName,
    carNumber,
    carBrand,
    startTime,
    finishTime,
    status,
    userName,
    facilityName,
  } = resultData;


  return {
    id,
    tariffName,
    carNumber,
    carBrand,
    startTime,
    finishTime,
    status,
    userName,
    facilityName,
  };
}

/* API requests */

export async function getOneSubscription({
  id,
}) {
  const endpoint = `subscriptions/${id}`;

  return callAPI(endpoint)
    .then((res) => validGetOneSubscription({
      res,
    }));
}

export async function getSubscriptions({
  id,
  status,
  page,
}) {
  /* eslint-disable */
  const query = '?'
    + (id ? `facility_id=${id}&` : '')
    + (status ? `status=${status}&` : '')
    + (page && (page !== 1)  ? `page=${page}&` : '')
  /* eslint-enable */

  const queryParams = query.slice(0, -1);

  const endpoint = `subscriptions${queryParams}`;

  return callAPI(endpoint)
    .then((res) => validGetSubscriptions({
      res,
    }));
}

export default {
  getSubscriptions,
  getOneSubscription,
};
