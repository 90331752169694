import React from 'react';

function BookingsIconActive({
  fill = '#ffffff',
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.052 3.51c.68-.68 1.783-.68 2.463 0l1.407 1.407c.705.705.602 1.732.195 2.398-.552.903-.442 1.785.17 2.398.613.612 1.495.722 2.398.17.666-.407 1.693-.51 2.398.195l1.407 1.407c.68.68.68 1.783 0 2.463L13.948 28.49c-.68.68-1.783.68-2.463 0l-1.407-1.407c-.705-.705-.602-1.732-.195-2.398.552-.903.442-1.785-.17-2.398-.491-.49-1.463-.678-2.409-.143-.66.374-1.681.484-2.387-.221L3.51 20.514a1.741 1.741 0 010-2.463L18.052 3.51zM17 11.248a.75.75 0 10-1.06 1.06l3.753 3.753a.75.75 0 001.06-1.06L17 11.248z"
        fill={fill}
      />
    </svg>
  );
}

export default BookingsIconActive;
