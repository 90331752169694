import React from 'react';

function UnPaidIcon(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <rect
        x={1}
        y={1}
        width={16}
        height={16}
        rx={8}
        stroke="#7E8AA7"
        strokeWidth={1.5}
      />
      <path
        d="M9 4.5V9l4 3"
        stroke="#7E8AA7"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UnPaidIcon;
