import {
  GET_PLATFORM_ANALYTICS,
  GET_PLATFORM_DURATION_ANALYTICS,
} from 'actions/types';
import { updateStoreReqRes } from '@parkly/shared/helpers';
import { STATE_NAMES } from 'config/constants';

const INITIAL_STATE = {
  [STATE_NAMES.platformAnalytics]: {
    loading: false,
    list: [],
  },
  [STATE_NAMES.platformDurationAnalytics]: {
    loading: false,
    list: [],
  },
};

export default function analytics(
  state = INITIAL_STATE,
  action,
) {
  switch (action.type) {
  case GET_PLATFORM_ANALYTICS:
    return updateStoreReqRes(
      state,
      STATE_NAMES.platformAnalytics,
      action,
    );

  case GET_PLATFORM_DURATION_ANALYTICS:
    return updateStoreReqRes(
      state,
      STATE_NAMES.platformDurationAnalytics,
      action,
    );

  default:
    return state;
  }
}
