import React from 'react';
import Viewer from 'react-viewer';

import { useStyles } from './styles';

function ImageViewer({
  className = '',
  src = '',
  alt = '',
  openInNewTab = false,
  ...props
}) {
  const classes = useStyles();
  const [visible, setVisible] = React.useState(false);

  const classNameStr = `${classes.image} ${className}`;

  function showImg() {
    if (openInNewTab) {
      window.open(src, '_blank');
    } else {
      setVisible(true);
    }
  }
  function hideImg() {
    setVisible(false);
  }

  return (
    <>
      <Viewer
        className={classes.viewer}
        visible={visible}
        onClose={hideImg}
        onMaskClick={hideImg}
        images={[{ src, alt }]}

        noNavbar
        noToolbar
        noImgDetails
        noFooter
      />
      <img
        className={classNameStr}
        onClick={showImg}
        aria-hidden="true"
        src={src}
        alt={alt}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
      />
    </>
  );
}

export default ImageViewer;
