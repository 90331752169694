import React from 'react';
// import PropTypes from 'prop-types';

import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomDatePicker from '@parkly/shared/components/atoms/CustomDatePicker';

import { useStyles } from './styles';

function getHeaders(allOperatorsList = []) {
  const transformedOperatorsList = allOperatorsList.map(({ id, name }) => ({
    id,
    title: name,
  }));

  return transformedOperatorsList;
}

function OperatorActionsHeader({
  allOperatorsList,
  selOperatorId,
  selDate = null,
  updateSettings = () => {},
}) {
  const classes = useStyles();

  function handleSelectorChange(event) {
    updateSettings(event.target.name, event.target.value);
  }
  function handleDateChanges(date) {
    updateSettings('selDate', date);
  }

  const headerSelectors = getHeaders(allOperatorsList);

  return (
    <div className={classes.container}>
      <ItemSelectors
        name="selOperatorId"
        selectorsType="secondary"
        currentValue={selOperatorId}
        classNameForm={classes.itemSelectorsForm}
        className={classes.itemSelectors}
        items={headerSelectors}
        onChange={handleSelectorChange}
      />
      <CustomDatePicker
        className={classes.datePicker}
        value={selDate}
        allowKeybord
        showArrowBtns={false}
        onChange={handleDateChanges}
      />
    </div>
  );
}

export default OperatorActionsHeader;
