import { getPlatformAnalytics, getPlatformDurationAnalytics } from 'api/analytics';

import {
  GET_PLATFORM_ANALYTICS,
  GET_PLATFORM_DURATION_ANALYTICS,
} from './types';

export function getPlatformAnalyticsReqAction(param) {
  const {
    id,
    day,
  } = param || {};

  const apiParam = {
    id,
    day,
  };

  function getPlatformAnalyticsRequest(payload) {
    return { type: GET_PLATFORM_ANALYTICS, payload };
  }

  return (dispatch) => {
    dispatch(getPlatformAnalyticsRequest({
      loading: true,
    }));

    getPlatformAnalytics(apiParam)
      .then(
        (data) => {
          dispatch(getPlatformAnalyticsRequest({
            loading: false,
            data,
            param: apiParam,
          }));
        },
        (error) => {
          dispatch(getPlatformAnalyticsRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function getPlatDrtnAnalyticsReqAction(param) {
  const {
    id,
    startDate,
    endDate,
  } = param || {};

  const apiParam = {
    id,
    startDate,
    endDate,
  };

  function getPlatDrtnAnalyticsRequest(payload) {
    return { type: GET_PLATFORM_DURATION_ANALYTICS, payload };
  }

  return (dispatch) => {
    dispatch(getPlatDrtnAnalyticsRequest({
      loading: true,
    }));

    getPlatformDurationAnalytics(apiParam)
      .then(
        (data) => {
          dispatch(getPlatDrtnAnalyticsRequest({
            loading: false,
            data,
            param: apiParam,
          }));
        },
        (error) => {
          dispatch(getPlatDrtnAnalyticsRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export default {
  getPlatformAnalyticsReqAction,
  getPlatDrtnAnalyticsReqAction,
};
