import React from 'react';

function SubscriptionsInactiveIcon({
  size = 32,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 12C13 11.5858 13.3358 11.25 13.75 11.25H16.5C18.1569 11.25 19.5 12.5931 19.5 14.25C19.5 15.9069 18.1569 17.25 16.5 17.25H14.5V20C14.5 20.4142 14.1642 20.75 13.75 20.75C13.3358 20.75 13 20.4142 13 20V16.5V12ZM14.5 15.75H16.5C17.3284 15.75 18 15.0784 18 14.25C18 13.4216 17.3284 12.75 16.5 12.75H14.5V15.75Z"
        fill="#FFFFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53033 16.5303C9.23744 16.8232 8.76256 16.8232 8.46967 16.5303L6 14.0607L3.53033 16.5303C3.23744 16.8232 2.76256 16.8232 2.46967 16.5303C2.17678 16.2374 2.17678 15.7626 2.46967 15.4697L5.46967 12.4697C5.61032 12.329 5.80109 12.25 6 12.25C6.19891 12.25 6.38968 12.329 6.53033 12.4697L9.53033 15.4697C9.82322 15.7626 9.82322 16.2374 9.53033 16.5303Z"
        fill="#FFFFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5303 15.4697C29.2374 15.1768 28.7626 15.1768 28.4697 15.4697L26 17.9393L23.5303 15.4697C23.2374 15.1768 22.7626 15.1768 22.4697 15.4697C22.1768 15.7626 22.1768 16.2374 22.4697 16.5303L25.4697 19.5303C25.6103 19.671 25.8011 19.75 26 19.75C26.1989 19.75 26.3897 19.671 26.5303 19.5303L29.5303 16.5303C29.8232 16.2374 29.8232 15.7626 29.5303 15.4697Z"
        fill="#FFFFFF"
      />
      <path
        d="M7.6081 9.2809C7.34902 9.60409 7.40099 10.0761 7.72418 10.3352C8.04737 10.5943 8.51939 10.5423 8.77847 10.2191L7.6081 9.2809ZM24.3919 22.7191C24.651 22.3959 24.599 21.9239 24.2758 21.6648C23.9526 21.4057 23.4806 21.4577 23.2215 21.7809L24.3919 22.7191ZM16 25.25C10.8914 25.25 6.75 21.1086 6.75 16H5.25C5.25 21.9371 10.0629 26.75 16 26.75V25.25ZM16 6.75C21.1086 6.75 25.25 10.8914 25.25 16H26.75C26.75 10.0629 21.9371 5.25 16 5.25V6.75ZM6.75 16V14.125H5.25V16H6.75ZM25.25 16V17.875H26.75V16H25.25ZM8.77847 10.2191C10.4749 8.10288 13.0792 6.75 16 6.75V5.25C12.6048 5.25 9.57697 6.82481 7.6081 9.2809L8.77847 10.2191ZM23.2215 21.7809C21.5251 23.8971 18.9208 25.25 16 25.25V26.75C19.3952 26.75 22.423 25.1752 24.3919 22.7191L23.2215 21.7809Z"
        fill="#FFFFFF"
      />
      <path
        d="M6 13.5V16C6 21.5228 10.4772 26 16 26C19.491 26 22.5642 24.2111 24.353 21.5M26 18.5V16C26 10.4772 21.5228 6 16 6C12.509 6 9.43576 7.78889 7.64702 10.5"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SubscriptionsInactiveIcon;
