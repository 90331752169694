import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';

import { useStyles } from './styles';

const propTypes = {
  open: PropTypes.bool,
  carInfo: PropTypes.exact({
    brand: PropTypes.string,
    model: PropTypes.string,
    vin: PropTypes.string,
    owner: PropTypes.string,
    year: PropTypes.number,
    weight: PropTypes.number,
    isCarSharing: PropTypes.bool,
    taxiExpiredAt: PropTypes.string,
  }),
  handleClose: PropTypes.func,
};

function DialogShowCarInfo({
  open,
  carInfo,
  handleClose = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      className={classes.dialogMain}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography className={classes.titleStr}>
          {t('carInfo.title')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid className={classes.gridContainer} container spacing={3}>
          <GridTitleBase
            classNameContainer={classes.gridItemContainer}
            title={t('carInfo.brand')}
            titleSm={5}
          >
            <Typography>
              {carInfo.brand || '-'}
            </Typography>
          </GridTitleBase>
          <GridTitleBase
            classNameContainer={classes.gridItemContainer}
            title={t('carInfo.model')}
            titleSm={5}
          >
            <Typography>
              {carInfo.model || '-'}
            </Typography>
          </GridTitleBase>
          <GridTitleBase
            classNameContainer={classes.gridItemContainer}
            title={t('carInfo.vin')}
            titleSm={5}
          >
            <Typography>
              {carInfo.vin || '-'}
            </Typography>
          </GridTitleBase>
          <GridTitleBase
            classNameContainer={classes.gridItemContainer}
            title={t('carInfo.year')}
            titleSm={5}
          >
            <Typography>
              {carInfo.year || '-'}
            </Typography>
          </GridTitleBase>
          <GridTitleBase
            classNameContainer={classes.gridItemContainer}
            title={t('carInfo.weight')}
            titleSm={5}
          >
            <Typography>
              {`${carInfo.weight} кг` || '-'}
            </Typography>
          </GridTitleBase>
          <GridTitleBase
            classNameContainer={classes.gridItemContainer}
            title={t('carInfo.owner')}
            titleSm={5}
          >
            <Typography>
              {carInfo.owner || '-'}
            </Typography>
          </GridTitleBase>
          <GridTitleBase
            classNameContainer={classes.gridItemContainer}
            title={t('carInfo.isCarSharing')}
            titleSm={5}
          >
            <Typography>
              {(carInfo.isCarSharing || false) ? 'Возможно' : '-'}
            </Typography>
          </GridTitleBase>
          <GridTitleBase
            classNameContainer={classes.gridItemContainer}
            title={t('carInfo.taxiExpiredAt')}
            style={{ borderBottom: 0 }}
            titleSm={5}
          >
            <Typography className={classes.timeStr}>
              {carInfo.taxiExpiredAt ? moment(carInfo.taxiExpiredAt).format('DD.MM.yyyy') : '-'}
            </Typography>
          </GridTitleBase>
        </Grid>

      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CustomBtn
          color="primary"
          onClick={handleClose}
        >
          {t('others.done')}
        </CustomBtn>
      </DialogActions>
    </Dialog>
  );
}

DialogShowCarInfo.propTypes = propTypes;

export default DialogShowCarInfo;
