import React, { useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';

import { useStyles } from './styles';


const propTypes = {
};

function CustomDialog({
  dialogTitle,
  dialogContent = '',
  agreeTitle,
  disagreeTitle,
  open,
  isLoading = false,
  handleClose = () => {},
  handleAgree = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const dialogTitleStr = dialogTitle || t('others.attention');
  const isDialogContentStr = typeof dialogContent === 'string';

  const onClose = useCallback((e, reason) => {
    if (reason === 'backdropClick' && isLoading) {
      return;
    }

    handleClose();
  }, [handleClose, isLoading]);

  return (
    <Dialog
      className={classes.dialogMain}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography className={classes.titleStr}>
          {dialogTitleStr}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {isDialogContentStr && (
          <Typography id="alert-dialog-description" className={classes.dialogContentStr}>
            {dialogContent}
          </Typography>
        )}
        {!isDialogContentStr && dialogContent}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {disagreeTitle && (
          <CustomBtn
            color="primary"
            onClick={handleClose}
            className={classes.btnDisagree}
            disabled={isLoading}
          >
            {disagreeTitle}
          </CustomBtn>
        )}
        {agreeTitle && (
          <CustomBtn
            btnType="secondary"
            onClick={handleAgree}
            className={classes.btnAgree}
            disabled={isLoading}
          >
            {agreeTitle}
            {isLoading && (
              <CircularProgress
                style={{ marginLeft: 5 }}
                size={18}
                color="inherit"
              />
            )}
          </CustomBtn>
        )}
      </DialogActions>
    </Dialog>
  );
}

CustomDialog.propTypes = propTypes;

export default CustomDialog;
