import React from 'react';

function CarWashIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M12.333 2.793c0 .553-.446 1-1 1-.553 0-1-.447-1-1 0-.667 1-1.8 1-1.8s1 1.133 1 1.8zM9 2.793c0 .553-.447 1-1 1-.553 0-1-.447-1-1 0-.667 1-1.8 1-1.8s1 1.133 1 1.8zM5.667 2.793c0 .553-.447 1-1 1-.554 0-1-.447-1-1 0-.667 1-1.8 1-1.8s1 1.133 1 1.8z"
        fill="#7E8AA7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.298 8.872c.268.183.542.37.62.914.063.517.09 1.037.08 1.558-.009.39-.042.777-.1 1.162v2.024c0 .338-.106.45-.424.45h-.838c-.318 0-.425-.113-.425-.45V13.52H3.76v1.009c0 .337-.106.45-.425.45h-.839c-.317 0-.425-.112-.425-.45v-2.016a9.308 9.308 0 01-.099-1.17c-.011-.523.015-1.046.08-1.564.077-.547.352-.734.62-.916.21-.142.414-.282.518-.587.198-.585.783-2.031 1.535-2.462C5.192 5.553 7.075 5.5 8 5.5c.925 0 2.823.053 3.28.315.761.43 1.298 1.877 1.5 2.469.104.305.31.445.518.588zM6.118 7a1 1 0 00-.894.553l-.39.778a.5.5 0 00.412.722l1.614.116c.759.054 1.521.054 2.28 0l1.614-.116a.5.5 0 00.412-.722l-.39-.778A1 1 0 009.882 7H6.118zm-2.025 3.152l1.484.38a.478.478 0 01-.143.94l-1.502-.077a.5.5 0 01-.475-.524l.013-.26a.5.5 0 01.623-.46zm6.33.38l1.484-.38a.5.5 0 01.623.46l.013.26a.5.5 0 01-.474.523l-1.503.076a.478.478 0 01-.143-.94z"
        fill="#7E8AA7"
      />
    </svg>
  );
}

export default CarWashIcon;
