import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  chartContainer: {
    position: 'relative',
  }
}));

export default {
  useStyles,
};
