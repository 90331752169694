import auth from '@parkly/shared/api/auth';
import { DEFAULT_PATH_PAGES } from '@parkly/shared/config/constants';
import { clearLocalStorage, removeUser, saveUser } from '@parkly/shared/helpers/auth';
import { LOGIN_FAILURE, LOGIN_LOGOUT_PROGRESS, LOGIN_SUCCESS, LOGOUT } from '@parkly/shared/actions/types';

export function login(username, password, history) {
  function success(user) { return { type: LOGIN_SUCCESS, user }; }
  function failure(error) { return { type: LOGIN_FAILURE, error }; }
  function progress(payload = {}) { return { type: LOGIN_LOGOUT_PROGRESS, payload }; }

  return (dispatch) => {
    dispatch(progress());

    // eslint-disable-next-line import/no-named-as-default-member
    auth.login(username, password)
      .then(
        (user) => {
          saveUser(user);
          dispatch(success({
            username,
          }));
          history.push('/');
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  };
}

export function logout(history) {
  function logoutInProgress() { return { type: LOGOUT }; }
  function successLogout() { return { type: LOGOUT }; }
  function failureLogout() { return { type: LOGOUT }; }

  function logouthandler() {
    removeUser();
    clearLocalStorage();
    history.push(DEFAULT_PATH_PAGES.login);
  }

  return (dispatch) => {
    dispatch(logoutInProgress());

    // eslint-disable-next-line import/no-named-as-default-member
    auth.logout()
      .then(
        () => {
          dispatch(successLogout());
          logouthandler();
        },
      )
      .catch(() => {
        dispatch(failureLogout());
        logouthandler();
      });
  };
}

export default {
  login,
  logout,
};
