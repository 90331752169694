import { getPlatformBookings, sendCommunicationRequest } from 'api/bookings';
import {
  GET_PLATFORM_BOOKINGS,
  SEND_COMMUNICATION_REQUEST,
} from './types';

export function getPlatformBookingsAction(param) {
  const {
    platformSign,
    status,
    date,
    search,
    sortBy,
    sortDirection,
    page,
    type,
  } = param || {};

  const platformId = platformSign === 'unselect' ? undefined : platformSign;
  const apiParam = {
    id: platformId,
    status: status.join(','),
    date: date ? date.format('YYYY-MM-DD') : date,
    search,
    sortBy,
    sortDirection,
    page,
    type,
  };

  function getPlatformBookingsRequest(payload) {
    return { type: GET_PLATFORM_BOOKINGS, payload };
  }

  return (dispatch) => {
    dispatch(getPlatformBookingsRequest({
      loading: true,
    }));

    getPlatformBookings(apiParam)
      .then(
        (platformBookings) => {
          dispatch(getPlatformBookingsRequest({
            loading: false,
            platformBookings,
            param,
          }));
        },
        (error) => {
          dispatch(getPlatformBookingsRequest({
            loading: false,
            error,
          }));
        },
      );
  };
}

export function sendCommunicationRequestAction(param) {
  const {
    reservationId,
    message,
  } = param || {};

  function sendCommunication(payload) {
    return { type: SEND_COMMUNICATION_REQUEST, payload };
  }

  return (dispatch) => {
    dispatch(sendCommunication({
      loading: true,
      reservationId,
    }));

    sendCommunicationRequest({
      reservationId,
      message,
    })
      .then(
        (result) => {
          dispatch(sendCommunication({
            loading: false,
            reservationId,
            result,
          }));
        },
        (error) => {
          dispatch(sendCommunication({
            loading: false,
            reservationId,
            error,
          }));
        },
      );
  };
}

export default {
  getPlatformBookingsAction,
  sendCommunicationRequestAction,
};
