/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme, withStyles } from '@material-ui/core/styles';

import Logo from '@parkly/shared/components/atoms/Logo';
import SitesIcon from '@parkly/shared/components/atoms/icons/SitesIcon';
import AnalyticsIcon from '@parkly/shared/components/atoms/icons/AnalyticsIcon';
import AnalyticsIconActive from '@parkly/shared/components/atoms/icons/AnalyticsIconActive';
import SitesIconActive from '@parkly/shared/components/atoms/icons/SitesIconActive';
import OperatorsIcons from '@parkly/shared/components/atoms/icons/OperatorsIcons';
import OperatorsIconsActive from '@parkly/shared/components/atoms/icons/OperatorsIconsActive';
import BookingsIcon from '@parkly/shared/components/atoms/icons/BookingsIcon';
import BookingsIconActive from '@parkly/shared/components/atoms/icons/BookingsIconActive';
import LogOutIcon from '@parkly/shared/components/atoms/icons/LogOutIcon';
import SubscriptionsActiveIcon from '@parkly/shared/components/atoms/icons/SubscriptionsActiveIcon';
import SubscriptionsInactiveIcon from '@parkly/shared/components/atoms/icons/SubscriptionsInactiveIcon';
import NavigationLink from '@parkly/shared/components/atoms/NavigationLink';
import MobilePaymentsIcon from '@parkly/shared/components/atoms/icons/MobilePaymentsIcon';
import MobilePaymentsIconActive from '@parkly/shared/components/atoms/icons/MobilePaymentsIconActive';

import { PATH_PAGES } from 'config/constants';
import { DEFAULT_PATH_PAGES } from '@parkly/shared/config/constants';
import { getLinkStrAnalytics, getLinkStrOperators, getLinkStrPlatform } from 'helpers';

import { useStyles } from './styles';

const CustomListItemMenu = withStyles({
  root: {
    height: 96,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderRadius: 12,
    '& .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary': {
      fontSize: 12,
    },
    padding: 0,
    textAlign: 'center',
  },

})(ListItem);

/* Main component */

const propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  drawerWidth: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  userRightFlags: PropTypes.object,
};

function LeftPageMenu({
  open = false,
  variant = 'permanent',
  drawerWidth = 103,
  userRightFlags = {},
  ...props
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles({ drawerWidth });

  const {
    isReadAllPlatforms,
    isReadSomePlatform,
    readPlatformInfoRightPlatforms,

    isReadOperatorsList,
    isCreateUpdOperators,
    isReadOperatorsInfo,
    isReadActBookList,
    isReadHistBookList,

    isReadAnalyticsDistributionByDuration,
    isReadAnalyticsDuration,

    isReadReportDocuments,
  } = userRightFlags || {};

  const isPlatformsBtn = isReadAllPlatforms || isReadSomePlatform;
  const isOperatorsBtn = isReadOperatorsList || isCreateUpdOperators || isReadOperatorsInfo;
  const isBookingsBtn = isReadActBookList || isReadHistBookList;
  const isAnalyticsBtn = isReadAnalyticsDistributionByDuration || isReadAnalyticsDuration;
  const isDocumentsBtn = isReadReportDocuments;

  return (
    <Drawer
      variant={variant}
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      {...props}
    >
      <div className={classes.container}>
        <div className={classes.toolbar} />
        <Divider />
        <List className={classes.drawerList}>
          <NavigationLink
            to="/"
          >
            <CustomListItemMenu button key="Logo">
              <Logo
                letterFill={theme.palette.primary.contrastText}
                backgroundFill="transparent"
              />
            </CustomListItemMenu>
          </NavigationLink>
          {isPlatformsBtn && (
            <NavigationLink
              className={classes.navigationLink}
              to={getLinkStrPlatform({
                isReadAllPlatforms,
                isReadSomePlatform,
                readPlatformInfoRightPlatforms,
              })}
            >
              { ({ isActive }) => (
                <CustomListItemMenu className={isActive ? classes.activeCustomListItemMenu : ''} divider button>
                  { isActive ? <SitesIconActive /> : <SitesIcon /> }
                  <ListItemText className={classes.text} primary={t('navigation.platform')} />
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {isOperatorsBtn && (
            <NavigationLink
              className={classes.navigationLink}
              to={getLinkStrOperators({
                isReadOperatorsList,
                isCreateUpdOperators,
                isReadOperatorsInfo,
              })}
            >
              { ({ isActive }) => (
                <CustomListItemMenu className={isActive ? classes.activeCustomListItemMenu : ''} divider button>
                  { isActive ? <OperatorsIconsActive /> : <OperatorsIcons /> }
                  <ListItemText className={classes.text} primary={t('navigation.operators')} />
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {isBookingsBtn && [
            <NavigationLink
              key={PATH_PAGES.bookings}
              className={classes.navigationLink}
              to={PATH_PAGES.bookings}
            >
              { ({ isActive }) => (
                <CustomListItemMenu className={isActive ? classes.activeCustomListItemMenu : ''} divider button>
                  { isActive ? <BookingsIconActive /> : <BookingsIcon /> }
                  <ListItemText className={classes.text} primary={t('navigation.bookings')} />
                </CustomListItemMenu>
              )}
            </NavigationLink>,
            <NavigationLink
              key={PATH_PAGES.subscriptions}
              className={classes.navigationLink}
              to={PATH_PAGES.subscriptions}
            >
              { ({ isActive }) => (
                <CustomListItemMenu className={isActive ? classes.activeCustomListItemMenu : ''} divider button>
                  { isActive ? <SubscriptionsActiveIcon /> : <SubscriptionsInactiveIcon /> }
                  <ListItemText className={classes.text} primary={t('navigation.subscriptions')} />
                </CustomListItemMenu>
              )}
            </NavigationLink>,
            <NavigationLink
              key={PATH_PAGES.mobilePayments}
              className={classes.navigationLink}
              to={PATH_PAGES.mobilePayments}
            >
              { ({ isActive }) => (
                <CustomListItemMenu className={isActive ? classes.activeCustomListItemMenu : ''} divider button>
                  { isActive ? <MobilePaymentsIconActive /> : <MobilePaymentsIcon /> }
                  <ListItemText className={classes.text} primary={t('navigation.mobilePayments')} />
                </CustomListItemMenu>
              )}
            </NavigationLink>,
          ]}
          {isAnalyticsBtn && (
            <NavigationLink
              className={classes.navigationLink}
              to={getLinkStrAnalytics({
                isReadAllPlatforms,
                isReadSomePlatform,
                readPlatformInfoRightPlatforms,
              })}
            >
              { ({ isActive }) => (
                <CustomListItemMenu className={isActive ? classes.activeCustomListItemMenu : ''} divider button>
                  { isActive ? <AnalyticsIconActive /> : <AnalyticsIcon /> }
                  <ListItemText className={classes.text} primary={t('analytics.analytics')} />
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          <NavigationLink className={`${classes.navigationLink} ${classes.logoutBtn}`} to={DEFAULT_PATH_PAGES.logout}>
            <CustomListItemMenu divider button>
              <LogOutIcon />
            </CustomListItemMenu>
          </NavigationLink>
        </List>
      </div>
    </Drawer>
  );
}

LeftPageMenu.propTypes = propTypes;

export default LeftPageMenu;
